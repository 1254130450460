/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, Input, Select, } from '~frontendComponents/Generic/index.js';
import {
    addToNormalize,
    getTableLowestPossibleIndex,
    getInterfacesTypesCreatable,
    getHlcfgRowUuid,
    updateInterface,
    getIsCluster,
    getVpnServiceFromDevice
} from '~frontendDucks/hlcfgEditor/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import {
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_WANLAN,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_MAC,
    INTERFACES_COLUMNS_TAG,
    INTERFACES,
    DHCP_VALUE,
    HW_IFACE_TYPE,
    VLAN_IFACE_TYPE,
    BRIDGE_IFACE_TYPE, NODE_A_ID,
    SELECTABLE_TABLE_INTERFACES,
    userSetting,
    FAKE_FUNCTION,
    INTERFACES_COLUMNS_BOND_MODE,
    BOND_IFACE_TYPE,
    INTERFACES_COLUMNS_PHYSICALIFACE,
} from '~frontendConstants/index.js';
import { getMyNode } from '~frontendRoot/ducks/clusterSetup/index.js';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';

import { getIcon } from '../rowsUtils.js';
import { selectOptionsListWanLan, getInternalExternal } from '../Row/Row.tsx';


const RowUnknown = ({
    spacing,
    uuid }: CreateRowPropsType) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [ allColumns, setColumn ] = useUserSettingPathSetter(userSetting.columnsByTable);

    const [ getColumnsShow, getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_INTERFACES);
    const data: any = useInterfacesUnknown(uuid);
    const numberOfVlans = useSelector(state => getTableLowestPossibleIndex(state, VLAN_IFACE_TYPE, 'vlanTag'));
    const numberOfBridges = useSelector(state => getTableLowestPossibleIndex(state, BRIDGE_IFACE_TYPE, 'ifaceTag'));
    const numberOfBonds = useSelector(state => getTableLowestPossibleIndex(state, BOND_IFACE_TYPE, 'ifaceTag'));
    const types = useSelector(state => getInterfacesTypesCreatable(state));
    const myNode = useSelector(state => getMyNode(state));
    const isCluster = useSelector(getIsCluster);


    const updateRow = useCallback((uuid) => {
        dispatch(updateInterface({ uuid }));
    }, [ dispatch ]);


    const addRow = useCallback((value, addingAfter?: boolean, extraValues?: any, newUuid?: string) => {
        dispatch(addToNormalize({
            type: value,
            typeId: INTERFACES,
            addingAfter,
            newUuid,
            successText: `widgets:${value}.added`,
            extraValues: extraValues || (value === VLAN_IFACE_TYPE ? { vlanTag: numberOfVlans } :
                value === BRIDGE_IFACE_TYPE ? { ifaceTag: numberOfBridges } : value === BOND_IFACE_TYPE ?
                    { ifaceTag: numberOfBonds } : undefined)

        }));
        if (!getColumnsShow(INTERFACES_COLUMNS_TAG)) {
            setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_TAG ], value: true });
        }
        if (value === VLAN_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_VLANIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_VLANIFACE ], value: true });
            }
        }
        if (value === BRIDGE_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE ], value: true });
            }
        }
        if (value === BOND_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_BOND_MODE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_BOND_MODE ], value: true });
            }
            if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE ], value: true });
            }
        }
    }, [ dispatch, numberOfVlans, numberOfBonds, numberOfBridges, getColumnsShow, setColumn ]);

    const addRowUnknow = useCallback((event, off) => {
        let uuid;
        if (data.types?.includes('vlan')) {
            uuid = getHlcfgRowUuid(VLAN_IFACE_TYPE);
            addRow(VLAN_IFACE_TYPE, true, { vlanTag: data.name.split('vlan')[1], __off: off }, uuid);
        }
        if (data.types?.includes('bridge')) {
            uuid = getHlcfgRowUuid(BRIDGE_IFACE_TYPE);
            addRow(BRIDGE_IFACE_TYPE, true, { ifaceTag: data.name.split('bridge')[1], __off: off }, uuid);
        }
        if (data.types?.includes('hw')) {
            uuid = getHlcfgRowUuid(HW_IFACE_TYPE);
            addRow(HW_IFACE_TYPE, true, { device: { [myNode]: data.name }, __off: off }, uuid);
        }
        updateRow(uuid);
    }, [ data, myNode, updateRow, addRow ]);


    const addRowUnknowAndHide = useCallback((event) => {
        addRowUnknow(event, true);
    }, [ addRowUnknow ]);

    const { name, physicalLayerUp, state, addresses4, addresses6, addressMac, device,
        color, isVpn, isInternal, isExternal
    } = data;

    return (
        <>
            <tr
                className="dataTableWidget__RowAdd"
                key={uuid + 'add'}
            >
                <td
                    className="dataTableWidget__RowAddRule"
                    colSpan={getColumnsSelectedLength()}
                >
                    <div className="dataTableWidget__RowAddButtons">
                        {
                            (types || []).map(item => {
                                if (isCluster && item === BRIDGE_IFACE_TYPE) {
                                    return;
                                }
                                return (
                                    <MDBBtn
                                        className="dataTableWidget__RowAddButtons--button"
                                        data-cy={item + 'Add'}
                                        key={uuid + item + ' above'}
                                        onClick={event => {
                                            event.preventDefault();
                                            addRow(item);
                                        }}
                                    >
                                        <Icon name="plus" /> {t(`widgets:${item}.${item}`)}
                                    </MDBBtn>
                                );
                            })
                        }
                    </div>
                </td>
            </tr>
            <tr
                className={classNames(
                    'network__rowNew',
                    { 'dataTableWidget__Row--unknown': !isVpn },
                )}
                key={uuid}
            >
                {getColumnsShow(INTERFACES_COLUMNS_STATE) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                    >
                        {
                            getIcon(state)
                        }
                        {
                            getIcon(physicalLayerUp ? 'connected' : 'disconnected')
                        }
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_TYPE) &&

                    <td className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    >
                        {getIcon(name?.startsWith('vlan') ? 'vlan' : name?.startsWith('br') ?
                            'bridge' : 'hw')}
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_NAME) &&

                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                    >
                        <Input
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { 'h-2': name },
                            )}
                            disabled
                            id={'interfaceName' + uuid}
                            isName
                            name="name"
                            onChange={FAKE_FUNCTION}
                            placeholder={device}
                            value={name}
                            withoutBorder
                        />
                        {Boolean(name) &&
                            <small
                                className="network__rowCell--deviceName"
                            >
                                {device}
                            </small>
                        }
                        <div
                            className={color && 'network__rowCell--deviceColor'}
                            style={
                                color ? {
                                    backgroundColor: color,
                                } : {}
                            }
                        />
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_IP4) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >

                        <Select
                            className="dataTableWidget__RowInput"
                            dhcp={addresses4?.some(item => item.dhcp)}
                            editable={false}
                            fake
                            id="shared"
                            isCreatable={!addresses4?.some(item => item.dhcp)}
                            isMulti
                            isRow
                            name="address"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={FAKE_FUNCTION}
                            options={[ { label: 'DHCP', value: DHCP_VALUE } ]}
                            value={addresses4?.some(item => item.dhcp) ? [ DHCP_VALUE ].concat(addresses4?.map(item =>
                                `${item.address}/${item.mask}`)) :
                                addresses4?.map(item => `${item.address}/${item.mask}`)}
                        />
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_WANLAN) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                            'network__rowCell--rightBorder',
                        )}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            fake
                            id={'wanLan' + uuid}

                            name="wanLanName"
                            noDropdownIndicator
                            noWrap
                            onChange={FAKE_FUNCTION}
                            options={selectOptionsListWanLan}
                            paste={false}
                            value={getInternalExternal(isInternal, isExternal)}
                            withoutBorder
                        />
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_TAG) &&
                    <td />
                }
                {getColumnsShow(INTERFACES_COLUMNS_BOND_MODE) &&
                    <td />
                }
                {getColumnsShow(INTERFACES_COLUMNS_VLANIFACE) &&
                    <td />
                }
                {getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE) &&
                    <td />
                }
                {getColumnsShow(INTERFACES_COLUMNS_ANTISPOOFING) &&
                    <td />
                }
                {getColumnsShow(INTERFACES_COLUMNS_IP6) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            disabled
                            id={'vlansAddress' + name}
                            isCreatable
                            isMulti
                            isRow
                            name="address"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={FAKE_FUNCTION}
                            value={addresses6?.map(item => `${item.address}/${item.prefix}`)}
                        />
                    </td>
                }
                {getColumnsShow(INTERFACES_COLUMNS_MAC) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            disabled
                            id="nodeA"
                            isCreatable={addressMac?.[NODE_A_ID] === ''}
                            isMulti
                            isRow
                            name="mac"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={FAKE_FUNCTION}
                            value={addressMac ? [ addressMac ] : []}
                        />
                    </td>
                }
                <td
                    className={classNames(
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    {!isVpn &&
                        <div className="network__rowNewAddButton">
                            <IconWithTooltip
                                btnClassName="network__rowNewAddButton--button"
                                btnText="widgets:network.unknown.title"
                                name="plus"
                                onClick={addRowUnknow}
                                tooltipText="widgets:network.unknown.desc"
                            />
                            <IconWithTooltip
                                btnClassName="network__rowNewAddButton--button"
                                btnText="widgets:network.hide.title"
                                name="plus"
                                onClick={addRowUnknowAndHide}
                                tooltipText="widgets:network.hide.desc"
                            />
                        </div>
                    }
                </td>
                <td />
                <td />
                <td />
            </tr>
            <tr
                className="dataTableWidget__RowAddEnd"
                key={uuid + 'addEnd'}
            >
                <td
                    className="dataTableWidget__RowAddRule"
                    colSpan={getColumnsSelectedLength()}
                >
                    <div className="dataTableWidget__RowAddButtons">
                        {
                            (types || []).map(item => (
                                <MDBBtn
                                    className="dataTableWidget__RowAddButtons--button"
                                    data-cy={item + 'Add'}
                                    key={uuid + item + ' above'}
                                    onClick={event => {
                                        event.preventDefault();
                                        addRow(item, true);
                                    }}
                                >
                                    <Icon name="plus" /> {t(`widgets:${item}.${item}`)}
                                </MDBBtn>
                            ))
                        }
                    </div>
                </td>
            </tr>
        </>

    );
};

const useInterfacesUnknown = (device: string) => {
    const { data: networkInterfacesInfo = [] } = useNetworkInterfacesQuery();
    const vpn = useSelector(state => getVpnServiceFromDevice(state, device));
    if (device.startsWith('tun') || device.startsWith('tap')) {
        const interfaceDevice = networkInterfacesInfo.find(item => item.name === device);
        return {
            ...interfaceDevice,
            isVpn: true,
            name: vpn?.name,
            device: interfaceDevice?.name,
            color: vpn?.color,
            isInternal: vpn?.isInternal,
            isExternal: vpn?.isExternal
        };
    }
    return networkInterfacesInfo.find(item => item.name === device) || {};
};

export default RowUnknown;
