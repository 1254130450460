/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  MDBCard, MDBCardHeader, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { Input, Select, } from '~frontendComponents/Generic/index.js';
import { setGlcfgValueScalar, getGlcfgValueHook, getGlcfgSchemaHook, setGlcfgObjectValue
} from '~frontendDucks/hlcfgEditor/index.js';
import {  setAuthenticationLoginModal, testAuthenticationStart,
    getIsAuthenticationInitialized, authenticationLoadInitialization,
} from '~frontendDucks/authentication/index.js';
import Message from '~frontendComponents/Message/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { DOMAIN_CONTROLLERS, HOSTNAME, PROXY, REALM } from '~frontendRoot/constants/index.js';


const AuthenticationLogin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const showTest = useSelector<any, boolean>(getIsAuthenticationInitialized);
    const domainControllers =  useSelector(getGlcfgValueHook(DOMAIN_CONTROLLERS));
    const domainControllersSchema = useSelector(getGlcfgSchemaHook(DOMAIN_CONTROLLERS));
    const realm = useSelector(getGlcfgValueHook(REALM));
    const realmSchema = useSelector(getGlcfgSchemaHook(REALM));


    const { alternativeFqdn } =  useSelector(getGlcfgValueHook(PROXY));
    const hostname =  useSelector(getGlcfgValueHook(HOSTNAME));


    useEffect(() => {
        dispatch(authenticationLoadInitialization());
    }, [ dispatch ]);


    const onChangeValue = useCallback(({ value, id }) => {
        dispatch(setGlcfgValueScalar(id, value));
    }, [ dispatch ]);

    const onChangeValueObject = useCallback(({ value, id, name }) => {
        dispatch(setGlcfgObjectValue(name, value, id));
    }, [ dispatch ]);


    const testAuth = useCallback(() => {
        dispatch(testAuthenticationStart({ preInit: false }));
    }, [ dispatch ]);


    const openModal = useCallback(() => {
        dispatch(setAuthenticationLoginModal({ name: 'isOpenLogin', value: true }));
    }, [ dispatch ]);

    return (
        <MDBCard>
            <MDBCardHeader>
                <MDBRow>
                    <MDBCol>
                        <Message message="widgets:Authentication.title" />
                    </MDBCol>
                    <MDBCol
                        className="authentication__loginIcons"

                    >
                        <IconWithTooltip
                            className={`icon--black ${showTest ? '' : 'displayNone'}`}
                            iconSize="sm"
                            link
                            name="play"
                            onClick={testAuth}
                            tooltipText="widgets:Authentication.actions.test"
                        />
                        <IconWithTooltip
                            className="icon--black"
                            iconSize="sm"
                            link
                            name="swap-vertical"
                            onClick={openModal}
                            tooltipText="widgets:Authentication.actions.connect"
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
                <Input
                    className="mb-3"
                    id={REALM}
                    label={t('widgets:Authentication.domain.title')}
                    name="domain"
                    onChange={onChangeValue}
                    schema={realmSchema}
                    value={realm}
                />

                <Input
                    className="mb-3"
                    id="alternativeFqdn"
                    label={t('widgets:Authentication.alternativeFqdn.title')}
                    name={PROXY}
                    onChange={onChangeValueObject}
                    placeholder={hostname}
                    value={alternativeFqdn}
                />

                <Select
                    id={DOMAIN_CONTROLLERS}
                    isCreatable
                    isMulti
                    label={t('widgets:Authentication.domainControllers.title')}
                    name="domainControlerAuthentication"
                    noDropdownIndicator
                    noOptionsMessage
                    onChange={onChangeValue}
                    schema={domainControllersSchema}
                    value={stringifyAddress(domainControllers)}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default  AuthenticationLogin;
