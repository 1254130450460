/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import React from 'react';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { isDeepEqual } from '~commonLib/objectUtils.ts';
import { getEnumTranslationPrefixPath, HlcfgSelectProps, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { EnumArraySelect } from '~frontendComponents/Generic/SelectV2/EnumSelect.tsx';


export const DataCiphersSelect = ({
    pathGetter
}: Pick<HlcfgSelectProps, 'pathGetter'>) => {
    const { id, schema, value, setValue, path } = useHlcfgInputModel(pathGetter);

    const { t } = useTranslation();
    switch (schema.type) {
    case 'array': {
        assert(value === undefined || Array.isArray(value), 'Invalid value type according to schema');
        if (schema.additionalItems.enum) {
            const staticValues = schema.items.map(it => it.enum[0]);
            return (
                <EnumArraySelect
                    enumeration={schema.additionalItems.enum}
                    enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
                    id={id}
                    label={t('differs:tables.openvpnRas.dataCiphers.title')}
                    maxItemsSelected={schema.maxItems}
                    message="data-ciphers"
                    onChange={values => {
                        if (isDeepEqual(values, staticValues)) {
                            return setValue(undefined);
                        }
                        setValue(values);
                    }}
                    staticValues={staticValues}
                    value={value ?? staticValues}
                />
            );
        }
        throw new Error('Unexpected data ciphers schema');
    }
    default:
        throw new Error('Unexpected data ciphers schema');
    }
};
