/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import getValue from 'get-value';
import { DefaultRootState } from 'react-redux';
import { createSelector } from 'reselect';

import { GLCFG_SETTER_PREFIX, NEW_ROW_CONSTANT } from '~frontendConstants/constants.ts';
import { TABLES } from '~frontendRoot/constants/index.js';
import { createSelectorArrayOfObjectsShallow, createSelectorSchemaObjectShallow } from '~frontendRoot/lib/reduxUtils.ts';
import { getTheLowestPossibleNumber } from '~frontendRoot/lib/stringUtils.js';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/index.ts';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { SCHEMA_TYPE_ROW_ID } from '~sharedLib/schemaTypes.ts';


export const getState = (rootState: DefaultRootState) => rootState.hlcfgEditor;

export const getGlcfgTree = rootState => getState(rootState).glcfgTree;

export const getInitGlcfgTree = rootState => getState(rootState).initGlcfgTree;

export const getVerificationErrors = rootState => getState(rootState).verificationErrors;

const getGlcfgNodeValue = (rootState, key) => getValue(getGlcfgTree(rootState), key);

const getInitGlcfgNodeValue = (rootState, key) => getValue(getInitGlcfgTree(rootState), key);

const getSchemaRoot = rootState => getState(rootState).glcfgSchema;

export const getSchemaNormalizeTableParams = (schema) => schema?.additionalProperties?.properties;

// configuration getters and setters
const getGlcfgNodeSchema = (state, key) => {
    const schemaRoot = getSchemaRoot(state);
    return schemaRoot[key];
};

export const getGlcfgValue = (rootState, glcfgKey) => getGlcfgNodeValue(rootState, glcfgKey);

export const getInitGlcfgValue = (rootState, glcfgKey) => getInitGlcfgNodeValue(rootState, glcfgKey);

export const getGlcfgSchema = (rootState, glcfgKey) => getGlcfgNodeSchema(rootState, glcfgKey);

export const getGetGlcfgSetterActionType =  (prefix) =>
    glcfgKey =>
        prefix + glcfgKey;


export const getGlcfgSetterActionType = getGetGlcfgSetterActionType(GLCFG_SETTER_PREFIX);

export const xToGlcfg = (schema) => schema?.items?.[SCHEMA_TYPE_ROW_ID] || schema?.[SCHEMA_TYPE_ROW_ID];
const getItemFromTableById = (tables, id) => {
    if (typeof id === 'string' && id !== NEW_ROW_CONSTANT && id) {
        const type = hlcfgTableNameByRowId(id);
        return tables[type][id];
    }
    return EMPTY_IMMUTABLE_OBJ;
};

const getSchemaFromTableById = (tables, id) => {
    if (typeof id === 'string') {
        const type = hlcfgTableNameByRowId(id);
        return tables?.properties[type]?.additionalProperties?.properties;
    }
    return EMPTY_IMMUTABLE_OBJ;
};

export const getTables = createSelector((state, glcfgGetter) => glcfgGetter(state, TABLES), (tables) => tables);
export const getTablesSchema = createSelector((state) => getGlcfgSchema(state, TABLES), (tables) => tables);


export const getDefaultNormalizedTableGetters = (glcfgGetter = getGlcfgValue) => {
    const getItem = (state, id) => getItemFromTableById(getTables(state, glcfgGetter), id);
    const getters = {
        getByIdType: createSelector((state, type) => {
            return getTables(state, glcfgGetter)[type];
        }, byId => byId),
        getLengthByType: createSelector((state, type) => {
            return Object.keys(getTables(state, glcfgGetter)[type] || EMPTY_IMMUTABLE_OBJ)?.length;
        }, length => length),
        getLowestPossibleIndex: createSelector((state, type, prop) => {
            return getTheLowestPossibleNumber(Object.values(
                getTables(state, glcfgGetter)[type]
            )?.map((item: any) => item[prop]));
        }, number  => number),
        getByIdTypes: createSelectorSchemaObjectShallow((state, types) => {
            let combined = {};
            types.forEach(item => combined = {
                ...combined,
                ...getTables(state, glcfgGetter)[item]
            });
            return combined;
        }, byId => byId),
        getItem: getItem,
        getItems: (state, ids) => ids.map(item => getItem(state, item)),
        getItemSiblings: createSelectorSchemaObjectShallow(
            (state, id) => getTables(state, glcfgGetter)[hlcfgTableNameByRowId(id)],
            items => items
        ),
        getSchema: (state, id) => getSchemaFromTableById(getTablesSchema(state), id),
        getSchemaGetter: (id) => (state) => getSchemaFromTableById(getTablesSchema(state), id),
        getHlcfgPath: (state, id) => {
            if (!id) {
                return '';
            }
            const glcfgSchema = getGlcfgSchema(state, TABLES);
            return `${glcfgSchema.hlcfgPath}.${hlcfgTableNameByRowId(id)}.${id}`;
        }
    };
    return getters;
};
export const getNormalizedTableGetters = (glcfgkey: string, withoutParent?: boolean, glcfgGetter = getGlcfgValue) => {
    const getItem = (state, id) => getItemFromTableById(getTables(state, glcfgGetter), id);
    const getters = {
        //parent
        getSchemaParent: createSelector((state) => getGlcfgSchema(state, glcfgkey), schema => schema),
        getFromParentChildTypes: (state) => xToGlcfg(getters.getSchemaParent(state)),
        getIds: createSelector((state) => glcfgGetter(state, glcfgkey), (ids) => ids || EMPTY_IMMUTABLE_ARR),
        //childs all
        getByIdType: createSelector((state, type?: string) => {
            if (type) {
                return getTables(state, glcfgGetter)[type];
            }
            const typeArray = withoutParent ? [ glcfgkey ] : xToGlcfg(getters.getSchemaParent(state));
            return getTables(state, glcfgGetter)[typeArray[0]];
        }, byId => byId),
        getItems: createSelectorArrayOfObjectsShallow(state => getters.getIds(state).map(id => getItem(state, id)),
            items => items),
    };
    return getters;
};
