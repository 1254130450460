/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

import { Icon, Switch } from '~frontendComponents/Generic/index.js';
import { testProps } from '~commonLib/PageObjectMap.ts';

import RowMenuButton, { RowMenuButtonType } from './RowMenuButton.tsx';
import { detailBtnPoDef } from './pageObjectMap.ts';


const RowMenuAndSwitchProps = {
    onChange: PropTypes.func,
    __off: PropTypes.oneOf([ true, undefined ]),
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    spacing: PropTypes.string,
    settings: PropTypes.func,
    menu: PropTypes.bool,
    showDetail: PropTypes.bool,
    small: PropTypes.bool,
    tooltipText: PropTypes.string,
};

type RowMenuAndSwitchType = InferProps<typeof RowMenuAndSwitchProps>;

interface RowMenuAndSwitchInterface extends RowMenuAndSwitchType, RowMenuButtonType {}

const RowMenuAndSwitch = ({ colorFunc, deleteFunc, copyFunc,
    __off, id, onChange, disabled = false, name = '__off', spacing, settings, menu = true,
    deleteButtonDisabled, color, showDetail, tooltipText }: RowMenuAndSwitchInterface) => {
    return (
        <td
            className={classNames(
                'dataTableWidget__cell',
                { [`dataTableWidget__cell--${spacing}`]: spacing },
                'dataTableWidget__cell--stickyMenu',
            )}
            style={
                {
                    backgroundColor: color ?? '',
                }
            }
        >
            <RowMenuAndSwitchBody
                __off={__off}
                color={color}
                colorFunc={colorFunc}
                copyFunc={copyFunc}
                deleteButtonDisabled={deleteButtonDisabled}
                deleteFunc={deleteFunc}
                disabled={disabled}
                id={id}
                menu={menu}
                name={name}
                onChange={onChange}
                settings={settings}
                showDetail={showDetail}
                spacing={spacing}
                tooltipText={tooltipText}
            />
        </td>
    );
};

export const RowMenuAndSwitchBody = ({ spacing, settings, showDetail, id, onChange,
    __off, name, tooltipText, disabled, menu = true, color, colorFunc, copyFunc,
    deleteButtonDisabled, deleteFunc, popupOnRight }: RowMenuAndSwitchInterface) => {
    return (
        <div className={classNames(
            { [`dataTableWidget__cell--${spacing}`]: spacing }
        )}
        >
            {settings && (
                <Icon
                    className={classNames(
                        { 'icon--primary': showDetail }
                    )}
                    datacy={`settings${id}`}
                    name="settings-outline"
                    onClick={settings}
                    size="sm"
                    {...testProps(detailBtnPoDef.id, { status: showDetail ? 'on' : 'off' })}
                />
            )}
            {onChange &&
                <Switch
                    align="left"
                    checked={__off}
                    className={classNames(
                        'dataTableWidget__RowInput',
                        'dataTableWidget__RowInput--sm',
                        'dataTableWidget__cell--stickyMenu--off',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    disabled={disabled}
                    //if there is a name (enabled) then we want to send true/false
                    id={id}
                    mini
                    name={name}
                    onChange={onChange}
                    tooltipText={tooltipText}
                    withoutBorder
                    withoutLabelMargins
                    withoutMinWidhtHeight
                />
            }
            {menu &&
                <RowMenuButton
                    color={color}
                    colorFunc={colorFunc}
                    copyFunc={copyFunc}
                    deleteButtonDisabled={deleteButtonDisabled}
                    deleteFunc={deleteFunc}
                    popupOnRight={popupOnRight}

                />
            }
        </div>
    );
};

export default RowMenuAndSwitch;
