/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MDBCardHeader, MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {  Input, Select } from '~frontendComponents/Generic/index.js';
import { getMultihomingUuid, getComponentEnabled,
    setNormalize, getTableSchema, } from '~frontendDucks/hlcfgEditor/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { GW4, MULTIHOMING, SECONDARY_GW, PRIMARY_GW } from '~frontendConstants/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { DEFAULT_MULTIHOMING_CONSIDER_DOWN_SECONDS } from '~sharedConstants/index.ts';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useDhcpOnExternal } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


export const GatewayMultihoming = (props: {index: number, type: string, update: boolean}) => {
    const { index, type, update } = props;
    const dhcpOnExternal = useDhcpOnExternal();
    const getMultihoming = useMemo(getMultihomingUuid, []);
    const multihomingValues = useSelector(state => getMultihoming(state, type));
    // @ts-expect-error create selector types do not work because importing from JS. Fix when changed to TS.
    const multihomingEnabled = useSelector(state => getComponentEnabled(state, MULTIHOMING));
    const schema = useSelector(state =>  getTableSchema(state, type));

    const { t } = useTranslation();
    const setObjectEvent = useDispatchCallback(({ value, name, key }) => {
        return setNormalize({ uuid: multihomingValues.id, key: key || name, value });
    }, [ multihomingValues ]);

    const setTimeout = setTimeoutComponentFunction({ setValue: setObjectEvent });
    const timeoutValidator = setTimeoutComponentValidator(t);

    if (!multihomingEnabled) {
        return null;
    }
    return (
        <MDBCard className={update ? 'bounce-in-left' : ''}>
            <MDBCardHeader className="profiles__title">
                {t(`widgets:Network.multihoming.${index ? SECONDARY_GW : PRIMARY_GW}.title`)}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <Select
                            datacy={'multihomingAddr' + index}
                            id={`gw${type}`}
                            isCreatable={!dhcpOnExternal}
                            isMulti
                            label={glcfgDefinition[GW4].title}
                            name={'addr'}
                            noOptionsMessage
                            onChange={setObjectEvent}
                            options={dhcpOnExternal ? [ {
                                value: dhcpOnExternal.gateway,
                                label: t('widgets:global.valueFromDhcp'),
                                tooltipValues: [ dhcpOnExternal.gateway ],
                                notRemovable: true,
                            } ] : undefined}
                            schema={schema.addr}
                            value={dhcpOnExternal ? [ dhcpOnExternal.gateway ] : multihomingValues.addr}
                        />

                        <Select
                            datacy={'multihomingPingIps' + index}
                            id="gwId"
                            isCreatable
                            isMulti

                            label={t('widgets:Network.multihoming.servers')}
                            name="pingIps"
                            onChange={setObjectEvent}
                            schema={schema.pingIps}
                            value={multihomingValues.pingIps}
                        />

                        <Input
                            datacy={'multihomingPing' + index}
                            label={t('widgets:Network.multihoming.ping')}
                            name="considerDownAfterSeconds"
                            onChange={setTimeout}
                            placeholder={DEFAULT_MULTIHOMING_CONSIDER_DOWN_SECONDS + 's'}
                            validator={timeoutValidator}
                            value={multihomingValues?.considerDownAfterSeconds ?
                                valueFormatter.formatSeconds(multihomingValues.considerDownAfterSeconds) :
                                ''}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
