/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { backendDataPost } from '~frontendLib/backendApiCalls.ts';
import { QueryDef } from '~frontendQueries/utils.ts';


export const openvpnQueries = {
    crlTest: {
        queryKey: [ 'crlTest' ],
        requiresAdditionalQueryKeys: true,
        queryFn: backendDataPost('/cfg/tryVpnCrlUrl'),
    },
} satisfies Record<string, QueryDef>;
