/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { MDBCard, MDBCardBody, MDBRow, MDBCardTitle, MDBCol } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { InferProps } from 'prop-types';
import { ValuesType } from 'utility-types';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { Icon  } from '~frontendComponents/Generic/index.js';
import { getStatusUuid, ipsecStatusRequest, StatusType, getIsLoading } from '~frontendDucks/ipsec/index.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import  Message  from '~frontendComponents/Message/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/WhenAdvanced.tsx';
import { IconNameType } from '~frontendComponents/Generic/Icon/Icon.tsx';


type IpsecStatusTypes = keyof Omit<ValuesType<StatusType>, 'isEstablished'>;


const CreateStatus = ({ status, type }: {status: ValuesType<StatusType>, type: IpsecStatusTypes }) => {
    const { icon, formater } = structure[type];
    return (
        <div>
            <IconWithTooltip
                className={'icon--textColor'}
                iconSize="md"
                name={icon}
                tooltipText={`widgets:global.${type}`}
            />
            <small className="mr-1">
                <Message
                    message={`widgets:global.${type}`}
                />:
            </small>
            {status[type] ? formater(status[type]) : '-'}
        </div>
    );

};

type StatusStructure = {
        icon: IconNameType;
        formater: (value: any) => string;
};

const structure: Record<IpsecStatusTypes, StatusStructure> = {
    'bytesIn': {
        icon: 'arrow-down',
        formater: valueFormatter.formatBytesPerSec,
    },
    'bytesOut': {
        icon: 'arrow-up',
        formater: valueFormatter.formatBytesPerSec,
    },
    'packetsIn': {
        icon: 'package-down',
        formater: (value) => value + ' /s',
    },
    'packetsOut': {
        icon: 'package-up',
        formater: (value) => value + ' /s',
    },
    'establishedSeconds': {
        icon: 'clock-outline',
        formater: valueFormatter.formatSeconds,
    },
    'lifeTime': {
        icon: 'clock-outline',
        formater: valueFormatter.formatSeconds,
    },
    'installTime': {
        icon: 'clock-outline',
        formater: valueFormatter.formatSeconds,
    },
};

const IpsecStatusProps = {
    uuid: PropTypes.string.isRequired,
};

const IpsecStatus = ({ uuid }: InferProps<typeof IpsecStatusProps>) => {
    const [ show, setShow ] = useBoolean(false);
    const status = useSelector((state) => getStatusUuid(state, uuid));
    const loading = useSelector(getIsLoading);
    const { t } = useTranslation();
    const dispetch = useDispatch();

    const reload = useCallback(() => {
        dispetch(ipsecStatusRequest());
    }, [ dispetch ]);

    return (
        <MDBCard className="card--withoutMH pb-0">
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': show },
            )}
            >
                <div
                    className="clicable"
                    onClick={setShow.swap}
                >
                    <>
                        {t('widgets:Ipsec.status')}
                        <Icon
                            name={show ? 'chevron-up' : 'chevron-down'}
                        />
                    </>
                </div>
                <MDBRow className="">
                    <MDBCol className="profile__icons">
                        <IconWithTooltip
                            className="icon--primary m-1"
                            iconSize="sm"
                            link
                            loading={loading}
                            name="reload"
                            onClick={reload}
                            tooltipText={t('widgets:global.reload')}
                            withoutTranslation
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>

            <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                { 'cardHide__body--hide': !show },
                { 'cardHide__title--borderBottom': show })}
            >
                <MDBRow className="mb-3">
                    <MDBCol>
                        <CreateStatus
                            status={status}
                            type={'establishedSeconds'}
                        />
                    </MDBCol>
                    <MDBCol>
                        <CreateStatus
                            status={status}
                            type={'lifeTime'}
                        />
                    </MDBCol>
                    <MDBCol>
                        <CreateStatus
                            status={status}
                            type={'installTime'}
                        />
                    </MDBCol>
                </MDBRow>
                <WhenAdvanced>
                    <pre>
                        {JSON.stringify(status, null, 2)}
                    </pre>
                </WhenAdvanced>
            </MDBCardBody>
        </MDBCard>
    );
};

export default IpsecStatus;
