/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useContext, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { Draggable } from '@hello-pangea/dnd';
import Svg from 'react-inlinesvg';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import logo from '~frontendRoot/img/svg/logo.svg';
import {  Icon } from '~frontendComponents/Generic/index.js';
import {
    VPN_ROUTES_TYPE_PUSH_TO_USER, VPN_ROUTES_TYPE_PUSH_TO_CLIENT,
    VPN_ROUTES_TYPE_SETTINGS, VPN_CLIENT_ROUTES_TYPE_SETTINGS
} from '~frontendConstants/index.js';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { makeSelectSearchedTableItem } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import RowDivider, { RowDividerTypes } from '~frontendRoot/components/RowDivider.tsx';
import { HlcfgPathGetter, useHlcfgValue, useTableManipulator, useTableRowManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { generateRouteForOpenVpnUser } from '~sharedLib/openvpnUtils.ts';
import { NetaddrArraySelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';


export const vpnRoutesTypeToHlcfgTableMap = {
    [VPN_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_CLIENT_ROUTES_TYPE_SETTINGS]: hlcfgTableName.route,
    [VPN_ROUTES_TYPE_PUSH_TO_CLIENT]: hlcfgTableName.vpnRoute,
    [VPN_ROUTES_TYPE_PUSH_TO_USER]: hlcfgTableName.vpnRoute,
};


type RoutingTableCtx = {
    routeTablePathGetter: HlcfgPathGetter<any>
    type: typeof VPN_ROUTES_TYPE_PUSH_TO_USER | typeof VPN_ROUTES_TYPE_PUSH_TO_CLIENT |
     typeof VPN_ROUTES_TYPE_SETTINGS | typeof VPN_CLIENT_ROUTES_TYPE_SETTINGS,
}
export const VpnRoutingTableContext = createContext({
    type: VPN_ROUTES_TYPE_PUSH_TO_USER,
} as RoutingTableCtx);

export const NoRowsRow = () => {
    const { type, routeTablePathGetter } = useContext(VpnRoutingTableContext);
    const { t } = useTranslation();
    const { addRow } = useTableManipulator({
        addRowType: vpnRoutesTypeToHlcfgTableMap[type],
        tablePathGetter: routeTablePathGetter,
        addRowSuccessText: 'widgets:routeTable.added',
    });
    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRoute"
        >
            <td colSpan={1}></td>
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={4}
            >
                <div className="pl-2">
                    <h2>{t('widgets:routeTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:routeTable.desc1')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={event => {
                                event.preventDefault();
                                addRow();
                            }}
                            {...testProps('AddRow')}
                        >
                            <Icon name="plus" /> {t('widgets:routeTable.route')}
                        </MDBBtn>
                        {t('widgets:routeTable.desc2')}
                    </p>
                    <p>
                        {t('widgets:routeTable.desc3')}
                        (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('widgets:routeTable.desc4')}

                    </p>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        onClick={event => {
                            event.preventDefault();
                            addRow();
                        }}
                        {...testProps('AddRow')}

                    >
                        <Icon name="plus" /> {t('widgets:routeTable.route')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );
};

export const VpnUserRoutesRow = ({ uuid, spacing }: CreateRowPropsType) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnUser'));
    const userPath = getRowPathGetter(uuid);

    const { value: __off } = useHlcfgValue(userPath.__off);

    const { value: type } = useHlcfgValue(userPath.type);
    const { value: addresses, schema: addrSchema } = useHlcfgValue(userPath.addresses);
    const { value: siteToSiteNetworks, schema: networksSchema } = useHlcfgValue(userPath.siteToSiteNetworks);
    const { t } = useTranslation();

    if (__off) {
        return null;
    }
    if (!siteToSiteNetworks || !addresses) {
        return null;
    }
    const routes = generateRouteForOpenVpnUser({ type, addresses, siteToSiteNetworks });
    if (!routes) {
        return null;
    }
    return routes.filter(it => it !== undefined).map((route, idx) => {
        const { destination, gateway } = route;
        if (!destination || !gateway) {
            return null;
        }
        return (
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'profiles__row',
                )}
                key={`${uuid}-${idx}`}
                {...testProps(uuid, { typeId: 'TableRow' })}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <Svg
                        className={classNames(
                            'p-1',
                        )}
                        height="32"
                        src={logo}
                        width="32"
                    />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <NetaddrArraySelect
                        className="select2--row"
                        id={`${uuid}-${idx}.destination`}
                        netaddrType={networksSchema}
                        notEditable
                        onChange={() => {
                            throw new Error('Is not editable');
                        }}
                        tooltip={t('widgets:Vpn.pushToUser.generated')}
                        value={[ destination ]}
                    />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <NetaddrArraySelect
                        className="select2--row"
                        id={`${uuid}-${idx}.gateway`}
                        netaddrType={addrSchema}
                        notEditable
                        onChange={() => {
                            throw new Error('Is not editable');
                        }}
                        tooltip={t('widgets:Vpn.pushToUser.generated')}
                        value={[ gateway ]}
                    />
                </td>
                <RowMenuAndSwitch
                    id={'routeTableOff' + uuid}
                    menu={false}
                    spacing={spacing}
                />
            </tr>
        ); });
};
const Row = ({ uuid, dataIndex, search, spacing }: CreateRowPropsType) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'route') || hlcfgRowIdIsFromTable(uuid, 'vpnRoute'));
    const routePath = getRowPathGetter(uuid);
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const { type, routeTablePathGetter } = useContext(VpnRoutingTableContext);


    const tableRowManipulator = useTableRowManipulator({
        rowPathGetter: routePath,
        addRowType: vpnRoutesTypeToHlcfgTableMap[type],
        tablePathGetter: routeTablePathGetter,
        addRowSuccessText: 'widgets:routeTable.added',
    });

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: tableRowManipulator.addRow, translation: 'widgets:routeTable.route' },
    ]);

    const { value: __off, setValue: setOff } = useHlcfgValue(routePath.__off);
    const { t } = useTranslation();
    return (
        <Draggable
            //it needs string
            draggableId={String(dataIndex)}
            index={dataIndex}
            key={dataIndex}
        >
            {(provided) => (

                <>
                    <RowDivider
                        id={uuid}
                        length={6}
                        types={types}
                    />

                    <HlcfgRowTr
                        className={classNames(
                            { 'dataTableWidget__Row--match': searchMatches },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        rowPathGetter={routePath}
                        {...provided.draggableProps}
                        {...testProps(uuid, { typeId: 'TableRow' })}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': searchMatches },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >
                            <Icon
                                name="drag"
                                size="sm"
                            />


                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSelect
                                className="select2--row"
                                pathGetter={routePath.destination}
                                tooltip={t('widgets:Vpn.pushToUser.generated')}
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSelect
                                className="select2--row"
                                pathGetter={routePath.gateway}
                                tooltip={t('widgets:Vpn.pushToUser.generated')}
                            />
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={tableRowManipulator.duplicateRow}
                            deleteFunc={tableRowManipulator.deleteRow}
                            id={'routeTableOff' + uuid}
                            menu={true}
                            onChange={({ value }) => setOff(value)}
                            spacing={spacing}
                        />
                    </HlcfgRowTr>
                    <RowDivider
                        after
                        id={uuid}
                        length={6}
                        types={types}

                    />

                </>
            )}
        </Draggable>
    );
};


export default Row;
