/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { typeTimestamp } from '~frontendTypes/index.js';
import { getAdaptiveFirewallRulesUpdateState,
    getDatabaseLoading, getDatabaseError,
    getAdaptiveFirewallDatabaseInfo, databaseInfoRequest } from '~frontendDucks/protectionDatabases/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import moment from '~commonLib/moment.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { Switch } from '~frontendComponents/Generic/index.js';
import { switchExpr } from '~sharedLib/helpers.ts';
import { useHlcfgOffableValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';

import AdaptiveFirewallUpdatePerformIcon from '../AdaptiveFirewallUpdatePerformIcon.js';


const StateMessageProps = {
    lastUpdate: typeTimestamp,
    adaptiveFirewallRulesState: PropTypes.string,
    info: PropTypes.bool,
};

const StateMessage = ({ lastUpdate, adaptiveFirewallRulesState, info }: InferProps<typeof StateMessageProps>) => (
    <>
        <span className="headerInfobox">
            <Message message={`widgets:AdaptiveFirewall.update.${info ? 'info' : 'lastUpdate'
            }.${adaptiveFirewallRulesState}.title`}
            />
        </span>
        {!info && lastUpdate &&
            <Message
                message={`widgets:AdaptiveFirewall.update.lastUpdate.${adaptiveFirewallRulesState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            />
        }
    </>
);


const AdaptiveFirewallUpdates = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { lastUpdate } = useSelector(getAdaptiveFirewallDatabaseInfo);
    const isLoading = useSelector(getDatabaseLoading);
    const error = useSelector(getDatabaseError);
    const adaptiveFirewallRulesState = useSelector(getAdaptiveFirewallRulesUpdateState);
    const adaptiveFirewall = useHlcfgOffableValue(hlcfgPathGetter.protection.adaptiveFirewall);
    const { value: afInitVal } = useHlcfgOffableValue(hlcfgPathGetter.protection.adaptiveFirewall, { initial: true });

    useEffect(() => {
        dispatch(databaseInfoRequest());
    }, [ dispatch ]);


    return (
        <MDBCard>
            <MDBCardTitle>
                <MDBRow>
                    <MDBCol lg="8">
                        {t('ips:update.title')}
                        <IconWithTooltip
                            className={classNames('ml-2 mb-1 database__stateIcon',
                                switchExpr(adaptiveFirewallRulesState, {
                                    error: 'icon--red',
                                    warning: 'icon--yellow',
                                    success: 'icon--primary',
                                }, 'icon--primary'))
                            }
                            iconSize="sm"
                            link
                            name={switchExpr(adaptiveFirewallRulesState, {
                                error: 'alert-circle-outline',
                                warning: 'progress-alert',
                                success: 'check-circle-outline',
                            }, 'check-circle-outline')
                            }
                            tooltipText={t(
                                `widgets:AdaptiveFirewall.update.lastUpdate.${adaptiveFirewallRulesState}.title`
                            )}
                            withoutTranslation
                        />
                    </MDBCol>
                    <MDBCol
                        className="database__reloadIcon"
                        lg="4"
                    >
                        <AdaptiveFirewallUpdatePerformIcon />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <StateWidget
                            apiError={error}
                            error={adaptiveFirewallRulesState === 'error' &&
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                            }
                            info={
                                adaptiveFirewall.isOff &&
                                    <StateMessage
                                        adaptiveFirewallRulesState={'info'}
                                        info
                                        lastUpdate={lastUpdate}
                                    />
                            }
                            isLoading={isLoading}
                            success={
                                adaptiveFirewallRulesState === 'success' &&
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                            }
                            warning={
                                adaptiveFirewallRulesState === 'warning' &&
                                    <StateMessage
                                        adaptiveFirewallRulesState={adaptiveFirewallRulesState}
                                        lastUpdate={lastUpdate}
                                    />
                            }
                        />
                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={adaptiveFirewall.value.betaServer || afInitVal.betaServer}>
                            <Switch
                                align="spaceBetween"
                                checked={adaptiveFirewall.value.betaServer}
                                className="pt-2"
                                id="betaServer"
                                label={t('ips:betaServers')}
                                name="betaServersName"
                                onChange={adaptiveFirewall.inputSetValue}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>

                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={!adaptiveFirewall.value.autoUpdate || !afInitVal.autoUpdate}>
                            <Switch
                                align="spaceBetween"
                                checked={adaptiveFirewall.value.autoUpdate}
                                className="pt-2"
                                id="autoUpdate"
                                label={t('ips:automaticUpdates')}
                                name="automaticUpdatesName"
                                onChange={adaptiveFirewall.inputSetValue}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};


export default AdaptiveFirewallUpdates;
