/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useSelector } from 'react-redux';
import assert from 'assert';
import { useCallback, useContext } from 'react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { default as Switch } from '~frontendComponents/Generic/Switch/Switch.tsx';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { getHlcfgSchema } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { HlcfgOffablePathGetter, HlcfgPathGetter, Path, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_NETADDR, SCHEMA_TYPE_NETPORT, SCHEMA_TYPE_NETSERVICE, SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_ROW_REFERENCE_ARR } from '~sharedLib/schemaTypes.ts';
import { NegatableNetaddrListSelect, NetaddrArraySelect, NetaddrSelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.tsx';
import Input from '~frontendComponents/Generic/Input/Input.js';
import { RowReferenceArraySelect, RowReferenceSelect } from '~frontendComponents/Generic/SelectV2/RowReferenceSelect.tsx';
import { poDef, testProps } from '~commonLib/PageObjectMap.ts';
import { SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import InputFile, { InputFileProps } from '~frontendComponents/Generic/InputFile/InputFile.tsx';
import { isUploadLocator } from '~sharedLib/resourceLocatorUtils.ts';
import { apiCallPostUploadFile } from '~frontendDucks/uploadFile/uploadFile.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { getApiError } from '~frontendLib/apiUtils.ts';
import { NetportSelect } from '~frontendComponents/Generic/SelectV2/NetportSelect.tsx';
import { ADDR_SELECTORS_DISALLOW_IFACE_TYPES, DEFAULT_SCHEMA_VALUE, PLACEHOLDER_SCHEMA_VALUE, SYSTEM_ONLY_SETTABLE_ENUMS, WITHOUT_ADDR_SELECTORS, WITHOUT_NAMED_OBJECTS } from '~commonLib/schemaFlags.ts';
import { EnumArraySelect, EnumSelect } from '~frontendComponents/Generic/SelectV2/EnumSelect.tsx';
import { resolvedPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import { JSXElement } from '~commonLib/types.ts';
import { parseTimeValue, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { enumIcons } from '~frontendConstants/constants.ts';
import { NetserviceArraySelect } from '~frontendComponents/Generic/SelectV2/NetserviceSelect.tsx';
import { jsonPP } from '~commonLib/stringUtils.ts';
import { HlcfgRowCtx } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowCtx.ts';
import { noop } from '~commonLib/functionUtils.ts';


export type HlcfgInputsCommonProps = {
    /**
     * Path getter with path to place in hlcfg.
     * Use exported constant hlcfgPathGetter to obtain the path getter easily.
     *
     * @example
     *    pathGetter={hlcfgPathGetter.protection.proxy}
     */
    pathGetter: HlcfgPathGetter<any>,
    label?: string|JSXElement,
    className?: string,
    placeholder?: string,
};

/**
 * Hook to use when using HlcfgInput is inconvenient do to wrapping of underlying components
 */
export const useHlcfgInputModel = <P extends Path>(
    pathGetter: HlcfgPathGetter<P>,
    opts: { disabled?: boolean|null, notEditable?: boolean } = {}
) => {
    const hlcfgSchema = useSelector(getHlcfgSchema);
    const { value, setValue, path, valueOrDefault, schema } = useHlcfgValue(pathGetter);
    const parentPath = path.slice(0, -1);
    const parentSchema = findSchemaByObjectPathAndSchema(parentPath, hlcfgSchema);
    const setWrappedValue = useCallback(({ value }) => setValue(value), [ setValue ]);
    const id = poDef.pathId(path);
    const rowCtx = useContext(HlcfgRowCtx);
    return {
        id, path, schema, value, setValue, isRequired: parentSchema.required?.includes(path.at(-1)), valueOrDefault,
        /**
         * setValue but wrapped for usage in inputs where input passes {value} to the onChange callback
         */
        setWrappedValue,
        disabled: opts.disabled ?? rowCtx.disabled,
        notEditable: opts.notEditable ?? rowCtx.notEditable,
        ...testProps(id),
    };
};
export type HlcfgSelectProps = HlcfgInputsCommonProps & Omit<SelectV2BaseProps, 'id'>;
export const HlcfgSelect = ({
    pathGetter, ...passThroughProps
}: HlcfgSelectProps) => {
    const {
        id, schema, value, setValue, isRequired, path, disabled, notEditable
    } = useHlcfgInputModel(pathGetter, passThroughProps);
    const { t } = useTranslation();

    const onChange = useCallback(value => {
        if (!isRequired && Array.isArray(value) && value.length === 0) {
            return setValue(undefined);
        }
        return setValue(value);
    }, [ isRequired, setValue ]);

    const commonProps = {
        placeholder: getPlaceholder(passThroughProps, schema, t),
        id,
        onChange,
        maxItemsSelected: schema.maxItems,
        isRequired,
        disabled,
        notEditable,
    };
    const singleValClassName = classNames(passThroughProps.className, 'select2--single-val');
    if (schema[SCHEMA_TYPE_NETPORT]) {
        assert(value === undefined || (value && typeof value === 'object'), 'Invalid value type according to schema');
        return (
            <NetportSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                netportType={schema[SCHEMA_TYPE_NETPORT]}
                value={value}
            />
        );
    }
    if (schema[SCHEMA_TYPE_NETADDR]) {
        assert(value === undefined || (value && typeof value === 'object'), 'Invalid value type according to schema');
        return (
            <NetaddrSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                netaddrType={schema[SCHEMA_TYPE_NETADDR]}
                value={value}
                withoutAddrSelectorIfaceTypes={schema[ADDR_SELECTORS_DISALLOW_IFACE_TYPES]}
                withoutAddrSelectors={schema[WITHOUT_ADDR_SELECTORS]}
                withoutNamedObjects={schema[WITHOUT_NAMED_OBJECTS]}
            />
        );
    }
    if (schema[SCHEMA_TYPE_ROW_ID]) {
        assert(
            value === undefined || (typeof value === 'string'),
            'Invalid value type according to schema'
        );
        const refTypes = schema[SCHEMA_TYPE_ROW_ID];
        return (
            <RowReferenceSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                referenceTypes={refTypes}
                value={value}
            />
        );
    }

    if (schema.enum) {
        assert(
            value === undefined || (typeof value === 'number' || typeof value === 'string'),
            'Invalid value type according to schema'
        );
        return (
            <EnumSelect
                {...passThroughProps}
                {...commonProps}
                className={singleValClassName}
                enumeration={schema.enum}
                enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
                hideValuesFromMenu={schema[SYSTEM_ONLY_SETTABLE_ENUMS]}
                value={value}
                valueIcons={enumIcons}
            />
        );
    }

    if (schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
        return (
            <NegatableNetaddrListSelect
                {...passThroughProps}
                {...commonProps}
                netaddrType={schema[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]}
                value={value as any}
            />
        );

    }

    switch (schema.type) {
    case 'array': {
        assert(value === undefined || Array.isArray(value), 'Invalid value type according to schema');
        if (schema.items.enum) {
            return (
                <EnumArraySelect
                    {...passThroughProps}
                    {...commonProps}
                    enumeration={schema.items.enum}
                    enumValueTranslationPathPrefix={getEnumTranslationPrefixPath(path)}
                    value={value ?? []}
                />
            );
        }
        if (schema.items[SCHEMA_TYPE_NETADDR]) {
            return (
                <NetaddrArraySelect
                    {...passThroughProps}
                    {...commonProps}
                    netaddrType={schema.items[SCHEMA_TYPE_NETADDR]}
                    value={value ?? []}
                    withoutAddrSelectorIfaceTypes={schema.items[ADDR_SELECTORS_DISALLOW_IFACE_TYPES]}
                    withoutAddrSelectors={schema.items[WITHOUT_ADDR_SELECTORS]}
                    withoutNamedObjects={schema.items[WITHOUT_NAMED_OBJECTS]}
                />
            );
        }
        if (schema.items[SCHEMA_TYPE_NETSERVICE]) {
            return (
                <NetserviceArraySelect
                    {...passThroughProps}
                    {...commonProps}
                    netserviceType={schema.items[SCHEMA_TYPE_NETSERVICE]}
                    value={value ?? []}
                />
            );
        }
        if (schema[SCHEMA_TYPE_ROW_REFERENCE_ARR]) {
            const refTypes = schema.items[SCHEMA_TYPE_ROW_ID];
            return (
                <RowReferenceArraySelect
                    {...passThroughProps}
                    {...commonProps}
                    referenceTypes={refTypes}
                    value={value ?? []}
                />
            );
        }
        throw new Error(`Schema not supported (${jsonPP(schema)})`);
    }
    default:
        throw new Error(`Schema not supported (${jsonPP(schema)})`);
    }
};

export type HlcfgSwitchProps = HlcfgInputsCommonProps & Omit<Parameters<typeof Switch>[0], 'id'|'onChange'|'checked'>;
export const HlcfgSwitch = ({ pathGetter, ...switchProps }:  HlcfgSwitchProps) => {
    const {
        id, schema, valueOrDefault, setWrappedValue, disabled, notEditable
    } = useHlcfgInputModel(pathGetter, switchProps);
    switch (schema.type) {
    case 'boolean': {
        assert(
            valueOrDefault === undefined || typeof valueOrDefault === 'boolean',
            'Invalid value type according to schema'
        );
        return (
            <Switch
                {...switchProps}
                checked={valueOrDefault}
                disabled={disabled}
                id={id}
                onChange={notEditable ? noop : setWrappedValue}

            />
        );
    }
    default:
        throw new Error('HlcfgSwitch used for non-boolean value');
    }
};

type HlcfgOffSwitchProps = HlcfgSwitchProps & {pathGetter: HlcfgOffablePathGetter<any>}
/**
 * Displays and sets __off property of object on provided path. This also handles logic when the object is undefined
 * so that the object shows as off:true when it does not exist
 * and reverses the off value so that the switch is enabled when the object is not off.
 */
export const HlcfgOffSwitch = ({ pathGetter, ...switchProps }: HlcfgOffSwitchProps) => {
    const {
        id, value, setWrappedValue, schema
    } = useHlcfgInputModel(pathGetter.__off);
    assert(value === undefined || typeof value === 'boolean', 'Invalid value type according to schema');
    const obj = useHlcfgInputModel(pathGetter);
    switch (schema.type) {
    case 'boolean': {
        assert(value === undefined || typeof value === 'boolean', 'Invalid value type according to schema');
        return (
            <Switch
                {...switchProps}
                checked={obj.value === undefined ? true : value}
                id={id}
                onChange={setWrappedValue}
                reverseValue
            />
        );
    }
    default:
        throw new Error('HlcfgSwitch used for non-boolean value');
    }
};

type HlcfgTextInputProps = HlcfgInputsCommonProps & {
    className?: string, rows?: number, type?: string, message?: string, withoutBorder?: boolean
    disabled?: boolean, inputClass?: string, isName?: boolean, withoutPaddingLeft?: boolean, isRow?: boolean
    noWrap?: boolean, generate?: boolean, generateLength?: number,
}
export const HlcfgTextInput = ({
    pathGetter, ...inputProps
}: HlcfgTextInputProps) => {
    const { id, schema, value, setWrappedValue, isRequired, disabled, notEditable } = useHlcfgInputModel(pathGetter);
    const { t } = useTranslation();

    switch (schema.type) {
    case 'number':
    case 'integer':
    case 'string': {
        assert(
            value === undefined || typeof value === 'string' || typeof value === 'number',
            'Invalid value type according to schema'
        );
        return (
            <Input
                {...inputProps}
                disabled={disabled}
                id={id}
                number={schema.type !== 'string'}
                onChange={notEditable ? noop : setWrappedValue}
                placeholder={getPlaceholder(inputProps, schema, t)}
                required={isRequired}
                useUndefined={!isRequired}
                value={value}
                {...testProps(id)}
            />
        );
    }
    default:
        throw new Error('HlcfgInput used for non-string value');
    }
};
export const HlcfgTimeTextInput = ({
    pathGetter, ...inputProps
}:  HlcfgInputsCommonProps & {className?: string, rows?: number, type?: string, message?: string}) => {
    const { id, schema, value, setValue, isRequired, disabled, notEditable } = useHlcfgInputModel(pathGetter);
    const { t } = useTranslation();

    switch (schema.type) {
    case 'integer': {
        assert(
            value === undefined || typeof value === 'number',
            'Invalid value type according to schema'
        );

        const validator = setTimeoutComponentValidator(t);
        const placeholder = getPlaceholder(inputProps, schema, t);
        return (
            <Input
                {...inputProps}
                disabled={disabled}
                id={id}
                onChange={notEditable ? noop : ({ value }) => {
                    const parsed = parseTimeValue(value);
                    if (parsed) {
                        setValue(parsed.value);
                    }
                }}
                placeholder={placeholder !== undefined ? valueFormatter.formatSeconds(placeholder) : ''}
                required={isRequired}
                useUndefined={!isRequired}
                validator={validator}
                value={value !== undefined ? valueFormatter.formatSeconds(value) : ''}
                {...testProps(id)}
            />
        );
    }
    default:
        throw new Error('HlcfgInput used for non-string value');
    }
};


export const HlcfgFileInput = ({
    pathGetter, ...inputProps
}:  HlcfgInputsCommonProps & Omit<InputFileProps, 'hlcfgPath'>) => {
    const { id, value, setValue, path } = useHlcfgInputModel(pathGetter);
    assert(
        value === undefined || isUploadLocator(value),
        'Invalid value in HlcfgFileInput. Must be undefined or upload locator.'
    );

    const { mutate } = useMutation({
        mutationFn: (file: File) => {
            return apiCallPostUploadFile(file);
        },
        onSuccess: (response) => {
            setValue(response.data);
            createNotification({
                title: 'widgets:global.upload.success.title',
                desc: 'widgets:global.upload.success.desc',
                type: 'success',
            });
        },
        onError: (error) => {
            createNotification({
                title: 'widgets:global.upload.error',
                type: 'danger',
                desc: getApiError(error).message,
            });
        }
    });
    const onChange = useCallback(({ value }: {value: File|undefined}) => {
        if (!value) {
            return setValue(undefined);
        }
        mutate(value);

    }, [ mutate, setValue ]);

    return (
        <InputFile
            {...inputProps}
            fakeFile={value}
            hlcfgPath={path.join('.')}
            id={id}
            onChange={onChange}
            renderId={id}
        />
    );
};

export const getEnumTranslationPrefixPath = (path: readonly string[]) => {
    const resolved = resolvedPathToRealPath(path);
    if (resolved[0] === 'tables') {
        // We delete ID, which is omitted in differs translation files.
        resolved.splice(2, 1);
    }
    return `differs:${resolved.join('.')}`;
};

const getPlaceholder = (props, schema, t) => {
    if (props.placeholder) {
        return props.placeholder;
    }
    const { [PLACEHOLDER_SCHEMA_VALUE]: placeholder, [DEFAULT_SCHEMA_VALUE]: defaultVal } = schema;
    if (placeholder) {
        return t(placeholder);
    }
    if (Array.isArray(defaultVal) && defaultVal.length === 0) {
        return undefined;
    }
    return defaultVal;
};
