/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import { useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Switch } from '~frontendComponents/Generic/index.js';
import { getResolverDnssec, getResolverDnssecSchema, setGlcfgObjectValue } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';


export const Dnssec = () => {
    const DnssecOn = useSelector(getResolverDnssec);
    const { t } = useTranslation();
    const schema = useSelector(getResolverDnssecSchema);
    const doSetGlcfgObjectValue = useDispatchCallback(setGlcfgObjectValue, []);
    const onChangeEvent = useCallback(({ id, value }) => {
        doSetGlcfgObjectValue('resolver', value, id);
    }, [ doSetGlcfgObjectValue ]);

    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:Dnssec.title')}
            </MDBCardHeader>
            <MDBCardBody>
                <Switch
                    align="spaceBetween"
                    checked={DnssecOn}
                    className="pt-2"
                    id={'dnssec'}
                    label={t('widgets:Dnssec.shortcut')}
                    onChange={onChangeEvent}
                    schema={schema}
                    withoutFlexWrap
                />
            </MDBCardBody>
        </MDBCard>
    );
};
