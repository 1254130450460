/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import PropTypes, { InferProps } from 'prop-types';
import { createSelector } from 'reselect';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { typeTimestamp } from '~frontendTypes/index.js';
import { getSuricataRulesUpdateState, getDatabaseLoading, getDatabaseError, getSuricataDatabaseInfo,
    databaseInfoRequest }
    from '~frontendDucks/protectionDatabases/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import Message from '~frontendComponents/Message/index.js';
import { StateWidget } from '~frontendRoot/widgets/index.js';
import moment from '~commonLib/moment.ts';
import WhenAdvanced from '~frontendRoot/components/WhenAdvanced/index.js';
import { switchExpr } from '~sharedLib/helpers.ts';
import { createGetHlcfgValue, useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getIsAdvanced } from '~frontendDucks/dynamicSettings/dynamicSettings.ts';

import SuricataRulesUpdatePerformIcon from '../SuricataRulesUpdatePerformIcon.js';


const StateMessageProps = {
    lastUpdate: typeTimestamp,
    dbVersion: typeTimestamp,
    suricataRulesState: PropTypes.string,
    info: PropTypes.bool,
};

const StateMessage = ({ lastUpdate, dbVersion, suricataRulesState, info }: InferProps<typeof StateMessageProps>) => (
    <>
        <span className="headerInfobox">
            <Message message={`ips:update.${info ? 'info' : lastUpdate ? 'lastUpdate' : 'dbVersion'
            }.${suricataRulesState}.title`}
            />
        </span>
        {!info && (lastUpdate ?
            <Message
                message={`ips:update.lastUpdate.${suricataRulesState}.desc`}
                params={{ time: moment(lastUpdate).fromNow() }}
            /> :
            dbVersion &&
            <Message
                message={`ips:update.dbVersion.${suricataRulesState}.desc`}
                params={{ time: moment(dbVersion).fromNow() }}
            />)
        }
    </>
);


const SuricataUpdates = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const suricataRulesInfo = useSelector(getSuricataDatabaseInfo);
    const isLoading = useSelector(getDatabaseLoading);
    const error = useSelector(getDatabaseError);
    const suricataRulesState = useSelector(getSuricataRulesUpdateState);
    const showAutomaticUpdatesAnyway = useSelector(shouldShowAutoUpdatesAnyway);
    const showBetaServersAnyway = useSelector(shouldShowBetaServersAnyway);
    const suricataEnabled = useHlcfgOffable(suricataPath.service).isOn;

    useEffect(() => {
        dispatch(databaseInfoRequest());
    }, [ dispatch ]);

    const { updatedTimestamp } = suricataRulesInfo || {};
    const updatedAt = updatedTimestamp;
    const createdAt = updatedTimestamp;

    return (
        <MDBCard>
            <MDBCardTitle>
                <MDBRow>
                    <MDBCol lg="8">
                        {t('ips:update.title')}
                        <IconWithTooltip
                            className={classNames('ml-2 mb-1 database__stateIcon',
                                switchExpr(suricataRulesState, {
                                    error: 'icon--red',
                                    warning: 'icon--yellow',
                                    success: 'icon--primary',
                                }, 'icon--primary'))
                            }
                            iconSize="sm"
                            link
                            name={switchExpr(
                                suricataRulesState,
                                {
                                    'error': 'alert-circle-outline',
                                    'warning': 'progress-alert',
                                    'success': 'check-circle-outline'
                                },
                                'check-circle-outline'
                            )}
                            tooltipText={t(
                                `widgets:AdaptiveFirewall.update.lastUpdate.${suricataRulesState}.title`
                            )}
                            withoutTranslation
                        />
                    </MDBCol>
                    <MDBCol
                        className="database__reloadIcon"
                        lg="4"
                    >
                        <SuricataRulesUpdatePerformIcon />
                    </MDBCol>
                </MDBRow>
            </MDBCardTitle>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <StateWidget
                            apiError={error}
                            error={suricataRulesState === 'error' &&
                                    <StateMessage
                                        dbVersion={createdAt}
                                        lastUpdate={updatedAt}
                                        suricataRulesState={suricataRulesState}
                                    />}
                            info={
                                !suricataEnabled &&
                                <StateMessage
                                    dbVersion={createdAt}
                                    info
                                    lastUpdate={updatedAt}
                                    suricataRulesState={'info'}
                                />
                            }
                            isLoading={isLoading}
                            success={
                                suricataRulesState === 'success' &&
                                 <StateMessage
                                     dbVersion={createdAt}
                                     lastUpdate={updatedAt}
                                     suricataRulesState={suricataRulesState}
                                 />
                            }
                            warning={
                                suricataRulesState === 'warning' &&
                                <StateMessage
                                    dbVersion={createdAt}
                                    lastUpdate={updatedAt}
                                    suricataRulesState={suricataRulesState}
                                />}
                        />
                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={showBetaServersAnyway}>
                            <HlcfgSwitch
                                align="spaceBetween"
                                className="pt-2"
                                label={t('ips:betaServers')}
                                pathGetter={suricataPath.updates.betaServer}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>

                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <WhenAdvanced showAnyway={showAutomaticUpdatesAnyway}>
                            <HlcfgSwitch
                                align="spaceBetween"
                                className="pt-2"
                                label={t('ips:automaticUpdates')}
                                pathGetter={suricataPath.service.autoUpdate}
                                withoutFlexWrap
                            />
                        </WhenAdvanced>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

const suricataPath = hlcfgPathGetter.protection.suricata;
const shouldShowAutoUpdatesAnyway = createSelector([
    createGetHlcfgValue(suricataPath.service.autoUpdate.getPath()),
    createGetHlcfgValue(suricataPath.service.autoUpdate.getPath(), { initial: true }),
    getIsAdvanced,
], (automaticUpdates, automaticUpdatesFromInit, advanced) => {
    return advanced || !automaticUpdates || !automaticUpdatesFromInit;
});
const shouldShowBetaServersAnyway = createSelector([
    createGetHlcfgValue(suricataPath.updates.betaServer.getPath()),
    createGetHlcfgValue(suricataPath.updates.betaServer.getPath(), { initial: true }),
    getIsAdvanced,
], (enabled, enabledFromInit, advanced) => {
    return advanced || enabled || enabledFromInit;
});

export default SuricataUpdates;
