/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { DetailedHTMLProps, forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import assert from 'assert';

import { HlcfgPathGetter, useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { HlcfgRowCtx } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowCtx.ts';


type HlcfgRowTrProps = {
    rowPathGetter: {__off?: HlcfgPathGetter<any>, fake?: HlcfgPathGetter<any>},
    disabled?: boolean,
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>
const HlcfgRowTrInner = ({ rowPathGetter, disabled, ...rest }: HlcfgRowTrProps, ref) => {
    assert(rowPathGetter.__off);
    const { value: __off } = useHlcfgValue(rowPathGetter.__off);
    assert(rowPathGetter.fake);
    const { value: fake } = useHlcfgValue(rowPathGetter.fake);

    const shouldBeDisabled = disabled ?? !!__off;
    const notEditable = disabled || !!fake;
    return (
        <HlcfgRowCtx.Provider value={{ disabled: shouldBeDisabled, notEditable }}>
            <tr
                {...rest}
                className={classNames(
                    rest.className,
                    'dataTableWidget__Row',
                    'profiles__row',
                    { 'dataTableWidget__Row--disable': __off },
                )}
                ref={ref}
            />
        </HlcfgRowCtx.Provider>
    );
};
export const HlcfgRowTr = forwardRef(HlcfgRowTrInner);
