/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBCol,
    MDBRow } from 'mdbreact';
import { Draggable, DraggableProvided } from '@hello-pangea/dnd';
import { useDispatch } from 'react-redux';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {  Icon } from '~frontendComponents/Generic/index.js';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { VPN_ROUTES_TYPE_PUSH_TO_USER, VPN_USER_QR_CODE } from '~frontendConstants/index.js';
import Message from '~frontendComponents/Message/index.js';
import { OPENVPN_USER_TYPE_CLIENT, OPENVPN_USER_TYPE_S2S } from '~sharedConstants/constants.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { vpnUserQrcodeRequest } from '~frontendDucks/vpnUserQrcode/index.ts';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import { HlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { useBoolean, useConstant, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import RowDivider, { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue, useTableManipulator, useTableRowManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { HlcfgSelect, HlcfgSwitch, HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';

import { VpnRoutingTableContext } from '../../../../VpnRouteTable/Row/index.ts';
import VpnRouteTable from '../../../../VpnRouteTable/index.js';


export const VpnPushToUserContext = createContext({ search: '', uuid: '' } as { search: string, uuid: HlcfgRowId });

export const NoRowsRow = () => {
    const { uuid: vpnUuid } = useContext(VpnPushToUserContext);
    assert(hlcfgRowIdIsFromTable(vpnUuid, 'openvpnRas'));
    const vpnPath = getRowPathGetter(vpnUuid);
    const { addRow: addClient } = useTableManipulator({
        tablePathGetter: vpnPath.pushToUser,
        addRowType: 'openvpnUser',
        addRowSuccessText: 'widgets:Vpn.pushToUser.added',
        addExtraValues: useConstant({
            type: OPENVPN_USER_TYPE_CLIENT
        })
    });
    const { addRow: addS2S } = useTableManipulator({
        tablePathGetter: vpnPath.pushToUser,
        addRowType: 'openvpnUser',
        addRowSuccessText: 'widgets:Vpn.pushToUser.added',
        addExtraValues: useConstant({
            type: OPENVPN_USER_TYPE_S2S
        })
    });
    const { t } = useTranslation();

    const handleAddRow = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addClient();
    }, [ addClient ]);


    const handleAddRowSite = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addS2S();
    }, [ addS2S ]);

    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRoute"
        >
            <td colSpan={1}></td>
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={6}
            >
                <div className="pl-2">
                    <h2>{t('widgets:Vpn.pushToUser.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:Vpn.pushToUser.desc1')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            data-cy="addVpnUser1"
                            onClick={handleAddRow}
                            {...testProps('AddRow')}
                        >
                            <Icon name="plus" /> {t('widgets:Vpn.pushToUser.user')}
                        </MDBBtn>
                    </p>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:Vpn.pushToUser.desc2')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            data-cy="addVpnUserS2S1"
                            onClick={handleAddRowSite}
                            {...testProps('AddRow')}
                        >
                            <Icon name="plus" /> {t('widgets:Vpn.pushToUser.s2s')}
                        </MDBBtn>
                    </p>
                    <p>
                        {t('widgets:Vpn.pushToUser.desc3')}
                        (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('widgets:Vpn.pushToUser.desc4')}

                    </p>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        data-cy="addVpnUser2"
                        onClick={handleAddRow}
                        {...testProps('AddRow')}
                    >
                        <Icon name="plus" /> {t('widgets:Vpn.pushToUser.user')}
                    </MDBBtn>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        data-cy="addVpnUserS2S2"
                        onClick={handleAddRowSite}
                        {...testProps('AddRow')}

                    >
                        <Icon name="plus" /> {t('widgets:Vpn.pushToUser.s2s')}
                    </MDBBtn>

                </div>
            </td>
        </tr>
    );
};

const VpnUserRow = ({ dataIndex, uuid, spacing }: CreateRowPropsType) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ routesOpen, setRoutesOpen ] = useBoolean();

    const { uuid: vpnUuid, search,  } = useContext(VpnPushToUserContext);
    assert(hlcfgRowIdIsFromTable(vpnUuid, 'openvpnRas'));
    const vpnPath = getRowPathGetter(vpnUuid);

    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnUser'));
    const userPath = getRowPathGetter(uuid);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const isGoogleAuth = useHlcfgValue(vpnPath.googleAuthEnabled).valueOrDefault;
    const isGoogleAuthInit = useHlcfgValue(vpnPath.googleAuthEnabled, { initial: true }).valueOrDefault;
    const isVpnUserFromInit = useHlcfgValue(vpnPath.pushToUser, { initial: true }).value?.some(id => id === uuid);


    const { addRow: addClient, deleteRow, duplicateRow } = useTableRowManipulator({
        tablePathGetter: vpnPath.pushToUser,
        rowPathGetter: userPath,
        addRowType: 'openvpnUser',
        addRowSuccessText: 'widgets:Vpn.pushToUser.added',
        addExtraValues: useConstant({
            type: OPENVPN_USER_TYPE_CLIENT
        })
    });
    const { addRow: addS2S } = useTableRowManipulator({
        tablePathGetter: vpnPath.pushToUser,
        rowPathGetter: userPath,
        addRowType: 'openvpnUser',
        addRowSuccessText: 'widgets:Vpn.pushToUser.added',
        addExtraValues: useConstant({
            type: OPENVPN_USER_TYPE_S2S
        })
    });


    const openQrcode = useCallback(() => {
        dispatch(vpnUserQrcodeRequest({ openvpnUserId: uuid }));
        dispatch(setModalState({ modal: VPN_USER_QR_CODE, value: true, specialValues: {} }));
    }, [ dispatch, uuid ]);

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addClient, translation: 'widgets:Vpn.pushToUser.user' },
        { addFunc: addS2S, translation: 'widgets:Vpn.pushToUser.s2s' },
    ]);

    const { value: siteToSiteNetworks } = useHlcfgValue(userPath.siteToSiteNetworks);
    const { value: useDefaults } = useHlcfgValue(userPath.useDefaults);
    const { value: disabled } = useHlcfgValue(userPath.disabled);
    const { value: type } = useHlcfgValue(userPath.type);
    const { value: routes } = useHlcfgValue(userPath.routes);
    const { value: id } = useHlcfgValue(userPath.id);
    const { value: __off, setValue: setOff } = useHlcfgValue(userPath.__off);

    return (
        <Draggable
            //it needs string
            draggableId={String(dataIndex)}
            index={dataIndex}
            key={dataIndex}
        >
            {(provided: DraggableProvided) => (

                <>
                    <RowDivider
                        id={id}
                        length={8}
                        types={types}
                    />
                    <HlcfgRowTr
                        className={classNames(
                            'dataTableWidget__Row--important',
                            'packetFilter__edit',
                            { 'dataTableWidget__Row--match': searchMatches },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        rowPathGetter={userPath}
                        {...provided.draggableProps}
                        {...testProps(uuid, { typeId: 'TableRow' })}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': searchMatches },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >

                            <Icon
                                name="drag"
                                size="sm"
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSelect
                                className="select2--row select2--justIcon"
                                pathGetter={userPath.type}
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSwitch
                                align="center"
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                    'dataTableWidget__RowInput--sm',
                                )}
                                inputStyle
                                mini
                                pathGetter={userPath.disabled}
                                tooltipText={disabled ? 'widgets:Vpn.pushToUser.columns.block.on' :
                                    'widgets:Vpn.pushToUser.columns.block.off'}
                                withoutBorder
                                withoutMinWidhtHeight
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgTextInput
                                className={classNames(
                                    'dataTableWidget__RowInput',
                                )}
                                pathGetter={userPath.commonName}
                                withoutBorder
                            />
                        </td>

                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'packetFilter__row',

                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSelect
                                className="select2--row"
                                pathGetter={userPath.addresses}
                            />
                        </td>

                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'packetFilter__row',

                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgSelect
                                className="select2--row"
                                disabled={type !== OPENVPN_USER_TYPE_S2S && !siteToSiteNetworks?.length}
                                pathGetter={userPath.siteToSiteNetworks}
                                tooltip={type !== OPENVPN_USER_TYPE_S2S ?
                                    'widgets:Vpn.pushToUser.notUsedNetwork' :
                                    undefined}
                            />
                        </td>
                        <td className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                        >
                            {(isGoogleAuth || isGoogleAuthInit) &&
                                <IconWithTooltip
                                    className={classNames(
                                        'ml-1',
                                        { 'icon--disabled': !isGoogleAuthInit || !isVpnUserFromInit },
                                    )}
                                    link
                                    name="qrcode"
                                    onClick={isGoogleAuthInit && isVpnUserFromInit ? openQrcode : undefined}
                                    tooltipText={isGoogleAuthInit && isVpnUserFromInit ? 'widgets:Vpn.qr.title' :
                                        'widgets:Vpn.qr.notActive'}
                                />}
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={duplicateRow}
                            deleteFunc={deleteRow}
                            id={'routeTableOff' + uuid}
                            onChange={({ value }) => setOff(value)}
                            settings={setRoutesOpen.swap}
                            spacing={spacing}
                        />
                    </HlcfgRowTr>
                    <tr
                        className={classNames(
                            'dataTableWidget__RowAdd',
                            { 'displayNone': !routesOpen },
                            { 'bounce-in-top': routesOpen },
                        )}
                    >
                        {routesOpen &&
                        <>
                            <td></td>

                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'packetFilter__row',
                                    { 'm-0 p-0': !routesOpen  },
                                )}
                                colSpan={5}
                            >
                                <MDBRow>
                                    <MDBCol
                                        lg="8"
                                        md="8"
                                        sm="12"
                                    >
                                        <VpnRoutingTableContext.Provider
                                            value={
                                                {
                                                    routeTablePathGetter: userPath.routes,
                                                    type: VPN_ROUTES_TYPE_PUSH_TO_USER,
                                                }
                                            }
                                        >
                                            <VpnRouteTable
                                                className={classNames(
                                                    { 'remove-height': !routesOpen },
                                                )}
                                                data={routes}
                                            />
                                        </VpnRoutingTableContext.Provider>
                                    </MDBCol>
                                    <MDBCol
                                        lg="4"
                                        md="4"
                                        sm="12"
                                    >
                                        <MDBCard className={classNames(
                                            { 'remove-height': !routesOpen },
                                        )}
                                        >
                                            <MDBCardTitle className="vpn__pushToUserHeader">
                                                <div className="d-flex align-items-center">
                                                    <Message message="widgets:Vpn.pushToUser.title" />
                                                </div>
                                                <div>
                                                    <HlcfgSwitch
                                                        align="left"
                                                        className={classNames(
                                                            'mr-2',
                                                            'justify-content-center',
                                                            'mb-0',
                                                            'mt-0'
                                                        )}
                                                        mini
                                                        name="useDefaults"
                                                        noticeMe={useDefaults}
                                                        pathGetter={userPath.useDefaults}
                                                        reverseValue
                                                        tooltipText={t(`widgets:Vpn.pushToUser.${!useDefaults ?
                                                            'deactivate' : 'activate'}`)}
                                                        withoutBorder
                                                        withoutLabelMargins
                                                        withoutMinWidhtHeight
                                                    />
                                                </div>
                                            </MDBCardTitle>
                                            <MDBCardBody>
                                                <HlcfgSelect
                                                    disabled={useDefaults}
                                                    label={t('widgets:Vpn.pushToClient.nameservers.addresses.title')}
                                                    message="dhcp-option DNS (windows only option)"
                                                    pathGetter={userPath.nameservers.addresses}
                                                />
                                                <HlcfgSwitch
                                                    align="spaceBetween"
                                                    className="mb-4"
                                                    disabled={useDefaults}
                                                    label={t('widgets:Vpn.pushToClient.nameservers.force.title')}
                                                    message="push 'block-outside-dns'"
                                                    pathGetter={userPath.nameservers.force}
                                                />
                                                <HlcfgSwitch
                                                    align="spaceBetween"
                                                    className="mb-4"
                                                    disabled={useDefaults}
                                                    label={t('widgets:Vpn.pushToClient.defaultGateway.title')}
                                                    message={'push "redirect-gateway def1"'}
                                                    pathGetter={userPath.defaultGateway}
                                                />
                                                <HlcfgSelect
                                                    disabled={useDefaults}
                                                    label={t('widgets:Vpn.pushToClient.ntp.title')}
                                                    message="dhcp-option NTP (windows only option)"
                                                    pathGetter={userPath.ntp}
                                                />
                                                <HlcfgSelect
                                                    className="mb-0 pb-0"
                                                    disabled={useDefaults}
                                                    label={t('widgets:Vpn.pushToClient.domain.title')}
                                                    message="dhcp-option DOMAIN (windows only option)"
                                                    pathGetter={userPath.domain}
                                                />
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </>
                        }
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={8}
                        types={types}
                    />
                </>
            )}
        </Draggable>
    );
};

export default VpnUserRow;
