/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { MDBCardHeader, MDBCard, MDBCardBody, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

import { TimeDuration } from '~frontendComponents/Moment/index.js';
import {  InputRangeTime } from '~frontendComponents/Generic/index.js';
import {
    databaseReinitializationRequest,
    deleteDatabaseValueRequest, getDatabaseState } from '~frontendDucks/database/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { DELETE_DATABASE_VALUES_MODAL, REINICIALIZATION_DATABASE_MODAL } from '~frontendConstants/constants.ts';
import { getGlcfgValue } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { REPORTER } from '~frontendRoot/constants/index.js';
import { DEFAULT_REPORTER_DB_MAX_DAYS } from '~sharedConstants/constants.ts';


@withTranslation()
@connect(
    (state,) => ({
        stateIcon: getDatabaseState(state),
        reporter: getGlcfgValue(state, REPORTER)

    }),
    {
        doReinitialization: databaseReinitializationRequest,
        doDelete: deleteDatabaseValueRequest,
        doSetModalState: setModalState

    }
)
class DatabaseActions extends Component {
    static get propTypes() {
        return {
            t: PropTypes.func,
            stateIcon: PropTypes.object,
            doSetModalState: PropTypes.func,
            reporter: PropTypes.object
        };
    }

    constructor(props) {
        super(props);
        const { reporter } = props;
        this.state = {
            selectedDelete: true,
            from: moment().add(-(reporter?.dbMaxDays || DEFAULT_REPORTER_DB_MAX_DAYS), 'days'),
            to: moment(),
        };
    }

    timeChange = ({ from, to }) => {
        this.setState({
            from,
            to,
        });
    };

    setModal = (isDelete) => {
        const { doSetModalState } = this.props;
        const { from, to } = this.state;
        doSetModalState({ modal: isDelete ?   DELETE_DATABASE_VALUES_MODAL :
            REINICIALIZATION_DATABASE_MODAL,
        value: true, specialValues: { from, to } });
    };

    render() {
        const { t, stateIcon } = this.props;
        const { from, to, selectedDelete } = this.state;
        return (
            <MDBCard>
                <MDBCardHeader className="database__actionsHeader">
                    <div
                        className={classNames(
                            'database__actionsHeaderDelete',
                            { 'database__actionsHeaderDelete--selected': selectedDelete }
                        )}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({
                                selectedDelete: true
                            });
                        }}
                    >
                        {t('widgets:database.action.delete.title')}
                    </div>
                    <div
                        className={classNames(
                            'database__actionsHeaderReini',
                            { 'database__actionsHeaderReini--selected': !selectedDelete }
                        )}
                        onClick={(event) => {
                            event.preventDefault();
                            this.setState({
                                selectedDelete: false
                            });
                        }}
                    >
                        {t('widgets:database.action.reinitialization.title')}
                    </div>
                </MDBCardHeader>
                <MDBCardBody className="database__actions">
                    {selectedDelete ?
                        <>
                            <MDBRow>
                                <MDBCol
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <p className="pb-0 mb-0">
                                        <span className="mb-0">{t('widgets:database.action.delete.desc')}</span>
                                    </p>
                                    <small>{t('widgets:database.action.delete.alert')}</small>
                                </MDBCol>
                                <MDBCol>
                                    <InputRangeTime
                                        endDate={to}
                                        endId="to"
                                        onChange={this.timeChange}
                                        startDate={from}
                                        startId="from"
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="database__actionsBtn">
                                    <small className="database__actionsBtn--text">
                                        {t('widgets:database.action.selectedTime')}
                                        <TimeDuration
                                            from={from}
                                            to={to}
                                        />
                                    </small>
                                    <MDBBtn
                                        color="danger"
                                        data-cy="deleteDatabase"
                                        disabled={!stateIcon.showData}
                                        onClick={() => this.setModal(true)}
                                    >
                                        {t('widgets:database.action.btn.delete')}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </> :
                        <>
                            <MDBRow>
                                <MDBCol>
                                    <div>
                                        <p className="mb-0">
                                            {t('widgets:database.action.reinitialization.desc1')}
                                            <span className="database__bold">
                                                {t('widgets:database.action.reinitialization.desc2')}
                                            </span>
                                            {t('widgets:database.action.reinitialization.desc3')}
                                        </p>
                                        <small>{t('widgets:database.action.reinitialization.alert')}</small>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol className="database__actionsBtn">
                                    <MDBBtn
                                        color="red"
                                        data-cy="reinitDatabase"
                                        onClick={() => this.setModal(false)}
                                    >
                                        {t('widgets:database.action.btn.reinitialization')}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </>}
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default DatabaseActions;
