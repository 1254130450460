/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid';

import { onMouseEnter, onMouseLeave } from '~frontendLib/onMouse.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { DEFAULT_SCHEMA_VALUE, REVERTED_BOOLEAN_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { SwitchAlignProp } from '~frontendConstants/types.ts';
import { testPropsStatus } from '~commonLib/PageObjectMap.js';

import InputMessage from '../Input/components/InputMessage.js';
import Message from '../../Message/index.js';


const getFalseAsUndefined = (value: boolean) => value || undefined;
const getValue = ({ checked, schema, revertValue }) => {
    const val = checked === undefined ? schema?.[DEFAULT_SCHEMA_VALUE] || false : checked;
    return revertValue ? !val : val;
};

const SwitchPropTypes = {
    align: SwitchAlignProp,
    dark: PropTypes.bool,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    withoutBorder: PropTypes.bool,
    isMessage: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    wrap: PropTypes.bool,
    messageOn: PropTypes.string,
    messageOff: PropTypes.string,
    rightLabel: PropTypes.bool,
    labelClassName: PropTypes.string,
    withoutLabelMargins: PropTypes.bool,
    bothActive: PropTypes.bool,
    withoutMinWidhtHeight: PropTypes.bool,
    mini: PropTypes.bool,
    falseAsUndefined: PropTypes.bool,
    tooltipText: PropTypes.string,
    withoutFlexWrap: PropTypes.bool,
    randomId: PropTypes.bool,
    noticeMe: PropTypes.bool,
    inputStyle: PropTypes.bool,
    message: PropTypes.string,
    whiteLabel: PropTypes.bool,
    reverseValue: PropTypes.bool,
    datacy: PropTypes.string,
    fake: PropTypes.bool,
    schema: PropTypes.object, //boolean schema only, probably todo
    switchClassName: PropTypes.string,
    withoutTranslation: PropTypes.bool,

};

const Switch = (props: PropTypes.InferProps<typeof SwitchPropTypes>) => {
    const {
        align = 'left',
        checked,
        className,
        id,
        label,
        disabled,
        wrap = true,
        withoutLabelMargins,
        messageOn,
        messageOff,
        rightLabel,
        labelClassName,
        bothActive,
        dark = false,
        isMessage,
        withoutBorder,
        withoutMinWidhtHeight,
        mini,
        withoutFlexWrap,
        tooltipText,
        randomId,
        noticeMe,
        inputStyle,
        message,
        whiteLabel,
        datacy = id,
        schema,
        switchClassName,
        fake,
        falseAsUndefined,
        onChange,
        name,
        reverseValue,
        withoutTranslation
    } = props;
    const revertValue = reverseValue || schema?.[REVERTED_BOOLEAN_SCHEMA_VALUE] || name === '__off' || id === '__off';
    const handleOnChange = useCallback((event) => {
        event.stopPropagation();
        if (fake) {
            return;
        }
        if (falseAsUndefined) {
            onChange({ value: revertValue ?
                getFalseAsUndefined(!event.target.checked) :
                getFalseAsUndefined(event.target.checked), id, name });
        } else {
            onChange({ value: revertValue ? !event.target.checked : event.target.checked, id, name: name });
        }
    }, [ onChange, id, name, revertValue, falseAsUndefined, fake ]);


    const alignClassName = `switch--${align}`;
    const Tag = tooltipText ? TextWithTooltip : React.Fragment;
    const tooltipTextInObject = tooltipText ? { tooltipText, withoutTranslation } : {};
    const checkedLocal = getValue({ schema, checked, revertValue });
    return (
        <Tag
            {...tooltipTextInObject}
        >
            <div
                className={classNames(
                    { 'form-group form-group--switch': wrap },
                    { 'no-wrap': !wrap },
                    { 'form-group--dark': dark && wrap },
                    className,
                )}
            >
                <div
                    className={classNames(
                        'switch',
                        { 'switch--disabled': disabled },
                        { 'switch--checked': checkedLocal },
                        { 'switch--bothActive': bothActive },
                        { 'switch--border': withoutBorder },
                        { 'switch--withoutMinWidhtHeight': withoutMinWidhtHeight },
                        { 'switch--withoutFlexWrap': withoutFlexWrap },
                        { 'switch--noticeMe': noticeMe },
                        alignClassName,
                        switchClassName
                    )}
                    id={`help__${id}`}
                    onMouseEnter={onMouseEnter(`help__${id}--text`)}
                    onMouseLeave={onMouseLeave(`help__${id}--text`)}
                >

                    {label && !rightLabel ?
                        <div className={classNames('switch__name',
                            { 'switch__name--likeLabel': inputStyle },
                            { 'switch__name--white': whiteLabel })}
                        >
                            {label}
                        </div> : null
                    }
                    <label
                        className={classNames(
                            'switch__label',
                            { 'switch__label--WithoutMargins': withoutLabelMargins },
                            { 'switch__label--likeInput': inputStyle },
                            labelClassName,
                        )}
                        data-cy={datacy}
                        {...testPropsStatus(id, checkedLocal ? 'on' : 'off')}
                    >
                        <span className={classNames(
                            'switch__off',
                            { 'switch__off--active': bothActive && !checkedLocal },
                        )}
                        >
                            {isMessage ? messageOn || <Message message="components:Switch.toggleOff" /> : null}
                        </span>
                        <input
                            checked={checkedLocal}
                            className={classNames(
                                'switch__input',
                                { 'switch__input--checked': checkedLocal }
                            )}
                            disabled={Boolean(disabled)}
                            id={randomId ? uuid() : id}
                            name={String(name)}
                            onChange={handleOnChange}
                            type="checkbox"
                        />
                        <span className={mini ? 'switch__on--mini' : 'switch__on'}>
                            {isMessage ? messageOff || <Message message="components:Switch.toggleOn" /> : null}
                        </span>
                    </label>
                    {rightLabel && label ?
                        <div className={classNames(
                            'switch__name',
                            { 'switch__name--white': whiteLabel },
                        )}
                        >
                            {label}
                        </div> : null
                    }
                </div>
                {message  &&
                <InputMessage
                    data={message}
                    id={id}

                />}
            </div>
        </Tag>
    );
};


export default Switch;
