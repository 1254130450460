/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useMemo, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import Svg from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import type { DraggableProvided } from '@hello-pangea/dnd';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Checkbox, Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/index.js';
import { useCombinedRefs } from '~frontendLib/reactUtils.js';
import { countValuesFromObject } from '~frontendLib/objectUtils.js';
import logo from '~frontendRoot/img/svg/logo.svg';
import { PROFILE_CATEGORIES, PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY,
    PROFILE_RULE_ACTION_BYPASS,
    REQUIRE_AUTH, TLS_INSPECTION, CWV2_CATEGORIES,
    LOW_LEVEL_POLITIC,
    MEDIUM_LEVEL_POLITIC,
    OWN_LEVEL_POLITIC,
    HIGH_LEVEL_POLITIC,
    PROFILE_RULE_ACTION_BLOCK,
    CW_BLOCK_HIGH_CATEGORIES,
    CW_LOW_CATEGORIES,
    CW_BLOCK_MEDIUM_CATEGORIES,
    CW_MEDIUM_CATEGORIES,
    CW_BLOCK_LOW_CATEGORIES,
    CW_HIGH_CATEGORIES } from '~sharedConstants/index.ts';
import {
    PROFILE_RULE_COLUMNS_DRAG,
    PROFILE_RULE_COLUMNS_ACTION,
    PROFILE_RULE_COLUMNS_NAME,
    PROFILE_RULE_COLUMNS_USER,
    PROFILE_RULE_COLUMNS_GROUP,
    PROFILE_RULE_COLUMNS_CLIENT,
    PROFILE_RULE_COLUMNS_SERVER,
    PROFILE_RULE_COLUMNS_CATEGORY,
    PROFILE_RULE_COLUMNS_USERAGENT,
    PROFILE_RULE_COLUMNS_CONTENT_POLICY,
    PROFILE_RULE_COLUMNS_LOG,
    PROFILE_RULE_COLUMNS_ACTION_PARAM,
    TableSizeType,
    userSetting,
    SELECTABLE_TABLE_PROFILES,
    PROFILE_RULE_COLUMNS_INTERVALS,
    PROFILE_RULE_COLUMNS_DAYS,
    PROXY_TIME_INTERVALS,
    PROFILE_RULE_COLUMNS_POLITICS,
    PROFILE_RULES,
    PROFILES
} from '~frontendConstants/index.js';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import RowDivider from '~frontendRoot/components/RowDivider.tsx';
import { Offable } from '~frontendRoot/constants/types.ts';
import { HlcfgTableItemSchema } from '~frontendRoot/types/externalTypes.ts';
import { getTableById, getTableItem, getTableSchemaGetter, isProfileAuthenticationEnabled, isTlsInspectionEnabled, setProxyActionCategories, setProxyCategories } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { makeSelectGetProfileRule, makeSelectSearchedTableItem } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { hlcfgRowIdIsFromTable, hlcfgRowObjectIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { useBoolean, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import Message from '~frontendRoot/components/Message/index.js';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { range } from '~commonLib/arrayUtils.ts';
import { objectWithKeys } from '~commonLib/objectUtils.ts';
import createOptionsArray, { OptionObjectType } from '~frontendComponents/Generic/Select/utils.tsx';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';

import { timeIntervalObj } from './timeIntervalObj.ts';
import GetActionParam from './components/GetActionParam.tsx';
import Categories from './components/Categories.tsx';
import ActionCategories from './components/ActionCategories.tsx';
import CWCategoriesV2 from './components/CWCategoriesV2.tsx';


const isOurHeader = (type) => type === REQUIRE_AUTH || type === TLS_INSPECTION;


const RenderEmptyUserRulesProps = {
    addRow: PropTypes.func.isRequired,
    addHeader: PropTypes.func.isRequired,
};

export const RenderEmptyUserRules = (
    { addRow,  addHeader }: InferProps<typeof RenderEmptyUserRulesProps>
) => {
    const { t } = useTranslation();
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PROFILES);

    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRule"
        >
            <td colSpan={1}></td>
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={getColumnsSelectedLength() - 1}
            >
                <div className="pl-2">
                    <h2>{t('profile:profiles.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:profiles.rules.title')}

                        {t('profile:profiles.rules.desc1')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={addRow}
                        >
                            <Icon name="plus" /> {t('profile:profiles.rules.rule')}
                        </MDBBtn>
                        {t('profile:profiles.rules.desc2')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            data-cy="adding-header-button"
                            onClick={addHeader}
                        >
                            <Icon name="plus" /> {t('packetFilter:header')}
                        </MDBBtn>
                        {t('profile:profiles.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:profiles.rules.desc4')}
                    (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('profile:profiles.rules.desc5')}
                    </p>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        onClick={addRow}
                    >
                        <Icon name="plus" /> {t('profile:profiles.rules.rule')}
                    </MDBBtn>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-header-button"
                        onClick={addHeader}
                    >
                        <Icon name="plus" /> {t('packetFilter:header')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );};

RenderEmptyUserRules.propTypes = RenderEmptyUserRulesProps;


const RenderDividerProps = {
    uuid: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    setValue: PropTypes.func,
    delRow: PropTypes.func,
    copyRow: PropTypes.func,
    closeRules: PropTypes.func.isRequired,
    spacing: PropTypes.string,
};

interface RenderDividerType extends InferProps<typeof RenderDividerProps> { provided: DraggableProvided }


export const RenderDivider = ({
    uuid,
    types,
    setValue,
    delRow,
    closeRules,
    spacing,
    provided,

}: RenderDividerType) => {
    const getter = useMemo(makeSelectGetProfileRule, []);
    const data =
        useSelector<any, Offable<'profileHeader'|'profileSpecialItem'>>(state => getter(state, uuid));
    const schemas = useSelector <any, HlcfgTableItemSchema<'profileHeader'>>(getTableSchemaGetter(uuid));
    const ref = useCombinedRefs(provided.innerRef);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PROFILES);

    const authenticationEnabled = useSelector(isProfileAuthenticationEnabled);
    const tlsInspectionEnabled = useSelector(isTlsInspectionEnabled);

    const close = useCallback(() => {
        if (hlcfgRowObjectIsFromTable(data, 'profileHeader')) {
            closeRules({ value: !data.closed });
        }
    }, [ closeRules, data ]);

    const { t } = useTranslation();
    if (!data.id) {
        return (
            <tr
                className="dataTableWidget__Row--closed"
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            />
        );
    }
    return (
        <>
            <RowDivider
                id={data.id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames(
                    'dataTableWidget__Row--important',
                    'dataTableWidget__header',
                    { 'dataTableWidget__Row--disable':
                             data.type === REQUIRE_AUTH && !authenticationEnabled },
                    { 'dataTableWidget__Row--disable':
                             data.type === TLS_INSPECTION && !tlsInspectionEnabled },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
                key={data.id}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    onClick={close}
                >
                    {
                        hlcfgRowObjectIsFromTable(data, 'profileHeader') ?
                            <Icon name={data.closed ? 'chevron-right' : 'chevron-down'} /> :
                            <Svg
                                height="24"
                                src={logo}
                                width="24"
                            />

                    }
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    colSpan={getColumnsSelectedLength() - 2}
                    onClick={closeRules}
                >
                    <Input
                        className={classNames(
                            'dataTableWidget__RowInput',
                            { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                        )}
                        disabled={isOurHeader(data.type)}
                        id={'profileName' + data.id}
                        isName
                        name="name"
                        onChange={setValue}
                        schema={schemas?.name}
                        value={hlcfgRowObjectIsFromTable(data, 'profileSpecialItem') ?
                            t(`profile:rule.${data.type}`) : data.name}
                        withoutBorder
                    />

                </td>
                <RowMenuAndSwitch
                    deleteButtonDisabled={hlcfgRowObjectIsFromTable(data, 'profileHeader') && data.closed}
                    deleteFunc={delRow}
                    id={'profilesHeader' + data.id}
                    menu={!isOurHeader(data.type)}
                    spacing={spacing}
                />
            </tr>
        </>
    );
};

RenderDivider.propTypes = RenderDividerProps;

const politics: OptionObjectType[] = [
    {
        name: LOW_LEVEL_POLITIC,
        color: 'primary',
        iconName: 'speedometerSlow',
    },
    {
        name: MEDIUM_LEVEL_POLITIC,
        color: 'yellow',
        iconName: 'speedometerMedium',
    },
    {
        name: HIGH_LEVEL_POLITIC,
        color: 'red',
        iconName: 'speedometer',
    },
    {
        name: OWN_LEVEL_POLITIC,
        color: 'secondary',
        iconName: 'shield-account',
    }
];
const politicOptions = createOptionsArray(politics, 'profile:politics.');

const actions: OptionObjectType[] = [
    {
        name: 'allow',
        color: 'primary',
        iconName: 'shield-check-outline',
    },
    {
        name: 'block',
        color: 'red',
        iconName: 'shield-off-outline',
    },
    {
        name: 'accordingToCategory',
        iconName: 'accordingToNewCategory'
    }
];
const actionOptions = createOptionsArray(actions, 'widgets:enum.');


const RenderRuleProps = {
    setValueObject: PropTypes.func.isRequired,
    handleTimeIntervals: PropTypes.func.isRequired,
    uuid: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    setValue: PropTypes.func,
    delRow: PropTypes.func,
    copyRow: PropTypes.func,
    validateUser: PropTypes.func,
    cwdbV2IsEnabled: PropTypes.bool.isRequired,
};

interface RenderRuleType extends InferProps<typeof RenderRuleProps> { provided: DraggableProvided,
     spacing: TableSizeType, search?: string }

export const RenderRule = ({
    //data
    types,
    uuid,
    provided,
    spacing,
    search,
    cwdbV2IsEnabled,

    //functions
    setValue,
    setValueObject,
    handleTimeIntervals,
    delRow,
    copyRow,
    validateUser,

}: RenderRuleType) => {

    assert(hlcfgRowIdIsFromTable(uuid, 'profileRule'));
    const pathGetter = getRowPathGetter(uuid);
    const getter = useMemo(makeSelectGetProfileRule, []);
    const [ showDetail, setShowDetail ] = useBoolean();
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_PROFILES);
    const activeProfile = useSelector(state => getActiveCard(state, PROFILES));
    const profileObject = useSelector(state => getTableItem(state, activeProfile));
    const groupDisabled = profileObject?.parameters?.authentication?.type === 'basic/text' &&
        !profileObject?.parameters?.authentication?.__off;
    const data =
        useSelector<any, Offable<'profileRule'>>(state => getter(state, uuid));
    const schemas = useSelector <any, HlcfgTableItemSchema<'profileRule'>>(getTableSchemaGetter(uuid));
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);
    const { t } = useTranslation();
    const ref = useCombinedRefs(provided.innerRef);
    const intervalsFromTable = useSelector(state => getTableById(state, PROXY_TIME_INTERVALS));
    const dispatch = useDispatch();

    if (data.closed) {
        return (
            <tr
                className="dataTableWidget__Row--closed"
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            />
        );
    }
    const { id, name, user, group, categories, timeConstraints,
        userAgent, log, action, contentPolicy, __off, fake, cwCategoriesV2 } = data;
    const checked = checkedWeekdays(timeConstraints?.weekdays, schemas);
    const isByCategory = action?.type === PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY;
    const isBlockRule = action?.type === PROFILE_RULE_ACTION_BLOCK;
    if (!id) {
        return null;
    }
    const intervalsByUuid = (intervals) => {
        if (intervals) {
            return intervals.map(element => intervalsFromTable[element]).reverse();
        }
    };
    const addWeekday = ({ name, value, id }) => {
        if (value === 'all') {
            const currentValue = checked.length === 7;
            setValueObject({ name, id, value: {
                [0]: !currentValue,
                [1]: !currentValue,
                [2]: !currentValue,
                [3]: !currentValue,
                [4]: !currentValue,
                [5]: !currentValue,
                [6]: !currentValue
            }
            });
        }
        else {
            const currentValue = timeConstraints?.weekdays?.[value] ??
            schemas.timeConstraints.properties.weekdays.properties[value][DEFAULT_SCHEMA_VALUE];
            setValueObject({ name, id, value: {
                ...timeConstraints?.weekdays ?? {},
                [value]: !currentValue
            }
            });
        }
    };

    const handleChangeSupercategory = (level) => {
        isByCategory ?
            dispatch(setProxyActionCategories({ uuid, type: PROFILE_RULES,
                value: objectWithKeys(level, PROFILE_RULE_ACTION_BLOCK) })) :
            dispatch(setProxyCategories({ uuid, type: PROFILE_RULES,
                value: objectWithKeys(level, false) }));
    };

    const setPolitics = ({ value }) => {
        if (value === HIGH_LEVEL_POLITIC) {
            const high = isBlockRule ? CW_BLOCK_HIGH_CATEGORIES : CW_HIGH_CATEGORIES;
            handleChangeSupercategory(high);
        } else if (value === MEDIUM_LEVEL_POLITIC) {
            const medium = isBlockRule ? CW_BLOCK_MEDIUM_CATEGORIES : CW_MEDIUM_CATEGORIES;
            handleChangeSupercategory(medium);
        } else if (value === LOW_LEVEL_POLITIC) {
            const low = isBlockRule ? CW_BLOCK_LOW_CATEGORIES  : CW_LOW_CATEGORIES;
            handleChangeSupercategory(low);
        } else {
            handleChangeSupercategory(CWV2_CATEGORIES);
        }
    };
    const getPolitics = () => {
        const data = isByCategory ? action.accordingToCwCategoryV2 : cwCategoriesV2;
        if (fake) {
            return;
        }
        if (!data) {
            return OWN_LEVEL_POLITIC;
        }
        const categoryIs = level => level.every(category => data?.[category] !==
            undefined) && level.length === Object.keys(data).length;

        const high = isBlockRule ? CW_BLOCK_HIGH_CATEGORIES : CW_HIGH_CATEGORIES;
        if (categoryIs(high)) {
            return HIGH_LEVEL_POLITIC;
        }

        const medium = isBlockRule ? CW_BLOCK_MEDIUM_CATEGORIES : CW_MEDIUM_CATEGORIES;
        if (categoryIs(medium)) {
            return MEDIUM_LEVEL_POLITIC;
        }

        const low = isBlockRule ? CW_BLOCK_LOW_CATEGORIES : CW_LOW_CATEGORIES;
        if (categoryIs(low)) {
            return LOW_LEVEL_POLITIC;
        }
        return OWN_LEVEL_POLITIC;
    };

    const options = [
        {
            id: 'weekdayAll',
            label: (
                <OptionItem
                    checked={checked.length === 7}
                    message="profile:weekdays.all"
                />),
            value: 'all'
        },
        ...range(7).map(index => ({
            id: `weekday${index}`,
            label: (
                <OptionItem
                    checked={checked.includes(index)}
                    message={`profile:weekdays.${index}`}
                />),
            value: index
        }))
    ];

    const exceptionArray = [ PROFILE_RULE_ACTION_BYPASS, PROFILE_RULE_ACTION_ACCORDING_TO_CATEGORY ];
    return (
        <>
            <RowDivider
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'profiles__row',
                    { 'dataTableWidget__Row--disable': __off },
                    { 'dataTableWidget__Row--match': searchMatches },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
                ref={ref}
                {...provided.draggableProps}
                key={id}
            >
                {getColumnsShow(PROFILE_RULE_COLUMNS_DRAG) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--match': searchMatches },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                                {...provided.dragHandleProps}
                            >
                                {
                                    fake ?
                                        <Svg
                                            className={classNames(
                                                { 'opacity-04': __off }
                                            )}
                                            height="24"
                                            src={logo}
                                            width="24"
                                        /> :

                                        <Icon
                                            name="drag"
                                            size="sm"
                                        />
                                }
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_ACTION) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                        { 'w-3p5': action.type === 'accordingToCategory' }
                                    )}
                                    disabled={__off}
                                    exceptions={fake ? exceptionArray : undefined}
                                    id="type"
                                    isRow
                                    justIcon
                                    name="action"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    noWrap
                                    onChange={setValueObject}
                                    options={cwdbV2IsEnabled && !fake ? actionOptions : undefined}
                                    paste={false}
                                    schema={!cwdbV2IsEnabled || fake ? schemas?.action?.properties?.type : undefined}
                                    value={action.type}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_NAME) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Input
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                        'packetFilter__nameInput'
                                    )}
                                    disabled={__off || fake}
                                    inputClass="'dataTableWidget__cell--leftPadding'"
                                    isName
                                    name="name"
                                    onChange={setValue}
                                    schema={schemas?.name}
                                    value={name}
                                    withoutBorder
                                    withoutPaddingLeft
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_USER) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off || fake}
                                    id={'profileUser' + id}
                                    isCreatable
                                    isMulti
                                    isRow
                                    maxValueShown={3}
                                    name="user"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    paste
                                    schema={schemas.user}
                                    validator={validateUser}
                                    value={user}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_GROUP) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off || fake || groupDisabled}
                                    id={'profileGroup' + id}
                                    isCreatable
                                    isMulti
                                    isRow
                                    maxValueShown={3}
                                    name="group"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    paste
                                    schema={schemas.group}
                                    tooltipText={groupDisabled ? 'widgets:Authentication.basicUserTable.groupWarning' :
                                        false}
                                    validator={validateUser}
                                    value={group}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_CLIENT) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <HlcfgSelect
                                    className={`select2--row select2--${spacing}`}
                                    disabled={__off || fake}
                                    maxItemsToDisplay={3}
                                    pathGetter={pathGetter.client}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_SERVER) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <HlcfgSelect
                                    className={`select2--row select2--${spacing}`}
                                    disabled={__off || fake}
                                    maxItemsToDisplay={3}
                                    pathGetter={pathGetter.server}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_INTERVALS) &&
                   <td
                       className={classNames(
                           'dataTableWidget__cell',
                           { [`dataTableWidget__cell--${spacing}`]: spacing },
                       )}
                   >
                       <Select
                           className={classNames(
                               'dataTableWidget__RowInput',
                               { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                           )}
                           disabled={__off || fake}
                           id="times"
                           isCreatable
                           isMulti
                           isRow
                           maxValueShown={3}
                           name="timeConstraints"
                           noDropdownIndicator
                           noOptionsMessage
                           onChange={timeIntervalObj.getValueSetter(handleTimeIntervals)}
                           schema={schemas.timeConstraints.properties.times}
                           validator={timeIntervalObj.isValid}
                           value={timeIntervalObj.stringify(intervalsByUuid(timeConstraints?.times))}
                       />
                   </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_DAYS) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            closeMenuOnSelect={false}
                            disabled={__off || fake}
                            id="weekdays"
                            isRow
                            name="timeConstraints"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={addWeekday}
                            options={options}
                            paste={false}
                            schema={schemas.timeConstraints.properties.weekdays}
                            value={`${checked.length}/7`}
                        />
                    </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_POLITICS) && cwdbV2IsEnabled &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className={classNames(
                                'dataTableWidget__RowInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={__off || fake}
                            id="politics"
                            isRow
                            justIcon
                            name="rulePolitics"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={setPolitics}
                            options={politicOptions}
                            paste={false}
                            value={getPolitics()}
                        />
                    </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_CATEGORY) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--leftPadding',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                                onClick={__off || fake ? undefined : setShowDetail.swap}
                            >
                                <div
                                    className={classNames(
                                        'policy__selectIcon',
                                        'dataTableWidget__cell--clicable',
                                        {
                                            'dataTableWidget__cell--disabled': isByCategory || fake
                                        },
                                    )}
                                >
                                    {isByCategory ?
                                        <TextWithTooltip
                                            className="pl-1 dataTableWidget__cell--disabled"
                                            text={cwdbV2IsEnabled ?
                                                `${CWV2_CATEGORIES.length}/${CWV2_CATEGORIES.length}` :
                                                `${PROFILE_CATEGORIES.length}/${PROFILE_CATEGORIES.length}`}
                                            tooltipText={t('profile:profiles.rules.categories')}
                                            withoutTranslation
                                        /> :
                                        <span className={classNames(
                                            'pl-1',
                                            {
                                                'dataTableWidget__cell--disabled': __off
                                            },
                                        )}
                                        >
                                            <CheckedCategories
                                                categories={categories}
                                                cwCategoriesV2={cwCategoriesV2}
                                                cwdbV2IsEnabled={cwdbV2IsEnabled}
                                            />
                                        </span>
                                    }
                                </div>

                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_USERAGENT) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--leftPadding',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off}
                                    id={'profileuserAgent' + id}
                                    isCreatable
                                    isMulti
                                    name="userAgent"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    value={userAgent}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_CONTENT_POLICY) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--leftPadding',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    id="name"
                                    name="contentPolicy"
                                    noDropdownIndicator
                                    onChange={setValue}
                                    value={contentPolicy?.name}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_LOG) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    'icon--clicable',
                                    { 'profile__log': !__off },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off}
                                    id={'log' + id}
                                    isRow
                                    justIcon
                                    name="log"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    noWrap
                                    onChange={setValue}
                                    paste={false}
                                    schema={schemas?.log}
                                    value={log}
                                />
                            </td>
                }
                {getColumnsShow(PROFILE_RULE_COLUMNS_ACTION_PARAM) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--leftPadding',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <GetActionParam
                                    action={action}
                                    disabled={Boolean(__off)}
                                    isNewCW={cwdbV2IsEnabled}
                                    onClick={__off ? undefined : setShowDetail.swap}
                                />
                            </td>
                }
                <RowMenuAndSwitch
                    __off={__off}
                    copyFunc={copyRow}
                    deleteFunc={delRow}
                    id={'profilesRule' + id}
                    menu={!fake}
                    onChange={fake ? undefined : setValue}
                    spacing={spacing}
                />
            </tr>
            <tr
                className={classNames(
                    'dataTableWidget__RowAdd',
                    'categories__More__background',
                    'nft',
                    { 'bounce-in-top': showDetail },
                )}
            >
                {showDetail &&
                        <>
                            <td className="relative">


                            </td>
                            <td colSpan={getColumnsSelectedLength() - 2}>
                                {cwdbV2IsEnabled ?
                                    <CWCategoriesV2
                                        action={action.type}
                                        actionData={action.accordingToCwCategoryV2}
                                        byCategory={isByCategory}
                                        data={cwCategoriesV2}
                                        spacing={spacing}
                                        uuid={id}
                                    /> :
                                    isByCategory ?
                                        <ActionCategories
                                            data={action.accordingToCategory}
                                            spacing={spacing}
                                            uuid={id}
                                        /> :

                                        <Categories
                                            data={categories}
                                            spacing={spacing}
                                            uuid={id}
                                        />
                                }
                            </td>
                            <td className="relative">
                                <Icon
                                    className="profiles__close"
                                    name="close"
                                    onClick={setShowDetail.off}
                                />
                            </td>
                        </>
                }
            </tr>
        </>
    );
};

const checkedWeekdays = (weekdays, schema: HlcfgTableItemSchema<'profileRule'>) => {
    const checked: number[] = [];
    for (let index = 0; index < 7; index++) {
        if ((weekdays && weekdays[index]) ??
                schema.timeConstraints.properties.weekdays.properties[index][DEFAULT_SCHEMA_VALUE]) {
            checked.push(index);
        }
    }
    return checked;
};

interface OptionItemProps {
    checked: boolean,
    message: string,
}

const OptionItem = ({ checked, message }: OptionItemProps) => {
    return (
        <div className="spaceBetween w-100">
            <Message message={message} />
            <Checkbox
                checked={checked}
                wrap={false}
            />
        </div>
    );
};

type CheckedCategoriesType = {
    cwdbV2IsEnabled: boolean,
    categories?: Record<string, boolean>,
    cwCategoriesV2?: Record<string, boolean>,
}

const CheckedCategories = ({ cwdbV2IsEnabled, categories, cwCategoriesV2 }: CheckedCategoriesType) => {
    return (
        <span>
            {cwdbV2IsEnabled ? `${CWV2_CATEGORIES.length - countValuesFromObject(cwCategoriesV2, false)}/
                ${CWV2_CATEGORIES.length}` :
                `${PROFILE_CATEGORIES.length - countValuesFromObject(categories, false)}/${PROFILE_CATEGORIES.length}`
            }
        </span>
    );
};

RenderRule.propTypes = RenderRuleProps;
