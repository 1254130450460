/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const ADAPTIVE_FIREWALL = 'adaptiveFirewall';
export const ADDRESSES_TABLE = 'addressesTable';
export const AK_EVENTS_FEEDBACK_ENABLED = 'akEventsFeedbackEnabled';
export const ANTIVIRUS = 'antivirus';
export const CLOUD_BACKUP_FEEDBACK_ENABLED = 'cloudBackupFeedbackEnabled';
export const CLUSTER_SHARED = 'clusterShared';
export const CLUSTER_NODE = 'clusterNode';
export const CLUSTER_STEP_A = 'clusterStepA';
export const CLUSTER_STEP_B = 'clusterStepB';
export const CONFIGURATION_FEEDBACK_ENABLED = 'configurationFeedbackEnabled';
export const CW_EVENTS_FEEDBACK_ENABLED = 'cwEventsFeedbackEnabled';
export const DASHBOARDS = 'dashboards';
export const DHCPD = 'dhcpd';
export const DNS = 'dns';
export const DOMAIN = 'domain';
export const DOMAIN_CONTROLLERS = 'domainControllers';
export const ERRORS_FEEDBACK_ENABLED = 'errorsFeedbackEnabled';
export const FIREWALL_CLUSTER_A_HOSTNAME = 'firewallClusterAHostname';
export const FIREWALL_CLUSTER_A_MASTERKEY = 'firewallClusterAMasterkey';
export const FIREWALL_CLUSTER_A_NODE = 'firewallClusterANode';
export const FIREWALL_CLUSTER_B_HOSTNAME = 'firewallClusterBHostname';
export const FIREWALL_CLUSTER_B_NODE = 'firewallClusterBNode';
export const GUI_ADDRESSES_HTTP = 'guiAddressesHTTP';
export const GUI_ADDRESSES_HTTPS = 'guiAddressesHTTPS';
export const GW4 = 'gw4';
export const GW6 = 'gw6';
export const HONEYPOT = 'honeypot';
export const HOSTNAME = 'hostname';
export const HOSTS = 'hosts';
export const INTERFACES = 'interfaces';
export const IPV6_ENABLED = 'ipv6Enabled';
export const IS_CLUSTER = 'isCluster';
export const LICENSE = 'license';
export const GUI_PUB_KEY = 'guiPubkey';
export const GUI_PRIV_KEY = 'guiPrivkey';
export const MULTIHOMING = 'multihoming';
export const NAMED_OBJECTS_NETADDR_SCALAR = 'namedObjectsNetaddrScalar';
export const NAMED_OBJECTS_NETADDR_SCALAR_TABLE = 'netaddrScalar';
export const NAMED_OBJECTS_NETADDR_VECTOR = 'namedObjectsNetaddrVector';
export const NAMED_OBJECTS_NETADDR_VECTOR_TABLE = 'netaddrVector';
export const NTP1 = 'ntp1';
export const NTP2 = 'ntp2';
export const OPENVPN_USER = 'openvpnUser';
export const PACKET_FILTER_RULES = 'packetFilterRules';
export const PROXY_TIME_INTERVALS = 'profileRuleTimeInterval';
export const PROFILE_RULES = 'profileRules';
export const PROFILES = 'profiles';
export const WAF_PROFILES = 'wafProfile';
export const DNS_PROXY_PROFILES = 'dnsProxyProfile';
export const PROXY = 'proxy';
export const QOS = 'qos';
export const QOS_NODES = 'qosNodes';
export const REALM = 'realm';
export const REPORTER = 'reporter';
export const REPORTER_FEEDBACK_ENABLED = 'reporterFeedbackEnabled';
export const RESOLVER = 'resolver';
export const ROUTE = 'route';
export const ROUTING_ENABLED = 'routingEnabled';
export const ROUTING_TABLE = 'routingTable';
export const SECURITY_ADMIN = 'securityAdmin';
export const SNMP_ADDRESSES = 'snmpAddresses';
export const SNMP_AUTH_PASSWORD = 'snmpAuthPassword';
export const SNMP_CIPHER = 'snmpCipher';
export const SNMP_ENC_PASSWORD = 'snmpEncPassword';
export const SNMP_HASH = 'snmpHash';
export const SNMP_OFF = 'snmpOff';
export const SNMP_RIGHTS = 'snmpRights';
export const SNMP_USERNAME = 'snmpUsername';
export const SSH_KEYS = 'sshKeys';
export const SSHD_SOCKETS = 'sshdSockets';
export const SURICATA_AUTO_UPDATE = 'suricataAutoUpdate';
export const SURICATA_BETA_SERVER_ENABLED = 'suricataBetaServerEnabled';
export const SURICATA_BLACKLIST = 'suricataBlacklist';
export const SURICATA_BLACKLIST_COMMENT = 'suricataBlacklistComment';
export const SURICATA_INTERFACES = 'suricataInterfaces';
export const SURICATA_IPS_MODE = 'suricataIpsMode';
export const SURICATA_VARIABLES = 'suricataVariables';
export const SURICATA_WHITELIST = 'suricataWhitelist';
export const SURICATA_WHITELIST_COMMENT = 'suricataWhitelistComment';
export const SYSTEM_STATUS_FEEDBACK_ENABLED = 'systemStatusFeedbackEnabled';
export const TIMEZONE = 'timezone';
export const UPGRADE = 'upgrade';
export const VERSION = 'version';
export const VPN = 'vpn';
export const IPSEC = 'ipsec';
export const NFT_DIVIDER = 'nftDivider';
export const NFT_RULE = 'nftRule';
export const MULTIHOMING_GATEWAY = 'multihomingGateway';
export const TABLES = 'tables';
export const JOURNAL = 'journal';
export const BASIC_AUTH_TABLE = 'basicAuthTable';


export const QOS_NODE_TABLE_TYPE = 'tcNode';
export const PROXY_AUTHENTICATION_KEY = 'authentication';
