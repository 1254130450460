/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { MDBCardTitle, MDBCard, } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, InputSearch, SelectColumns } from '~frontendComponents/Generic/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_WAF_ADDRESSES_POLICY, TableSizeType } from '~frontendRoot/constants/index.js';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';

import Row from './Row/index.ts';
import { SocketAddressesFuncContext } from './SocketAddressesFuncContext.ts';


const CreateRow = ({ dataIndex, uuid, spacing, search }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid || 'addingRow'}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


type SocketAddressesPropsType = {
    spacing?: TableSizeType,
    isHiddenTableShow?: true
};

const SocketAddresses = ({ spacing, isHiddenTableShow }: SocketAddressesPropsType) => {
    const { t } = useTranslation();
    const { passReorderData, addressesType, selector } = useContext(SocketAddressesFuncContext);

    const data = useSelector(selector);

    const [ hide, setHide ] = useBoolean();
    const [ searchValue, setSearchValue ] = useString();

    return (
        <MDBCard className={classNames('profile__card', { 'network__card': !isHiddenTableShow })}>
            <MDBCardTitle className={classNames(
                'profiles__title',
                { 'profiles__title--noBorderBottom': !hide },
            )}
            >

                <div> {t(`profile:${addressesType}.addressTable.title`)}
                    {isHiddenTableShow &&
                    <Icon
                        name={hide ? 'chevron-down' : 'chevron-up'}
                        onClick={setHide.swap}
                    />
                    }
                </div>

                <div className="profiles__titleActions">

                    {!hide &&
                 <>
                     <SelectColumns id="addressesPolicy" />
                     <InputSearch
                         className="mb-0 mt-0"
                         id="searchValueIDVlans"
                         search={searchValue}
                         setter={setSearchValue}
                     />
                 </>
                    }
                </div>
            </MDBCardTitle>
            <div className={classNames(
                { 'overflow-auto': !isHiddenTableShow },
                'mb-3',
                { 'profile__body--hide': hide },
                { 'profiles__title--borderBottom': !hide }
            )}
            >
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_WAF_ADDRESSES_POLICY}
                    createRow={CreateRow}
                    data={data?.length ? data : [ NEW_ROW_CONSTANT ]}
                    isDraggable={true}
                    passReorderData={passReorderData}
                    search={searchValue}
                    spacing={spacing}
                />
            </div>
        </MDBCard>

    );
};

export default SocketAddresses;
