/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { v4 as uuidv4 } from 'uuid';

import { put, takeEvery, select, call } from '~commonLib/reduxSagaEffects.ts';
import { createNotification } from '~frontendLib/reactUtils.js';
import { IPV6_ENABLED, NODE_A_ID, TABLES } from '~frontendRoot/constants/index.js';
import { copyName, generateRandomColor } from '~frontendRoot/lib/stringUtils.js';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ, REQUIRE_AUTH_UUID, TLS_INSPECTION_UUID } from '~sharedConstants/index.ts';
import tFilter from '~sharedLib/reporterLibrary/tFilter.js';
import { SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_NEGATABLE_NETADDR_LIST, SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY } from '~sharedLib/schemaTypes.ts';
import { hlcfgTableNameByRowId, hlcfgUuidByRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { queries } from '~frontendQueries/queries.ts';
import { queryClient } from '~frontendQueries/client.ts';

import {
    getIsCluster, getProfileRulesIds, getTableItem, getTableItemSiblings, isFromConfiguration
} from './glcfgGettersAndSetters.js';
import { getDefaultNormalizedTableGetters, getGlcfgSchema, getGlcfgSetterActionType,
    getGlcfgValue } from './glcfgGettersAndSettersUtils.ts';
import { getMyNode } from '../clusterSetup/index.js';


const {
    getSchema: getTableSchema,
} = getDefaultNormalizedTableGetters();

export const createObjectFromSchema = ({ createSchema, newUuid, names, extraValues }) => {
    const newObject = {};
    if (!createSchema) {
        return newObject; //SET EMPTY OBJECT
    }
    for (const requiredProp of createSchema.required || EMPTY_IMMUTABLE_ARR) {
        const defaultSchemaValue = createSchema.properties[requiredProp];
        if (requiredProp === 'id') {
            newObject[requiredProp] = newUuid;
        } else if (DEFAULT_SCHEMA_VALUE in defaultSchemaValue) {
            if (requiredProp === 'name') {
                newObject[requiredProp] = names?.length ? copyName(
                    `${tFilter(defaultSchemaValue[DEFAULT_SCHEMA_VALUE])}`, names, defaultSchemaValue?.maxLength
                ) :
                    tFilter(defaultSchemaValue[DEFAULT_SCHEMA_VALUE]);
            } else {
                newObject[requiredProp] = defaultSchemaValue[DEFAULT_SCHEMA_VALUE];
            }
        } else if (defaultSchemaValue.type === 'string') {
            newObject[requiredProp] = undefined;
        } else if (defaultSchemaValue.type === 'object') {
            newObject[requiredProp] = createObjectFromSchema({
                createSchema: defaultSchemaValue,
                names,
            });
        } else if (defaultSchemaValue.type === 'array') {
            newObject[requiredProp] = EMPTY_IMMUTABLE_ARR;
        } else if (defaultSchemaValue[SCHEMA_TYPE_NEGATABLE_NETADDR_LIST]) {
            newObject[requiredProp] = {
                list: []
            };
        } else {
            continue;
        }
    }
    if (createSchema?.properties?.color) {
        newObject['color'] = generateRandomColor();
    }
    if (extraValues) {
        return {
            ...newObject,
            ...extraValues,
        };
    }
    return newObject;
};

/** Names of tables that contain singleton rows. Those rows need to keep their rowId at all times. */
const exceptions = [ 'profileSpecialItem' ];

let id = 0;

const uuidGetter = (type, uuid) => {
    // creates same ID as was before
    if (exceptions.includes(type)) {
        return hlcfgUuidByRowId(uuid);
    }
    if (window.Cypress) {
        id++;
        return `id${id}`;
    }
    return uuidv4();
};

export const getHlcfgRowUuid = (type, uuid) => `${type}:${uuidGetter(type, uuid)}`;

const ADD_TO_NORMALIZE = '/ak/hlcfgEditor/ADD_TO_NORMALIZE';
const DELETE_FROM_NORMALIZE = '/ak/hlcfgEditor/DELETE_TO_NORMALIZE';
const DUPLICATE_FROM_NORMALIZE = '/ak/hlcfgEditor/DUPLICATE_TO_NORMALIZE';
const SET_NORMALIZE_ORDER_PROFILE_RULES = '/ak/hlcfgEditor/SET_NORMALIZE_ORDER_PROFILE_RULES';
const UPDATE_INTERFACE = '/ak/hlcfgEditor/UPDATE_INTERFACE';


export const addNormalize = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(props.type), action: 'add' });

export const setNormalize = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), action: 'setParameters' });

export const setObjectNormalize = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(props.glcfgKey), action: 'setParameters' });

export const updateNormalize = (props) => //specialInterface case
    ({ ...props, type: getGlcfgSetterActionType(TABLES), action: 'update' });

export const updateInterface = (props) => //specialInterface case
    ({ ...props, type: UPDATE_INTERFACE });

export const deleteNormalize = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(props.type), action: 'delete' });

export const copyNormalize = (props) =>
    ({ ...props, type: getGlcfgSetterActionType(TABLES), action: 'copy' });

export const setNormalizeOrder = (props) =>
    ({  ...props, type: getGlcfgSetterActionType(props.type), action: 'order' });

// SPECIFIC
export const setNormalizeOrderProfileRules = (actions) => ({ type: SET_NORMALIZE_ORDER_PROFILE_RULES, actions });

export const addToNormalize = (actions) => ({ type: ADD_TO_NORMALIZE, actions });
export const deleteFromNormalize = (actions) => ({ type: DELETE_FROM_NORMALIZE, actions });
export const duplicateFromNormalize = (actions) => ({ type: DUPLICATE_FROM_NORMALIZE, actions });


export const workerAddToNormalizeTable = function* (props) {
    try {
        const { typeId, type, key, subkey, uuid, addingAfter, successText, extraValues,
            newUuid = getHlcfgRowUuid(type), array = true, objectKey, uuidToAddBefore } = props.actions;

        const createSchema = yield select(getGlcfgSchema, TABLES);
        const names = Object.values(yield select(getTableItemSiblings, type) || EMPTY_IMMUTABLE_OBJ)?.filter(item =>
            !item.fake).map(item => item.name);
        yield put(addNormalize({
            type: TABLES,
            extraValues,
            newObject: createObjectFromSchema({
                createSchema: createSchema.properties[type]?.additionalProperties, newUuid, names,
                extraValues })
        }));
        //first add to tables then reference to it
        if (key && !objectKey) { // if its inside another table
            yield put(setNormalize({ key, uuid, value: newUuid, array, subkey, addingAfter, uuidToAddBefore }));
        } else {
            yield put(addNormalize({ type: typeId, newUuid, addingAfter, key, uuidToAddBefore }));
        }
        if (successText) {
            createNotification({
                title: `${successText}.title`,
                desc: `${successText}.desc`, type: 'success' });
        }
    } catch (error) {
        createNotification({
            title: 'widgets:global.error',
            desc: error, type: 'danger' });
        console.log(error); //eslint-disable-line
    }
};

export const workerDeleteFromNormalizeTable = function* (props) {
    try {
        const { typeId, typeTable, key, uuid, tableUuid,  } = props.actions;
        yield put(setNormalize({ type: typeId, key, value: uuid, tableUuid }));
        yield put(deleteNormalize({ type: typeTable, uuid }));
        createNotification({
            title: 'widgets:global.deleted', type: 'success' });
    } catch (error) {
        createNotification({
            title: 'widgets:global.error',
            desc: error, type: 'danger' });
        console.log(error); //eslint-disable-line

    }

};

export const workerDuplicateFromNormalizeTable = function* (props) {
    try {
        const { uuid, parentUuid, parent, parentKey, parentSubkey, reverse,
            newUuid = getHlcfgRowUuid(hlcfgTableNameByRowId(uuid), uuid),
            extraValues, uuidToAddBefore } = props.actions;
        const names = Object.values(yield select(getTableItemSiblings, hlcfgTableNameByRowId(uuid)) ||
        EMPTY_IMMUTABLE_OBJ)?.filter(item =>
            !item.fake).map(item => item.name);
        const schema = yield select(getTableSchema, uuid);
        yield put(copyNormalize({
            uuid, newUuid, extraValues, reverse, names, nameMaxLength: schema?.name?.maxLength
        }));
        if (parentKey) { // if its inside another table
            yield put(setNormalize({
                key: parentKey,
                subkey: parentSubkey,
                uuid: parentUuid,
                value: newUuid,
                uuidToAddBefore: uuid,
                array: true,
                reverse
            }));
        } else {
            yield put(addNormalize({ type: parent, uuid, newUuid, uuidToAddBefore: uuidToAddBefore || uuid, }));
        }
        const item = yield select(getTableItem, uuid);
        for (const prop of Object.keys(schema || EMPTY_IMMUTABLE_OBJ)) {
            if (
                schema[prop].type === 'array' &&
                    schema[prop].items[SCHEMA_TYPE_ROW_ID] &&
                    !schema[prop].items[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]
            ) {
                yield put(setNormalize({ //empty the references
                    key: prop,
                    subkey: parentSubkey,
                    uuid: newUuid,
                    value: [],
                }));
                const ids = [ ...item?.[prop] || [] ];
                for (const oldChildId of ids.reverse()) {
                    yield put(
                        duplicateFromNormalize({
                            uuid: oldChildId,
                            reverse: true,
                            parentKey: prop,
                            parentUuid: newUuid })
                    );
                }
            }
            if (schema[prop].type === 'object') {
                for (const propObj of Object.keys(schema[prop].properties || {})) {
                    if (
                        schema[prop].properties[propObj].type === 'array' &&
                            schema[prop].properties[propObj].items?.[SCHEMA_TYPE_ROW_ID] &&
                            !schema[prop].properties[propObj].items?.[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY]
                    ) {
                        yield put(setNormalize({ //empty the references
                            key: prop,
                            subkey: propObj,
                            uuid: newUuid,
                            value: [],
                        }));
                        const ids = [ ...item?.[prop]?.[propObj] || [] ];
                        for (const oldChildId of ids.reverse()) {
                            yield put(
                                duplicateFromNormalize({
                                    uuid: oldChildId,
                                    reverse: true,
                                    parentKey: prop,
                                    parentSubkey: propObj,
                                    parentUuid: newUuid })
                            );
                        }
                    }
                }
            }
        }
        if (!reverse) { // just one notification is fine
            createNotification({
                title: 'widgets:global.duplicated',
                type: 'success' });
        }
    } catch (error) {
        createNotification({
            title: 'widgets:global.error',
            desc: error, type: 'danger' });
        console.log(error); //eslint-disable-line
    }

};


//SPECIFIC


const checkRuleOrder = (rules, originIds) => {
    const tlsInspIndex = rules.findIndex(item => item === TLS_INSPECTION_UUID);
    const authRequiredIndex = rules.findIndex(item => item === REQUIRE_AUTH_UUID);

    if (!(tlsInspIndex === -1 || authRequiredIndex === -1 || tlsInspIndex > authRequiredIndex)) {
        createNotification({
            title: 'profile:rule.unsupporterOrder.title',
            desc: 'profile:rule.unsupporterOrder.desc',
            type: 'info'
        });
        rules.splice(authRequiredIndex, 0, rules.splice(tlsInspIndex, 1)[0]);
    }
    if (originIds[originIds.length - 1] !== rules[rules.length - 1]) {
        rules = rules.filter(item => item !== originIds[originIds.length - 1]);
        rules.push(originIds[originIds.length - 1]);
    }
    return rules;
};


const workerUpdateInterfaceItem = function* (props) {
    const { uuid } = props;
    const networkInterfacesInfo = yield call(async () => queryClient.fetchQuery(queries.system.networkInfo));
    const interfaceItem = yield select(getTableItem, uuid);

    // TODO: AK-3661: This feature only works for current node. Could be made to work for both at once.
    const myNode = yield select(getMyNode);
    const hwParams = networkInterfacesInfo.networkInterfaces.find(
        item => isFromConfiguration({ item: item, value: interfaceItem, clusterNodeSelector: myNode })
    );
    const isCluster = yield select(getIsCluster);
    const ipv6Enabled = yield select(state => getGlcfgValue(state, IPV6_ENABLED));

    const data = yield select((state) => getTableItem(state, uuid));

    /**
     * hwParams is ParsedIp type that consists of addresses4 that is REQUIRED.
     * This is dummy check if that condition isnt met it should display warning notification
     * that something is wrong with the interface and return.
     */
    if (!hwParams?.addresses4) {
        createNotification({
            type: 'warning',
            title: 'widgets:Interfaces.hwParams.warningIpv4.title',
            desc: 'widgets:Interfaces.hwParams.warningIpv4.desc'
        });
        return;
    }

    yield put(updateNormalize({ uuid, hwParams, isCluster, myNode, ipv6: ipv6Enabled }));

    createNotification({
        title: 'widgets:hwIface.updated.title',
        desc: 'widgets:hwIface.updated.desc',
        type: 'success',
        descParams: {
            device: data.name ? `${data.name} (${data.device?.[NODE_A_ID] ||
                data.vlanIface})` : data.device?.[NODE_A_ID]
        }
    });
};

const workerSetNormalizeOrderProfileRules = function* (props) {
    const { order, active } = props.actions;
    const ids = yield select(getProfileRulesIds);
    yield put(setNormalize({  uuid: active, key: 'rules', value: checkRuleOrder(order, ids) }));
};

export const sagasNormalize = [
    takeEvery(ADD_TO_NORMALIZE, workerAddToNormalizeTable),
    takeEvery(DELETE_FROM_NORMALIZE, workerDeleteFromNormalizeTable),
    takeEvery(DUPLICATE_FROM_NORMALIZE, workerDuplicateFromNormalizeTable),
    takeEvery(UPDATE_INTERFACE, workerUpdateInterfaceItem),

    //specific
    takeEvery(SET_NORMALIZE_ORDER_PROFILE_RULES, workerSetNormalizeOrderProfileRules),


];
