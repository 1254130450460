/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { getIsProductionMode } from '~frontendDucks/constantInformation/index.js';
import { getGlcfgValue } from '~frontendDucks/hlcfgEditor/index.js';
import { HANDBOOK } from '~frontendLib/scenes.js';
import { NOT_FOUND_SCENE } from '~frontendComponents/NotFound/index.js';
import { getIsAdvanced } from '~frontendRoot/ducks/dynamicSettings/index.ts';


class RouteScenesBase extends Component {
    render() {
        const { deployment, isProductionMode, scenes, isAdvanced, t } = this.props;
        return (
            <Switch>
                {scenes({ deployment, isProductionMode }).map(objRoute => {
                    if (objRoute.isAdvanced && !isAdvanced) {
                        return null;
                    }
                    if (!objRoute.isDevice) {
                        return objRoute.route;
                    }
                })}
                {HANDBOOK(t).route}
                {NOT_FOUND_SCENE.route}
            </Switch>
        );
    }
}

const mapStateToProps = state => ({
    deployment: getGlcfgValue(state, 'deployment'),
    isProductionMode: getIsProductionMode(state),
    isAdvanced: getIsAdvanced(state),
});

const RouteScenes = withTranslation()(connect(mapStateToProps)(RouteScenesBase));

RouteScenesBase.propTypes = {
    scenes: PropTypes.func,
    deployment: PropTypes.string,
    isProductionMode: PropTypes.bool,
    isAdvanced: PropTypes.bool,
    t: PropTypes.func
};

export default RouteScenes;
