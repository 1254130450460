/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';


export const upgradeSceneMap = poDef.scene('upgradeScene', '/system/management/upgrade', {
    upgradeState: poDef.container('upgradeState', {
        card: poDef.container('upgradeVersionCard', {
            versionDescription: poDef.statusBox('versionDescription'),
            infoDisplay: poDef.statusBox('infoDisplay'),
            versionSelect: poDef.select('selectVersion'),
            upgradeCardButton: poDef.clickableStatusBox('upgradeCardButton'),
        }),
        checkNewVersion: poDef.clickableStatusBox('checkNewVersion')
    })
});

export const upgradeVersionCardMap = upgradeSceneMap.child.upgradeState.child.card;
export const versionDescriptionMap = upgradeVersionCardMap.child.versionDescription;
