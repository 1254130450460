/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import Divider from '~frontendRoot/components/Divider/index.js';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgOffSwitch, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';


const SafeSearch = () => {
    const { t } = useTranslation();
    const ssPath = hlcfgPathGetter.services.dns.safesearch;
    const safesearch = useHlcfgOffable(ssPath);
    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:Dns.safesearch.title')}
            </MDBCardHeader>
            <MDBCardBody className="safeSearch--card">
                {t('widgets:Dns.safesearch.desc')}

                <HlcfgOffSwitch
                    align="spaceBetween"
                    className="mb-4"
                    label={t('widgets:Dns.safesearch.title')}
                    pathGetter={ssPath}
                />
                {safesearch.isOn &&
                    <div className="safeSearch">
                        <Divider />
                        <div className="pl-4 pr-4 pt-2">

                            <HlcfgSwitch
                                align="spaceBetween"
                                label={t('widgets:Dns.safesearch.google')}
                                pathGetter={ssPath.google}
                            />
                            <HlcfgSwitch
                                align="spaceBetween"
                                label={t('widgets:Dns.safesearch.bing')}
                                pathGetter={ssPath.bing}
                            />
                        </div>
                    </div>}
            </MDBCardBody>

        </MDBCard>
    );
};

export default SafeSearch;
