/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, SyntheticEvent, useCallback, useContext, useMemo, useRef } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import { GroupBase, InputActionMeta } from 'react-select';
import assert from 'assert';

import { JSXElement } from '~commonLib/types.ts';
import { useSelectReducer } from '~frontendComponents/Generic/SelectV2/reducer.ts';
import { SelectV2Props, ReactSelectOnChange, SelectOption, SelectV2InternalProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { LIGHT_BLACK } from '~frontendConstants/constants.ts';
import { noop } from '~commonLib/functionUtils.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';


/**
 * See {@link https://www.w3.org/TR/uievents/}
 */
const KEY_IDS = {
    escape: 'Escape',
    end: 'End',
    home: 'Home',
};

type SelCtx<T = unknown> = {
    tooltip?: JSXElement,
    setSelectFocus: (focused: boolean) => void,
    value: SelectOption<T>[],
    allValues: SelectOption<T>[],
    maxItemsToDisplay?: number,
    controlState: {state: 'invalid'|'warning'|'ok'|'disabled', msg?: string},
    stringifyForCopy?: (value: T[]) => string,
    stringify: (value: T) => string,
    startEditingItem: (item: SelectOption<T>) => void,
    setInputValue: (inputValue: string) => void,
    copyToClipboard: () => void,
    canCopyToClipboard: boolean,
    disabled: boolean,
    selectIsNotEditable: boolean,
    getColor: (option: SelectOption<T>) => {backgroundColor?: string};
    message?: string,
}
export const SelectV2Context = createContext(undefined as SelCtx|undefined);
export const useSelectContext = () => {
    const ctx = useContext(SelectV2Context);
    assert(ctx, 'Invalid usage. Must be wrapped in SelectV2Context provider');
    return ctx;
};

const useInternalizeProps = <T>(propsInput: SelectV2Props<T>): SelectV2InternalProps<T> => {
    type Opt = SelectOption<T>;
    const { value, options, prepareOption, groups = EMPTY_IMMUTABLE_ARR } = propsInput;
    const optionsGroups: GroupBase<Opt>[] = useMemo(() => {
        const groupsById = Object.fromEntries(groups.map((group) => {
            return [ group.groupId, { label: group.label, options: [] as Opt[] } ];
        }));
        const defaultGroup = { options: [] as Opt[] };
        const theGroups: GroupBase<Opt>[] = [
            defaultGroup,
            ...groups?.map(it => groupsById[it.groupId]),
        ];
        options?.forEach(opt => {
            const prepared = prepareOption(opt);
            if (prepared.hideFromMenu) {
                return;
            }
            const group = prepared.groupId ? groupsById[prepared.groupId] : defaultGroup;
            assert(group, 'Developer did not provide group for given groupId of option.');
            group.options.push(prepared);

        });
        return theGroups.filter(it => it.options.length);
    }, [ options, prepareOption, groups ]);

    return {
        ...propsInput,
        value: useMemo(() => value?.map(prepareOption), [ value, prepareOption ]),
        options: optionsGroups,
    };

};
const INPUT_ORDER_LAST = 99999;
export const useSelectV2Model = <T>(propsInput: SelectV2Props<T>) => {
    const internalizedProps = useInternalizeProps(propsInput);
    const ref = useRef<any>(null);

    const {
        value, maxItemsToDisplay, disabled, maxItemsSelected, stringify, notEditable, message,
    } = internalizedProps;
    const [ selectState, dispatch ] = useSelectReducer(internalizedProps);
    const {
        editingPosition,
        inputValue,
        focused
    } = selectState;

    const setSelectFocus = useCallback((focused: boolean) => {
        dispatch.setFocused(focused);
        if (focused) {
            ref.current?.focus();
        } else {
            ref.current?.blur();
        }
    }, [ dispatch ]);

    const valueToUse = value;

    const maxItemsReached = maxItemsSelected === undefined ?
        false :
        maxItemsSelected <= valueToUse.length;

    const inputIsDisabled = notEditable || maxItemsReached;

    const valueClamped = useMemo(() => {
        if (maxItemsToDisplay && !focused) {
            return valueToUse.slice(0, maxItemsToDisplay);
        }
        return valueToUse;
    }, [ valueToUse, maxItemsToDisplay, focused ]);

    const valueWithoutEditing = useMemo(() => {
        return valueClamped.filter((_val, idx) => idx !== editingPosition);
    }, [ editingPosition, valueClamped ]);

    const shouldHideValue = propsInput.singleValueMode && inputValue !== '' && focused;
    const valueToDisplay = shouldHideValue ? EMPTY_IMMUTABLE_ARR : valueWithoutEditing;
    const onChangeWrap = useCallback<ReactSelectOnChange<T>>((...args) => {
        dispatch.onChange(args);
    }, [ dispatch ]);

    const controlState = useMemo((): SelCtx['controlState'] => {
        if (disabled) {
            return { state: 'disabled' };
        }
        if (!focused && inputValue !== '') {
            return { state: 'invalid', msg: 'components:Select.notConfirmed' };
        }
        const withError = valueToUse.find(it => it.errorMsg);
        if (withError) {
            return { state: 'invalid', msg: withError.errorMsg };
        }
        const withWarning = valueToUse.find(it => it.warnMsg);
        if (withWarning) {
            return { state: 'warning', msg: withWarning.warnMsg };
        }
        return { state: 'ok' };
    }, [ valueToUse, disabled, focused, inputValue ]);

    const canCopyToClipboard = !valueToUse.length || !internalizedProps.stringifyForCopy;

    const getColor = disabled ? getColorDisabled : getColorEnabled;

    const setInputValue = inputIsDisabled ? noop : dispatch.setInputValue;
    const selectContext = useMemo(() => {
        const ctx: SelCtx<T> = {
            tooltip: internalizedProps.tooltip,
            stringifyForCopy: internalizedProps.stringifyForCopy,
            maxItemsToDisplay: focused ? undefined : maxItemsToDisplay,
            setSelectFocus: disabled ? noop : setSelectFocus,
            allValues: valueToUse,
            value: valueToDisplay,
            controlState,
            copyToClipboard: dispatch.copyToClipboard,
            canCopyToClipboard,
            startEditingItem: disabled || notEditable ? noop : dispatch.setEditingPosition,
            setInputValue,
            getColor,
            stringify: stringify ?? defaultStringify,
            disabled: !!disabled,
            message,
            selectIsNotEditable: !!notEditable,
        };
        return ctx;
    }, [
        internalizedProps.tooltip, getColor, disabled, stringify, notEditable, message,
        dispatch, controlState, setSelectFocus, canCopyToClipboard, setInputValue,
        internalizedProps.stringifyForCopy, valueToDisplay, maxItemsToDisplay, focused, valueToUse
    ]);

    const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback((event) => {
        switch (event.key) {
        case KEY_IDS.escape: {
            if (event.ctrlKey) {
                setSelectFocus(false);
            } else {
                dispatch.clearInput();
            }
            break;
        }
        case KEY_IDS.home: {
            event.preventDefault();
            // TS being difficult with React events and DOM access
            const target: any = event.target;
            if (event.shiftKey) {
                target.selectionStart = 0;
            }
            else {
                target.setSelectionRange(0, 0);
            }
            break;
        }
        case KEY_IDS.end: {
            event.preventDefault();
            // TS being difficult with React events and DOM access
            const target: any = event.target;
            const len = target.value.length;
            if (event.shiftKey) {
                target.selectionEnd = len;
            }
            else {
                target.setSelectionRange(len, len);
            }
            break;
        }
        default:
            break;
        }
    }, [ dispatch, setSelectFocus ]);
    const handlePaste = useCallback((event: ClipboardEvent) => {
        const clipboard = event.clipboardData;
        if (!clipboard) {
            return;
        }
        event.preventDefault();
        dispatch.paste(clipboard.getData('text'));
    }, [ dispatch ]);

    const commonProps = {
        value: valueToDisplay,
        disabled,
        getColor,
        ref,
        selectContext,
        maxItemsReached,
        options: maxItemsReached ? EMPTY_IMMUTABLE_ARR : internalizedProps.options,
    };
    if (disabled) {
        return {
            ...commonProps,
            inputOrder: INPUT_ORDER_LAST,
            menuIsOpen: false,
            backspaceRemovesValue: false,
            onFocusCapture: (evn: SyntheticEvent) => {
                evn.stopPropagation();
            },
            inputValue: undefined,
        };
    }
    return {
        ...commonProps,
        onChange: onChangeWrap,
        onCreateOption: dispatch.createOption,
        onBlur: () => {
            ref.current?.controlRef.removeEventListener('paste', handlePaste);
            dispatch.setFocused(false);
        },
        ref,
        backspaceRemovesValue: editingPosition === undefined,
        onKeyDown,
        onFocus: () => {
            if (internalizedProps.clipboardParse) {
                ref.current?.controlRef.addEventListener('paste', handlePaste);
            }
            dispatch.setFocused(true);
        },
        inputValue,
        onInputChange: (newData: string, actionMeta: InputActionMeta) => {
            if (actionMeta.action === 'input-blur' || actionMeta.action === 'menu-close') {
                return;
            }
            setInputValue(newData);
        },
        inputOrder: editingPosition !== undefined ? editingPosition * 2 - 1 : INPUT_ORDER_LAST,
        onFocusCapture: undefined,
    };
};
const defaultStringify = (it: any) => it.toString();
const getColorDisabled = (option: SelectOption<unknown>) => ({
    backgroundColor: option.disabledBackgroundColor ?? LIGHT_BLACK
});
const getColorEnabled = (option: SelectOption<unknown>) => ({ backgroundColor: option.backgroundColor });
