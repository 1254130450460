/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext } from 'react';
import { MDBBtn, MDBRow } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Overlay, Icon } from '~frontendComponents/Generic/index.js';
import { useLicenseInfo } from '~frontendQueries/system/hooks.ts';
import Message from '~frontendComponents/Message/Message.tsx';
import { ObjRouteContext, isSceneLocked } from '~frontendRoot/scenes/ObjRouteContext.ts';
import { HlcfgOffablePathGetter, useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { poDef, testPropsStatus } from '~commonLib/PageObjectMap.ts';


type HlcfgServiceProps = {
    offOverlay?: React.ReactNode,
    children: React.ReactNode,
    serviceTranslationPath: string,
    translationFile?: string,
    pathGetter: HlcfgOffablePathGetter<any>,
    attention?: boolean
}
export const HlcfgService = ({
    offOverlay, children, serviceTranslationPath, attention, translationFile, pathGetter
}: HlcfgServiceProps) => {
    const svc = useHlcfgOffable(pathGetter);
    return (
        <Service
            attention={attention}
            controlsId={poDef.pathId(svc.path)}
            id={serviceTranslationPath}
            on={svc.isOn}
            showService={children}
            translationFile={translationFile}
            turnOff={svc.setOff}
            turnOn={svc.setOn}
        >{offOverlay}
        </Service>
    );
};


type ServiceType = {
    turnOn?: any
    turnOff?: any
    showService?: React.ReactNode
    children?: React.ReactNode
    id: string
    translationFile?: string
    on?: boolean,
    hideOverlay?: boolean
    fullyHide?: boolean
    attention?: boolean
    controlsId?: string
}

const Service = ({ on, turnOff, turnOn, showService, children, id, controlsId,
    translationFile = 'widgets', hideOverlay, fullyHide, attention }: ServiceType) => {

    const { t } = useTranslation();
    const requiredLicense = useContext(ObjRouteContext).license;
    const licenseInfo = useLicenseInfo().data;

    const locked = isSceneLocked(licenseInfo, requiredLicense);
    return (
        <>
            <div
                className="userNavigation"
                {...controlsId ? testPropsStatus(controlsId, on ? 'on' : 'off') : {}}
            >
                {turnOn && !fullyHide && (
                    <MDBBtn
                        className="navigation__button pulse startButton"
                        color="primary"
                        data-cy="turnOn"
                        disabled={on || locked}
                        id="on"
                        onClick={turnOn}
                        size="sm"
                        type="button"
                    >
                        {t('widgets:global.service.on')}
                    </MDBBtn>
                )}
                {turnOff && !fullyHide && (
                    <MDBBtn
                        className="navigation__button ml-1 pulse"
                        color="red"
                        data-cy="turnOff"
                        disabled={!on}
                        id="off"
                        onClick={turnOff}
                        size="sm"
                        type="button"
                    >
                        {t('widgets:global.service.off')}
                    </MDBBtn>
                )}
            </div>
            <div className={classNames({ 'withOverlay': !on && !hideOverlay })}>
                {showService}
                <Overlay
                    active={!on && !hideOverlay}
                    isVisible
                >
                    <div
                        className={locked ? 'service__locked' : 'service__overlay'}
                        data-cy={`${id}--text`}
                    >
                        <div>
                            {locked &&
                                <MDBRow center>
                                    <Icon
                                        className={'lockedIconSize icon--locked'}
                                        name={'shield-lock'}
                                    />
                                </MDBRow>}
                            <h2>{t(`${translationFile}:${id}.title`)}</h2>
                            <article>
                                <p className="mb-2">
                                    {t(`${translationFile}:${id}.desc`)}
                                </p>
                                {!locked && turnOn && turnOff &&
                                    <p className="dataTableWidget__Rowtext">
                                        {t(`${translationFile}:${id}.desc1`)}
                                        <MDBBtn
                                            className="navigation__button pulse startButton"
                                            color="primary"
                                            data-cy="turnOn"
                                            disabled={on}
                                            onClick={turnOn}
                                            size="sm"
                                            type="button"
                                        >
                                            {t('widgets:global.service.on')}
                                        </MDBBtn>
                                        {t(`${translationFile}:${id}.desc2`)}
                                        <MDBBtn
                                            className="navigation__button ml-1 pulse"
                                            color="red"
                                            data-cy="turnOff"
                                            disabled={!on}
                                            onClick={turnOff}
                                            size="sm"
                                            type="button"
                                        >
                                            {t('widgets:global.service.off')}
                                        </MDBBtn>
                                        {t(`${translationFile}:${id}.desc3`)}
                                    </p>
                                }
                                {attention && (
                                    <p className="bold mb-1">{t(`${translationFile}:${id}.attention`)}</p>
                                )}
                                {!locked && turnOn && (
                                    <MDBBtn
                                        className="navigation__button pulse startButton mb-4"
                                        color="primary"
                                        data-cy="turnOn"
                                        disabled={on}
                                        onClick={turnOn}
                                        size="sm"
                                        type="button"
                                    >
                                        {t('widgets:global.service.on')}
                                    </MDBBtn>
                                )}
                                {children}
                            </article>
                        </div>
                        {locked && (
                            <div>
                                <MDBRow
                                    center
                                    className={'mb-2'}
                                >
                                    <Message message="widgets:license.unavailable" />
                                </MDBRow>
                                <MDBRow center>
                                    <MDBBtn
                                        className="navigation__button pulse startButton"
                                        href="http://www.kernun.cz"
                                        size="sm"
                                        target="_blank"
                                        type="button"
                                    >
                                        <Message message="widgets:license.purchaseHere" />
                                    </MDBBtn>
                                </MDBRow>
                            </div>
                        )}
                    </div>
                </Overlay>
            </div>
        </>
    );
};

export default React.memo(Service);
