/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { DependencyList, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { ActionCreator } from 'redux';


export type UseBooleanFuncType = {
    on: () => void,
    off: () => void,
    swap: () => void
}

/**
 * Default use boolean hook, to replace copypaste code with setters
 *
 * @example const [hide, setHide] = useBoolean();
 * <Icon
 *    onClick={setHide.swap}
 * />
 */
export const useBoolean = (defaultValue = false): [boolean, UseBooleanFuncType ] => {
    const [ val, setVal ] = useState(defaultValue);
    const [ setBoolean ] = useState({
        on: () => setVal(true),
        off: () => setVal(false),
        swap: () => setVal(val => !val),

    });
    return [ val, setBoolean ];
};


export type UseStringFuncType = {
    clear: () => void,
    set: (val: string) => void,
    inputSet: ({ value }: { value: string }) => void
}

/**
 * Default use string hook, to replace copypaste code with setter of default state use this to have hook ready for
 * Input.js setter
 *
 * @example const [search, setSearch] = useString('');
 * <Input
 *    onChange={setSearch.inputSet}
 *    value={search}
 * />
 */
export const useString = (defaultValue = ''): [string, UseStringFuncType] => {
    const [ val, setVal ] = useState(defaultValue);
    const [ setString ] = useState({
        clear: () => setVal(''),
        set: (val: string) => setVal(val),
        inputSet: ({ value }) => setVal(value),

    });
    return [ val, setString ];
};

type UseStateWithDepsType =
     <T extends any[], K>(valueInitializer: (input: T) => K, input: T) => [K, (value: K) => void];
/**
 * Use when you need the state to be updated on props update.
 *
 * @example
 * const [ localValue, setLocalValue ] = useStateWithDeps(getValue, [ value, schema ]);
 */
export const useStateWithDeps: UseStateWithDepsType = (valueInitializer, input) => {
    const [ value, setValue ] = useState(valueInitializer(input));

    useEffect(() => {
        setValue(valueInitializer(input));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ...input, setValue ]);

    return [ value, setValue ];
};

/**
 * Use value that keeps the same reference between re-renders.
 * This is useful for preventing re-renders when
 * creating object or array inside a component.
 */
export const useConstant = <T>(initialValue: T): T => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => initialValue, []);
};

export const useDispatchCallback = <T extends ActionCreator<{type: string}>>(
    actionCreator: T, dependencies: DependencyList
) => {
    const dispatch = useDispatch();
    return useCallback((...params: Parameters<T>) => {
        dispatch(actionCreator(...params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dispatch, ...dependencies ]);
};

type Selector = (state: DefaultRootState, ...other: any[]) => any;

/**
 * Hook designed to help using "makeSelector" pattern optimally.
 * First param is selector maker and other parameters are passed to the selector maker.
 */
export const useMakeSelector = <P extends any[], S extends Selector>(
    getSelector: (...params: P) => S, ...params: P
): ReturnType<S> => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selector = useMemo(() => getSelector(...params), [ getSelector, ...params ]);
    return useSelector(selector);
};

/**
 * Monitors provided value and triggers onChangedCallback with new value if the value has changed.
 * Does strict comparison, so reference changes on arrays and objects will always trigger the callback
 */
export const useValueChangedEffect = <T>(
    value: T, onChangedCallback: ((newValue: T, prevValue: T) => void)
) => {
    const latestValue = useRef(value);
    const callback = useRef(onChangedCallback);
    callback.current = onChangedCallback;

    useEffect(() => {
        if (value !== latestValue.current) {
            callback.current(value, latestValue.current);
        }
        latestValue.current = value;
    }, [ value ]);
};
