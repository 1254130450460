/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import classNames from 'classnames';

import {  Icon } from '~frontendComponents/Generic/index.js';
import { countValuesFromObject } from '~frontendLib/objectUtils.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { EMPTY_IMMUTABLE_OBJ, CWV2_CATEGORIES, PROFILE_CATEGORIES } from '~sharedRoot/constants/constants.ts';
import { HlcfgDirtyTableItem } from '~frontendConstants/types.ts';


interface GetActionParamProps {
    action: HlcfgDirtyTableItem<'profileRule'>['action'],
    onClick?: () => void,
    disabled: boolean,
    isNewCW: boolean
}

const GetActionParam = ({ action, onClick, disabled, isNewCW }: GetActionParamProps) => {
    const categories = isNewCW ?
        { definition: CWV2_CATEGORIES, obj: action.accordingToCwCategoryV2 } :
        { definition: PROFILE_CATEGORIES, obj: action.accordingToCategory };
    if (action.type === 'accordingToCategory') {
        return (
            <>
                <div
                    className="policy__selectIcon"
                >
                    <div
                        className={classNames(
                            'policy__selectIcon icon--clicable',
                            {
                                'policy__selectIcon--disabled': disabled
                            },
                        )}
                        onClick={onClick}
                    >
                        <span className="pl-1">
                            {
                                categories.definition.length - Object.values(categories.obj ||
                                    EMPTY_IMMUTABLE_OBJ).filter(Boolean)?.length
                            }
                        </span>
                        <Icon
                            className={classNames(
                                'policy__icon icon--primary',
                                { 'icon--textColor': disabled }
                            )}
                            name="checkbox-marked-circle"
                            size="sx"
                        />
                        {!isNewCW ?
                            <>
                                <span className="pl-2">
                                    {
                                        countValuesFromObject(categories.obj, 'bypass')
                                    }
                                </span>
                                <Icon
                                    className={classNames(
                                        'policy__icon icon--yellow',
                                        { 'icon--textColor': disabled }
                                    )}
                                    name="alert-circle"
                                    size="sx"
                                />
                            </> : null}
                        <span className="pl-2">
                            {
                                countValuesFromObject(categories.obj, 'block')
                            }
                        </span>
                        <Icon
                            className={classNames(
                                'policy__icon icon--red',
                                { 'icon--textColor': disabled }
                            )}
                            name="close-circle"
                            size="sx"
                        />
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <IconWithTooltip
                className="icon--textColor"
                iconSize="sx"
                link
                name="checkbox-blank-circle"
                tooltipText={'profile:profiles.rules.action.needed'}
            />
        );
    }
};


export default GetActionParam;
