/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';


export const getBasicAuthenticationUserMap = (rowId: string) => {
    const rule = getRowPathGetter<'basicTextUser'>(rowId as any);
    return poDef.container(rowId, {
        name: poDef.textInput(poDef.pathId(rule.name)),
        password: poDef.textInput(poDef.pathId(rule.password.raw)),
        resetPassword: poDef.clickable('resetPassword')
    });
};

export const authenticationSceneMap = poDef.scene('authentication', '/system/authentication', {
    rules: poDef.table(poDef.pathId(hlcfgPathGetter.protection.proxy.authentication.basicTextUser),
        getBasicAuthenticationUserMap),
});
