/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback, useMemo } from 'react';
import { DefaultRootState, useSelector } from 'react-redux';

import { getValue, PathGetter } from '~commonLib/objectUtils.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';
import { DELETE_CONFIRM } from '~frontendConstants/constants.ts';
import { duplicateHlcfgRow, createNewHlcfgRow, deleteHlcfgRow, getHlcfgSchema, HlcfgEditorState, setHlcfgValue, setHlcfgTableReorder, getEnabledNetaddrSelectInterfaceNamesById, getNamedObjectNetaddrConfigured } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { PathGetterResult, PathGetValueResult } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2Types.ts';
import { setModalState } from '~frontendDucks/modals/modals.js';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.ts';
import { HlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { resolveStaticHlcfgReferences } from '~sharedLib/hlcfg/staticReferences/resolveStaticHlcfgReferences.ts';
import { getStaticReferenceName, isStaticReference, STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES, STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS, STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS, STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS, STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN } from '~sharedLib/staticHlcfgReferenceUtils.js';
import { netaddr } from '~commonLib/Netaddr/Netaddr.ts';
import { netservice } from '~sharedLib/Netservice/Netservice.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { stringifyAddrSelector, stringifyNamedObject } from '~frontendLib/hlcfg/utils.ts';
import { isNamedObject, NamedObjectReference } from '~sharedLib/namedObjectUtils.ts';
import { AddressesSelector, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';

import { getRowPathGetter } from './constants.ts';


declare module 'react-redux' {
    interface DefaultRootState {
        hlcfgEditor: HlcfgEditorState
    }
}

const getState = (rootState: DefaultRootState) => rootState.hlcfgEditor;

export type Path = string[]
export type HlcfgPathGetter<P extends Path> = PathGetter<any, P>;
export type HlcfgOffablePathGetter<P extends Path> = HlcfgPathGetter<P> & {__off: HlcfgPathGetter<any>};
export type HlcfgRowPathGetter<P extends Path> = HlcfgPathGetter<P> & {id: HlcfgPathGetter<any>};
const getWorkHlcfg = (rootState: DefaultRootState) => getState(rootState).hlcfgTree;
const getInitHlcfg = (rootState: DefaultRootState) => getState(rootState).initHlcfgTree;

export type GetHlcfgOpts = {
    initial?: boolean
}
export const getHlcfgValue = <const P extends Path>(
    state: DefaultRootState, path: P, opts: GetHlcfgOpts = {}
): PathGetValueResult<P, HlcfgDirtyTree> => {
    const hlcfgTree = opts.initial ? getInitHlcfg(state) : getWorkHlcfg(state);
    if (!hlcfgTree) {
        throw new Error('Trying to use hlcfg when it is not initialized');
    }
    return getValue(hlcfgTree, path);
};

export const getResolvedStaticRefValues = (state: DefaultRootState, refObj: unknown) => {
    assert(isStaticReference(refObj), 'Invalid static hlcfg reference');
    const hlcfgTree = getWorkHlcfg(state);
    if (!hlcfgTree) {
        throw new Error('Trying to use hlcfg when it is not initialized');
    }
    const { resolved } = resolveStaticHlcfgReferences(hlcfgTree, { resolved: refObj });

    return resolved;
};

export const getStringifiedStaticHlcfgRef = (state: DefaultRootState, refObj: unknown, t) => {
    assert(isStaticReference(refObj), 'Invalid static hlcfg reference');
    const values = getResolvedStaticRefValues(state, refObj);
    const refName = getStaticReferenceName(refObj);
    switch (refName) {
    case STATIC_HLCFG_REFERENCE_NAME_GUI_HTTPS:
    case STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN:
    case STATIC_HLCFG_REFERENCE_HONEYPOT_ADDRESSES: {
        const interfaceNamesById = getEnabledNetaddrSelectInterfaceNamesById(state);
        const namedObjectAll = getNamedObjectNetaddrConfigured(state);
        const stringifyAddrSel = (selector: AddressesSelector) =>
            stringifyAddrSelector(selector, interfaceNamesById, t);
        const stringifyNO = (namedObjectReference: NamedObjectReference) =>
            stringifyNamedObject(namedObjectReference, namedObjectAll, t);
        return values.map(it => {
            if (isNamedObject(it)) {
                return stringifyNO(it);
            }
            if (isAddressesSelector(it)) {
                return stringifyAddrSel(it);
            }
            return netaddr(it).toString();
        }).join(', ');
    }
    case STATIC_HLCFG_REFERENCE_HONEYPOT_PORTS:
    case STATIC_HLCFG_REFERENCE_NAME_GUI_PORTS:
        return values.map(it => netservice('tcp:' + netport(it).toString()).toString()).join(', ');
    default:
        throw new Error('Unsupported static hlcfg reference');
    }
};

export const getHlcfgOffableIsEnabled = <P extends Path>(
    state: DefaultRootState, getPathGetter: HlcfgOffablePathGetter<P>
) => {
    const path  = getPathGetter.getPath();
    const offable: any = getHlcfgValue(state, path);
    return offable && typeof offable === 'object' && !offable.__off;
};

type GetHlcfgValueSelector<T extends Path> = (state: DefaultRootState) => PathGetValueResult<T, HlcfgDirtyTree>
export const createGetHlcfgValue = <T extends Path>(path: T, opts?: GetHlcfgOpts): GetHlcfgValueSelector<T> =>
    state => getHlcfgValue(state, path, opts);

const createGetHlcfgPathDefined = <T extends Path>(path: T, opts?: GetHlcfgOpts) =>
    state => !!getHlcfgValue(state, path, opts);

export const useMemoedHlcfgPath = <P extends Path>(
    pathGetter: HlcfgPathGetter<P>
): P  => {
    const pathNoMemo  = pathGetter.getPath();
    // The pathNoMemo IS the dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const path = useMemo(() => pathNoMemo, pathNoMemo);
    return path;
};

const useMemoedHlcfgValue = <P extends Path>(
    getPathGetter: HlcfgPathGetter<P>, opts?: GetHlcfgOpts
): {value: PathGetterResult<HlcfgDirtyTree, P>, path: Path}  => {
    const path = useMemoedHlcfgPath(getPathGetter);
    const value = useSelector(useMemo(() => createGetHlcfgValue(path, opts), [ path, opts ]));
    return {
        path, value
    };
};

/**
 * Like useHlcfgValue but only gets the value without additional information and utilities.
 */
export const useHlcfgOnlyValue = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>, opts?: GetHlcfgOpts
) => {
    return useMemoedHlcfgValue(getPathGetter, opts).value;
};

/**
 * Gets value from path gained from provided pathGetter.
 * Provides additional information and utilities to work with the value.
 */
export const useHlcfgValue = <P extends Path>(
    getPathGetter: Pick<HlcfgPathGetter<P>, 'getPath'>, opts?: GetHlcfgOpts
) => {
    const { value, path } = useMemoedHlcfgValue(getPathGetter, opts);
    const hlcfgSchema = useSelector(getHlcfgSchema);
    const schema = findSchemaByObjectPathAndSchema(path, hlcfgSchema);
    const setValue = useDispatchCallback((newValue: typeof value) => {
        return setHlcfgValue({ hlcfgPath: [ ...path ], value: newValue });
    }, [ path ]);
    return {
        value,
        setValue,
        path,
        valueOrDefault: (value ?? schema?.[DEFAULT_SCHEMA_VALUE]) as typeof value,
        schema
    } as const;
};

export const useHlcfgBoolean = <P extends Path>(
    getPathGetter: HlcfgPathGetter<P>, opts?: GetHlcfgOpts
) => {
    const { value, setValue, path } = useHlcfgValue(getPathGetter, opts);
    assert(value === undefined || typeof value === 'boolean', 'Not a boolean value in useHlcfgBoolean.');
    return useMemo(() => ({
        on: () => setValue(true as any),
        off: () => setValue(false as any),
        swap: () => setValue(!value as any),
        value,
        path,
    }), [ value, setValue, path ]);
};

export const useHlcfgOffable = <P extends Path>(
    getPathGetter: HlcfgOffablePathGetter<P>
) => {
    const parentPath = getPathGetter.getPath();
    const { value, on, off, path } = useHlcfgBoolean(getPathGetter.__off);
    const parentIsDefined = useSelector(useMemo(() => createGetHlcfgPathDefined(parentPath), [ parentPath ]));
    return {
        isOn: parentIsDefined && !value,
        isOff: !parentIsDefined || !!value,
        setOn: off,
        setOff: on,
        path,
    };
};
export const useHlcfgOffableValue = <P extends Path>(
    getPathGetter: HlcfgOffablePathGetter<P>, opts?: GetHlcfgOpts
) => {
    const { value, setValue } = useHlcfgValue(getPathGetter, opts);

    assert(value !== undefined, 'Hook not implemented for undefined values.');
    const { setOn, setOff, isOn, isOff } = useHlcfgOffable(getPathGetter);

    const inputSetValue = useCallback((params) => {
        assert(params.id, 'Must provide ID to inputSetValue');
        assert(value && typeof value === 'object', 'Non object can not be offable');
        setValue({
            ...value,
            [params.id]: params.value
        });
    }, [ value, setValue ]);

    return {
        value,
        setValue,
        setOn, setOff, isOn, isOff,
        inputSetValue,
    };
};

interface TablePathParams {
    tablePathGetter: HlcfgPathGetter<any>,
}
export const useTableReorder = ({ tablePathGetter }: TablePathParams) => {
    return useDispatchCallback((newIds: string[]) => {
        return setHlcfgTableReorder({ idsArrPath: tablePathGetter.getPath(), newIds });
    }, [ tablePathGetter ]);
};
interface TableManipulatorParams extends TablePathParams {
    addRowType?: HlcfgTableName,
    addRowSuccessText?: string,
    addExtraValues?: Record<string, unknown>,
    duplicateExtraValues?: Record<string, unknown>,
}
export const useTableManipulator = ({
    tablePathGetter, addRowType, addRowSuccessText, addExtraValues, duplicateExtraValues,
}: TableManipulatorParams) => {
    const addRow = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            tableName: addRowType,
            extraValues: addExtraValues,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [ tablePathGetter, addRowType, addRowSuccessText, addExtraValues ]);

    const deleteRow = useDispatchCallback(({ uuid }: {uuid: string}) => {
        return deleteHlcfgRow({ id: uuid, idsArrPath: tablePathGetter.getPath() });
    }, [ tablePathGetter ]);

    const duplicateRow = useDispatchCallback(({ uuid }: {uuid: string}) => {
        return duplicateHlcfgRow({
            id: uuid, idsArrPath: tablePathGetter.getPath(), extraValues: duplicateExtraValues
        });
    }, [ tablePathGetter, duplicateExtraValues ]);
    return {
        addRow,
        deleteRow,
        duplicateRow,
        reorder: useTableReorder({ tablePathGetter }),
    };
};
interface TableCardsModelProps extends TableManipulatorParams {
    /**
     * Maps to translation message like:
     * bodyText={`widgets:${specialValues.service}.modal.body`}
     */
    service: string,
    menuItemProps: Record<string, {name: string}>,
}
export const useCardsHlcfgTableModel = ({
    tablePathGetter, addExtraValues, service, menuItemProps,
}: TableCardsModelProps) => {

    const { deleteRow, duplicateRow } = useTableManipulator({ tablePathGetter, addExtraValues });
    const deleteService = useDispatchCallback(({ uuid }) => {
        return setModalState({
            modal: DELETE_CONFIRM,
            value: true,
            specialValues: { uuid, name: menuItemProps[uuid]?.name, action: deleteRow, service }
        });
    }, [ deleteRow, menuItemProps, service ]);

    const setValue = useDispatchCallback(({ uuid, key, value }) => {
        const rowPathGetter = getRowPathGetter(uuid);
        return setHlcfgValue({ hlcfgPath: rowPathGetter[key].getPath(), value });
    }, []);
    const reorder = useTableReorder({ tablePathGetter });
    const setOrder = useCallback(({ order }) => reorder(order), [ reorder ]);
    return {
        deleteService,
        copyService: duplicateRow,
        setOrder,
        menuItemProps,
        setValue,
        ids: useHlcfgValue(tablePathGetter).value,
    };
};

interface TableRowManipulatorParams extends TableManipulatorParams {
    rowPathGetter: HlcfgRowPathGetter<any>
}
export const useTableRowManipulator = ({
    tablePathGetter, addRowType, addExtraValues, rowPathGetter,
    addRowSuccessText = 'notifications:row.added',
    duplicateExtraValues,
}: TableRowManipulatorParams) => {

    const { value: rowId } = useHlcfgValue(rowPathGetter.id);
    assert(typeof rowId === 'string', `Id is not a string. Wrong path was provided. ${rowPathGetter.getPath()}`);
    const addRowAfter = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            afterId: rowId,
            tableName: addRowType,
            extraValues: addExtraValues,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [ tablePathGetter, addRowType, rowId, addRowSuccessText, addExtraValues ]);

    const addRowBefore = useDispatchCallback(() => {
        assert(addRowType, 'Tried adding row without specifying what kind of row');
        return createNewHlcfgRow({
            beforeId: rowId,
            extraValues: addExtraValues,
            tableName: addRowType,
            idsArrPath: tablePathGetter.getPath(),
            successText: addRowSuccessText,
        });
    }, [ tablePathGetter, addRowType, rowId, addRowSuccessText, addExtraValues ]);

    const addRow = useCallback((after = false)  => {
        if (after) {
            addRowAfter();
        } else {
            addRowBefore();
        }
    }, [ addRowAfter, addRowBefore ]);

    const deleteRow = useDispatchCallback(() => {
        return deleteHlcfgRow({ id: rowId, idsArrPath: tablePathGetter.getPath() });
    }, [ rowId, tablePathGetter ]);

    const duplicateRow = useDispatchCallback(() => {
        return duplicateHlcfgRow({
            id: rowId, idsArrPath: tablePathGetter.getPath(), extraValues: duplicateExtraValues
        });
    }, [ rowId, tablePathGetter, duplicateExtraValues ]);

    return {
        addRowAfter, addRowBefore, addRow, deleteRow, duplicateRow,
    };
};
