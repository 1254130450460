/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';

import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SelectOption, SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { PORT_COLOR, PORT_OPTIONS } from '~frontendConstants/constants.ts';
import { netportValidate } from '~sharedLib/Netport/lib/netportValidate.ts';
import { netport, stringifyNetport } from '~sharedLib/Netport/Netport.ts';
import { NetportDataObj, TypeNetport } from '~sharedLib/types.ts';


export type NetportSelectValue = NetportDataObj;
export interface NetportSelectProps extends SelectV2BaseProps {
    netportType: TypeNetport,
    value: NetportSelectValue|undefined,
    onChange: (newValue: NetportSelectValue|undefined) => void,
}

export const NetportSelect = (props: NetportSelectProps) => {
    return (
        <SelectV2
            {...useNetportSelectModel(props)}
            {...props}
            {...useSingleValueSelectWrapper(props)}
        />
    );
};

export interface NetportArraySelectProps extends SelectV2BaseProps {
    netportType: TypeNetport,
    value: NetportSelectValue[],
    onChange: (newValue: NetportSelectValue[]) => void,
}
export const NetportArraySelect = (props: NetportArraySelectProps) => {
    return (
        <SelectV2
            {...useNetportSelectModel(props)}
            {...props}
        />
    );
};

const PREDEFINED_PORTS = Object.values(PORT_OPTIONS);
const NETPORT_OPTIONS = PREDEFINED_PORTS.map(it => netport(it.value).toObject());
const NETPORT_LABEL_BY_STRINGIFIED = Object.fromEntries(PREDEFINED_PORTS.map(it => [ it.value, it.label ]));

const useNetportSelectModel = (
    { netportType }: Pick<NetportSelectProps, 'netportType'>
) => {
    const prepareOption = useCallback((value: NetportSelectValue): SelectOption<NetportSelectValue> => {
        const netportStringified = netport(value).toString();
        const label = NETPORT_LABEL_BY_STRINGIFIED[netportStringified] ?? netportStringified;

        return {
            label, value,
            backgroundColor: PORT_COLOR,
            searchStrings: [ netportStringified ],
            tooltip: label === netportStringified ? undefined : netportStringified,
        };
    }, []);

    const parse = useCallback((strVal: string) => {
        try {
            const netportObj = netport(strVal);
            const errors = netportValidate(it => it, netportObj, netportType);
            if (errors.length) {
                return undefined;
            }
            return { parsed: netportObj.toObject() };
        } catch (err) {
            return undefined;
        }
    }, [ netportType ]);

    return {
        prepareOption,
        parse,
        options: NETPORT_OPTIONS,
        stringify: stringifyNetport,
    };
};
