/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useMemo, useCallback } from 'react';
import {  MDBCard, MDBCardTitle,  } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { InputSearch } from '~frontendComponents/Generic/index.js';
import { getHostsIds, setNormalizeOrder } from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { HOSTS, NEW_ROW_CONSTANT, SELECTABLE_TABLE_HOST } from '~frontendRoot/constants/index.js';

import  Row  from './Row/index.js';


const CreateRow = ({  dataIndex,
    spacing,
    search, uuid }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid || 'newHostsRow'}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


const Hosts = () => {
    const [ search, setSearch ] = useString('');
    const { t } = useTranslation();
    const hosts: string[] = useSelector(getHostsIds);
    const data = useMemo(() => hosts.length ?  hosts : [ NEW_ROW_CONSTANT ], [ hosts ]);
    const dispatch = useDispatch();

    const passReorderData = useCallback((value) => {
        dispatch(setNormalizeOrder({ value, type: HOSTS }));
    }, [ dispatch ]);

    return (
        <MDBCard
            className="network__card"
        >
            <MDBCardTitle className="profiles__title">
                {t('widgets:Hosts.title')}
                <InputSearch
                    className="mb-3 mt-0"
                    id="searchValueIDHosts"
                    search={search}
                    setter={setSearch}
                />
            </MDBCardTitle>
            <DatatableWidget
                columnsId={SELECTABLE_TABLE_HOST}
                createRow={CreateRow}
                data={data}
                isDraggable
                passReorderData={passReorderData}
                search={search}
            />
        </MDBCard>
    );
};

export default Hosts;
