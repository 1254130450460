/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useMemo } from 'react';
import {
    MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Overlay, Icon, Cards } from '~frontendComponents/Generic/index.js';
import { getVpnProps,
    getLowestPossibleTunIndex
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useHlcfgOffable, useHlcfgValue, useCardsHlcfgTableModel, useTableManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { openvpnSceneMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/pageObjectMap.js';
import { testProps } from '~commonLib/PageObjectMap.js';

import VpnService from './components/VpnService/index.js';


const VPNScene = () => {
    const tablePathGetter = hlcfgPathGetter.services.vpn.openvpn;
    const lowestPossibleTunIndex = useSelector(getLowestPossibleTunIndex);
    const addExtraValues = useMemo(() => ({ tunIndex: lowestPossibleTunIndex }), [ lowestPossibleTunIndex ]);
    const duplicateExtraValues = addExtraValues;
    const vpnProps: any = useSelector(getVpnProps);
    const activeVpnId = useSelector(getActiveCardHook('vpn'));
    const ids = useHlcfgValue(tablePathGetter).value;

    const { addRow: addRas } = useTableManipulator({
        tablePathGetter,
        addRowType: hlcfgTableName.openvpnRas,
        addExtraValues,
        duplicateExtraValues,
        addRowSuccessText: 'widgets:Vpn.accessServer.added',
    });
    const { addRow: addClient } = useTableManipulator({
        tablePathGetter,
        addRowType: hlcfgTableName.openvpnClient,
        addExtraValues,
        duplicateExtraValues,
        addRowSuccessText: 'widgets:Vpn.client.added',
    });

    const { t } = useTranslation();

    return (
        <div {...testProps(openvpnSceneMap.id)}>
            <div className="userNavigation">
                <MDBBtn
                    className="navigation__button pulse"
                    color="secondary"
                    data-cy="addingAccessServer"
                    onClick={addRas}
                    size="sm"
                    type="button"
                    {...testProps(openvpnSceneMap.child.addRas.id)}
                >
                    <Icon name="plus" />
                    {t('widgets:Vpn.accessServer.title')}
                </MDBBtn>
                <MDBBtn
                    className="navigation__button ml-1"
                    color="secondary"
                    data-cy="addingClient1"
                    onClick={addClient}
                    size="sm"
                    type="button"
                    {...testProps(openvpnSceneMap.child.addClient.id)}
                >
                    <Icon name="plus" />
                    {t('widgets:Vpn.client.title')}
                </MDBBtn>
            </div>
            <div  className={classNames(
                { 'withOverlay': !ids.length }
            )}
            >
                <Cards
                    {...useCardsHlcfgTableModel({
                        tablePathGetter, menuItemProps: vpnProps,
                        service: 'Vpn',
                        addExtraValues,
                        duplicateExtraValues,
                    })}
                    id={openvpnSceneMap.child.cardHandles.id}
                    type="vpn"
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">

                    {activeVpnId && <Service uuid={activeVpnId} />}
                </div>
                {!activeVpnId && (
                    <Overlay
                        active={!ids.length}
                    >
                        <div className="dhcpd__overlay">
                            <h2>{t('widgets:Vpn.title')}</h2>
                            <p className="mb-2">
                                {t('widgets:Vpn.desc')}
                            </p>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Vpn.accessServer.desc')}
                                <MDBBtn
                                    className="navigation__button"
                                    color="secondary"
                                    data-cy="addingAccessServer"
                                    onClick={addRas}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Vpn.accessServer.title')}
                                </MDBBtn>

                            </p>
                            <p className="dataTableWidget__Rowtext">
                                {t('widgets:Vpn.client.desc')}
                                <MDBBtn
                                    className="navigation__button"
                                    color="secondary"
                                    data-cy="addingClient2"
                                    onClick={addClient}
                                    size="sm"
                                    type="button"
                                >
                                    <Icon name="plus" />
                                    {t('widgets:Vpn.client.title')}
                                </MDBBtn>

                            </p>
                            <MDBBtn
                                className="navigation__button pulse"
                                color="secondary"
                                data-cy="addingAccessServer"
                                onClick={addRas}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Vpn.accessServer.title')}
                            </MDBBtn>
                            <MDBBtn
                                className="navigation__button ml-1"
                                color="secondary"
                                onClick={addClient}
                                size="sm"
                                type="button"
                            >
                                <Icon name="plus" />
                                {t('widgets:Vpn.client.title')}
                            </MDBBtn>

                        </div>
                    </Overlay>
                )}
            </div>
        </div>
    );
};

const Service = ({ uuid }: {uuid: string}) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas') || hlcfgRowIdIsFromTable(uuid, 'openvpnClient'));
    const activeVpnPath = getRowPathGetter(uuid);
    const activeVpn = useHlcfgOffable(activeVpnPath);
    return (
        <>

            <VpnService
                uuid={uuid}
            />
            <TurnedOff
                disabled={activeVpn.isOff}
                onChange={activeVpn.setOn}
            />
        </>
    );
};

export default VPNScene;
