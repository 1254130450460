/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Draggable } from '@hello-pangea/dnd';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { closeHeaderProfile,
    addToNormalize,
    setNormalize,
    duplicateFromNormalize
} from '~frontendDucks/hlcfgEditor/index.js';
import {
    NEW_ROW_CONSTANT,
    WAF_PROFILES } from '~frontendConstants/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { RenderRule, RenderEmptyUserRules, RenderDivider } from './renderers.tsx';


const RowProps = {
    uuid: PropTypes.string.isRequired,
    dataIndex: PropTypes.number.isRequired,
    spacing: PropTypes.string.isRequired
};


const Row = ({ uuid, dataIndex, spacing }: InferProps<typeof RowProps>) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const active = useSelector(getActiveCardHook(WAF_PROFILES));


    const addRow = useCallback((addingAfter) => {
        dispatch(addToNormalize(
            {
                type: 'wafProfileRule',
                typeId: WAF_PROFILES,
                addingAfter: typeof addingAfter === 'boolean',
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:rule.added',
            }
        ));
    }, [ active, uuid, dispatch ]);


    const addRowHeader = useCallback((addingAfter) => {
        dispatch(addToNormalize(
            {
                type: 'profileHeader',
                typeId: WAF_PROFILES,
                addingAfter: typeof addingAfter === 'boolean',
                key: 'rules',
                uuid: active,
                uuidToAddBefore: uuid,
                successText: 'profile:header.added',
            }
        ));
    }, [ active, uuid, dispatch ]);


    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({
            uuid: uuid,
            parentKey: 'rules',
            parentUuid: active,
        }));
    }, [ active, uuid, dispatch ]);

    const delRow = useCallback(() => {
        dispatch(setNormalize({
            uuid: active,
            key: 'rules',
            value: uuid
        }));
    }, [ active, uuid, dispatch ]);


    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ value, key: name, uuid }));
    }, [ uuid, dispatch ]);


    const closeRules = useCallback(({ value }) => {
        dispatch(closeHeaderProfile({ uuid, value: value, type: WAF_PROFILES }));
    }, [ dispatch, uuid ]);

    const [ types ] = useState([
        { addFunc: addRow, translation: 'profile:profiles.rules.rule' },
        { addFunc: addRowHeader, translation: 'packetFilter:header' }
    ]);

    if (uuid === NEW_ROW_CONSTANT) {
        return (
            <RenderEmptyUserRules
                addHeader={addRowHeader}
                addRow={addRow}
                t={t}
            />
        );
    }

    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={false}
            key={uuid}
        >
            {(provided) =>  {
                if (hlcfgTableNameByRowId(uuid) === 'wafProfileRule') {
                    return (
                        <RenderRule
                            closeRules={closeRules}
                            copyRow={copyRow}
                            delRow={delRow}
                            provided={provided}
                            setValue={setValue}
                            spacing={spacing}
                            types={types}
                            uuid={uuid}
                        />
                    );
                }
                return (
                    <RenderDivider
                        closeRules={closeRules}
                        copyRow={copyRow}
                        delRow={delRow}
                        provided={provided}
                        setValue={setValue}
                        spacing={spacing}
                        types={types}
                        uuid={uuid}
                    />
                );}
            }
        </Draggable>
    );
};

Row.propTypes = RowProps;

export default Row;
