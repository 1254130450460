/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { VPN_SETTING_MENU_ADVANCED,
    VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE
} from '~frontendConstants/index.js';


const vpnTable = hlcfgPathGetter.services.vpn.openvpn;

export const getRouteMap = (routeId: string) => {
    const route = getRowPathGetter<'route'|'vpnRoute'>(routeId as any);
    return poDef.container(routeId, {
        destination: poDef.select(poDef.pathId(route.destination)),
        gateway: poDef.select(poDef.pathId(route.gateway)),

    });
};
export const getVpnUserMap = (userId: string) => {
    const user = getRowPathGetter<'openvpnUser'>(userId as any);
    return poDef.container(userId, {
        type: poDef.select(poDef.pathId(user.type)),
        commonName: poDef.textInput(poDef.pathId(user.commonName)),
        addresses: poDef.select(poDef.pathId(user.addresses)),
        siteToSiteNetworks: poDef.select(poDef.pathId(user.siteToSiteNetworks)),
    });
};
export const getVpnCardMap = (vpnId: string) => {
    const ras = getRowPathGetter<'openvpnRas'>(vpnId as any);
    const client = getRowPathGetter<'openvpnClient'>(vpnId as any);
    return poDef.container(vpnId, {
        settings: poDef.collapsible('settings', {
            vpnAddress: poDef.select(poDef.pathId(ras.vpnAddress)),
            serverPort: poDef.select(poDef.pathId(ras.serverAddress.port)),
            serverIsTcp: poDef.switch(poDef.pathId(ras.isTcp)),
            serverAddress: poDef.select(poDef.pathId(ras.serverAddress.addr)),
            routeNopull: poDef.switch(poDef.pathId(client.routeNopull)),

            tabs: poDef.group({
                certificatesTabHandle: poDef.clickableStatusBox(VPN_SETTING_MENU_FILES),
                // There is no system in tests that allows uploading files. So this is not tested.
                // certificates: poDef.container(poDef.pathId(ras.files), {
                //    ...
                // }),
                routeTableTabHandle: poDef.clickableStatusBox(VPN_SETTING_MENU_ROUTE),
                routeTableTab: poDef.container('routeTableTab', {
                    routes: poDef.table(poDef.pathId(ras.routes), getRouteMap),
                }),
                advancedSettingsTabHandle: poDef.clickableStatusBox(VPN_SETTING_MENU_ADVANCED),
                advancedSettings: poDef.group({
                    // Client/RAS shared
                    remoteCertTls: poDef.switch(poDef.pathId(ras.remoteCertTls)),
                    keepaliveInterval: poDef.textInput(poDef.pathId(ras.keepalive.interval)),
                    keepaliveTimeout: poDef.textInput(poDef.pathId(ras.keepalive.timeout)),
                    interfaceTopology: poDef.select(poDef.pathId(ras.interfaceTopology)),
                    wanLan: poDef.select('wanLan'),
                    tlsAuthToggle: poDef.switch(poDef.pathId(ras.tlsAuth.__off)),
                    tlsAuthHash: poDef.textInput(poDef.pathId(ras.tlsAuth.hash)),
                    tlsAuthOptionAuto: poDef.clickableStatusBox(poDef.pathId(ras.tlsAuth.option) + '-'),
                    tlsAuthOption0: poDef.clickableStatusBox(poDef.pathId(ras.tlsAuth.option) + '-0'),
                    tlsAuthOption1: poDef.clickableStatusBox(poDef.pathId(ras.tlsAuth.option) + '-1'),

                    // RAS only
                    clientToClient: poDef.switch(poDef.pathId(ras.clientToClient)),
                    duplicateCn: poDef.switch(poDef.pathId(ras.duplicateCn)),
                    compressMigrate: poDef.switch(poDef.pathId(ras.compressMigrate)),
                    googleAuthEnabled: poDef.switch(poDef.pathId(ras.googleAuthEnabled)),
                    renegSec: poDef.textInput(poDef.pathId(ras.renegSec)),
                    maxClients: poDef.textInput(poDef.pathId(ras.maxClients)),
                    authAlg: poDef.select(poDef.pathId(ras.authAlg)),
                    dataCiphers: poDef.select(poDef.pathId(ras.dataCiphers)),
                    dataCiphersFallback: poDef.select(poDef.pathId(ras.dataCiphersFallback)),
                    crlVerifyToggle: poDef.switch(poDef.pathId(ras.crlVerify.__off)),
                    crlVerifyTypeIsFile: poDef.switch(poDef.pathId(ras.crlVerify.type)),
                    crlVerifyUrl: poDef.textInput(poDef.pathId(ras.crlVerify.url)),
                }),
            }),
        }),
        pushToClient: poDef.collapsible('pushToClientSection', {
            dnsServers: poDef.select(poDef.pathId(ras.pushToClient.nameservers.addresses)),
            forceDns: poDef.switch(poDef.pathId(ras.pushToClient.nameservers.force)),
            ntp: poDef.select(poDef.pathId(ras.pushToClient.ntp)),
            domain: poDef.select(poDef.pathId(ras.pushToClient.domain)),
            redirectGateway: poDef.switch(poDef.pathId(ras.pushToClient.defaultGateway)),
            routes: poDef.table(poDef.pathId(ras.pushToClient), getRouteMap),

        }),
        pushToUser: poDef.collapsible('pushToUserSection', {
            ccdExclusive: poDef.switch(poDef.pathId(ras.ccdExclusive)),
            users: poDef.table(poDef.pathId(ras.pushToUser), getVpnUserMap)
        }),
    });
};

export const openvpnSceneMap = poDef.scene('openvpnScene', '/configuration/services/vpnServer', {
    addRas: poDef.clickable('addRas'),
    addClient: poDef.clickable('addClient'),
    cardHandles: poDef.cardHandles(poDef.pathId(vpnTable)),
    getVpnCard: getVpnCardMap,
});
