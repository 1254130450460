/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBCardHeader, MDBCard, MDBCardBody, MDBRow, MDBCol } from 'mdbreact';
import { useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {  Input, Switch, Select, ConfirmPopover } from '~frontendComponents/Generic/index.js';
import { setGlcfgValueScalar, getGateway, getComponentEnabled,
    setObjectNormalize, addToNormalize,
    getMultihomingIds,
    useGlcfgValue,
    useGlcfgSchema } from '~frontendDucks/hlcfgEditor/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { DEFAULT_GW, MULTIHOMING, ROUTING_ENABLED, GW4, GW6, IPV6_ENABLED } from '~frontendConstants/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { DEFAULT_MULTIHOMING_PREMPT_SECONDS } from '~sharedConstants/index.ts';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import { useBoolean, useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';
import { useDhcpOnExternal } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';


export const Gateway = () => {
    const type = DEFAULT_GW;
    const routingEnabled = useGlcfgValue(ROUTING_ENABLED);
    const dhcpOnExternal = useDhcpOnExternal();
    const gateways = useSelector(getGateway);
    // @ts-expect-error create selector types do not work because importing from JS. Fix when changed to TS.
    const multihomingEnabled = useSelector(state => getComponentEnabled(state, MULTIHOMING));
    const multihomingGateways = useSelector(getMultihomingIds);
    const schemaIpv4 = useGlcfgSchema(GW4);
    const ipv6Enabled = useGlcfgValue(IPV6_ENABLED);
    const schemaIpv6 = useGlcfgSchema(GW6);

    const doSetValueScalar = useDispatchCallback(setGlcfgValueScalar, []);
    const setValue = useDispatchCallback(setObjectNormalize, []);
    const add = useDispatchCallback(addToNormalize, []);

    const { t } = useTranslation();

    const [ multihomingTurnOn, setMultihomingTurnOn ] = useBoolean();

    const setObjectEventOff = useCallback(({ value, name }) => {
        if (!value) {
            setMultihomingTurnOn.on();
        } else {
            setValue({ glcfgKey: MULTIHOMING, value, key: name });
        }
    }, [ setValue, setMultihomingTurnOn ]);
    const onChangeEvent = useCallback(({ name, value }) => {
        if (dhcpOnExternal && name === GW4) {
            return;
        }
        doSetValueScalar(name, { addr: value });
    }, [ dhcpOnExternal, doSetValueScalar ]);

    const onChangeEventBool = useCallback(({ name, value }) => {
        doSetValueScalar(name, value);
    }, [ doSetValueScalar ]);

    const onChangeEvent6 = useCallback(({ name, value }) => {
        if (dhcpOnExternal && name === GW6) {
            return;
        }
        doSetValueScalar(name, value);
    }, [ dhcpOnExternal, doSetValueScalar ]);

    const setObjectEvent = useCallback(({ value, key }) => {
        setValue({ glcfgKey: MULTIHOMING, value: value, key }); //saga would be nice
    }, [ setValue ]);

    const addMultihoming = useCallback(() => {
        setValue({ glcfgKey: MULTIHOMING, value: false, key: '__off' }); //saga would be nice
        if (!multihomingGateways?.length) {
            add({
                type: 'multihomingGateway', //just one type
                typeId: MULTIHOMING,
                key: 'gateways',
                objectKey: true
            });
            add({
                type: 'multihomingGateway', //just one type
                typeId: MULTIHOMING,
                key: 'gateways',
                objectKey: true,
            });
        }
        setMultihomingTurnOn.off();

    }, [ multihomingGateways, add, setValue, setMultihomingTurnOn ]);


    const setTimeout = setTimeoutComponentFunction({ setValue: setObjectEvent });

    const timeoutValidator = setTimeoutComponentValidator(t);

    return (
        <MDBCard>
            <MDBCardHeader className="profiles__title">
                {t(`widgets:Network.multihoming.${DEFAULT_GW}.title`)}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <Select
                            disabled={multihomingEnabled}
                            id={`gw${type}`}
                            isCreatable={!dhcpOnExternal}
                            isMulti
                            label={glcfgDefinition[GW4].title}
                            name={'gw4'}
                            noOptionsMessage
                            onChange={onChangeEvent}
                            options={dhcpOnExternal ? [ {
                                value: dhcpOnExternal.gateway,
                                label: t('widgets:global.valueFromDhcp'),
                                tooltipValues: [ dhcpOnExternal.gateway ],
                                notRemovable: true,
                            } ] : undefined}
                            schema={schemaIpv4.properties.addr}
                            value={multihomingEnabled ?
                                [ t('widgets:Network.multihoming.on') ] :
                                dhcpOnExternal ? [ dhcpOnExternal.gateway ] : gateways.addr}
                        />
                        {ipv6Enabled &&
                            <Select
                                disabled={multihomingEnabled}
                                id={`gw${type}`}
                                isCreatable={!dhcpOnExternal}
                                isMulti
                                label={glcfgDefinition[GW6].title}
                                name={'gw6'}
                                noOptionsMessage
                                onChange={onChangeEvent6}
                                schema={schemaIpv6}
                                value={multihomingEnabled ?
                                    [ t('widgets:Network.multihoming.on') ] :
                                    dhcpOnExternal ? undefined : gateways.addr6}
                            />
                        }
                        {multihomingEnabled &&
                            <Input
                                label={t('widgets:Network.multihoming.preemptive')}
                                name="preemptAfterSeconds"
                                onChange={setTimeout}
                                placeholder={DEFAULT_MULTIHOMING_PREMPT_SECONDS + 's'}
                                validator={timeoutValidator}
                                value={gateways?.preemptAfterSeconds ?
                                    valueFormatter.formatSeconds(gateways.preemptAfterSeconds) : ''}
                            />
                        }
                        <Switch
                            align="spaceBetween"
                            checked={routingEnabled}
                            id="routingEnabledId"
                            label={glcfgDefinition[ROUTING_ENABLED].title}
                            name="routingEnabled"
                            onChange={onChangeEventBool}
                        />
                        <ConfirmPopover
                            isOpen={multihomingTurnOn}
                            onCancel={setMultihomingTurnOn.off}
                            onSuccess={addMultihoming}
                            text="widgets:Network.multihoming.warning.desc"
                            title="widgets:Network.multihoming.warning.title"
                        >
                            <Switch
                                align="spaceBetween"
                                checked={!multihomingEnabled}
                                id="multihomingEnabled"
                                label={t('widgets:Network.multihoming.title')}
                                name="__off"
                                onChange={setObjectEventOff}
                            />
                        </ConfirmPopover>
                    </MDBCol>

                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
