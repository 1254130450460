/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { MDBCol } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { ToggleBox, Icon, Switch } from '~frontendComponents/Generic/index.js';
import { componentActionSequenceOpen,  } from '~frontendDucks/systemComponentAction/systemComponentAction.ts';
import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import { getStringMatch } from '~frontendLib/stringUtils.js';
import { SYSTEMCTL_RETURN_VALUE_INACTIVE } from '~sharedConstants/index.ts';
import { getComponentName } from '~frontendComponents/SystemComponents/getComponentName.js';
import { getSystemComponentsBasedOnName } from '~frontendDucks/hlcfgEditor/index.js';
import {
    useExpectedSystemComponentQuery, useSystemComponentActionsQuery, useSystemComponentStatusQuery
} from '~frontendQueries/system/hooks.ts';


const updateState = ({ componentStatus, componentActions }) => {
    const enabled = componentStatus?.stdout?.match(/(enabled;)|(indirect;)/g);
    return {
        enabled: enabled,
        disabled: !enabled &&
            componentActions?.enable &&
            componentActions?.disable
    };
};


const isError = ({ componentStatus, expectedComponent, enabled }) => {
    if (componentStatus?.stderr) {
        return true;
    }
    else if (componentStatus?.code && componentStatus?.stdout) {
        if (componentStatus?.code === SYSTEMCTL_RETURN_VALUE_INACTIVE) {
            if (expectedComponent === 'enabled' || enabled) {
                return true;
            }
            return false;
        }
        return true;
    }
    return false;

};
isError.propTypes = {
    componentStatus: PropTypes.object,
    expectedComponent: PropTypes.string,
    enabled: PropTypes.bool,
    t: PropTypes.func,
};


const SystemComponentButtonNoMemo = (props: {
    instanceId?: string, instanceOfComponentType?: string, name: string, searchValue?: string, fullSize?: boolean
    datacy?: string
}) => {
    const { name, instanceId, instanceOfComponentType, searchValue, fullSize, datacy } = props;
    const componentStatus = useSystemComponentStatusQuery(name).data;
    const componentActions = useSystemComponentActionsQuery(name).data;
    const expectedComponent = useExpectedSystemComponentQuery(name).data;
    // @ts-expect-error create selector types do not work because importing from JS. Fix when changed to TS.
    const systemName = useSelector(state => getSystemComponentsBasedOnName(state, name));

    const { t } = useTranslation();

    const { enabled, disabled } = updateState({ componentStatus, componentActions });
    const dispatch = useDispatch();
    const doAction = useCallback((componentAction: string, allowed: any) => {
        if (allowed) {
            dispatch(componentActionSequenceOpen({ componentAction, componentType: name, componentId: null }));
        }
    }, [ dispatch, name ]);

    const tKey = getComponentName(instanceOfComponentType || name);
    const tParams = { instanceId };
    if (expectedComponent === 'disabled' && componentStatus?.code) {
        return null;
    }
    if (searchValue && !getStringMatch({
        toMatch: t(`widgets:SystemComponents.components.${tKey}.title`, tParams),
        searchValue }))
    {
        return null;
    }
    return (
        <MDBCol
            className="pt-1"
            key={name}
            lg={fullSize ? '12' : '2'}
            md={fullSize ? '12' : '4'}
            sm={fullSize ? '12' : '6'}
        >
            <ToggleBox
                blockOpening
                className={classNames(
                    { 'systemComponents--isActive': !componentStatus?.code },
                    { 'systemComponents--isDown': (expectedComponent === 'disabled' &&
                             !componentStatus?.code) ||
                            componentStatus?.code },
                    { 'systemComponents--isDisabled': !expectedComponent && disabled },
                    'systemComponents__textFlex'
                )}
                description={
                    (
                        <>
                            {componentActions?.enable && componentActions?.disable ?
                                <Switch
                                    align="left"
                                    checked={Boolean(enabled?.length)}
                                    className="systemComponents__enabled"
                                    datacy={datacy ? `${datacy}-${enabled ? 'disable' : 'enable'}` : ''}
                                    id="disabled"
                                    mini
                                    name="log"
                                    onChange={() => doAction(enabled ? 'disable' : 'enable', true)}
                                    withoutBorder
                                    withoutLabelMargins
                                    withoutMinWidhtHeight
                                /> :
                                null}
                            <SystemServiceStatusIcon
                                enabled={enabled}
                                isError={isError({ componentStatus, expectedComponent, enabled })}
                                status={componentStatus?.stderr || componentStatus?.stdout}
                                tKey={tKey}
                                tParams={tParams}
                            />

                            {componentStatus?.code ? disabled ? null :
                                <>
                                    <Icon
                                        className="icon--red systemComponents__activityIcon"
                                        name="cancel"
                                        size="sx"
                                    />
                                </> :
                                <Icon
                                    className="icon--primary systemComponents__activityIcon"
                                    name="check-circle-outline"
                                    size="sx"
                                />
                            }
                            <div className="systemComponents__textFlex--maxWidth">
                                {systemName || t(`widgets:SystemComponents.components.${tKey}.title`, tParams)}
                                <p className="m-0 systemComponents__realService">
                                    {name}
                                </p>
                            </div>
                        </>
                    )
                }

                id="downgrade"
                isOpen
                name="downgrade"
                replaceButton={(
                    <div>

                        <IconWithTooltip
                            className={classNames(
                                { 'icon--grey systemComponents__cursor':
                                 !componentActions?.start || !componentStatus?.code || disabled },
                                'icon--secondary',
                                'icon--clicable'
                            )}
                            link={true}
                            name="play"
                            onClick={(event) => {
                                event.preventDefault();
                                doAction('start', componentActions?.start &&
                                         componentStatus?.code && !disabled);
                            }}
                            tooltipText={t('widgets:SystemComponents.actions.start')}
                            withoutTranslation
                        />

                        <IconWithTooltip
                            className={classNames(
                                { 'icon--grey systemComponents__cursor':
                                 !componentActions?.stop || componentStatus?.code || disabled },
                                'icon--secondary',
                                'icon--clicable'
                            )}
                            link={true}
                            name="pause"
                            onClick={(event) => {
                                event.preventDefault();
                                doAction('stop', componentActions?.stop &&
                                         !componentStatus?.code && !disabled);
                            }}
                            tooltipText={t('widgets:SystemComponents.actions.pause')}
                            withoutTranslation
                        />
                        <IconWithTooltip
                            className={classNames(
                                { 'icon--grey systemComponents__cursor':
                                 !componentActions?.restart || disabled },
                                'icon--secondary',
                                'icon--clicable'
                            )}
                            link={true}
                            name="repeat"
                            onClick={(event) => {
                                event.preventDefault();
                                doAction('restart', componentActions?.restart && !disabled);
                            }}
                            tooltipText={t('widgets:SystemComponents.actions.restart')}
                            withoutTranslation
                        />
                        <IconWithTooltip
                            className={classNames(
                                { 'icon--grey systemComponents__cursor':
                                 !componentActions?.reload  || disabled },
                                'icon--secondary',
                                'icon--clicable'
                            )}
                            link={true}
                            name="restore"
                            onClick={(event) => {
                                event.preventDefault();
                                doAction('reload', componentActions?.reload && !disabled);}}
                            tooltipText={t('widgets:SystemComponents.actions.reload')}
                            withoutTranslation
                        />
                    </div>

                )}
            />
        </MDBCol>


    );
};

const SystemComponentButton = React.memo(SystemComponentButtonNoMemo);

type SystemServiceStatusIconType = {
    enabled: boolean,
    isError: boolean,
    tKey: string,
    tParams: any,
    status?: string,
}

const SystemServiceStatusIcon = ({ isError, enabled, tKey, tParams, status }: SystemServiceStatusIconType) => {

    const { t } = useTranslation();

    if (!status) {
        return null;
    }

    return (
        <IconWithPopover
            body={
                <pre>
                    {status}
                </pre>
            }
            className={`icon--${!enabled ? 'grey' : isError ? 'red' : 'primary'}`}
            divClassName="systemComponents__errorIcon"
            header={
                t(`widgets:SystemComponents.components.${tKey}.title`, tParams)
            }
            iconSize="sx"
            name="alert-circle"
        />
    );
};

export default SystemComponentButton;
