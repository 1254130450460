/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import type { HttpIncomingMessage } from 'exegesis';
import { RequestHandler } from 'express';
import type { IncomingHttpHeaders } from 'http';
import { ReactNode } from 'react';
import type { Required } from 'utility-types';

import type {
    CfgActivationTargetNode,
    HTTP_HEADER_CLUSTER_ASKING_NODE,
    HTTP_HEADER_CLUSTER_NODE,
    HTTP_HEADER_REQUEST_SEQ_ID,
    HTTP_HEADER_SESSION_SEQ_ID,
    HTTP_HEADER_USER_LANGUAGE,
    HTTP_HEADER_USER_NAME,
    HTTP_HEADER_USER_ROLE,
} from '~commonLib/constants.ts';

/**
 * TypeBrand is mostly used to make type display Type name in IDE, instead of its resolved type.
 * Very useful for association of strings with their meaning.
 *
 * Note that type checking will work only among branded type.
 * So it is not very useful to actually typecheck string types.
 * For that use-case, use "Template Literal Types"
 *
 * @example
 * type Address = string
 * const address: Address = '';
 * // mouse hover over address shows "const name: string"
 * type Name = string & TypeBrand<'Name'>
 * const name: Name = '';
 * // mouse hover over name shows "const name: Name"
 *
 * type Email = string & TypeBrand<'Email'>
 * const email: Email = '';
 *
 * // Expect TS2322 (Incompatible type)
 * const typeChecked: Name = email;
 * // Expect no error;
 * const notTypeChecked: Name = address;
 */

export type TypeBrand<T> = {
    z__typeBrand?: T,
}

export type StrType<T extends string> = string & TypeBrand<T>

export interface HttpRequestInfo {
    req: any,
    path: string,
    method: string,
    requestBody: any
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> =
    Pick<T, Exclude<keyof T, Keys>>
    & {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
}[Keys]

export type SocketIODataPayloadItem<T> = T;
export type SocketIODataPayload<T> = SocketIODataPayloadItem<T>[];

interface BackendSocketInfo {
    backendSocket: string,
}
export interface TargetNodeInfo {
    targetNode: CfgActivationTargetNode,
}
export interface SocketIoEventRoutingInfo extends TargetNodeInfo, BackendSocketInfo {
    namespace: string,
    sourceNodes: CfgActivationTargetNode[],
}

export interface DataForSocketIODataStorage extends TargetNodeInfo {
    namespace: string,
    event: string,
    ioPayload: SocketIODataPayloadItem<any>,
    options?: {
        omitSender?: boolean
    }
    req?: any,
    recordReplay?: boolean,
    isLastReplayEvent?: boolean,
}

export type DataStorageOptions = {
    ttl?: number,
    persistent?: boolean,
}

export interface SocketIOStoragePayload {
    data: DataForSocketIODataStorage,
    storageOptions?: DataStorageOptions,
}

export type SocketIOEmitterDescriptor = {
    nodeName: string,
    namespace: string,
}

export type RequestBody<T> = {
    requestBody: T,
    req: ReqObject,
}

export interface RequestSession {
    sessionId?: number,
    requestId?: number,
    language: string,
    user?: {
        username: string,
        role: string,
    },
    save: Express.Request['session']['save'],
    reload: Express.Request['session']['reload'],
}

interface Headers extends IncomingHttpHeaders {
    [HTTP_HEADER_USER_NAME]?: string,
    [HTTP_HEADER_USER_ROLE]?: string,
    [HTTP_HEADER_SESSION_SEQ_ID]?: string,
    [HTTP_HEADER_REQUEST_SEQ_ID]?: string,
    [HTTP_HEADER_CLUSTER_NODE]?: string,
    [HTTP_HEADER_CLUSTER_ASKING_NODE]?: string,
    [HTTP_HEADER_USER_LANGUAGE]?: string,
}
export interface ReqObject extends HttpIncomingMessage {
    headers: Headers,
    sessionID?: string,
    session: RequestSession,
    i18n?: any,
}

export type NotUndefined<T> = T extends undefined ? never : T
export type NotNull<T> = T extends null ? never : T

export type ApiRequesterType = (args: HttpRequestInfo) => Promise<any>;

/**
 * @deprecated This type was created because type "Awaited" was unknown. Use that instead.
 */
export type UnpackPromise<T> = T extends Promise<infer U> ? U : T;

export type MultiHomingConfig = PojObject & {
    enabled: boolean,
    pingIntervalMs?: number,
    preemptAfterMilliseconds: number,
    watchDevices: {
        doNotBindDevice?: boolean,
        pingIps: string[],
        considerDownAfterMilliseconds: number,
        sourceDevice: string,
    }[];
}

export type ElementType<T extends ReadonlyArray<unknown>> =
    T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export type HoneypotConfig = PojObject & {
    blacklistTimeMs: number,
    addressWhitelist: string[],
    useImmediateSender?: boolean,
};

export type PromiseOrNot<T> = Promise<T>|T;
export type ArrayOrNot<T> = T[]|T;

export type Impossible<K extends keyof any> = {
    [P in K]: never;
};

export type NoExtraProperties<T, U extends T = T> = U & Impossible<Exclude<keyof U, keyof T>>;
export type WithChildrenOfSelf<T> = T & { children: WithChildrenOfSelf<T>[] };
export type FilterUndefined<T extends unknown[]> = T extends [] ? [] :
    T extends [infer H, ...infer R] ?
        H extends undefined ? FilterUndefined<R> : [H, ...FilterUndefined<R>] : T

export const dangerouslyCast = <T>(val: any) => val as T;

export type Primitive = bigint | boolean | null | number | string | undefined;

export type JsonValue = Primitive | JsonObject | Array<JsonValue>;

export interface JsonObject {
    [key: string]: JsonValue;
}

export type PojString = string & Partial<Record<GetterKeys, never>>;

export type PojBigint = bigint & Partial<Record<GetterKeys, never>>;

export type PojBoolean = boolean & Partial<Record<GetterKeys, never>>;

export type PojNumber = number & Partial<Record<GetterKeys, never>>;

export type PojArray = Array<PojValue>;

// plain old JavaScript value
export type PojValue =
    null |
    undefined |
    PojBigint |
    PojBoolean |
    PojNumber |
    PojString |
    PojObject |
    PojArray;

type GetterKeys = '_get' | '_getRaw';

// plain old JavaScript object aka POJO
export type PojObject = {
    [key: string]: PojValue;
} & Partial<Record<GetterKeys, never>>;

export type User = 'kernun' | 'root' | '_icamd' | 'sysadmin' | '_icasd';
export type Group = 'kernun' | 'root' | '_icamd' | 'sysadmin' | '_icasd';

export type AnyFunc<T = any> = (...args: any[]) => T;
export type AnyAsyncFunc<T = any> = (...args: any[]) => Promise<T>;
export type AnyFuncMaybeAsync<T = any> = (...args: any[]) => PromiseOrNot<T>;

/** Returns a record that contains only those properties of T that extend undefined. */
type UndefinedProperties<T> = {
    [P in keyof T]-?: undefined extends T[P] ? P : never
}[keyof T]

/**
 * Make all properties in T required and not undefined
 */
export type RequiredDefined<T> = {
    [P in keyof T]-?: NotUndefined<T[P]>;
};

/**
 * Returns an object that has the same content as T with the only exception
 * being that properties that extend undefined are optional.
 */
export type MakeUndefinedPropertiesOptional<T> =
    Partial<Pick<T, UndefinedProperties<T>>>
    & Pick<T, Exclude<keyof T, UndefinedProperties<T>>>

/**
 * @example
 *  http://test.tld/
 *  https://test.tld/index.html
 *  https://test.tld/index.html
 *  git+ssh://example.org
 *  magnet:?xt.1=http://example.org
 *  file://dev/zero
 */
export type UrlString = string & TypeBrand<'UrlString'>

/**
 * @example
 *  test.js
 *  // BAD EXAMPLE: ./test.js
 */
export type FilenameString = string & TypeBrand<'FilenameString'>

/**
 * @example
 *  test.js
 *  ./test.js
 *  /home/test/test.js
 *  ../test.js
 */
export type FilePathString = string & TypeBrand<'FilePathString'>

/**
 * @example
 *  /dir
 *  ./dir
 *  ../../dir
 */
export type DirectoryPathString = string & TypeBrand<'DirectoryPathString'>

/**
 * Returns first argument type of function
 */
export type FirstParam<F extends (...args: any) => any> = Parameters<F>[0];


type RequiredKeys<T> = {
    [K in keyof T]-?: object extends { [P in K]: T[K] } ? never : K
}[keyof T]
type OptionalKeys<T> = {
    [K in keyof T]-?: object extends { [P in K]: T[K] } ? K : never
}[keyof T]
type PickRequired<T> = Pick<T, RequiredKeys<T>>
type PickOptional<T> = Pick<T, OptionalKeys<T>>
type Nullable<T> = { [P in keyof T]-?: Exclude<T[P], undefined> | null }

/**
 * Changes every property in T that is optional, into required nullable property.
 */
export type OptionalPropertiesToNullable<T> = PickRequired<T> & Nullable<PickOptional<T>>


export type NullPropsToOptional<T extends object> = {
    [K in keyof T]: NonNullable<T[K]>
}


export type GetSessionMiddleware = (params: {
    sessionSecrets: string[],
    cookieNameSuffix?: string,
    sessionTimeoutMs?: number,
    isHttps: boolean,
}) => RequestHandler
export type PartiallyRequired<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;
export type JSXElement = ReactNode;
