/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBCard, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useBoolean, useString } from '~frontendLib/hooks/defaultHooks.ts';
import { Icon, InputSearch, SelectColumns } from '~frontendComponents/Generic/index.js';
import { DatatableContext } from '~frontendRoot/scenes/Protection/scenes/Waf/WafProfile/components/ProfileRules/ProfileRules.tsx';
import { DatatableWidget } from '~frontendRoot/widgets/index.js';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES, TableSizeType } from '~frontendConstants/constants.ts';
import { setNormalize } from '~frontendDucks/hlcfgEditor/normalizeTableGettersAndSetters.js';
import { getDnsProxyActiveProfile } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';

import Row from '../DnsProxyRow/index.ts';


const CreateRow = (props :{ dataIndex: number, uuid: string, spacing: TableSizeType }) => {
    const { dataIndex, uuid, spacing } = props;
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid || 'addingRow'}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

const ProfileRules = (props: { activeProfile: string, spacing?: TableSizeType }) => {

    const { activeProfile, spacing } = props;
    const [ searchValue, setSearchValue ] = useString();
    const [ showRules, setShowRules ] = useBoolean();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profile = useSelector(getDnsProxyActiveProfile);
    const passReorderData = useCallback((value) => {
        if (!value.includes(NEW_ROW_CONSTANT)) {
            dispatch(setNormalize({
                uuid: activeProfile,
                key: 'rules',
                value
            }));
        }
    }, [ activeProfile, dispatch ]);

    if (!profile) {
        return null;
    }
    const { rules } = profile;
    return (
        <MDBCard>
            <MDBCardTitle
                className={classNames(
                    'profiles__title',
                    { 'profiles__title--noBorderBottom': !showRules }
                )}
            >
                <div>
                    {t('profile:dnsProxy.rules.title')}
                    <Icon
                        name={showRules ? 'chevron-down' : 'chevron-up'}
                        onClick={setShowRules.swap}
                    />
                </div>
                <div className={'profiles__titleActions'}>
                    {!showRules ? (
                        <>
                            <SelectColumns id={SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES} />
                            <InputSearch
                                className={'mb-3 mt-0'}
                                id={'searchValueIDVlans'}
                                search={searchValue}
                                setter={setSearchValue}
                            />
                        </>
                    ) : null}
                </div>
            </MDBCardTitle>
            <div
                className={classNames(
                    'profile__body',
                    { 'profile__body--hide': showRules },
                    { 'profile__title--borderBottom': !showRules }
                )}
            >
                <DatatableContext.Provider value={{ search: searchValue }}>
                    <DatatableWidget
                        columnsId={SELECTABLE_TABLE_DNS_PROXY_PROFILE_RULES}
                        createRow={CreateRow}
                        data={rules?.length === 0 ? [ NEW_ROW_CONSTANT ] : rules}
                        passReorderData={passReorderData}
                        spacing={spacing}
                    />
                </DatatableContext.Provider>
            </div>
        </MDBCard>
    );
};

export default ProfileRules;
