/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';
import { useCallback } from 'react';
import { useStore, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { SelectModel, SelectOption } from '~frontendComponents/Generic/SelectV2/types.ts';
import Message from '~frontendComponents/Message/Message.tsx';
import { DOMAIN_COLOR } from '~frontendConstants/constants.ts';
import { getStringifiedStaticHlcfgRef } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { isStaticReference, StaticHlcfgReference, getStaticReferenceName } from '~sharedLib/staticHlcfgReferenceUtils.ts';


export const useStaticHlcfgReferenceModelWrap = (
    getPossibleRefFromValue: (value: any) => unknown = value => value,
    opts: {noStringifyForCopyOverride?: boolean} = {}
) => {
    // This is a way to access redux store in callback without making this
    // component dependent on it (and re-render with every single store change)
    const store = useStore();
    const { t } = useTranslation();
    return useCallback((model: SelectModel<unknown>) => {
        const modelStringify = model.stringify;
        assert(modelStringify, 'Static hlcfg reference model wrapper requires base model to have stringify');
        const prepareOption = (value): SelectOption => {
            const ref = getPossibleRefFromValue(value);
            if (isStaticReference(ref)) {
                return {
                    label: <StaticHlcfgReferenceLabel refObj={ref} />,
                    tooltip: <StaticHlcfgReferenceTooltip refObj={ref} />,
                    value,
                    backgroundColor: DOMAIN_COLOR,
                };
            }
            return model.prepareOption(value);
        };

        const stringify = (value) => {
            const ref = getPossibleRefFromValue(value);
            if (isStaticReference(ref)) {
                return getStringifiedStaticHlcfgRef(store.getState(), ref, t);
            }
            return modelStringify(value);
        };
        return {
            ...model,
            stringify,
            prepareOption,
            stringifyForCopy: !opts.noStringifyForCopyOverride && model.stringifyForCopy ? (values) => {
                return values.map(stringify).join(', ');
            } : model.stringifyForCopy,
        };
    }, [ getPossibleRefFromValue, store, t, opts.noStringifyForCopyOverride ]);
};

const StaticHlcfgReferenceLabel = ({ refObj }: { refObj: StaticHlcfgReference }) => {
    return <Message message={`packetFilter:staticRef.${getStaticReferenceName(refObj)}.title`} />;
};
const StaticHlcfgReferenceTooltip = ({ refObj }: {refObj: StaticHlcfgReference}) => {
    const { t } = useTranslation();
    const resolved = useSelector(state => getStringifiedStaticHlcfgRef(state, refObj, t));
    return <div>{resolved}</div>;
};
