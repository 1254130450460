/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';


const suriPath = hlcfgPathGetter.protection.suricata;
export const suricataSceneMap = poDef.scene('suricataScene', '/protection/ips/settings', {
    status: poDef.onOffButtons(poDef.pathId(suriPath.service.__off)),
    whitelistComment: poDef.textInput(poDef.pathId(suriPath.service.whitelistComment)),
    blacklistComment: poDef.textInput(poDef.pathId(suriPath.service.blacklistComment)),
    autoUpdate: poDef.switch(poDef.pathId(suriPath.service.autoUpdate)),
    betaServer: poDef.switch(poDef.pathId(suriPath.updates.betaServer)),
    interfaces: poDef.select(poDef.pathId(suriPath.service.interfaces)),
    blacklist: poDef.select(poDef.pathId(suriPath.service.blacklist)),
    whitelist: poDef.select(poDef.pathId(suriPath.service.whitelist)),
});
