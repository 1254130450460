/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { getObjectCrawler, getValue, mapObjectProps } from '~commonLib/objectUtils.ts';
import { findPathsBySchemaPath } from '~commonLib/schema/findPathsBySchemaPath.ts';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SelectOption, SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { COLOR_ERROR_TEXT } from '~frontendConstants/constants.ts';
import { getHlcfgSchema, getWorkHlcfg } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { createGetHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { createSelectorArrayOfObjectsShallow } from '~frontendLib/reduxUtils.ts';
import { hlcfgTableName, HlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { SCHEMA_TYPE_ROW_ID, SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY } from '~sharedLib/schemaTypes.ts';
import { HlcfgSchemaPath } from '~sharedLib/types.ts';


export type RowReferenceSelectValue = string;
type HlcfgRow = {id: string, color: string, name: string};
type ModelProps = {
    isRequired: boolean,
    hlcfgRowsById: Record<string, HlcfgRow>,
}
type HlcfgBindingsProps = {
    referenceTypes: string[],
}
type SingleVal = {
    value: RowReferenceSelectValue|undefined,
    onChange: (newValue: RowReferenceSelectValue|undefined) => void,
}
type ArrayVal = {
    value: RowReferenceSelectValue[],
    onChange: (newValue: RowReferenceSelectValue[]) => void,
}
interface RowReferenceSelectCommonProps extends SingleVal, SelectV2BaseProps {
    isRequired: boolean
}
interface RowReferenceSelectProps extends RowReferenceSelectCommonProps, HlcfgBindingsProps {
    isRequired: boolean
}
interface RowReferenceArraySelectCommonProps extends ArrayVal, SelectV2BaseProps {}
interface RowReferenceArraySelectProps extends RowReferenceArraySelectCommonProps, HlcfgBindingsProps {}

export const RowReferenceSelect = (props: RowReferenceSelectProps) => {
    return (
        <RowReferenceSelectBase
            {...props}
            {...useHlcfgBindings(props)}
        />
    );

};
export const RowReferenceArraySelect = (props: RowReferenceArraySelectProps) => {
    return (
        <RowReferenceArraySelectBase
            {...props}
            {...useHlcfgBindings(props)}
        />
    );

};
export const RowReferenceSelectBase = (props: RowReferenceSelectCommonProps & ModelProps) => {
    return (
        <SelectV2
            {...props}
            {...useRowReferencesSelectModel(props)}
            {...useSingleValueSelectWrapper(props)}
        />
    );
};
export const RowReferenceArraySelectBase = (
    props: RowReferenceArraySelectCommonProps & Omit<ModelProps, 'isRequired'>
) => {
    return (
        <SelectV2
            {...props}
            {...useRowReferencesSelectModel({ ...props, isRequired: false })}
        />
    );
};

const useHlcfgBindings = (props: HlcfgBindingsProps) => {
    const { referenceTypes } = props;
    const existingIds = useSelector(createOrGetHlcfgPrimaryIdsGetter(referenceTypes as HlcfgTableName[]));
    const getRowsById = useMemo(() =>
        createSelector(
            referenceTypes.map(refType => createGetHlcfgValue(hlcfgPathGetter.tables[refType].getPath())),
            (...tables) => {
                const byId = {};
                tables.forEach(table => {
                    Object.entries(table).forEach(([ id, row ]: [string, any]) => {
                        if (row.__off !== true && existingIds.includes(id)) {
                            byId[id] = row;
                        }

                    });
                });
                return byId;
            }
        ),
    [ referenceTypes, existingIds ]);
    return { hlcfgRowsById: useSelector(getRowsById) };

};

const useRowReferencesSelectModel = (props: ModelProps) => {
    const { hlcfgRowsById, isRequired } = props;
    const { t } = useTranslation();

    const prepareOption = useCallback((value: RowReferenceSelectValue): SelectOption<RowReferenceSelectValue> => {
        const row = hlcfgRowsById[value];
        return {
            label: row?.name ?? t('widgets:global.invalidId.title'), value: value, notRemovable: isRequired,
            tooltip: row === undefined ? t('widgets:global.invalidId.desc', { item: value }) : undefined,
            backgroundColor: row === undefined ? COLOR_ERROR_TEXT : row?.color,
        };
    }, [ isRequired, hlcfgRowsById, t  ]);
    const options = Object.values(hlcfgRowsById).map(it => it.id);
    return { prepareOption, options };
};


// Selectors below are huge big brain time. I am not proud of the complexity, but I do not want to compromise
// performance or DX to make things simple. They use caching heavily to avoid re-renders and to share computed
// results between many selects.
// The sole reason for them to exist is to check generically whether row has been removed from current working HLCFG
// Which can not be simply found out from just checking the table containing the row - all the primary reference
// arrays have to be checked to see if the id is present in one of them.

const hlcfgPrimaryIdsGettersCache: Record<string, (state: any) => string[]> = {};
/**
 * This little helper caches created selectors per combination of reference types.
 * It is used so that many components serving the same schema path can share the same selector.
 */
const createOrGetHlcfgPrimaryIdsGetter = (refTypes: HlcfgTableName[]) => {
    const cacheKey = refTypes.join(',');
    if (!hlcfgPrimaryIdsGettersCache[cacheKey]) {
        hlcfgPrimaryIdsGettersCache[cacheKey] = createSelector(
            refTypes.map((refType: HlcfgTableName) => gettersHlcfgPrimaryIds[refType]),
            (...perRefType) => {
                return [ ...new Set(perRefType.flat(2)) ];
            }
        );
    }
    return hlcfgPrimaryIdsGettersCache[cacheKey];

};

/**
 * These selectors (one per hlcfg table) will run for every hlcfg change when they are currently being used.
 * But presumably it is fairly cheap to run. It outputs array of stable references when it is run,
 * unless one of the array changes.
 *
 * Not meant to be used as a standalone because it would cause frequent re-renders.
 */
const gettersHlcfgPrimaryIdArrsByTable = mapObjectProps(hlcfgTableName, tableName => {
    return createSelector(
        [
            (state) => getRowPrimarySchemaPaths(state)[tableName],
            getWorkHlcfg
        ],
        (primarySchemaPathsByTable, hlcfg) => {
            return primarySchemaPathsByTable.flatMap(
                schemaPath => findPathsBySchemaPath(hlcfg, schemaPath).map(path => getValue(hlcfg, path) as string[])
            );
        }
    );
});

/**
 * These selectors (on per hlcfg table) provide caching because the input selector is array of mostly stable references.
 * But the array itself changes often (with every hlcfg change).
 * So this selector provides convenience of flattening the arrays
 * and shields user components from frequent re-renders
 */
const gettersHlcfgPrimaryIds = mapObjectProps(hlcfgTableName, tableName => {
    return createSelectorArrayOfObjectsShallow(
        [ gettersHlcfgPrimaryIdArrsByTable[tableName] ],
        (idsArrs) => {
            return idsArrs.flat();
        }
    );
});

/**
 * This selector is presumably pretty expensive because it crawls the whole schema,
 * but should run only once because the schema reference
 * should never change during the runtime of frontend.
 */
const getRowPrimarySchemaPaths = createSelector(
    [ getHlcfgSchema ],
    (schema) => {
        const rowsPrimarySchemaPathsById: Record<string, HlcfgSchemaPath[]> = {};
        getObjectCrawler({
            matchValue: schema => {
                return schema?.[SCHEMA_TYPE_ROW_ID] && !schema[SCHEMA_TYPE_ROW_REFERENCE_IS_SECONDARY];
            },
            onValueMatched: (value, opts) => {
                value[SCHEMA_TYPE_ROW_ID].forEach((tableType: HlcfgTableName) => {
                    const paths = rowsPrimarySchemaPathsById[tableType] ??= [];
                    paths.push(opts.path);
                });
            },
        })(schema);
        return rowsPrimarySchemaPathsById;
    }
);
