/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Select, } from '~frontendComponents/Generic/index.js';
import { interfaceDeviceOptions } from '~frontendLib/interfaceDeviceUtils.ts';
import { getHoney, getGuiHttps, getInterfacesFromConfiguration, getProxyWpad,
    getIsCluster,
    isFromConfiguration, } from '~frontendDucks/hlcfgEditor/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { getMyNode, getOtherNode } from '~frontendRoot/ducks/clusterSetup/index.js';
import { JSXElement } from '~commonLib/types.ts';
import { useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';


const NetworkInterfaceDevice = (prop: {
    withAllValues?: boolean
    withAllValuesNetwork?: boolean
    isSearchable?: boolean
    withoutBorder?: boolean
    fake?: boolean,
    formGroupClassName?: string,
    datacy?: string,
    classNamePrefix?: string,
    iconName?: string,
    placeholder?: string,
    isCreatable?: boolean,
    isMulti?: boolean,
    isRow?: boolean,
    name?: string,
    noDropdownIndicator?: boolean,
    noOptionsMessage?: boolean,
    paste?: boolean,
    maxValueShown?: number,
    maxNumberOfValues?: number,
    networkInterfaces?: any[],
    label?: JSXElement,
    error?: any,
    onChange?: any,
    defaultValue?: any,
    noWrap?: any,
    iface: any,
    id?: string,
    singleInterface?: boolean,
    disabled?: boolean,
    schema?: object,
    options?: any[],
    wpad?: object,
    guiHTTPS?: object,
    honeypot?: object,
    exceptions?: any[],
    className?: string,
}) => {
    const { t } = useTranslation();

    const isCluster = useSelector(getIsCluster);
    const myNode = useSelector(getMyNode);
    const otherNode = useSelector(getOtherNode);

    const wpad = useSelector(getProxyWpad);
    const guiHTTPS = useSelector(getGuiHttps);
    const honeypot = useSelector(getHoney);

    const interfacesFromCfg = useSelector(getInterfacesFromConfiguration);
    const {
        id,
        label,
        iface,
        singleInterface,
        schema,
        options,
        fake,
        exceptions,
    } = prop;
    const interfacesFromServer = useNetworkInterfacesQuery().data ?? [];
    const networkInterfaces = interfacesFromCfg.map(item => {
        if (!item.dhcp) {
            return item;
        }
        const ifaceFromNetwork = interfacesFromServer.find(
            // TODO: AK-3661: clusterNodeSelector should by passed from the caller defaulting to myNode
            ifaceNetwork => isFromConfiguration({ item: ifaceNetwork, value: item, clusterNodeSelector: myNode })
        );
        return {
            ...item,
            dhcpValue: ifaceFromNetwork?.addresses4?.concat(ifaceFromNetwork?.address6 ?? [])?.filter(addr => addr.dhcp)
        };
    });
    return (
        <Select
            {...prop}
            interfaceSelect={true}
            isMulti={!singleInterface}

            label={label || glcfgDefinition[id ?? '']?.title || null}
            noOptionsMessage
            options={interfaceDeviceOptions({
                networkInterfaces,
                schema,
                options,
                fake,
                wpad: fake ? wpad : undefined,
                guiHTTPS: fake ? guiHTTPS : undefined,
                honeypot: fake ? honeypot : undefined,
                t,
                exceptions,
                isCluster,
                myNode,
                otherNode,
            })}
            value={iface}
        />
    );
};


export default NetworkInterfaceDevice;
