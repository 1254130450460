/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { TFunction } from 'i18next';

import type { HlcfgInputTree, HlcfgTypeDhcpPool, HlcfgTypeOpenVpnUser } from '~frontendTypes/externalTypes.ts';
import { hlcfgTableNameByRowId, HlcfgTableName, HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { getAddressesSelectorContent, isAddressesSelector } from '~sharedLib/addressesSelectorUtils.ts';
import {
    HlcfgTypeProxyProfile, HlcfgTableItem, HlcfgTypeRoute, HlcfgTypeHost,
    HlcfgTypeProfileSpecialItem, HlcfgTypeMultihomingGateway,
    HlcfgTypeProfileAddressesTable, HlcfgTypeDhcpLease, HlcfgTypeTimeInterval } from '~frontendTypes/externalTypes.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import { NetaddrDataObj } from '~sharedLib/types.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { timeIntervalObj } from '~frontendRoot/scenes/Protection/scenes/Proxy/PolicyProfilesNew/components/Row/timeIntervalObj.ts';

import { stringifyAddress } from '../addressUtils.ts';
import { isStaticSegment, TranslationSegment } from './descriptiveHlcfgPathToTranslationSegment.ts';


type HlcfgTableNamesWithoutName = 'profile' | 'route' | 'routeMixedIp' | 'vpnRoute' |'host' | 'profileSpecialItem' |
    'multihomingGateway' | 'dhcpLease' | 'dhcpPool' | 'clusterNode' | 'addressesTable' | 'openvpnUser' |
    'profileRuleTimeInterval';

type HlcfgTableNamesWithName = Exclude<HlcfgTableName, HlcfgTableNamesWithoutName>;

type NamedTranslation = {
    name: string,
    rowId: HlcfgRowId,
}

type DefaultTranslation = {
    default: string,
}

type StaticTranslation = {
    staticTranslation: string
}

export const isStaticTranslation = (segment: TranslationSegmentResolved): segment is StaticTranslation =>
    'staticTranslation' in segment;

export const isNamedTranslation = (segment: TranslationSegmentResolved): segment is NamedTranslation =>
    'name' in segment;

export type TranslationSegmentResolved = NamedTranslation | DefaultTranslation | StaticTranslation;

type Flatten<T> = T extends any[] ? T[number] : T;
/**
 * If an item in {@link addrs} is a reference that does not exist in {@link hlcfg},
 * returns {@link revisionHlcfg}, otherwise returns {@link hlcfg}.
 * It can still happen that {@link addrs} contains a reference that does not exist
 * in the returned HLCFG schema, so use the returned HLCFG schema with caution.
 */
const checkWhichHlcfgHasAllReferences = <T extends NetaddrDataObj>(addrs: T[], hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined) =>  {
    if (!revisionHlcfg) {
        return hlcfg;
    }
    const filteredAddrs = addrs.filter(isNamedObject);
    const missing = filteredAddrs.some(item => !hlcfg.tables[hlcfgTableNameByRowId(namedObjectToString(item))][
        namedObjectToString(item)]);
    return missing ? revisionHlcfg : hlcfg;
};

const translateAddresses = <T extends NetaddrDataObj>(
    addr: T[], hlcfg: HlcfgInputTree, t: TFunction<'translation', undefined>) => {
    return addr?.map(item => translateAddress(item, hlcfg, t));
};

const translateAddress = <T extends NetaddrDataObj>(
    addr: T, hlcfg: HlcfgInputTree, t: TFunction<'translation', undefined>): string => {
    if (!addr) {
        return t('differs:placeholder.empty');
    }
    if (isNamedObject(addr)) {
        return hlcfg.tables[hlcfgTableNameByRowId(namedObjectToString(addr))][namedObjectToString(addr)]?.name;
    }
    if (isAddressesSelector(addr)) {
        const selector = getAddressesSelectorContent(addr);
        if (selector.ifaceType) {
            return t(`widgets:global.${selector.ifaceType.replace('is', 'all')}.${selector.addressType}`);
        }
        if (selector.ifaceId) {
            return t(`widgets:network.selector.${selector.addressType}`, {
                interface: hlcfg.tables[hlcfgTableNameByRowId(selector.ifaceId)][selector.ifaceId]?.name
            });
        }
    }
    return netaddr<T>(addr).toString();
};

const createTranslationObj = (
    rowId: HlcfgRowId,
    rowName: string | undefined,
    defaultTran: string
): Exclude<TranslationSegmentResolved, StaticTranslation> => {
    if (rowName) {
        return {
            rowId: rowId,
            name: rowName,
        };
    } else {
        return {
            default: defaultTran,
        };
    }
};

const getRowFromCorrectHlcfg = <T>(
    hlcfg: HlcfgInputTree,
    revisionHlcfg: HlcfgInputTree | undefined,
    rowId: HlcfgRowId
): T => {
    return hlcfg?.tables[hlcfgTableNameByRowId(rowId)][rowId] ||
        revisionHlcfg?.tables[hlcfgTableNameByRowId(rowId)][rowId];
};

const getRowName =
    (rowId: HlcfgRowId, hlcfg: HlcfgInputTree, revisionHlcfg: HlcfgInputTree | undefined,
        t: TFunction<'translation', undefined>): Exclude<TranslationSegmentResolved, StaticTranslation> => {
        switch (hlcfgTableNameByRowId(rowId)) {
        case 'profile':
            return createTranslationObj(
                rowId,
                getRowFromCorrectHlcfg<HlcfgTypeProxyProfile>(hlcfg, revisionHlcfg, rowId)?.name,
                t('differs:placeholder.withoutName')
            );
        case 'routeMixedIp':
        case 'route': {
            const table = hlcfgTableNameByRowId(rowId);
            const route = getRowFromCorrectHlcfg<HlcfgTypeRoute>(
                hlcfg, revisionHlcfg, rowId
            );
            const hlcfgWithTranslations = route ?
                checkWhichHlcfgHasAllReferences<HlcfgTypeRoute['destination' | 'gateway']>([
                    route.destination,
                    route.gateway
                ], hlcfg, revisionHlcfg) : hlcfg;
            return createTranslationObj(
                rowId,
                route &&
                t(`differs:rowNames.${table}`, {
                    destination: translateAddress<HlcfgTypeRoute['destination']>(
                        route.destination, hlcfgWithTranslations, t
                    ),
                    gateway: translateAddress<HlcfgTypeRoute['gateway']>(
                        route.gateway, hlcfgWithTranslations, t
                    )
                }),
                t(`differs:tables.route.${table}`)
            );
        }
        case 'host': {
            const host = getRowFromCorrectHlcfg<HlcfgTypeHost>(
                hlcfg, revisionHlcfg, rowId
            );
            const hlcfgWithTranslations = host ?
                checkWhichHlcfgHasAllReferences<HlcfgTypeHost['domain' | 'address']>([
                    host.domain,
                    host.address
                ], hlcfg, revisionHlcfg) : hlcfg;
            return createTranslationObj(
                rowId,
                host &&
                t('differs:rowNames.host', {
                    domain: translateAddress<HlcfgTypeHost['domain']>(host.domain, hlcfgWithTranslations, t),
                    address: translateAddress<HlcfgTypeHost['address']>(host.address, hlcfgWithTranslations, t)
                }),
                t('differs:tables.host.title')
            );
        }
        case 'profileSpecialItem': {
            const profileSpecialItem = getRowFromCorrectHlcfg<HlcfgTypeProfileSpecialItem>(
                hlcfg, revisionHlcfg, rowId
            );
            return createTranslationObj(
                rowId, profileSpecialItem &&
                profileSpecialItem.type, t('differs:tables.profileSpecialItem.title')
            );
        }
        case 'multihomingGateway': {
            const multihomingGateway =  getRowFromCorrectHlcfg<HlcfgTypeMultihomingGateway>(
                hlcfg, revisionHlcfg, rowId
            );
            const hlcfgWithTranslations = multihomingGateway ?
                checkWhichHlcfgHasAllReferences<HlcfgTypeMultihomingGateway['addr']>(
                    [ multihomingGateway.addr ]
                    , hlcfg, revisionHlcfg
                ) : hlcfg;
            return createTranslationObj(
                rowId,
                multihomingGateway &&
                t('differs:rowNames.multihomingGateway', {
                    addr: translateAddresses<HlcfgTypeMultihomingGateway['addr']>(
                        [ multihomingGateway.addr ], hlcfgWithTranslations, t
                    ),
                }),
                t('differs:tables.multihomingGateway.title')
            );
        }
        case 'addressesTable': {
            const addressesTable = getRowFromCorrectHlcfg<HlcfgTypeProfileAddressesTable>(
                hlcfg, revisionHlcfg, rowId
            );
            const hlcfgWithTranslations = addressesTable ?
                checkWhichHlcfgHasAllReferences<NonNullable<Flatten<HlcfgTypeProfileAddressesTable['addresses']>>>(
                    addressesTable.addresses || []
                    , hlcfg, revisionHlcfg
                ) : hlcfg;
            return createTranslationObj(
                rowId,
                addressesTable &&
                t('differs:rowNames.addressesTable', {
                    addresses: translateAddresses<NonNullable<Flatten<HlcfgTypeProfileAddressesTable['addresses']>>>(
                        addressesTable.addresses || [], hlcfgWithTranslations, t
                    ),
                    ports: stringifyAddress(addressesTable.ports?.map(netport), false, netport)
                }),
                t('differs:tables.addressesTable.title')
            );
        }
        case 'dhcpLease': {
            const dhcpLease =  getRowFromCorrectHlcfg<HlcfgTypeDhcpLease>(
                hlcfg, revisionHlcfg, rowId
            );

            const hlcfgWithTranslations = dhcpLease ?
                checkWhichHlcfgHasAllReferences<HlcfgTypeDhcpLease['ip']>([
                    dhcpLease.ip,
                ], hlcfg, revisionHlcfg) : hlcfg;
            return createTranslationObj(
                rowId,
                dhcpLease && t('differs:rowNames.dhcpLease', {
                    mac: dhcpLease.mac,
                    ip: translateAddress(dhcpLease.ip, hlcfgWithTranslations, t)
                }),
                t('differs:placeholder.withoutComment')
            );
        }
        case 'dhcpPool': {
            const dhcpPool =  getRowFromCorrectHlcfg<HlcfgTypeDhcpPool>(
                hlcfg, revisionHlcfg, rowId
            );
            const hlcfgWithTranslations = dhcpPool ?
                checkWhichHlcfgHasAllReferences<HlcfgTypeDhcpPool['rangeFrom' | 'rangeTo']>([
                    dhcpPool.rangeFrom,
                    dhcpPool.rangeTo
                ], hlcfg, revisionHlcfg) : hlcfg;
            return createTranslationObj(
                rowId,
                dhcpPool && t('differs:rowNames.dhcpPool', {
                    rangeFrom: translateAddress(
                        dhcpPool.rangeFrom, hlcfgWithTranslations, t
                    ),
                    rangeTo: translateAddress(
                        dhcpPool.rangeTo, hlcfgWithTranslations, t
                    ),
                }),
                t('differs:placeholder.withoutComment')
            );
        }
        case 'clusterNode':
            return createTranslationObj(
                rowId,
                hlcfg.tables.clusterNode[rowId].uuid,
                t('differs:tables.clusterNode.title')
            );
        case 'openvpnUser':
            return createTranslationObj(
                rowId,
                getRowFromCorrectHlcfg<HlcfgTypeOpenVpnUser>(hlcfg, revisionHlcfg, rowId).commonName,
                t('differs:placeholder.withoutName')
            );
        case 'profileRuleTimeInterval': {
            const timeInterval = getRowFromCorrectHlcfg<HlcfgTypeTimeInterval>(
                hlcfg, revisionHlcfg, rowId
            );
            return createTranslationObj(
                rowId,
                timeInterval && t('differs:rowNames.timeInterval', {
                    range: timeIntervalObj.stringify([ timeInterval ])
                }),
                t('differs:placeholder.withoutName')
            );
        }
        default:
            if (hlcfgTableNameByRowId(rowId) === 'nftDivider' &&
                hlcfg.tables[hlcfgTableNameByRowId(rowId)][rowId].fake) { // Kernun headers names
                return createTranslationObj(
                    rowId,
                    t(hlcfg.tables[hlcfgTableNameByRowId(rowId)][rowId]?.name.substring(1)),
                    t('differs:placeholder.withoutName')
                );
            }
            return createTranslationObj(
                rowId,
                getRowFromCorrectHlcfg<HlcfgTableItem<HlcfgTableNamesWithName>>(
                    hlcfg, revisionHlcfg, rowId
                )?.name,
                t('differs:placeholder.withoutName')
            );
        }
    };


const translationSegmentResolverPure = (translationSegment: TranslationSegment[], hlcfg: HlcfgInputTree, revisionHlcfg:
    HlcfgInputTree | undefined,
t: TFunction<'translation', undefined>, cutLast?: boolean): TranslationSegmentResolved[] => {
    return translationSegment.filter((item, index) => {
        if (isStaticSegment(item)) {
            if (cutLast && index === translationSegment.length - 1 &&
                 item.staticTranslationPath.startsWith('differs')) {
                return undefined;
            }
        }
        return true;
    }).flatMap(item => {
        if (isStaticSegment(item)) {
            return {
                staticTranslation: t(
                    item.staticTranslationPath.endsWith('.title') ?
                        [ item.staticTranslationPath ] :
                        [ item.staticTranslationPath + '.title', item.staticTranslationPath  ]
                ) } as StaticTranslation;
        }
        return [ { staticTranslation: t(item.translationPath) } as StaticTranslation,
            getRowName(item.rowId, hlcfg, revisionHlcfg, t) ];
    });
};

const translationSegmentResolver = (translationSegment: TranslationSegment[],
    hlcfg: HlcfgInputTree, revisionHlcfg: HlcfgInputTree | undefined,
    t: TFunction<'translation', undefined>, cutLast?: boolean) => {
    return translationSegmentResolverPure(translationSegment, hlcfg, revisionHlcfg, t, cutLast);
};

export default translationSegmentResolver;
