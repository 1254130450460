/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect, useContext, useState, useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import Svg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import assert from 'assert';

import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import { useCombinedRefs } from '~frontendLib/reactUtils.js';
import { getObjectMatch } from '~frontendLib/objectUtils.js';
import logo from '~frontendRoot/img/svg/logo.svg';
import {
    WAF_PROFILE_RULE_COLUMNS_DRAG,
    WAF_PROFILE_RULE_COLUMNS_NAME,
    WAF_PROFILE_RULE_COLUMNS_CLIENT,
    WAF_PROFILE_RULE_COLUMNS_SERVER,
    WAF_PROFILE_RULE_COLUMNS_ACTION,
    WAF_PROFILE_RULE_COLUMNS_LOG,
    WAF_PROFILE_RULE_COLUMNS_ADDRESS,
    WAF_PROFILE_RULE_COLUMNS_PORT,
    SELECTABLE_TABLE_WAF_PROFILE_RULES,
    userSetting
} from '~frontendConstants/index.js';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/index.js';
import RowDivider from '~frontendComponents/RowDivider.tsx';
import { Offable } from '~frontendConstants/types.ts';
import { HlcfgTableItemSchema } from '~frontendTypes/externalTypes.ts';
import { getTableSchemaGetter,
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { makeSelectGetWafProfileRule
} from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';

import { DatatableContext } from '../ProfileRules/ProfileRules.tsx';


export const RenderEmptyUserRules = ({ addRow, t, addHeader }) => {
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_WAF_PROFILE_RULES);
    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRule"
        >
            <td></td>
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={getColumnsSelectedLength() - 1}
            >
                <div className="pl-2">
                    <h2>{t('profile:waf.rules.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('profile:waf.rules.title')}

                        {t('profile:waf.rules.desc1')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={addRow}
                        >
                            <Icon name="plus" /> {t('profile:waf.rules.rule')}
                        </MDBBtn>
                        {t('profile:waf.rules.desc2')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            data-cy="adding-header-button"
                            onClick={addHeader}
                        >
                            <Icon name="plus" /> {t('packetFilter:header')}
                        </MDBBtn>
                        {t('profile:waf.rules.desc3')}
                    </p>
                    <p>
                        {t('profile:waf.rules.desc4')}
                    (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('profile:waf.rules.desc5')}

                    </p>
                    <MDBBtn
                        className="dataTableWidget__RowAddButtons--button"
                        onClick={addRow}
                    >
                        <Icon name="plus" /> {t('profile:waf.rules.rule')}
                    </MDBBtn>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-header-button"
                        onClick={addHeader}
                    >
                        <Icon name="plus" /> {t('packetFilter:header')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );
};

RenderEmptyUserRules.propTypes = {
    addRow: PropTypes.func,
    t: PropTypes.func,
    addHeader: PropTypes.func,
};

const RenderDividerProps = {
    //props
    provided: PropTypes.shape({
        innerRef: PropTypes.any,
        draggableProps: PropTypes.object,
        dragHandleProps: PropTypes.object,
    }).isRequired,
    spacing: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    uuid: PropTypes.string.isRequired,

    //functions
    setValue: PropTypes.func.isRequired,
    delRow: PropTypes.func.isRequired,
    copyRow: PropTypes.func.isRequired,
    closeRules: PropTypes.func.isRequired,
};


export const RenderDivider = ({
    provided,
    copyRow,
    delRow,
    setValue,
    closeRules,
    spacing,
    types,
    uuid

}: InferProps<typeof RenderDividerProps>) => {
    const getter = useMemo(makeSelectGetWafProfileRule, []);
    const { name, id, closed } =
        useSelector<any, Offable<'profileHeader'>>(state => getter(state, uuid));
    const schemas = useSelector <any, HlcfgTableItemSchema<'profileHeader'>>(getTableSchemaGetter(uuid));
    const ref = useCombinedRefs(provided.innerRef);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ , getColumnsSelectedLength ] =
        getColumnsUtils(allColumns, SELECTABLE_TABLE_WAF_PROFILE_RULES);
    if (!id) {
        return null;
    }
    return (

        <>
            <RowDivider
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                ref={ref}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classNames(
                    'dataTableWidget__Row--important',
                    'dataTableWidget__header',
                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                )}
                key={id}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    onClick={() => closeRules({ value: !closed })}
                >

                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />

                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                    colSpan={getColumnsSelectedLength() - 2}
                >
                    <Input
                        className="dataTableWidget__RowInput"
                        id={'profileName' + uuid}
                        isName
                        name="name"
                        onChange={setValue}
                        schema={schemas?.name}
                        value={name}
                        withoutBorder
                    />

                </td>
                <RowMenuAndSwitch
                    copyFunc={copyRow}
                    deleteButtonDisabled={closed}
                    deleteFunc={delRow}
                    id={'wafHeader' + id}
                    spacing={spacing}
                />
            </tr>
            <RowDivider
                after
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>
    );
};

RenderDivider.propTypes = RenderDividerProps;


const RenderRuleProps = {
    //props
    provided: PropTypes.shape({
        innerRef: PropTypes.any,
        draggableProps: PropTypes.object,
        dragHandleProps: PropTypes.object,
    }).isRequired,
    spacing: PropTypes.string.isRequired,
    types: PropTypes.array.isRequired,
    uuid: PropTypes.string.isRequired,

    //functions
    setValue: PropTypes.func.isRequired,
    delRow: PropTypes.func.isRequired,
    copyRow: PropTypes.func.isRequired,
    closeRules: PropTypes.func.isRequired,
};


export const RenderRule = ({
    provided,
    copyRow,
    delRow,
    setValue,
    spacing,
    types,
    uuid

}: InferProps<typeof RenderRuleProps>) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'wafProfileRule'));
    const pathGetter = getRowPathGetter(uuid);
    const getter = useMemo(makeSelectGetWafProfileRule, []);
    const data =
        useSelector<any, Offable<'wafProfileRule'>>(state => getter(state, uuid));
    const schemas = useSelector <any, HlcfgTableItemSchema<'wafProfileRule'>>(getTableSchemaGetter(uuid));
    const { search } = useContext(DatatableContext);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] =
     getColumnsUtils(allColumns, SELECTABLE_TABLE_WAF_PROFILE_RULES);
    const [ matches, setMatches ] = useState([]);
    useEffect(() => {
        setMatches(getObjectMatch({
            object: data, searchValue: search, net: netaddr, exceptions: [], matchesProp: [] }));
        // eslint requires also data as dependencies but for optimalization reasons and that we need to implement
        // better way for searching in table we will ignore it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ search ]);

    const ref = useCombinedRefs(provided.innerRef);

    if (data.closed) {
        return null;
    }
    const { id, name,
        log, action,  __off, fake, sendToServerAddr, sendToServerPort } = data;
    if (!id) {
        return null;
    }
    return (
        <>
            <RowDivider
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    'profiles__row',
                    { 'dataTableWidget__Row--disable': __off },
                    { 'dataTableWidget__Row--match': matches.length },
                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                )}
                ref={ref}
                {...provided.draggableProps}
                key={id}
            >
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_DRAG) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { 'dataTableWidget__cell--match': matches.length },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                        {...provided.dragHandleProps}
                    >
                        {
                            fake ?
                                <Svg
                                    className={classNames(
                                        'p-1',
                                        { 'opacity-04': __off }
                                    )}
                                    height={'32'}
                                    src={logo}

                                    width="32"
                                /> :

                                <Icon
                                    name="drag"
                                    size="sm"
                                />
                        }
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_ACTION) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                    >
                        <Select
                            disabled={__off || fake}
                            id={'action' + id}
                            isRow
                            justIcon
                            name="action"
                            noDropdownIndicator
                            noOptionsMessage
                            noWrap
                            onChange={setValue}
                            paste={false}
                            schema={schemas?.action}
                            value={action}
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_NAME) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                    >
                        <Input
                            className="dataTableWidget__RowInput  packetFilter__nameInput"
                            disabled={__off || fake}
                            inputClass="'dataTableWidget__cell--leftPadding'"
                            isName
                            name="name"
                            onChange={setValue}
                            schema={schemas?.name}
                            value={name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_CLIENT) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <HlcfgSelect
                            className="select2--row"
                            disabled={__off || fake}
                            maxItemsToDisplay={3}
                            pathGetter={pathGetter.client}
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_SERVER) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <HlcfgSelect
                            className="select2--row"
                            disabled={__off || fake}
                            maxItemsToDisplay={3}
                            pathGetter={pathGetter.server}
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_ADDRESS) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <NetworkInterfaceDevice
                            className="dataTableWidget__RowInput"
                            disabled={__off || fake}
                            id={'sendToServerAddr' + id}
                            iface={sendToServerAddr}
                            isCreatable
                            isMulti
                            isRow
                            name="sendToServerAddr"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={setValue}
                            paste
                            schema={schemas.sendToServerAddr}
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_PORT) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                        )}
                    >
                        <Select
                            className="dataTableWidget__RowInput"
                            disabled={__off || fake}
                            id={'sendToServerPort' + id}
                            isCreatable
                            isMulti
                            isRow
                            name="sendToServerPort"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={setValue}
                            schema={schemas.sendToServerPort}
                            value={sendToServerPort}
                        />
                    </td>
                }
                {getColumnsShow(WAF_PROFILE_RULE_COLUMNS_LOG) &&
                    <td
                        className={classNames(
                            'dataTableWidget__cell',
                            'dataTableWidget__cell--icon',
                            'icon--clicable',
                            { 'profile__log': !__off },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                    >
                        <Select
                            disabled={__off || fake}
                            id={'log' + id}
                            isRow
                            justIcon
                            name="log"
                            noDropdownIndicator
                            noOptionsMessage
                            noWrap
                            onChange={setValue}
                            paste={false}
                            schema={schemas.log}
                            value={log}
                        />
                    </td>
                }
                <RowMenuAndSwitch
                    __off={__off}
                    copyFunc={copyRow}
                    deleteFunc={delRow}
                    id={'wafProfile' + id}
                    menu={!fake}
                    onChange={fake ? undefined : setValue}
                />
            </tr>
            <RowDivider
                after
                id={id}
                length={getColumnsSelectedLength()}
                types={types}
            />
        </>

    );
};

RenderRule.propTypes = RenderRuleProps;
