/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';

import Divider  from '~frontendComponents/Divider/index.js';
import {  Input,  Select, Switch, Icon } from '~frontendComponents/Generic/index.js';
import { getProfileUuid, setNormalize, addToNormalize, getTableSchema, getComponentEnabledHook, getGlcfgValue
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { setTimeoutComponentFunction, setTimeoutComponentValidator } from '~frontendLib/timeUtils.ts';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { IPV6_ENABLED, PROFILES, PROXY, PROXY_AUTHENTICATION_KEY } from '~frontendConstants/index.js';
import { REQUIRE_AUTH_UUID, TLS_INSPECTION_UUID, REQUIRE_AUTH, TLS_INSPECTION } from '~sharedConstants/index.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import NetworkInterfaceDevice from '~frontendRoot/components/NetworkInterfaceDevice/NetworkInterfaceDevice.tsx';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/WhenAdvanced.tsx';


const makeMapStateToProps = () => {
    const getProfile = getProfileUuid();
    const getComponent = getComponentEnabledHook(PROXY, PROXY_AUTHENTICATION_KEY);
    const mapStateToProps = (state, { profile }) => {
        return {
            profileObject: getProfile(state, profile),
            authenticationEnabled: getComponent(state),
            schema: getTableSchema(state, profile),
            ipv6Enabled: getGlcfgValue(state, IPV6_ENABLED),
        };
    };
    return mapStateToProps;
};
@withTranslation()
@connect(
    makeMapStateToProps,
    {
        setProfile: setNormalize,
        add: addToNormalize,
    }
)
class ProfileSettings extends Component {
    static get propTypes() {
        return {
            profileObject: PropTypes.object,
            profile: PropTypes.string,
            t: PropTypes.func,
            setProfile: PropTypes.func,
            schema: PropTypes.object,
            add: PropTypes.func,
            authenticationEnabled: PropTypes.bool,
            ipv6Enabled: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            shownSettings: false,
            shownRules: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { profile } = this.props;
        if (profile !== prevProps.profile) {
            this.setState({
                shownRules: false
            });
        }
    }

    hideRules = () => {
        const { shownRules } = this.state;
        this.setState({
            shownRules: !shownRules
        });
    };

    hideSettings = () => {
        const { shownSettings } = this.state;
        this.setState({
            shownSettings: !shownSettings
        });
    };


    setProfileParameters = ({ value, name, id }) => {
        const { setProfile, profile, add, profileObject } = this.props;
        if (id === 'tlsInsp') {
            setProfile({ uuid: profile, value: !value, key: name, subkey: id, subsubkey: '__off' });
            if (!profileObject?.rules.includes(TLS_INSPECTION_UUID)) {
                add({
                    type: 'profileSpecialItem',
                    typeId: PROFILES,
                    key: 'rules',
                    uuid: profile,
                    extraValues: {
                        type: TLS_INSPECTION
                    },
                    newUuid: TLS_INSPECTION_UUID,
                    addingAfter: profileObject?.rules.includes(REQUIRE_AUTH_UUID),
                    uuidToAddBefore: REQUIRE_AUTH_UUID
                });
            }
            return;
        }
        if (id === 'authentication') {
            setProfile({
                uuid: profile, value: !value, key: name, subkey: id, subsubkey: '__off'  });
            if (!profileObject?.rules.includes(REQUIRE_AUTH_UUID)) {
                add({
                    type: 'profileSpecialItem',
                    typeId: PROFILES,
                    key: 'rules',
                    uuid: profile,
                    extraValues: {
                        type: REQUIRE_AUTH
                    },
                    newUuid: REQUIRE_AUTH_UUID
                });
            }
            return;
        }
        setProfile({ uuid: profile, value, key: name, subkey: id });
    };

    setAuthenticationType = ({ value }) => {
        const { setProfile, profile } = this.props;
        setProfile({ uuid: profile, value, key: 'parameters', subkey: 'authentication', subsubkey: 'type' });
    };

    setTimeoutFunc = ({ value }) => {
        const { setProfile, profile, } = this.props;
        setProfile({ uuid: profile, value, key: 'parameters', subkey: 'timeout',  subsubkey: 'both'  });
    };

    setTimeout = setTimeoutComponentFunction({ setValue: this.setTimeoutFunc, uuid: this.props.profile });

    timeoutValidator = setTimeoutComponentValidator(this.props.t);


    render() {
        const { t, profileObject, schema, authenticationEnabled, ipv6Enabled } = this.props;
        const { shownSettings } = this.state;
        const { authentication, tlsInsp, timeout, comment, sourceAddress,
            disableNiceBlocking, outgoingIpVersionPreference } =  profileObject?.parameters || {};
        return (
            <MDBCard>
                <MDBCardTitle className={classNames(
                    'profiles__title',
                    { 'profiles__title--noBorderBottom': shownSettings },
                )}
                >
                    <div> {t('profile:settings.header')}
                        <Icon
                            name={shownSettings ? 'chevron-up' : 'chevron-down'}
                            onClick={() => this.setState({
                                shownSettings: !shownSettings
                            })}
                        />
                    </div>
                </MDBCardTitle>
                <MDBCardBody
                    className={classNames(
                        'profile__body',
                        'pt-0', 'pb-0',
                        { 'profile__body--hide': !shownSettings },
                        { 'profiles__title--borderBottom': shownSettings },
                    )}
                >
                    <MDBRow className="pb-2">
                        <MDBCol>
                            <span className="profile__headersText">{t('profile:profiles.profile')}</span>
                            <NetworkInterfaceDevice
                                id="sourceAddress"
                                iface={stringifyAddress(sourceAddress)}
                                isCreatable
                                isMulti
                                label={t('profile:sourceAddress.title')}
                                name="parameters"
                                onChange={this.setProfileParameters}
                                placeholder="widgets:global.automaticChoosen"
                                schema={schema?.parameters?.properties?.sourceAddress}
                            />
                            <Input
                                id="comment"
                                label={t('profile:comment')}
                                name="parameters"
                                onChange={this.setProfileParameters}
                                type="textarea"
                                value={comment}
                            />
                        </MDBCol>
                        <Divider vertical />
                        <MDBCol>
                            <span className="profile__headersText">{t('profile:outgoingConnection.title')}</span>
                            <Select
                                className="packetFilterColumnsSelect w-100"
                                disabled={!ipv6Enabled}
                                enumTranslationDifferPath={'differs:tables.wafProfile.outgoingIpVersionPreference'}
                                id="outgoingIpVersionPreference"
                                name="parameters"
                                onChange={this.setProfileParameters}
                                paste={false}
                                schema={schema?.parameters?.properties?.outgoingIpVersionPreference}
                                tooltipText={!ipv6Enabled && t('profile:outgoingConnection.disabled')}
                                value={outgoingIpVersionPreference}
                            />
                        </MDBCol>
                        <Divider vertical />
                        <MDBCol>
                            <span className="profile__headersText">{t('profile:tls.title')}</span>
                            <Switch
                                align="spaceBetween"
                                checked={tlsInsp && !tlsInsp?.__off}
                                id="tlsInsp"
                                label={t('profile:tls.inspection')}
                                name="parameters"
                                onChange={this.setProfileParameters}
                            />
                            <Switch
                                align="spaceBetween"
                                checked={disableNiceBlocking}
                                id="disableNiceBlocking"
                                label={t('differs:tables.profile.parameters.disableNiceBlocking')}
                                name="parameters"
                                onChange={this.setProfileParameters}
                            />
                        </MDBCol>
                    </MDBRow>
                    <Divider />
                    <MDBRow className="pt-2">
                        <MDBCol size="4">
                            <span className="profile__headersText">{t('profile:authentication.title')}</span>
                            <Switch
                                align="spaceBetween"
                                checked={authentication && !authentication.__off}
                                disabled={!authenticationEnabled && authentication?.__off}
                                id="authentication"
                                label={t('profile:authentication.title')}
                                name="parameters"
                                onChange={this.setProfileParameters}
                                tooltipText={authenticationEnabled ? undefined : t('profile:authentication.offed')}
                            />
                        </MDBCol>
                        <Divider vertical />
                        <MDBCol size="4">
                            <span className="profile__headersText">{t('profile:timeouts.title')}</span>
                            <Input
                                id="timeout"
                                label={t('profile:timeouts.both')}
                                name="parameters"
                                onChange={this.setTimeout}
                                schema={schema?.parameters?.properties?.timeout?.properties?.both}
                                validator={this.timeoutValidator}
                                value={valueFormatter.formatSeconds(timeout?.both ||
                                    schema?.parameters?.properties?.timeout?.properties?.both[DEFAULT_SCHEMA_VALUE])}
                            />
                        </MDBCol>
                        <Divider vertical />
                        <WhenAdvanced>
                            <MDBCol>
                                <span className="profile__headersText">{t('profile:authentication.type')}</span>
                                <Select
                                    className="packetFilterColumnsSelect w-100"
                                    disabled={authentication?.__off}
                                    enumTranslationDifferPath={'differs:tables.profile.parameters.authentication.type'}
                                    onChange={this.setAuthenticationType}
                                    paste={false}
                                    schema={schema?.parameters?.properties?.authentication?.properties?.type}
                                    value={authentication?.type}
                                />
                            </MDBCol>
                        </WhenAdvanced>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default ProfileSettings;
