/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBCardHeader, MDBCard, MDBCardBody } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {  Input } from '~frontendComponents/Generic/index.js';
import { setGlcfgValueScalar, getGlcfgValue } from '~frontendDucks/hlcfgEditor/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';
import { DOMAIN, HOSTNAME } from '~frontendRoot/constants/index.js';


const Hostname = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const hostname = useSelector(state => getGlcfgValue(state, HOSTNAME));
    const domain = useSelector(state => getGlcfgValue(state, DOMAIN));

    const onChangeEvent = useCallback(({ name, value }) => {
        dispatch(setGlcfgValueScalar(name, value));
    }, [ dispatch ]);

    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:Resolver.hostname')}
            </MDBCardHeader>
            <MDBCardBody>
                <Input
                    id="hostnameId"
                    label={glcfgDefinition[HOSTNAME].title}
                    name="hostname"
                    onChange={onChangeEvent}
                    value={hostname}
                />
                <Input
                    className="mb-0"
                    id="domainId"
                    label={glcfgDefinition[DOMAIN].title}
                    name="domain"
                    onChange={onChangeEvent}
                    value={domain}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default Hostname;
