/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { memo, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import Svg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { DraggableProvided } from '@hello-pangea/dnd';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, Input, Tooltip } from '~frontendComponents/Generic/index.js';
import { SMALL_SIZE } from '~frontendConstants/index.js';
import logo from '~frontendRoot/img/svg/logo.svg';
import {
    INTERFACE_COLOR,
    PACKET_FILTER_COLUMNS_ACTION,
    PACKET_FILTER_COLUMNS_ARROW,
    PACKET_FILTER_COLUMNS_DESTINATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION,
    PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT,
    PACKET_FILTER_COLUMNS_DRAG,
    PACKET_FILTER_COLUMNS_INTERFACE,
    PACKET_FILTER_COLUMNS_LOG,
    PACKET_FILTER_COLUMNS_NAME,
    PACKET_FILTER_COLUMNS_QOS_NODES,
    PACKET_FILTER_COLUMNS_SERVICE,
    PACKET_FILTER_COLUMNS_SOURCE,
    PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION,
    PACKET_FILTER_COLUMNS_TRANSLATION_ICON,
    PACKET_FILTER_COLUMNS_WEB_PROFILE,
    SECOND_DEFAULT_HEADER_UUID,
    SELECTABLE_TABLE_PACKET_FILTER,
    TableSizeType,
    userSetting
} from '~frontendConstants/constants.ts';
import {
    ACCEPT_ACTION,
    FORCE_ACCEPT_ACTION,
    HLCFG_OFF,
    SCHEMA_TYPE_NETADDR,
    WEB_ACTION,
} from '~sharedConstants/index.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { useUserSettingToggleable } from '~frontendRoot/lib/hooks/userSettings.ts';
import { useColumnIsShown, useColumnsSelectedLength } from '~frontendRoot/lib/columnUtils.ts';
import RowDivider from '~frontendRoot/components/RowDivider.tsx';
import { makeGetRuleHasError, getNamedObjectNetaddrAllValues
} from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { useCombinedRefs } from '~frontendRoot/lib/reactUtils.js';
import { HlcfgRowId, hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { UseBooleanFuncType, useBoolean, useConstant, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { isNamedObjectObjRef } from '~sharedLib/namedObjectUtils.ts';
import { AnyFunc } from '~commonLib/types.ts';
import { noWhiteSpace } from '~commonLib/stringUtils.js';
import { HlcfgSelect, HlcfgSelectProps, HlcfgTextInput, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.js';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.js';
import { useHlcfgValue, useTableManipulator, useTableRowManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.js';
import { SelectModel, SelectOption } from '~frontendComponents/Generic/SelectV2/types.js';
import { NetaddrSelect } from '~frontendComponents/Generic/SelectV2/NetaddrSelect.js';
import { isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.js';
import { isAddressesSelector } from '~sharedLib/addressesSelectorUtils.js';
import { poDef, testProps, testPropsCollapsible, testPropsPoDef } from '~commonLib/PageObjectMap.ts';
import { HlcfgRowTr } from '~frontendComponents/Generic/HlcfgElements/HlcfgRowTr.tsx';

import GetIcon from '../GetIcon/index.js';
import { RuleDetail } from '../RuleDetail/RuleDetail.tsx';
import { getPFRowMap, getPFRuleDetailMap } from '../../pageObjectMap.ts';


const getIconForTranslation = ({ sourceTranslation, destinationTranslation }) => {
    switch (true) {
    case Boolean(sourceTranslation && destinationTranslation?.address?.length):
        return 'SNAT REDIRECT';
    case Boolean(sourceTranslation):
        return 'SNAT';
    case Boolean(destinationTranslation?.address?.length):
        return 'REDIRECT';
    default:
        return null;
    }
};


export const RenderEmptyUserRules = () => {
    const selectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_PACKET_FILTER);
    const { t } = useTranslation();
    const addRule = useTableManipulator({
        addRowType: 'nftRule',
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules,
        addRowSuccessText: 'packetFilter:added'
    }).addRow;
    const addHeader = useTableManipulator({
        addRowType: 'nftDivider',
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules,
        addRowSuccessText: 'packetFilter:added',
    }).addRow;
    return (
        <tr
            className="packetFilter__firstRule"
            key={'newRule_emptyUserRules'}
        >
            <td />
            <td
                className="packetFilter__firstRuleRow"
                colSpan={selectedLength - 1}
            >
                <div className="pl-2">
                    <h2>{t('scenes:scenes.Protection.scenes.PacketFilter.title')}</h2>
                    <p>
                        {t('packetFilter:packetFilter.descPart1')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            onClick={addRule}
                            {...testProps('AddRow', { typeId: 'nftRule' })}
                        >
                            <Icon name="plus" /> {t('packetFilter:rule')}
                        </MDBBtn>
                        {t('packetFilter:packetFilter.descPart2')}
                        <MDBBtn
                            className="packetFilter__addButtons--button"
                            data-cy="adding-header-button--empty"
                            onClick={addHeader}
                            {...testProps('AddRow', { typeId: 'nftDivider' })}
                        >
                            <Icon name="plus" /> {t('packetFilter:header')}
                        </MDBBtn>
                        {t('packetFilter:packetFilter.descPart3')}
                    </p>
                    <p>
                        {t('packetFilter:packetFilter.addingDesc.part1')}
                    (
                        <Icon
                            name="menu"
                            size="sm"
                        />)
                        {t('packetFilter:packetFilter.addingDesc.part2')}

                    </p>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-rule-button--empty"
                        onClick={addRule}
                        {...testProps('AddRow', { typeId: 'nftRule' })}
                    >
                        <Icon name="plus" /> {t('packetFilter:rule')}
                    </MDBBtn>
                    <MDBBtn
                        className="packetFilter__addButtons--button"
                        data-cy="adding-header-button--empty"
                        onClick={addHeader}
                        {...testProps('AddRow', { typeId: 'nftDivider' })}
                    >
                        <Icon name="plus" /> {t('packetFilter:header')}
                    </MDBBtn>
                </div>
            </td>
        </tr>
    );
};


type RenderNftDividerProps = {
    provided: DraggableProvided,
    spacing: TableSizeType;
    uuid: HlcfgRowId;
    closeRules: ({ value }: {value:boolean}) => void;
    search?: string;
}

const RenderNftDividerNoMemo = ({
    provided,
    closeRules,
    spacing,
    uuid,
    search
}: RenderNftDividerProps) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'nftDivider'), 'Found not nftDivider uuid inside nftDivider renderer');
    const rowPathGetter = getRowPathGetter(uuid);
    const ruleTableManipulator = useTableRowManipulator({
        addRowType: 'nftRule',
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules
    });
    const headerTableManipulator = useTableRowManipulator({
        addRowType: 'nftDivider',
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules
    });
    const types = useConstant([
        { addFunc: headerTableManipulator.addRow, translation: 'packetFilter:header' },
        { addFunc: ruleTableManipulator.addRow, translation: 'profile:profiles.rules.rule' },
    ]);

    const { value: color, setValue: setColor } = useHlcfgValue(rowPathGetter.color);
    const { value: fake } = useHlcfgValue(rowPathGetter.fake);
    const { value: id } = useHlcfgValue(rowPathGetter.id);
    const { value: closed } = useHlcfgValue(rowPathGetter.closed);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const hasError = useMakeSelector(makeGetRuleHasError, uuid);
    const ref = useCombinedRefs(provided.innerRef);
    const selectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_PACKET_FILTER);
    const [ stickyRuleNamePFDisabled ] = useUserSettingToggleable(userSetting.stickyRuleNamePF);

    const { t } = useTranslation();
    const collapsible = testPropsCollapsible(id, !closed, { typeId: poDef.typeIds.tableRow });
    return (
        <>
            <RowDivider
                className={'dataTableWidget__RowAddPFButtons'}
                id={id}
                length={selectedLength}
                types={types}
                withoutButtons={!(!fake || id === SECOND_DEFAULT_HEADER_UUID)}
            />
            <tr
                ref={ref}
                {...fake ? {} : provided.draggableProps}
                {...fake ? {} : provided.dragHandleProps}
                className={classNames(
                    'dataTableWidget__Row',
                    { 'dataTableWidget__cell--match': searchMatches },
                    { 'dataTableWidget__Row--noticeMe': searchMatches && closed },
                    'dataTableWidget__Row--important',
                    'dataTableWidget__header',
                    { 'dataTableWidget__Row--error': hasError },
                )}
                key={id}
                style={
                    {
                        backgroundColor: color,
                        ...fake ? {} : provided.draggableProps.style
                    }
                }
                {...collapsible.containerProps}
            >
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--error': hasError },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                    onClick={event => {
                        event.preventDefault();
                        closeRules({ value: !closed });
                    }}
                    {...collapsible.buttonProps}
                >
                    <Icon name={closed ? 'chevron-right' : 'chevron-down'} />
                </td>
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--stickyName': !stickyRuleNamePFDisabled },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                    colSpan={2}
                    onClick={event => {
                        event.preventDefault();
                        if (fake) {
                            closeRules({ value: !closed });
                        }
                    }}
                >
                    {fake ? (
                        <Input
                            className={classNames(
                                'dataTableWidget__RowInput',
                                'packetFilter__nameInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={true}
                            inputClass={'dataTableWidget__cell--stickyName--label pl-0'}
                            isName
                            value={t('packetFilter:kernunRules')}
                            withoutBorder
                        />
                    ) : (
                        <HlcfgTextInput
                            className={classNames(
                                'dataTableWidget__RowInput',
                                'packetFilter__nameInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            disabled={fake}
                            inputClass={'dataTableWidget__cell--stickyName--label pl-0'}
                            isName
                            pathGetter={rowPathGetter.name}
                            withoutBorder
                        />
                    )}

                </td>
                <td colSpan={selectedLength - 4} />
                <RowMenuAndSwitch
                    color={color}
                    colorFunc={({ value }) => setColor(value)}
                    deleteButtonDisabled={closed}
                    deleteFunc={headerTableManipulator.deleteRow}
                    id={'pfHeader' + id}
                    menu={!fake}
                    spacing={spacing}
                />
            </tr>
            {!fake && (
                <RowDivider
                    after
                    className={'dataTableWidget__RowAddPFButtons'}
                    id={id}
                    length={selectedLength}
                    types={types}
                />
            )}
        </>
    );
};
export const RenderNftDivider = memo(RenderNftDividerNoMemo);


type RenderNftRuleRowProps = {
    provided: DraggableProvided,
    spacing: TableSizeType;
    uuid: HlcfgRowId;
    search?: string;
}
const RenderNftRuleRowNoMemo = ({
    provided,
    uuid,
    search,
    spacing,
}: RenderNftRuleRowProps) => {

    assert(hlcfgRowIdIsFromTable(uuid, 'nftRule'), 'Found not nftRule uuid inside nftRule renderer');
    const rowPathGetter = getRowPathGetter(uuid);
    const ruleTableManipulator = useTableRowManipulator({
        addRowType: 'nftRule',
        addRowSuccessText: 'packetFilter:added',
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules,
    });
    const headerTableManipulator = useTableRowManipulator({
        addRowType: 'nftDivider',
        addRowSuccessText: 'packetFilter:added',
        rowPathGetter,
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules,
    });
    const types = useConstant([
        { addFunc: headerTableManipulator.addRow, type: 'nftRule', translation: 'packetFilter:header' },
        { addFunc: ruleTableManipulator.addRow, type: 'nftDivider', translation: 'profile:profiles.rules.rule' },
    ]);

    const { value: __off } = useHlcfgValue(rowPathGetter.__off);
    const { value: destinationAddress } = useHlcfgValue(rowPathGetter.destinationAddress);
    const { value: fake } = useHlcfgValue(rowPathGetter.fake);
    const { value: sourceAddress } = useHlcfgValue(rowPathGetter.sourceAddress);
    const { value: closed } = useHlcfgValue(rowPathGetter.closed);

    const [ showDetail, setShowDetail ] = useBoolean(false);
    const ruleMatchesSearch = useMakeSelector(makeSelectSearchedTableItem, uuid, search);
    const hasError = useMakeSelector(makeGetRuleHasError, uuid);
    const allValues = useSelector(state => getNamedObjectNetaddrAllValues(state));
    const ref = useCombinedRefs(provided.innerRef);
    const selectedLength = useColumnsSelectedLength(SELECTABLE_TABLE_PACKET_FILTER);
    const { value: ipv6Enabled } = useHlcfgValue(hlcfgPathGetter.network.ipv6Enabled);
    const isIpv6 = useMemo(() => {
        const src = isStaticReference(sourceAddress?.list) ? [] : sourceAddress?.list ?? [];
        const dst = isStaticReference(destinationAddress?.list) ? [] : destinationAddress?.list ?? [];
        return [ ...src, ...dst ].some(item => {
            if (isAddressesSelector(item)) {
                return item.__addressesSelectorObject.ipVersion === 'ipv6';
            }
            if (isNamedObjectObjRef(item)) {
                const found = allValues[item.__namedObjectReference].flat()
                    .find((address: string) => netaddr(address).isIp6());
                return found;
            }
            return netaddr(item).isIp6();
        });

    }, [ destinationAddress, sourceAddress, allValues ]);

    const enabled = isIpv6 ? !__off && ipv6Enabled : !__off;
    // Wrapper of td to pass to RenderRuleCells to avoid drilling all the provided props
    const DndHandleTd = useCallback((props: any) => {
        return (
            <td
                {...props}
                className={classNames(
                    props.className,
                    { 'dataTableWidget__cell--error': hasError },
                    { 'dataTableWidget__cell--match': ruleMatchesSearch },
                )}
                {...fake ? {} : provided.dragHandleProps}
            >
                {props.children}
            </td>
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ...Object.values(provided.dragHandleProps ?? {}), hasError, ruleMatchesSearch ]);
    if (closed) {
        //react beautiful dnd Draggable components require a HTMLElement to be provided
        //this is done using the innerRef property(ref)
        return (
            <tr
                className="dataTableWidget__Row--closed"
                ref={ref}
                {...fake ? {} : provided.draggableProps}
                {...fake ? {} : provided.dragHandleProps}
            >
            </tr>
        );
    }

    return (
        <>
            <RowDivider
                className={'dataTableWidget__RowAddPFButtons'}
                datacy="adding-button"
                id={uuid}
                length={selectedLength}
                types={types}
                withoutButtons={fake}
            />


            <HlcfgRowTr
                ref={ref}
                {...fake ? {} : provided.draggableProps}
                className={classNames(
                    'packetFilter__edit',
                    'dataTableWidget__Row--important',
                    { 'dataTableWidget__Row--error': hasError },
                    { 'dataTableWidget__Row--closed': closed },
                    { 'dataTableWidget__Row--match': ruleMatchesSearch },

                )}
                data-cy="packet-filter-row"
                disabled={!enabled}
                key={uuid}
                rowPathGetter={rowPathGetter}
                {...testPropsPoDef(getPFRowMap(uuid).child.rule)}
            >
                <RenderRuleCells
                    DndHandleTd={DndHandleTd}
                    isIpv6={isIpv6}
                    setShowDetail={setShowDetail}
                    showDetail={showDetail}
                    spacing={spacing}
                    uuid={uuid}
                />
            </HlcfgRowTr>

            {showDetail && (
                <tr
                    className={classNames(
                        'dataTableWidget__RowAdd',
                        'nft',
                        { 'bounce-in-top': showDetail },
                    )}
                    {...testPropsPoDef(getPFRuleDetailMap(uuid))}
                >
                    <td className="relative">


                    </td>
                    <td colSpan={selectedLength - 2}>
                        <RuleDetail
                            smallSpacing={spacing === SMALL_SIZE}
                            uuid={uuid}
                        />
                    </td>
                    <td className="relative">


                    </td>
                </tr>
            )}
            {!fake && (
                <RowDivider
                    after
                    className={'dataTableWidget__RowAddPFButtons'}
                    datacy="adding-button"
                    id={uuid}
                    length={selectedLength}
                    types={types}
                />
            )}
        </>
    );
};
type RenderRuleCellsProps = {
    spacing: TableSizeType;
    uuid: HlcfgRowId;
    showDetail: boolean,
    isIpv6: boolean,
    setShowDetail: UseBooleanFuncType,
    DndHandleTd: AnyFunc
}

const RenderRuleCellsNoMemo = ({
    uuid,
    spacing,
    showDetail,
    setShowDetail,
    DndHandleTd,
    isIpv6,
}: RenderRuleCellsProps) => {
    assert(hlcfgRowIdIsFromTable(uuid, 'nftRule'), 'Found not nftRule uuid inside nftRule renderer');
    const rowPathGetter = getRowPathGetter(uuid);

    const { value: fake } = useHlcfgValue(rowPathGetter.fake);

    const tableManipulator = useTableRowManipulator({
        tablePathGetter: hlcfgPathGetter.protection.nftables.rules, rowPathGetter,
        duplicateExtraValues: fake ? {
            fake: false,
            comment: undefined,
        } : undefined,
    });


    const { value: __off, setValue: setOff } = useHlcfgValue(rowPathGetter.__off);
    const { value: destinationTranslation } = useHlcfgValue(rowPathGetter.destinationTranslation);
    const { value: id } = useHlcfgValue(rowPathGetter.id);
    const { value: action } = useHlcfgValue(rowPathGetter.action);
    const { value: comment = '' } = useHlcfgValue(rowPathGetter.comment);
    const { value: service } = useHlcfgValue(rowPathGetter.service);
    const { value: sourceTranslation } = useHlcfgValue(rowPathGetter.sourceTranslation);

    const columnsShow = useColumnIsShown(SELECTABLE_TABLE_PACKET_FILTER);
    const [ stickyRuleNamePFDisabled ] = useUserSettingToggleable(userSetting.stickyRuleNamePF);
    const { t } = useTranslation();
    const { value: ipv6Enabled } = useHlcfgValue(hlcfgPathGetter.network.ipv6Enabled);
    const commentTranslate = fake ? t(comment) : `${t('packetFilter:comment')}: ${comment}`;
    const enabled = isIpv6 ? !__off && ipv6Enabled : !__off;
    const enableTooltip = isIpv6 && !ipv6Enabled;

    const notEditable = fake || !enabled;
    return (
        <>
            {columnsShow[PACKET_FILTER_COLUMNS_DRAG] && (
                <DndHandleTd
                    className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                > {
                        fake ?
                            <Svg
                                className={classNames(
                                    'p-1',
                                    { 'opacity--04': !enabled }
                                )}
                                height={spacing === SMALL_SIZE ? '24' : '32'}
                                src={logo}
                                width="32"
                            /> :

                            <div
                                className="dataTableWidget__cell--small"
                            >
                                <Icon
                                    className="packetFilter__icon"
                                    name="drag"
                                    size="sm"
                                />
                            </div>

                    }
                </DndHandleTd>
            )}
            {columnsShow[PACKET_FILTER_COLUMNS_ACTION] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__defaultCursor',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--justIcon select2--${spacing}`}
                        pathGetter={rowPathGetter.action}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_NAME] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'dataTableWidget__cell--stickyName--whiteBg': !stickyRuleNamePFDisabled },
                        { 'dataTableWidget__cell--stickyName': !stickyRuleNamePFDisabled },
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <Tooltip content={noWhiteSpace(comment) ? commentTranslate : null}>
                        <HlcfgTextInput
                            className={classNames(
                                'dataTableWidget__RowInput',
                                'packetFilter__nameInput',
                                { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                            )}
                            inputClass={'dataTableWidget__cell--stickyName--label'}
                            isName
                            pathGetter={rowPathGetter.name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </Tooltip>
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_SOURCE] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        'dataTableWidget__cell--leftPadding',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },


                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={3}
                        pathGetter={rowPathGetter.sourceAddress}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_ARROW] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__defaultCursor',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <Icon
                        name="arrow-right"
                        size="sx"
                    />
                </td>
            )}
            {columnsShow[PACKET_FILTER_COLUMNS_DESTINATION] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}

                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={3}
                        pathGetter={rowPathGetter.destinationAddress}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_SERVICE] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={3}
                        pathGetter={rowPathGetter.service}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_WEB_PROFILE] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing }
                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        notEditable={action !== WEB_ACTION || !enabled}
                        pathGetter={rowPathGetter.webProfile}
                    />
                </td>
            )}
            {columnsShow[PACKET_FILTER_COLUMNS_INTERFACE] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={6}
                        notEditable={notEditable || action === ACCEPT_ACTION || action === FORCE_ACCEPT_ACTION}
                        pathGetter={rowPathGetter.iface}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_TRANSLATION_ICON] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__translation',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <GetIcon
                        destinationTranslationAddress={destinationTranslation?.address}
                        icon={getIconForTranslation({ sourceTranslation, destinationTranslation })}
                        spacing={spacing}
                        t={t}
                    />
                </td>
            )}
            {columnsShow[PACKET_FILTER_COLUMNS_SOURCE_TRANSLATION] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <SourceTranslationSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={6}
                        notEditable={(notEditable || (action !== ACCEPT_ACTION && action !== FORCE_ACCEPT_ACTION))}
                        pathGetter={rowPathGetter.sourceTranslation}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={6}
                        notEditable={notEditable || action !== ACCEPT_ACTION}
                        pathGetter={rowPathGetter.destinationTranslation.address}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_DESTINATION_TRANSLATION_PORT] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >

                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        maxItemsToDisplay={6}
                        notEditable={notEditable ||
                                        !(Number(destinationTranslation?.address?.length) > 0 ||
                                         destinationTranslation?.port) ||
                                        !service?.length || (action !== ACCEPT_ACTION &&
                                            action !== FORCE_ACCEPT_ACTION)}
                        pathGetter={rowPathGetter.destinationTranslation.port}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_QOS_NODES] ? (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'packetFilter__row',
                        { 'packetFilter__defaultCursor': fake },
                        { [`dataTableWidget__cell--${spacing}`]: spacing },
                    )}
                >
                    <HlcfgSelect
                        className={`select2--row select2--${spacing}`}
                        notEditable={notEditable || action === WEB_ACTION}
                        pathGetter={rowPathGetter.qosNodes}
                    />
                </td>
            ) : null}
            {columnsShow[PACKET_FILTER_COLUMNS_LOG] && (
                <td
                    className={classNames(
                        'dataTableWidget__cell',
                        'dataTableWidget__cell--icon',
                        { [`dataTableWidget__cell--${spacing}`]: spacing },

                    )}
                >
                    <HlcfgSelect
                        className={`packetFilter__log select2--row select2--justIcon select2--${spacing}`}
                        notEditable={!enabled}
                        pathGetter={rowPathGetter.log}
                    />
                </td>
            )}
            <RowMenuAndSwitch
                __off={!enabled}
                copyFunc={tableManipulator.duplicateRow}
                deleteButtonDisabled={fake}
                deleteFunc={tableManipulator.deleteRow}
                disabled={enableTooltip}
                id={'off' + id}
                name={HLCFG_OFF}
                onChange={({ value }) => setOff(value)}
                settings={setShowDetail.swap}
                showDetail={showDetail}
                spacing={spacing}
                tooltipText={enableTooltip ? 'packetFilter:ipv6Disabled' : undefined}
            />
        </>
    );
};

export const SourceTranslationSelect = ({ pathGetter, ...selectProps }: HlcfgSelectProps) => {
    const { id, schema, value, setValue, isRequired } = useHlcfgInputModel(pathGetter);
    const commonProps = {
        id,
        onChange: setValue,
        value,
        isRequired,
    };
    const modelWrap = (model: SelectModel<unknown>) => {
        const modelStringify = model.stringify;
        assert(modelStringify, 'Static hlcfg reference model wrapper requires base model to have stringify');
        const prepareOption = (value): SelectOption => {
            if (value === 'MASQUERADE') {
                return { label: value, value, backgroundColor: INTERFACE_COLOR };
            }
            return model.prepareOption(value);
        };
        const stringify = (value) => {
            if (value === 'MASQUERADE') {
                return 'MASQUERADE';
            }
            return modelStringify(value);
        };
        return {
            ...model,
            prepareOption,
            stringify,
            options: [ 'MASQUERADE', ...model.options ]
        };
    };
    return (
        <NetaddrSelect
            {...selectProps}
            {...commonProps}
            modelWrap={modelWrap}
            netaddrType={schema.anyOf[0][SCHEMA_TYPE_NETADDR]}
            value={value as any}
        />
    );
};

const RenderRuleCells = memo(RenderRuleCellsNoMemo);
export const RenderNftRule = memo(RenderNftRuleRowNoMemo);
