/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import Service from '~frontendComponents/Service/index.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import { useHlcfgOffable } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';

import AdaptiveFirewallSettings from './components/AdaptiveFirewallSettings/index.js';
import AdaptiveFirewallUpdates from './components/AdaptiveFirewallUpdates/index.ts';
import AdaptiveFirewallBlackList from './components/AdaptiveFirewallBlackList/index.js';
import AdaptiveFirewallIsInAF from './components/AdaptiveFirewallIsInAF/index.js';
import AdaptiveFirewallImInAF from './components/AdaptiveFirewallImInAF/index.js';


const AdaptiveFirewallScene = () => {
    const { t } = useTranslation();
    const af = useHlcfgOffable(hlcfgPathGetter.protection.adaptiveFirewall);
    return (
        <Scene>
            <Service
                attention
                id="AdaptiveFirewall"
                on={af.isOn}
                showService={(
                    <>
                        <MDBRow>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <AdaptiveFirewallSettings />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <AdaptiveFirewallIsInAF />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <AdaptiveFirewallImInAF />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol
                                className="mb-2 mb-lg-4"
                            >
                                <AdaptiveFirewallUpdates />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"

                            >
                                <AdaptiveFirewallBlackList
                                    list="blacklist"
                                    listComment="blacklistComment"
                                />
                            </MDBCol>
                            <MDBCol
                                className="mb-2 mb-lg-4"

                            >
                                <AdaptiveFirewallBlackList
                                    list="ignorelist"
                                    listComment="ignoreComment"
                                />
                            </MDBCol>
                        </MDBRow>
                    </>
                )}
                turnOff={af.setOff}
                turnOn={af.setOn}
            >
                <h3>{t('widgets:AdaptiveFirewall.policy.title')}</h3>
                <p className="mb-1">
                    {t('widgets:AdaptiveFirewall.policy.desc')}
                </p>
                <p className="mb-1">
                    {t('widgets:AdaptiveFirewall.policy.desc1')}
                </p>
                <p className="mb-1">
                    {t('widgets:AdaptiveFirewall.policy.desc2')}
                </p>
                <p className="mb-2">
                    {t('widgets:AdaptiveFirewall.policy.desc3')}
                </p>
                <h3>{t('widgets:AdaptiveFirewall.database.title')}</h3>
                <p className="mb-2">
                    {t('widgets:AdaptiveFirewall.database.desc')}
                </p>
                <h3>{t('widgets:AdaptiveFirewall.blacklist.title')}</h3>
                <p className="mb-2">
                    {t('widgets:AdaptiveFirewall.blacklist.desc')}
                </p>
            </Service>


        </Scene>
    );
};


export default AdaptiveFirewallScene;
