/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createPathGetter, PathGetter } from '~commonLib/objectUtils.ts';
import { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.ts';
import { hlcfgTableByRowId, HlcfgTableName, HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';


export const hlcfgPathGetter = createPathGetter<HlcfgDirtyTree>();
export type HlcfgRowPathGetter<TableName extends HlcfgTableName> = PathGetter<
    HlcfgDirtyTree['tables'][TableName]['k'], ['tables', TableName, 'k']
>;

export const getRowPathGetter = <
    Table extends HlcfgTableName
>(id: HlcfgTableRowId<Table>): HlcfgRowPathGetter<Table> => {
    const table = hlcfgTableByRowId(id);
    return hlcfgPathGetter.tables[table][id] as any as HlcfgRowPathGetter<Table>;
};
