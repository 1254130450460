/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { MDBBtn, MDBCard } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import { SELECTABLE_TABLE_MONITORING_LOGS, userSetting, MONITORING_LOGS_COLUMNS_LEVEL,
    MONITORING_LOGS_COLUMNS_TIME, MONITORING_LOGS_COLUMNS_SYSLOGID, MONITORING_LOGS_COLUMNS_MESSAGE,
    MONITORING_LOGS_COLUMNS_PID, MONITORING_LOGS_COLUMNS_BOOT,
    FULL_SYSLOG_MESSAGE_MODAL, SCROLL, HIDE, BOOT, UNITS, PIDS } from '~frontendConstants/constants.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { TIME_FORMAT } from '~commonLib/moment.ts';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import Icon from '~frontendComponents/Generic/Icon/Icon.tsx';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import RowDivider from '~frontendRoot/components/RowDivider.tsx';
import { useLogRowIsMatching } from '~frontendDucks/logDataWindow/index.ts';
import { EMPTY_IMMUTABLE_ARR } from '~sharedConstants/constants.ts';
import { getNewParams, getShowFullMessage, setNewParams } from '~frontendDucks/systemLogs/index.ts';


const AUDIT_MSG = '-1';
const LOG_COLORS = {
    [AUDIT_MSG]: 'light-blue',
    0: 'red',
    1: 'red',
    2: 'red',
    3: 'red',
    4: 'yellow',
    5: 'grey-off',
    6: 'secondary-green-dark',
    7: 'white',

};

const EmptyWrapper = ({ children }) => children;
const ColumnMenuNoMemo = (props: { value: string, id: string, unit?: string }) => {

    const [ menuIsOpen, setMenuIsOpen ] = useBoolean();
    const currentParams = useSelector(state => getNewParams(state));
    const disptach = useDispatch();
    const { t } = useTranslation();
    const { value, id, unit } = props;

    const TippyWrap = menuIsOpen ? Tippy : EmptyWrapper;

    const trimUnit = (unit: string | undefined) => unit?.substring(0, unit?.lastIndexOf('.'));

    const unitOrValue = () => {
        switch (id) {
        case PIDS:
            return currentParams?.[PIDS] ? [ ...currentParams[PIDS], value ] : [ value ];
        case UNITS:
            return currentParams?.[UNITS] ? [ ...currentParams[UNITS], trimUnit(unit) ] : [ trimUnit(unit) ];
        default:
            return value;
        }
    };

    const handleAddFilter = () => {
        disptach(setNewParams({
            newParams: {
                [id]: id === BOOT ? unitOrValue() : [ ...new Set(unitOrValue()) ]
            }
        }));
        setMenuIsOpen.off();
    };
    return (
        <TippyWrap
            allowHTML={true}
            appendTo={document.body}
            content={menuIsOpen &&
                <div>
                    <MDBCard className="packetFilter__menu">
                        <MDBBtn
                            className="packetFilter__menuButtonPopup
                                packetFilter__menuButtonPopup--copy"
                            disabled={!unit && id === UNITS}
                            onClick={!unit && id === UNITS ? () => null : handleAddFilter}
                            outline
                        >

                            {!unit && id === UNITS ?
                                t('systemLogs:logWithoutUnit') :
                                <>
                                    <Icon
                                        name="filterPlus"
                                    />
                                    {t('systemLogs:addToFilter')}
                                </>
                            }
                        </MDBBtn>
                    </MDBCard>
                </div>
            }
            interactive={true}
            onClickOutside={setMenuIsOpen.off}
            placement="left"
            visible={menuIsOpen}
        >
            <span
                className="clicable"
                onClick={setMenuIsOpen.swap}
            >
                {value}
            </span>
        </TippyWrap>
    );
};
const ColumnMenu = React.memo(ColumnMenuNoMemo);

const FullMessage = (props: { value: string, showOrHide: boolean }) => {

    const dispatch = useDispatch();
    const handleOpenModal = () => {
        dispatch(setModalState({ modal: FULL_SYSLOG_MESSAGE_MODAL, value: true, specialValues: { value } }));
    };
    const { value, showOrHide } = props;
    return (
        <div
            className={classNames(
                `systemMessage--${showOrHide ? SCROLL : HIDE}`,
                'clicable'
            )}
            onClick={handleOpenModal}
        >
            {value}
        </div>
    );
};


type RowProps = {
    id: string,
    data: any,
    rowIdx: number,
    windowIdx: number,
}

const RowWithNoMemo = ({ id, data, rowIdx, windowIdx }: RowProps) => {

    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_MONITORING_LOGS);
    const dataWithDate = { ...data,
        timestampUnixMicroS: moment(data.timestampUnixMicroS / 1000). format(TIME_FORMAT.systemdTime)
    };
    const matching = useLogRowIsMatching(rowIdx, windowIdx);
    const showOrHide = useSelector(state => getShowFullMessage(state));
    return (
        <>
            <RowDivider
                id={`dataDivider-${id}`}
                length={getColumnsSelectedLength()}
                types={EMPTY_IMMUTABLE_ARR}
                withoutButtons
            />
            <tr
                className={classNames(
                    'dataTableWidget__Row',
                    { 'dataTableWidget__Row--match': matching },
                )}
            >
                {getColumnsShow(MONITORING_LOGS_COLUMNS_LEVEL) &&
                <td
                    className={classNames(
                        'syslogTableCell__background',
                        `syslogTableCell__background--${LOG_COLORS[data.priority]}`
                    )}
                >
                    {data.priority}
                </td>
                }

                {getColumnsShow(MONITORING_LOGS_COLUMNS_TIME) &&
                <td className={'dataTableWidget__cell'}>
                    {dataWithDate.timestampUnixMicroS}
                </td>
                }

                {getColumnsShow(MONITORING_LOGS_COLUMNS_SYSLOGID) &&
                <td className={'dataTableWidget__cell'}>
                    <ColumnMenu
                        id={UNITS}
                        unit={data.unit}
                        value={data.syslogId}
                    />
                </td>
                }

                {getColumnsShow(MONITORING_LOGS_COLUMNS_MESSAGE) &&
                <td className={'dataTableWidget__cell'}>
                    <FullMessage
                        showOrHide={showOrHide}
                        value={data.message}
                    />
                </td>
                }

                {getColumnsShow(MONITORING_LOGS_COLUMNS_PID) &&
                <td className={'dataTableWidget__cell'}>
                    <ColumnMenu
                        id={PIDS}
                        value={data.pid}
                    />
                </td>
                }

                {getColumnsShow(MONITORING_LOGS_COLUMNS_BOOT) &&
                <td className={'dataTableWidget__cell'}>
                    <ColumnMenu
                        id={BOOT}
                        value={data.bootRelative}
                    />
                </td>
                }
            </tr>
        </>
    );
};

const Row = React.memo(RowWithNoMemo);

export default Row;
