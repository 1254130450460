/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ValuesType } from 'utility-types';

import { OPENVPN_USER_TYPE_S2S } from '~sharedRoot/constants/index.ts';
import { NetaddrIp4Data } from '~sharedLib/types.ts';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { HlcfgDirtyTableItem } from '~frontendConstants/types.ts';
import { NotUndefined } from '~commonLib/types.ts';


type Usr = Pick<HlcfgDirtyTableItem<'openvpnUser'>, 'siteToSiteNetworks'|'addresses'|'type'>
type GeneratedRoute<T extends Usr> = {
    destination: NotUndefined<T['siteToSiteNetworks']>[0],
    gateway: T['addresses'][0]
}
export const generateRouteForOpenVpnUser = <T extends Usr>(
    vpnUser: T
): GeneratedRoute<T>[]|undefined => {
    if (vpnUser.type !== OPENVPN_USER_TYPE_S2S || !vpnUser.siteToSiteNetworks) {
        return;
    }
    return vpnUser.siteToSiteNetworks.map(destination => ({
        destination: destination as ValuesType<NotUndefined<T['siteToSiteNetworks']>>,
        gateway: vpnUser.addresses[0]
    }));
};


export const vpnGateway = (gateway?: NetaddrIp4Data) => {
    return gateway ? netaddr(gateway) : 'vpn_gateway';
};
