/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import {
    MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Overlay, Icon, Cards } from '~frontendComponents/Generic/index.js';
import {
    getVpnTypes,
    deleteNormalize, duplicateFromNormalize, setNormalize, addToNormalize, setNormalizeOrder,
    getIpsecProps,
    getIpsecIds
} from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCard } from '~frontendDucks/activeCards/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { DELETE_CONFIRM, IPSEC } from '~frontendConstants/index.js';
import TurnedOff from '~frontendComponents/TurnedOff/index.js';

import IpsecService from './components/IpsecService.js';


@withTranslation()
@connect(
    (state) => ({
        ids: getIpsecIds(state),
        ipsecProps: getIpsecProps(state),
        activeIpsecService: getActiveCard(state, 'ipsec'),
        types: getVpnTypes(state),
    }),
    {
        add: addToNormalize,
        setValue: setNormalize,
        setOrder: setNormalizeOrder,
        doSetModal: setModalState,
        del: deleteNormalize,
        copy: duplicateFromNormalize,

    }
)
class Ipsec extends Component {
    static get propTypes() {
        return {
            ids: PropTypes.array,
            t: PropTypes.func,
            add: PropTypes.func,
            activeIpsecService: PropTypes.string,
            setValue: PropTypes.func,
            setOrder: PropTypes.func,
            ipsecProps: PropTypes.object,
            doSetModal: PropTypes.func,
            del: PropTypes.func,
            copy: PropTypes.func,

        };
    }

    addIpsec = () => {
        const { add, activeIpsecService } = this.props;
        add({
            type: 'ipsec',
            typeId: IPSEC,
            uuidToAddBefore: activeIpsecService,
            successText: 'widgets:Ipsec.added',
        });
    };


    deleteService = ({ uuid }) => {
        const { doSetModal,  ipsecProps } = this.props;
        doSetModal({ modal: DELETE_CONFIRM, value: true, specialValues: { uuid, name: ipsecProps[uuid]?.name,
            action: this.delete, service: 'Ipsec' } });
    };

    delete = ({ uuid }) => {
        const { del } = this.props;
        del({ type: IPSEC, value: uuid });
    };

    copyService = ({ uuid }) => {
        const { copy  } = this.props;
        copy({
            uuid: uuid,
            parent: IPSEC
        });
    };

    changeValue = ({ value, uuid, key, name }) => {
        const { setValue, activeIpsecService } = this.props;
        setValue({ type: IPSEC, uuid: uuid || activeIpsecService, value, key: key || name });
    };

    setOrder = ({ order }) => {
        const { setOrder } = this.props;
        setOrder({ type: IPSEC, value: order });
    };

    render() {
        const { t, ids, ipsecProps,  activeIpsecService, } = this.props;
        return (
            <>
                <div className="userNavigation">

                    <MDBBtn
                        className="navigation__button ml-1"
                        color="secondary"
                        data-cy="ipsecAdd"
                        onClick={this.addIpsec}
                        size="sm"
                        type="button"
                    >
                        <Icon name="plus" />
                        {t('widgets:Ipsec.title')}
                    </MDBBtn>
                </div>
                <div  className={classNames(
                    { 'withOverlay': !ids.length }
                )}
                >
                    <Cards
                        copyService={this.copyService}
                        deleteService={this.deleteService}
                        ids={ids}
                        menuItemProps={ipsecProps}
                        setOrder={this.setOrder}
                        setValue={this.changeValue}
                        type="ipsec"
                        wrapClassName="vpn__scene"
                    />

                    <div className="overlay--div">

                        <IpsecService
                            uuid={activeIpsecService}
                        />
                        <TurnedOff
                            disabled={ipsecProps[activeIpsecService]?.__off}
                            onChange={this.changeValue}
                        />
                    </div>
                    {!ids.length &&
                            <Overlay
                                active={!ids.length}
                            >
                                <div className="dhcpd__overlay">
                                    <h2>{t('widgets:Ipsec.title')}</h2>
                                    <p className="mb-2">
                                        {t('widgets:Ipsec.desc')}
                                    </p>
                                    <p className="dataTableWidget__Rowtext">
                                        {t('widgets:Ipsec.desc1')}
                                        <MDBBtn
                                            className="navigation__button"
                                            color="secondary"
                                            data-cy="addingAccessServer"
                                            onClick={this.addIpsec}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t('widgets:Ipsec.title')}
                                        </MDBBtn>
                                    </p>

                                    <MDBBtn
                                        className="navigation__button ml-1"
                                        color="secondary"
                                        onClick={this.addIpsec}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t('widgets:Ipsec.title')}
                                    </MDBBtn>

                                </div>
                            </Overlay>}

                </div>
            </>
        );
    }
}

export default Ipsec;
