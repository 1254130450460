/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, InputSearch } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_DHCP_ROUTING, SMALL_SIZE } from '~frontendConstants/index.js';
import { useBoolean, useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';

import Row from './Row/index.ts';
import { DhcpdServerContext } from '../DhcpdService/index.ts';


const CreateRow = ({
    dataIndex,
    spacing,
    search, uuid, }: CreateRowPropsType) => {

    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

const DhcpdRoutes = ({ data }: {uuid: HlcfgRowId, data: HlcfgRowId[]}) => {
    const [ search, setSearch ] = useString('');
    const [ shownRoutes, setShownRoutes ] = useBoolean(false);
    const { uuid } = useContext(DhcpdServerContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const passReorderData = useCallback((data) => {
        dispatch(setNormalize({ value: data, uuid: uuid, key: 'routes', }));
    }, [ dispatch, uuid ]);

    const ids = useMemo(() => data?.length ? data : [ NEW_ROW_CONSTANT ], [ data ]);

    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': shownRoutes },
            )}
            >
                <div
                    className="clicable"
                    onClick={setShownRoutes.swap}
                >
                    {t('widgets:Dhcpd.routeTable.title')}
                    <Icon
                        name={shownRoutes ? 'chevron-up' : 'chevron-down'}
                    />
                </div>
                {shownRoutes &&
                <MDBRow className="">

                    <InputSearch
                        className="mb-0 mt-0"
                        id="searchValueIDRoute"
                        search={search}
                        setter={setSearch}
                    />
                </MDBRow>
                }
            </MDBCardTitle>

            <MDBCardBody  className={classNames('cardHide__body', 'p-0',
                { 'cardHide__body--hide': !shownRoutes },
                { 'cardHide__title--borderBottom': shownRoutes },)}
            >
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_DHCP_ROUTING}
                    createRow={CreateRow}
                    data={ids}
                    passReorderData={passReorderData}
                    search={search}
                    spacing={SMALL_SIZE}
                />
            </MDBCardBody>
        </MDBCard>

    );
};

export default DhcpdRoutes;
