/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { NetportBase } from '~sharedLib/Netport/NetportBase.ts';
import {
    NetportSimpleData,
    NetportSimpleString
} from '~sharedLib/types.ts';
import { INTEGER_REGEX, MAX_PORT_VALUE, MIN_PORT_VALUE } from '~sharedConstants/index.ts';
import { isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.ts';


export class NetportSimple extends NetportBase {
    port: number;

    constructor(port: NetportSimpleString | NetportSimpleData | number) {
        super();

        if (isNetportSimpleString(port)) {
            this.port = parseInt(port);
            return;
        }
        if (typeof port === 'number') {
            this.port = port;
            return;
        }

        if (isNetportSimpleData(port)) {
            this.port = port.port;
            return;
        }
        throw new Error(`Invalid port passed to NetportSimple: ${JSON.stringify(port)}`);
    }

    getPort() {
        return this.port;
    }

    toString() {
        return this.port.toString();
    }

    isValid() {
        return this.port >= MIN_PORT_VALUE && this.port <= MAX_PORT_VALUE;
    }
    isSimple(): this is NetportSimple {
        return true;
    }

    containsPortNum(portNum: number) {
        return this.port === portNum;
    }
}

export const isNetportSimpleData = (data): data is NetportSimpleData => {
    return 'port' in data && (typeof data.port === 'number' || isStaticReference(data.port));
};
export const isNetportSimpleString = (strPort): strPort is NetportSimpleString => {
    return typeof strPort === 'string' && INTEGER_REGEX.test(strPort);
};
