/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getShowTodos } from '~frontendDucks/dynamicSettings/index.ts';

import Icon from '../../../../components/Generic/Icon/index.ts';
import Input from '../../../../components/Generic/Input/index.js';
import Message from '../../../../components/Message/index.js';
import { getGuiLoadingError } from '../../../../ducks/userAuthentication/ducks/guiLoading.js';
import { getIsLoginChecking, getLoginError, loginRequest, getIsLoggedIn } from '../../../../ducks/userAuthentication/ducks/login.js';
import { typeApiError } from '../../../../types/index.js';


@connect(
    state => ({
        guiLoadingError: getGuiLoadingError(state),
        isFirstApproach: true,
        isLoginChecking: getIsLoginChecking(state),
        isLoggedIn: getIsLoggedIn(state),
        loginError: getLoginError(state),
        showTodos: getShowTodos(state),
    }),
    {
        doLoginRequest: loginRequest,
    }
) class LoginForm extends Component {
    static get propTypes() {
        return {
            doLoginRequest: PropTypes.func,
            isFirstApproach: PropTypes.bool,
            isLoginChecking: PropTypes.bool,
            isLoggedIn: PropTypes.bool,
            loginError: typeApiError,
            guiLoadingError: typeApiError,
            showTodos: PropTypes.bool,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    handleInputOnChange = ({ value,  name }) => {
        this.setState(
            { [name]: value },
        );
    };

    handleLoginSubmit = (event) => {
        const { doLoginRequest } = this.props;

        event.preventDefault();
        const username = event.target.loginUsername.value;
        const password = event.target.loginPassword.value;

        doLoginRequest(username, password);
    };

    render() {
        const {
            guiLoadingError, isFirstApproach, isLoginChecking, loginError, isLoggedIn, showTodos
        } = this.props;
        const { username, password } = this.state;
        return (
            <form
                onSubmit={this.handleLoginSubmit}
            >
                <fieldset className="loginForm__fieldset">
                    <Input
                        autoComplete="username"
                        className="loginForm__username"
                        dark
                        disabled={Boolean(isLoginChecking || loginError || guiLoadingError || isLoggedIn)}
                        hint={isFirstApproach ? 'admin' : ''}
                        id="loginUsername"
                        label={<Message message="login:form.user" />}
                        name="username"
                        onChange={this.handleInputOnChange}
                        outline={false}
                        type="text"
                        value={username}
                    />
                    <Input
                        autoComplete="current-password"
                        className="loginForm__password"
                        dark
                        disabled={Boolean(isLoginChecking || loginError || guiLoadingError || isLoggedIn)}
                        hint={isFirstApproach ? 'admin' : ''}
                        id="loginPassword"
                        label={<Message message="login:form.password" />}
                        name="password"
                        onChange={this.handleInputOnChange}
                        outline={false}
                        type="password"
                        value={password}
                    />
                    <MDBBtn
                        className="loginForm__btn--submit"
                        color="primary"
                        disabled={Boolean(loginError || guiLoadingError || isLoggedIn || isLoginChecking)}
                        type="submit"
                    >
                        <Message message="login:form.btnSubmit1" />
                        <Icon name="arrow-right" />
                    </MDBBtn>

                    {
                        showTodos ?
                            <div className="loginForm__btnGroup">
                                <MDBBtn
                                    className="loginForm__btn--lostPass btn-link"
                                    color="primary"
                                    disabled={Boolean(isLoginChecking || loginError || guiLoadingError)}
                                >
                                    <Message message="login:form.lostPass" />
                                </MDBBtn>
                                <MDBBtn
                                    className="loginForm__btn--ssoLogin btn-link"
                                    color="primary"
                                    disabled={Boolean(isLoginChecking || loginError || guiLoadingError)}
                                >
                                    <Message message="login:form.ssoLogin" />
                                </MDBBtn>
                            </div> :
                            null
                    }
                </fieldset>
            </form>
        );
    }
}

export default LoginForm;
