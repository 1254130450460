/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import {
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query';
import assert from 'assert';
import { AxiosError } from 'axios';
import { useCallback, useRef } from 'react';

import { AnyAsyncFunc } from '~commonLib/types.ts';


export type QueryDef = {
    queryFn: AnyAsyncFunc, queryKey: any,
    requiresAdditionalQueryKeys?: boolean,
};
type QueryFetchingOpts<Query extends QueryDef, SelRes> = {
    refetchInterval?: number,
    additionalQueryKeys?: unknown[]
    queryParams?: Parameters<Query['queryFn']>[0],
    staleTime?: number,
    select?: (data: Awaited<ReturnType<Query['queryFn']>>) => SelRes
}
// Little helper to help with inferring results.
type QueryFetchingSelectOpt<Query extends QueryDef, SelRes> = {
    select: (data: Awaited<ReturnType<Query['queryFn']>>) => SelRes
}

type QueryWrappedHook<T extends QueryDef> = <Opts extends QueryFetchingOpts<T, any>|undefined>(
    opts?: Opts
) => Opts extends QueryFetchingSelectOpt<T, infer SelRes> ?
    UseQueryResult<SelRes, AxiosError> :
    UseQueryResult<Awaited<ReturnType<T['queryFn']>>, AxiosError>

/**
 * Helper used to wrap queries to dedicated hooks making passing of more options to them more ergonomic.
 * This makes it easier to use more granular queries, and to compose query hooks.
 *
 * Also has axios error baked-in to the result.
 *
 * @example
 * // With helper
 * const useUpgradeState = createQueryHook('upgradeState')
 * const upgradeState = useUpgradeState();
 * const { data: downgradeVersion } = useUpgradeState({ select: it => it.downgradeVersion });
 *
 * // Without helper
 * const upgradeState = useQuery(queries.upgradeState);
 * const { data: downgradeVersion } = useQuery({...queries.upgradeState, select: it => it.downgradeVersion });
 */
export const createQueryHook = <T extends QueryDef>(
    queryDefinition: T
): QueryWrappedHook<T> => {
    const useHook: any = (opts?: QueryFetchingOpts<T, unknown>) => {
        if (queryDefinition.requiresAdditionalQueryKeys) {
            assert(opts?.additionalQueryKeys, `Query ${queryDefinition.queryKey} requires additional query keys`);
        }
        return useQuery({
            ...queryDefinition,
            queryKey: [ ...queryDefinition.queryKey, ...opts?.additionalQueryKeys ?? [] ],
            queryFn: () => queryDefinition.queryFn(opts?.queryParams),
            ...opts,
        });
    };
    return useHook;
};

export const useSomeIsRefetching = (queries: QueryDef[]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return queries.map(it => useQuery({ staleTime: Infinity, ...it }).isRefetching).some(it => it);
};
export const useRefetchMany = (queries: QueryDef[]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const refetches = queries.map(it => useQuery({ staleTime: Infinity, ...it }).refetch);
    const callback = useRef(refetches);
    callback.current = refetches;
    return useCallback(() => {
        callback.current.forEach(it => void it());
    }, [ callback ]);
};
export const useQueriesReloader = (queries: QueryDef[]) => {
    const isLoading = useSomeIsRefetching(queries);
    const refetch = useRefetchMany(queries);
    const reload = useCallback(() => {
        if (isLoading) {
            return;
        }
        refetch();
    }, [ isLoading, refetch ]);

    return { isLoading, reload };
};
