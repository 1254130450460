/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import InputRange from 'react-input-range';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { InputCallbackFunction } from '~frontendConstants/types.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { useStateWithDeps } from '~frontendRoot/lib/hooks/defaultHooks.ts';


const getValue = ([ value, schema ]: [string, any]): number => {
    if (value) {
        return schema.enum.findIndex((item: string) => item === value);
    } else if (schema[DEFAULT_SCHEMA_VALUE]) {
        return schema.enum.findIndex((item: string) => item === schema[DEFAULT_SCHEMA_VALUE]);
    } else {
        return 0;
    }
};

/**
 * Hlcfg ENUM setter.
 */

interface SliderProps {
    maxValue: number,
    minValue: number,
    onChange: InputCallbackFunction,
    value?: string,
    schema: any,
    id: string,
    name: string,
    labelClass?: string,
    label: string | React.ReactNode,
}

const Slider = ({ maxValue, minValue, onChange, value, schema, name, id,
    labelClass, label }: SliderProps) => {
    const [ localValue, setLocalValue ] = useStateWithDeps(getValue, [ value, schema ]);

    const { t } = useTranslation();
    const formatLabel = useCallback((value: number) => {
        return  t(`widgets:enum.${schema.enum[value]}.title`);
    }, [ schema.enum, t ]);

    const onChangeComplete = useCallback((value: number) => {
        onChange({ value: schema.enum[value], name: name, id: id });
    }, [ schema.enum, onChange, name, id ]);

    return (

        <div className="slider form-group">
            <label
                className={classNames(
                    'form-control__label', 'active',
                    labelClass,
                )}
                htmlFor={id}
            >
                {label}
            </label>
            <InputRange
                formatLabel={formatLabel}
                maxValue={maxValue}
                minValue={minValue}
                onChange={setLocalValue}
                onChangeComplete={onChangeComplete}

                value={localValue}
            />
        </div>
    );

};

export default Slider;
