/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBRow, MDBCol } from 'mdbreact';

import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import { getHardwareInfo, setHardwareInfoModalOpen  } from '~frontendDucks/systemInfo/index.js';
import { replaceSizeString } from '~frontendLib/stringUtils.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';


@withTranslation()
@connect(
    (state,) => ({
        hwInfo: getHardwareInfo(state),
    }),
    {
        doSetHardwareInfoModalOpen: setHardwareInfoModalOpen
    }
)
class HardwareController extends Component {
    static get propTypes() {
        return {
            hwInfo: PropTypes.object,
            t: PropTypes.func,
            doSetHardwareInfoModalOpen: PropTypes.func,
        };
    }


    openModal = ({ name }) => {
        const { doSetHardwareInfoModalOpen } = this.props;
        doSetHardwareInfoModalOpen({ name, value: true });
    };

    render() {
        const { t, hwInfo } = this.props;
        return (
            <MDBCard>
                <MDBCardHeader>
                    <MDBRow>
                        <MDBCol lg="4">
                            {t('widgets:device.title')}
                        </MDBCol>
                        <MDBCol
                            className="device__icons"
                            lg="8"
                        >
                            <IconWithTooltip
                                className="device__rebootIcon icon--red"
                                iconSize="sm"
                                link
                                name="reload"
                                onClick={() => this.openModal({ name: 'rebootModal' })}
                                tooltipText={t('widgets:device.reboot.title')}
                                withoutTranslation
                            />
                            <IconWithTooltip
                                className="icon--red"
                                iconSize="sm"
                                link
                                name="power"
                                onClick={() => this.openModal({ name: 'shutdownModal' })}
                                tooltipText={t('widgets:device.shutdown.title')}
                                withoutTranslation
                            />

                        </MDBCol>

                    </MDBRow>
                </MDBCardHeader>
                <MDBCardBody>
                    <ul className="pl-3">
                        <li>
                            <strong>{t('widgets:device.model')}</strong>
                            {hwInfo?.machineModel}
                        </li>
                        <li>
                            <strong>{t('widgets:device.serialNumber')}</strong>
                            {hwInfo?.serialNumber}
                        </li>
                        <li>
                            <strong>{t('widgets:device.cpu.title')}</strong>
                            {hwInfo?.cpu.name}
                            <ul>
                                <li>
                                    {t('widgets:device.cpu.sockets')}
                                    {hwInfo?.cpu.sockets}
                                </li>
                                <li>
                                    {t('widgets:device.cpu.coresPerSocket')}
                                    {hwInfo?.cpu.coresPerSocket}
                                </li>
                                <li>
                                    {t('widgets:device.cpu.threadsPerCore')}
                                    {hwInfo?.cpu.threadsPerCore}
                                </li>

                            </ul>
                        </li>
                        <li>
                            <strong>{t('widgets:device.memory')}</strong>
                            {replaceSizeString(hwInfo?.memory, t)}
                        </li>
                        <li>
                            <strong>{t('widgets:device.disks.title')}</strong>
                            <ul>
                                {hwInfo?.disks.map(item => (
                                    <li key={item.modelName + item.size}>
                                        {item.modelName}
                                        <ul>
                                            <li>
                                                {t('widgets:device.disks.size')}
                                                {valueFormatter.formatBytes(item.size, 1)}
                                            </li>
                                            <li>
                                                {t('widgets:device.disks.serial')}
                                                {item.serial}
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </MDBCardBody>
            </MDBCard>
        );
    }
}

export default HardwareController;
