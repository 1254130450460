/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useEffect, useState } from 'react';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBBtn } from 'mdbreact';
import className from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Message from '~frontendComponents/Message/index.js';
import { Input, Icon, Switch } from '~frontendRoot/components/Generic/index.js';
import { backupRequest, getIsLoading, getRecoveryMode, startRecoveryRequest } from '~frontendRoot/ducks/backup/index.js';
import WhenAdvanced from '~frontendComponents/WhenAdvanced/index.js';
import Divider from '~frontendComponents/Divider/index.js';
import { getIsUploadingFile, uploadFileRequest } from '~frontendDucks/uploadFile/index.js';


const Backup = () => {
    const [ psw, setPsw ] = useState('');
    const [ noProxyCerts, setNoProxyCerts ] = useState(false);
    const [ locator, setLocator ] = useState(undefined);

    const [ openPsw, setOpenPsw ] = useState(false);
    const dispatch = useDispatch();
    const loading: boolean = useSelector(getIsLoading);
    const uploadingFile: boolean = useSelector(getIsUploadingFile);
    const recoveryMode: boolean = useSelector(getRecoveryMode);

    useEffect(() => {
        setLocator(undefined);
        setPsw('');
    }, [ recoveryMode, openPsw ]);

    const sendWithPassword = ({ value }) => {
        if (locator) {
            dispatch(startRecoveryRequest({ password: value, backupLocator: locator }));
        } else {
            dispatch(backupRequest({ password: value, noProxyCerts: noProxyCerts }));
            setOpenPsw(val => !val);
        }
    };

    const sendWithPasswordIcon = () => {
        sendWithPassword({ value: psw });
    };

    const startRecovery = (value) => {
        setLocator(value);
    };

    const uploadFile = ({ value, id, name }) => {
        if (value instanceof File) {
            dispatch(uploadFileRequest({ value, funcToCall: startRecovery, props: [ id, name ] }));
            openSetPsw();
        }
    };

    const openSetPsw = () => {
        setOpenPsw(val => !val);
    };

    return (
        <MDBCard>
            <MDBCardHeader>
                <Message message="widgets:backup.title" />
            </MDBCardHeader>
            <MDBCardBody className="backup">
                {recoveryMode ?
                    <div className="backup__recoveryMode">
                        <h3 className="backup__recoveryMode__title">
                            <Message message="widgets:backup.recoveryMode" />
                        </h3>
                    </div> :
                    <>
                        <div className={className({ 'slide-out-left': openPsw }, 'm-2')}>
                            <div className="backup__btn">
                                <MDBBtn
                                    color="secondary"
                                    disabled={loading}
                                    onClick={openSetPsw}
                                ><Message message="widgets:backup.download" />
                                    {loading &&
                             <Icon
                                 className="ml-2"
                                 name="loading"
                             />
                                    }
                                </MDBBtn>
                            </div>
                        </div>
                        <div className={className({ 'slide-in-right': openPsw },
                            { 'displayNone': !openPsw },
                            'flex')}
                        >

                            <p><Message message={`widgets:backup.password.${locator ? 'upload' : 'desc'}`} /></p>
                            <Input
                                className="m-0"
                                disabled={uploadingFile}
                                id="backupPassword"
                                label={<Message message="widgets:backup.password.title" />}
                                loading={uploadingFile}
                                name="password"
                                onChange={({ value }) => setPsw(value)}
                                onEnterPress={sendWithPassword}
                                type="password"
                                value={psw}
                            />
                            <WhenAdvanced>
                                <Switch
                                    align="spaceBetween"
                                    checked={noProxyCerts}
                                    className="m-0"
                                    id="noProxyCerts"
                                    label={<Message message="widgets:backup.noProxyCerts.title" />}
                                    onChange={({ value }) => setNoProxyCerts(value)}
                                />
                            </WhenAdvanced>
                            <Icon
                                className="clicable icon--textColor mt-3 "
                                name="arrow-left"
                                onClick={openSetPsw}
                            />
                            <Icon
                                className="clicable icon--textColor backup__icn"
                                name="arrow-right"
                                onClick={sendWithPasswordIcon}
                            />
                        </div>
                        {!(openPsw || loading) &&
                <>
                    <Divider text={<Message message="widgets:global.or" />} />
                    <Input
                        className="cluster__keytab mb-0"
                        id="loginKeytab"
                        name="file"
                        onChange={uploadFile}
                        outline={false}
                        type="file"
                    />
                </>

                        }
                    </>
                }
            </MDBCardBody>
        </MDBCard>
    );
};

export default Backup;
