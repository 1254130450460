/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { Component } from 'react';
import { MDBCard, MDBBtn } from 'mdbreact';
import { connect } from 'react-redux';

import Table from '~frontendComponents/Generic/Table/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    loadSelectedUserHistoryRequest, getSelectedUserHistory
} from '~frontendDucks/userManagement/ducks/selectedUser.js';
import { getShowTodos } from '~frontendDucks/dynamicSettings/index.ts';
import { typeTimestamp } from '~frontendRoot/types/index.js';

import { parseUserActivities } from '../../utils/userActivityUtils.js';


@connect(
    state => ({
        userActivity: getSelectedUserHistory(state),
        showTodos: getShowTodos(state),
    }),
    {
        doLoadAccountHistory: loadSelectedUserHistoryRequest
    }
)
class UserDetailLog extends Component {
    static get propTypes() {
        return {
            userActivity: PropTypes.array,
            selectedUser: PropTypes.object,
            doLoadAccountHistory: PropTypes.func,
            currentServerTime: typeTimestamp,
            showTodos: PropTypes.bool,
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedUser, doLoadAccountHistory } = this.props;
        if (prevProps.selectedUser !== selectedUser) {
            doLoadAccountHistory(selectedUser);
        }
    }

    render() {
        const { userActivity, currentServerTime, showTodos } = this.props;
        return (
            <MDBCard className="userDetailLog">
                <Table
                    className="card__table"
                    currentServerTime={currentServerTime}
                    userActivity={parseUserActivities(userActivity)}
                />
                <div className="card__footer p-0">
                    {
                        showTodos ?
                            <>
                                <MDBBtn
                                    className="btn tableFooterBtn__allRecords"
                                    color="secondary"
                                    outline
                                    type="button"
                                >
                                    <Message message="user:button.allRecords" />
                                </MDBBtn>
                                <MDBBtn
                                    className="btn tableFooterBtn__lastQRecords"
                                    color="secondary"
                                    outline
                                    type="button"
                                >
                                    <Message message="user:button.lastQRecords" />
                                </MDBBtn>
                            </> :
                            null
                    }
                </div>
            </MDBCard>
        );
    }
}

export default UserDetailLog;
