/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// charts for develop purposes
export const DEVEL_ALERT_CHART_ID = 'dddddddd-44ba-4859-aeed-devedevedeve';

// system health charts
export const MEMORY_USAGE_CHART_ID = 'fa456553-6151-49d4-b8cc-cfea51731440';
export const SWAP_USAGE_CHART_ID  = '9d826168-1ac9-4bb1-9b04-edfc4909db5e';

export const DISK_USAGE_CHART_ID = 'fadf75ed-6ef9-42e5-a8a4-19d429ce62d2';
export const DISK_SPACE_TOTAL_CHART_ID = '17f11b85-86ac-4967-bec1-52d50e52d4aa';
export const DISK_OPENED_FILES_ID = '9c3495eb-f896-42f9-8a2f-f75aa4acc8b0';
export const DISK_DONEIOS_ID = '517d1c7e-b0f7-4b10-916c-8e8273b70842';

export const CPU_BUSY_CHART_ID = '76dbe522-f85f-49e9-bd7d-790c3a22ea1f';
export const CPU_THREADS_CHART_ID = '959699bc-11c6-42eb-af57-14912352861f';
export const CPU_LOAD_CHART_ID = '0db9feed-8256-4f16-9832-5341ea245681';
export const SYSMON_GENERAL = '796e6401-99d3-4ef6-976e-35d7e7c07d8a';
export const GENERIC_UPTIME_ID = '0ff7049c-c23b-49bc-b0e6-cddb8b488192';
export const GENERIC_PACKETS_ID = '4344037d-7acd-4e64-91da-510a2d452f7b';
export const GENERIC_BYTES_ID = '4f259822-17f1-4783-9d98-7e5b159e4257';
export const GENERIC_DROP_PACKETS_ID = '751a12a9-be3a-42de-a22d-e0536fec0033';
export const GENERIC_DROP_BYTES_ID = 'dbd27883-be72-46a8-9a1b-76118a787717';


//network
export const FAST_NETWORK = '8341a97e-a969-401a-a13f-a6d7944b124c';
export const FAST_NETWORK_CONNECTIONS = '81388888-a969-401a-a13f-a6d7944b124c';


export const IPS_EVENTS_NEW_REPORT = '2f2e1052-96a7-4114-8b29-900c58df3b17';
export const IPS_EVENTS_NEW_REPORT_DETAIL_ALLOWED = 'cea1d352-234e-4f39-a2f0-675ab56d2696';
export const IPS_EVENTS_NEW_REPORT_DETAIL_BLOCKED = '8f7fea81-ed35-443a-a79a-daae3a5a8a47';
export const IPS_EVENTS_TOP_BLOCKED = '5ca93cd6-8fae-4e10-887e-96d5b444cb08';
export const IPS_EVENTS_TOP_ALLOWED = '92d0cbcb-0a62-4cb5-ae73-52a288e2d75e';


//nftables
export const NFTABLE_EVENT_REPORT = '6394d106-17f8-43d1-92a1-6c1a6a41fab0';
export const NFTABLE_TOP_NAME_BLOCKED = '76620658-5012-4f1c-b679-cda427ab92b4';
export const NFTABLE_TOP_NAME_ALLOWED = 'e2a175ec-b3b5-4a2b-8bce-18b4740af3b8';
export const NFTABLE_TOP_ADDRESS = 'b30cb5c8-bc82-4891-9d80-b62bf1b4837c';
export const NFTABLE_TOP_ADDRESS_BLOCKED = '00225c78-c138-459a-92a4-6ff35d7ef407';
export const NFTABLE_EVENT_REPORT_PER_HOUR = 'ea18557d-6bdf-46f5-acfd-11efeb6389ab';

//adaptive
export const ADAPTIVE_FIREWALL_EVENTS_REPORT  = '058d7f1e-94f1-4214-bdc8-4ab4317e6678';
export const ADAPTIVE_FIREWALL_EVENTS_REPORT_OUT  = 'b126aa00-0e60-4bb9-b982-455a98dc6bec';
export const ADAPTIVE_FIREWALL_TOP_ADDRESS = '0b3a599d-d834-4df9-90cf-305f319bd5d1';
export const ADAPTIVE_FIREWALL_TOP_PORT = 'cdceb422-35f5-48d9-af0b-0d7c4d343a9b';
export const ADAPTIVE_FIREWALL_TOP_LOCAL_ADDRESSES = 'b2aaef29-e555-4c51-a719-aee6475b08eb';
export const ADAPTIVE_FIREWALL_TOP_LOCAL_PORT = 'd2341fd7-e92c-460b-aa0e-7cb0e2215764';
export const ADAPTIVE_FIREWALL_REPORT_PER_HOUR = '492d3df0-665a-4cb1-82b8-6de5f491df0d';

//ips
export const IPS_EVENTS_REPORT  = '5689afd8-25be-45a2-b612-c7ccebd18d04';
export const IPS_TOP_PROTOCOL = '89d50e7c-ac10-46ec-b708-44d971b670e5';
export const IPS_TOP_ATTACK = '1207373c-5986-4210-9608-4263d9fbdc38';
export const IPS_REPORT_PER_HOUR = 'facc5d15-d9ec-4835-8e4c-547095e3efd4';


//proxy
export const PROXY_REPORT_ALLOWED = '0198fac5-9873-4c1a-be06-38bbb45c6f89';
export const PROXY_PER_HOUR = '74a0b218-4f26-4c87-ba42-1ff5d1eb932a';
export const PROXY_REPORT_IN_AND_OUT = '59d9d58e-1e69-4ffa-9494-809025dcc5d7';
export const PROXY_REPORT_IN_AND_OUT_SLOW = 'b2840b4c-3722-48c9-b24b-21d53fdcb555';
export const PROXY_REPORT_ERROR = 'a5fdbcd7-d59f-46ba-b6f5-ec7c16819da2';
export const PROXY_REPORT_DEST_HOST = '999f2302-8d14-44e4-a4ff-13ffebd7c13a';
export const PROXY_REPORT_SRC_IP = '4e6c406d-b414-45c7-8417-b13a01748c49';
export const PROXY_REPORT_DEST_HOST_BUBBLE = '9eaca442-2b78-453a-a622-087c11655df3';
export const PROXY_REPORT_SRC_IP_BUBBLE = '3b207c44-4859-4e12-a712-82f7f393506b';
export const PROXY_REPORT_SRC_USER_BUBBLE = '40f2cb8d-e581-4581-8dde-ba6efeead426';
