/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useTranslation } from 'react-i18next';

import { JSXElement } from '~commonLib/types.ts';
import { SimpleTranslationCtx } from '~frontendLib/useTranslation.ts';


export const SimpleTranslationCtxProvider = ({ children }: {children: JSXElement}) => {
    const { t: origT, i18n  } = useTranslation();
    return (
        <SimpleTranslationCtx.Provider value={{ t: origT, lang: i18n.language }}>
            {children}
        </SimpleTranslationCtx.Provider>
    );
};
