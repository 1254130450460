/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCol, MDBRow,  MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    ACCEPT_ACTION,
    WEB_ACTION,
    DROP_ACTION,
    TRANSPARENT_RULE_NAME,
    TRANSPARENT_S_RULE_NAME,
    FORCE_ACCEPT_ACTION,
    HLCFG_OFF
} from '~sharedConstants/index.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { HlcfgOffSwitch, HlcfgSelect, HlcfgTextInput, useHlcfgInputModel } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { Input } from '~frontendComponents/Generic/index.js';
import { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { testProps } from '~commonLib/PageObjectMap.ts';

import { Action } from './Action.tsx';
import { Conditions } from './Conditions.tsx';
import { getPFRuleDetailMap } from '../../pageObjectMap.ts';


export const RuleDetail = ({ uuid, smallSpacing }: { uuid: HlcfgTableRowId<'nftRule'>, smallSpacing: boolean }) => {
    const pathGetter = getRowPathGetter(uuid);
    const { t } = useTranslation();
    const { value: fake } = useHlcfgValue(pathGetter.fake);
    const { value: name } = useHlcfgValue(pathGetter.name);
    const { value: action, setValue: setAction } = useHlcfgValue(pathGetter.action);
    const { value: __off } = useHlcfgValue(pathGetter.__off);
    const comment = useHlcfgInputModel(pathGetter.comment);
    const notEditable = fake || __off;
    const rule = { fake, action, name, __off };
    const poMap = getPFRuleDetailMap(uuid).child;
    return (
        <MDBRow
            className="vertical p-2"
        >

            <MDBCol size="12">
                <MDBCard>

                    <MDBCardTitle className="nft--title">
                        <MDBRow>
                            <MDBCol lg="4">
                                <HlcfgTextInput
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                    )}
                                    disabled={notEditable}
                                    inputClass={'dataTableWidget__cell--stickyName--label'}
                                    isName
                                    isRow
                                    noWrap
                                    pathGetter={pathGetter.name}
                                    withoutBorder
                                />
                            </MDBCol>
                            <MDBCol className="justify-content-around">
                                <div className="nft--action">
                                    <div className="nft--actionSingle">

                                        <div className="m-1">
                                            <IconWithTooltip
                                                className={classNames(
                                                    'icon--red',
                                                    { 'icon--textColor': rule.action !== DROP_ACTION ||
                                                        rule[HLCFG_OFF] }
                                                )}
                                                iconSize={smallSpacing ? 'md' : 'lg'}
                                                link
                                                linkClassName={classNames(
                                                    { 'cursor--auto': rule.fake },
                                                )}
                                                name="drop"
                                                onClick={!notEditable ? () => setAction(DROP_ACTION) : undefined}
                                                tooltipText={t('packetFilter:drop')}
                                                withoutTranslation
                                                {...testProps(poMap.actionDrop.id)}
                                            />
                                        </div>

                                    </div>
                                    <div className="nft--actionSingle">

                                        <div className="m-1">
                                            <IconWithTooltip
                                                className={classNames(
                                                    'icon--primary',
                                                    { 'icon--textColor': (rule.action !== ACCEPT_ACTION &&
                                                            rule.action !== FORCE_ACCEPT_ACTION) ||
                                                        rule[HLCFG_OFF] }
                                                )}
                                                iconSize={smallSpacing ? 'md' : 'lg'}
                                                link
                                                linkClassName={classNames(
                                                    { 'cursor--auto': rule.fake },
                                                )}
                                                name="shield-check-outline"
                                                onClick={!notEditable ? () => setAction(ACCEPT_ACTION) : undefined}
                                                tooltipText={t('packetFilter:accept')}
                                                withoutTranslation
                                                {...testProps(poMap.actionAccept.id)}
                                            />
                                        </div>

                                    </div>
                                    <div className="nft--actionSingle">
                                        <div className="m-1">
                                            <IconWithTooltip
                                                className={classNames(
                                                    { 'icon--yellow': rule.action === WEB_ACTION &&
                                                        !rule[HLCFG_OFF] },
                                                    { 'icon--textColor': rule.action !== WEB_ACTION ||
                                                        rule[HLCFG_OFF] }
                                                )}
                                                iconSize={smallSpacing ? 'md' : 'lg'}
                                                link
                                                linkClassName={classNames(
                                                    { 'cursor--auto': rule.fake },
                                                )}
                                                name="web"
                                                onClick={!notEditable ? () => setAction(WEB_ACTION) : undefined}
                                                tooltipText={t('packetFilter:web')}
                                                withoutTranslation
                                                {...testProps(poMap.actionWeb.id)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol
                                className="database__reloadIcon"
                                lg="4"
                            >
                                <HlcfgSelect
                                    className={'packetFilter__log select2--row select2--justIcon'}
                                    notEditable={notEditable}
                                    pathGetter={pathGetter.log}
                                />
                                <HlcfgOffSwitch
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        'dataTableWidget__RowInput--sm',
                                        'mt-3'
                                    )}
                                    mini
                                    noticeMe={__off}
                                    pathGetter={pathGetter}
                                    tooltipText={[
                                        TRANSPARENT_RULE_NAME,
                                        TRANSPARENT_S_RULE_NAME
                                    ].includes(
                                        rule.name
                                    ) ? 'packetFilter:source.proxy.active' : ''}
                                    withoutBorder
                                    withoutLabelMargins
                                    withoutMinWidhtHeight
                                />

                            </MDBCol>
                        </MDBRow>
                    </MDBCardTitle>
                </MDBCard>
            </MDBCol>
            <MDBCol size="12">
                <Conditions
                    disabled={!!rule[HLCFG_OFF]}
                    pathGetter={pathGetter}
                    smallSpacing={smallSpacing}
                />
            </MDBCol>
            <MDBCol size="12">
                <Action
                    disabled={!!rule[HLCFG_OFF]}
                    pathGetter={pathGetter}
                    smallSpacing={smallSpacing}
                />
            </MDBCol>
            <MDBCol
                size="12"
            >
                <MDBCard>
                    <MDBCardBody className={smallSpacing ? 'px-2 pb-1 pt-0' : 'pb-1 pt-0'}>
                        <MDBRow>

                            <MDBCol
                                lg="12"
                                md="12"
                                sm="12"
                            >
                                <Input
                                    className={classNames({ 'mb-1': smallSpacing })}
                                    label={t('packetFilter:comment')}
                                    rows={3}
                                    type="textarea"
                                    {...comment}
                                    disabled={rule.fake}
                                    onChange={rule.fake ? () => {} : comment.setWrappedValue}
                                    value={rule.fake ? t(comment.value ?? '') : comment.value}
                                />
                            </MDBCol>

                        </MDBRow>

                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>

    );
};
