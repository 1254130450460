/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { Input } from '~frontendComponents/Generic/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { getHlcfgOffableIsEnabled } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { setSshKeys, getSshKeys, getSchemaSshKeys } from '~frontendDucks/hlcfgEditor/index.js';
import glcfgDefinition from '~frontendLib/glcfgDefinition/index.js';


const SshInputBase = ({
    doSetSshKeys, sshKeys, glcfgKey = 'sshKeys', enabled
}) => (
    <Input
        disabled={!enabled}
        id="sshKeys"
        label={glcfgDefinition[glcfgKey].title}
        name="comment"
        onChange={({ value }) => doSetSshKeys(value)}
        rows={9}
        type="textarea"
        value={sshKeys}
    />
);


SshInputBase.propTypes = {
    doSetSshKeys: PropTypes.func.isRequired,
    sshKeys: PropTypes.string,
    glcfgKey: PropTypes.string,
    enabled: PropTypes.bool,
};


const mapStateToProps = state => ({
    sshKeys: getSshKeys(state),
    schemaSshKeys: getSchemaSshKeys(state),
    enabled: getHlcfgOffableIsEnabled(state, hlcfgPathGetter.services.sshd),
});

const mapDispatchToProps = {
    doSetSshKeys: setSshKeys,
};

const SshInput = connect(mapStateToProps, mapDispatchToProps)(SshInputBase);
export default SshInput;
