/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardBody, MDBCardHeader } from 'mdbreact';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Switch } from '~frontendComponents/Generic/index.js';
import { DNS } from '~frontendRoot/constants/index.js';
import { getDnsCache, setGlcfgObjectValue } from '~frontendRoot/ducks/hlcfgEditor/index.js';


const DnsCache = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dnsCacheAny = useSelector(state => getDnsCache(state));

    const onChange = useCallback(({ value, id }) => {
        dispatch(setGlcfgObjectValue(DNS, value, id));
    }, [ dispatch ]);

    return (
        <MDBCard>
            <MDBCardHeader>
                {t('widgets:Dns.dnsCache.title')}
            </MDBCardHeader>
            <MDBCardBody>
                {t('widgets:Dns.dnsCache.desc')}
                <Switch
                    align="spaceBetween"
                    checked={dnsCacheAny}
                    id="queryCacheAny"
                    label={t('widgets:Dns.dnsCache.title')}
                    name="dnsCache"
                    onChange={onChange}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default DnsCache;
