/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import 'core-js'; // this has to be first
import 'regenerator-runtime/runtime.js'; // this has to be right after core-js
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { Component, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { connect, Provider } from 'react-redux';
import { StaticRouter } from 'react-router';
import ReactNotifications from 'react-notifications-component';
import {
    QueryClientProvider,
} from '@tanstack/react-query';
import util from 'util';
import { Route, Switch } from 'react-router-dom';

import Handbook from '~handbook/index.js';
import initializeI18n from '~sharedLib/initializeI18n.js';
import { FORCED_SESSION_END_REASONS, NOT_AUTHENTICATED } from '~sharedConstants/index.ts';
import { getSessionTerminateReason } from '~frontendDucks/sessionsManagement/index.js';
import { setServerTimestamp } from '~frontendDucks/currentTime/index.js';
import { SimpleTranslationCtxProvider } from '~frontendComponents/SimpleTranslationCtxProvider.tsx';
import { queryClient } from '~frontendQueries/client.ts';

import { getInitialState } from './ducks/index.js';
import { notifyMissingMessage } from './ducks/i18next/index.js';
import { setLocalization } from './ducks/language/index.js';
import { getIsErrorStatusCode } from './ducks/statusCode/index.js';
import { getIsGuiLoadingDone } from './ducks/userAuthentication/ducks/guiLoading.js';
import { getIsLoggedIn, logoutRequest } from './ducks/userAuthentication/ducks/login.js';
import { Application, LoginPage } from './layout/index.js';
import { setOnSubSagaError } from './lib/combineSagas.js';
import { getReactWrapper, registerInstanceI18n } from './lib/reactUtils.js';
import { createStoreFunc } from './store/storeLib.js';
import getStore from './store/store.js';
import { runGlobalTask, updateCurrentOnRootSagaError } from './saga/sagaMiddleware.js';

// Import moment locales we use to prevent them being tree shaked
// en is imported by default.
import 'moment/locale/cs.js';

import './styles/index.scss';


// On the client, initializes the single page application (SPA).
// On the server, exports a functions that renders the initial HTML for server-side rendering (SSR).

@connect(
    (state) => ({
        isErrorStatusCode: getIsErrorStatusCode(state),
        isGuiLoadingDone: getIsGuiLoadingDone(state),
        isLoggedIn: getIsLoggedIn(state),
    })
)
class MainElement extends Component {
    static get propTypes() {
        return {
            isGuiLoadingDone: PropTypes.bool,
            isLoggedIn: PropTypes.bool,
            isErrorStatusCode: PropTypes.bool,
        };
    }

    render() {
        const { isErrorStatusCode, isGuiLoadingDone, isLoggedIn } = this.props;
        if (!isErrorStatusCode && isGuiLoadingDone && isLoggedIn) {
            document.body.classList.add('loggedIn');
            window['__react-beautiful-dnd-disable-dev-warnings'] = true; //removes warning in console
            return (
                <Switch>
                    <Route path="/handbook">
                        <HandbookView />
                    </Route>
                    <Route>
                        <Application />
                    </Route>
                </Switch>
            );
        }
        return <LoginPage />;
    }
}
const HandbookView = () => {
    const { t } = useTranslation();
    return <Handbook t={t} />;
};


/**
 * Returns main React application element.
 *
 * @param {object} params
 * @param {object} params.history - instance of a history object
 * @param {object} params.instanceI18n - instance of i18next
 * @param {object} params.initialState - initial redux state
 * @param {object} params.reqUrl
 * @returns {Component}
 */
const RootElement = ({ history, instanceI18n, initialState, reqUrl }) => {
    const props = initialState;
    createStoreFunc(history, props);
    const store = getStore();

    if (window.Cypress) { //testing
        window.appReady = true;
        window.store = store; // upgrade.cy.ts
        window.queryClient = queryClient;
    }

    axios.interceptors.response.use(function(response) {
        store.dispatch(setServerTimestamp(response.headers.date));
        return response;
    }, function(error) {
        const message = error?.response?.data?.message;
        const sessionTerminateReason = getSessionTerminateReason(store.getState());
        const isLoggedIn = getIsLoggedIn(store.getState());
        if (message in FORCED_SESSION_END_REASONS && !(sessionTerminateReason in FORCED_SESSION_END_REASONS)) {
            store.dispatch(logoutRequest({ alreadySignout: true, reason: message, statusCode: 401 }));

        }
        if (message === NOT_AUTHENTICATED && isLoggedIn) {
            store.dispatch(logoutRequest({ alreadySignout: true, reason: message, statusCode: 401 }));
        }
        return Promise.reject(error);
    });

    // rootSaga has to run on backend as well, otherwise the state would be slightly different due to state
    // initialization
    runGlobalTask();
    const ChosenRouter = reqUrl ? StaticRouter : ConnectedRouter;
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <I18nextProvider i18n={instanceI18n}>
                        <ChosenRouter
                            history={history}
                            location={reqUrl}
                        >
                            <SimpleTranslationCtxProvider>
                                <MainElement />
                            </SimpleTranslationCtxProvider>
                        </ChosenRouter>
                    </I18nextProvider>
                </QueryClientProvider>
            </Provider>
            <ReactNotifications.default />
        </Suspense>
    );
};

RootElement.propTypes = {
    history: PropTypes.object.isRequired,
    instanceI18n: PropTypes.object.isRequired,
    initialState: PropTypes.object.isRequired,
    reqUrl: PropTypes.string,
};
export const startUp = async (paramsForInitialState) => {
    const history = createBrowserHistory();
    const initialState = getInitialState(paramsForInitialState, history);
    const options = {
        ...paramsForInitialState.i18nOptions,
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            notifyMissingMessage(lng, ns, key, fallbackValue);
        }
    };
    const instanceI18n = initializeI18n([], options, false);
    registerInstanceI18n(instanceI18n);
    await setLocalization(initialState, instanceI18n);
    if (window.Cypress?.testingType === 'component') {
        return { history, initialState, instanceI18n };
    }

    const rootElement = (
        <RootElement
            history={history}
            initialState={initialState}
            instanceI18n={instanceI18n}
        />
    );
    const reactWrapper = getReactWrapper();
    if (initialState.useSSR) {
        ReactDOM.hydrate(rootElement, reactWrapper);
    } else {
        ReactDOM.render(rootElement, reactWrapper);
        removeLoadingPage(reactWrapper);
    }

};

const removeLoadingPage = (reactWrapper) => {
    const loadingPageSelector = ':scope > .loadingPage';
    // This shouldnt be needed, for us to explicitly remove the loadingPage, but it has been observed that in chrome,
    // the element is still there after refresh for unknown reason. So here we ensure that it is deleted.
    reactWrapper.querySelector(loadingPageSelector)?.remove();
    setTimeout(() => {
        // I am not sure what is happening. The loadingPage is in the html element (as logged by console log)
        // right after render, but this query selector returns null. So we wait a few milliseconds
        // and then reattempt removal. Sorry for the hacks. I do not know why it is like this... I can not do better :(
        reactWrapper.querySelector(loadingPageSelector)?.remove();
    }, 10);
    setTimeout(() => {
        reactWrapper.querySelector(loadingPageSelector)?.remove();
    }, 100);
};

/**
 *  Errors that are actually warnings and are out of our control. Under no circumstances, supress errors originating
 *  in our codebase, or errors that we can fix.
 */
const SUPRESSED_ERRORS = [
    // These 3 errors are coming from paged DataTableWidget. I have not found way to fix that, except upgrading MUI
    // which is not currently plausible.
    // eslint-disable-next-line max-len
    'Warning: Failed prop type: The prop `onChangePage` of `ForwardRef(TablePagination)` is deprecated. Use the `onPageChange` prop instead.',
    // eslint-disable-next-line max-len
    'Warning: Failed prop type: The prop `onChangeRowsPerPage` of `ForwardRef(TablePagination)` is deprecated. Use the `onRowsPerPageChange` prop instead.',
    // eslint-disable-next-line max-len
    'Warning: Failed prop type: The prop `onPageChange` is marked as required in `ForwardRef(TablePagination)`, but its value is `undefined`.',
];
if (typeof window !== 'undefined' && window.Cypress?.testingType !== 'component') {
    // eslint-disable-next-line no-console
    const origErr = console.error;
    // Hacking console.error is extremely dirty. But there is no other way as far as i know.
    // eslint-disable-next-line no-console
    console.error = (...args) => {
        // chopping off the stacktrace from the message.
        const message = util.format(...args.slice(0, -1));
        if (SUPRESSED_ERRORS.some(it => message.includes(it))) {
            return;
        }
        origErr(...args);
    };
    window.onload = startUp(window._PARAMS_FOR_INITIAL_STATE);
}

/**
 * Returns the entire application. Used on backend.
 *
 * @returns {object}
 */
const Frontend = ({ history, instanceI18n, initialState, reqUrl, onRootSagaError, onSubSagaError }) => {
    history.push(reqUrl);
    updateCurrentOnRootSagaError(onRootSagaError);
    setOnSubSagaError(onSubSagaError);
    return (
        <RootElement
            history={history}
            initialState={initialState}
            instanceI18n={instanceI18n}
            reqUrl={reqUrl}
        />
    );
};

Frontend.propTypes = {
    history: PropTypes.object.isRequired,
    instanceI18n: PropTypes.object.isRequired,
    initialState: PropTypes.object.isRequired,
    reqUrl: PropTypes.string.isRequired,
    onRootSagaError: PropTypes.func.isRequired,
    onSubSagaError: PropTypes.func.isRequired,
};

export default Frontend;
