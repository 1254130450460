/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Scene } from '~frontendComponents/Scene/index.js';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgService } from '~frontendComponents/Service/Service.tsx';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { suricataSceneMap } from '~frontendRoot/scenes/Protection/scenes/Ips/components/Suricata/pageObjectMap.ts';

import SuricataSettings from './components/SuricataSettings/SuricataSettings.tsx';
import SuricataUpdates from './components/SuricataUpdates/index.ts';
import BlackList from './components/BlackList/index.tsx';


const Suricata = () => {
    const { t } = useTranslation();
    const suricataPath = hlcfgPathGetter.protection.suricata.service;
    return (
        <Scene {...testProps(suricataSceneMap.id)}>
            <HlcfgService
                attention
                offOverlay={
                    <>
                        <h3>{t('ips:database.title')}</h3>
                        <p className="mb-2">
                            {t('ips:database.desc')}
                        </p>
                        <h3>{t('ips:suricataBlacklist.title')}</h3>
                        <p className="mb-2">
                            {t('ips:suricataBlacklist.desc')}
                        </p>
                        <h3>{t('ips:suricataWhitelist.title')}</h3>
                        <p className="mb-2">
                            {t('ips:suricataWhitelist.desc')}
                        </p>
                    </>
                }
                pathGetter={suricataPath}
                serviceTranslationPath="ips"
                translationFile={'ips'}
            >
                <MDBRow>
                    <MDBCol
                        className="mb-2 mb-lg-4"
                    >
                        <SuricataSettings />
                    </MDBCol>

                    <MDBCol
                        className="mb-2 mb-lg-4"
                    >
                        <SuricataUpdates />
                    </MDBCol>
                    <MDBCol
                        className="mb-2 mb-lg-4"
                    >
                        <BlackList
                            commentPath={suricataPath.blacklistComment}
                            idsList="alertList"
                            ipsList="suricataBlacklist"
                            valuePath={suricataPath.blacklist}
                        />
                    </MDBCol>
                    <MDBCol
                        className="mb-2 mb-lg-4"
                    >
                        <BlackList
                            commentPath={suricataPath.whitelistComment}
                            idsList="suricataWhitelist"
                            ipsList="suricataWhitelist"
                            valuePath={suricataPath.whitelist}
                        />
                    </MDBCol>

                </MDBRow>

            </HlcfgService>
        </Scene>
    );
};

export default Suricata;
