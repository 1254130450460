/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Reducer, useCallback, useEffect, useReducer, useRef } from 'react';
// eslint-disable-next-line node/file-extension-in-import
import { ActionMeta } from 'react-select';
import assert from 'assert';

import { isDeepEqual } from '~commonLib/objectUtils.ts';
import { useConstant } from '~frontendLib/hooks/defaultHooks.ts';
import { SelectOption, SelectV2InternalProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { createNotification } from '~frontendLib/reactUtils.js';


export const useSelectReducer = <T>(props: SelectV2InternalProps<T>) => {
    const initialState: SelectReducerState<T> = {
        valuesToSet: props.value,
        inputValue: '',
        focused: false,
    };
    type Reduce = Reducer<SelectReducerState<T>, SelectReducerAction<T>>

    const { onChange, value, parse, clipboardParse, prepareOption, stringify, stringifyForCopy } = props;
    const reduce: Reduce = useCallback((state, action) => {
        const valueToUse = value;
        switch (action.type) {
        case 'paste': {
            const inputValue = state.inputValue;
            if (inputValue !== '') {
                return {
                    ...state,
                    inputValue: inputValue + action.value,
                };
            }
            assert(clipboardParse);
            assert(prepareOption);
            try {
                const values = clipboardParse(action.value);
                if (values.length === 0) {
                    if (action.value && action.value.length <= 50) {
                        return {
                            ...state,
                            inputValue: action.value,
                        };
                    }
                    createNotification({ title: 'widgets:global.pasteFailed', type: 'danger' });
                    return state;
                }
                const existingValuesNoUndef = valueToUse.map(it => JSON.parse(JSON.stringify(it.value)));
                const newValues = values.filter(it => {
                    const itNoUndef = JSON.parse(JSON.stringify(it));
                    return existingValuesNoUndef.every(existingNoUndef => !isDeepEqual(existingNoUndef, itNoUndef));
                });
                if (newValues.length === 0) {
                    createNotification({ title: 'widgets:global.pasteNoNewValues', type: 'warning' });
                    return state;
                }
                const newValue = [ ...valueToUse, ...newValues.map(prepareOption) ];
                return {
                    ...state,
                    valuesToSet: newValue
                };
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
                createNotification({ title: 'widgets:global.pasteFailed', type: 'danger' });
                return state;
            }
        }
        case 'set-editing-item': {
            const newEditingPosition = action.value ? valueToUse.indexOf(action.value) : undefined;
            if (state.editingPosition === newEditingPosition) {
                return state;
            }
            const item = action.value;
            if (item?.notRemovable) {
                return state;
            }
            let inputValue = state.inputValue;
            if (item) {
                if (stringify) {
                    inputValue = stringify(item.value);
                } else if (typeof item.label === 'string') {
                    inputValue = item.label;
                } else {
                    throw new Error('Must provide stringify or labels must be string');
                }
            } else {
                inputValue = '';
            }
            return {
                ...state,
                editingPosition: newEditingPosition,
                inputValue,
            };
        }
        case 'set-input-value': {
            return {
                ...state,
                inputValue: action.value,
            };
        }
        case 'clear-input': {
            const stopEditState = reduce(state, { type: 'set-editing-item', value: undefined });
            if (stopEditState.editingPosition === state.editingPosition && state.inputValue === '') {
                return state;
            }
            return {
                ...stopEditState,
                inputValue: '',
            };
        }
        case 'set-focused': {
            if (action.value === state.focused) {
                return state;
            }
            if (action.value === false) {
                return {
                    ...reduce(state, { type: 'set-editing-item', value: undefined }),
                    focused: false,
                };
            }
            return {
                ...state,
                focused: true,
            };
        }
        case 'create-option': {
            assert(parse);
            assert(prepareOption);
            const parsed = parse(action.value);
            if (!parsed) {
                return state;
            }
            const theValue = parsed.parsed ?? parsed.suggest?.value;
            assert(theValue !== undefined);
            const newOption = prepareOption(theValue);
            if (state.editingPosition !== undefined) {
                return {
                    ...state,
                    inputValue: '',
                    editingPosition: undefined,
                    valuesToSet: valueToUse.toSpliced(state.editingPosition, 1, newOption),
                };
            }
            return {
                ...state,
                inputValue: '',
                valuesToSet: [ ...valueToUse, newOption ],
            };
        }
        case 'react-select-on-change': {
            const [ data, opts ] = action.value;
            let dataToSet = [ ...data ];
            if (opts.action === 'remove-value' || opts.action === 'pop-value') {
                if (opts.removedValue?.notRemovable) {
                    return state;
                }
            }
            if (state.editingPosition !== undefined) {
                if (opts.action === 'create-option' || opts.action === 'select-option') {
                    const addedItem = dataToSet.pop()!;
                    dataToSet.splice(state.editingPosition, 0, addedItem);
                } else if (opts.action === 'remove-value') {
                    dataToSet = valueToUse.filter(it => it !== opts.removedValue);
                } else {
                    throw new Error('Unsupported operation');
                }
            }

            return {
                ...state,
                inputValue: '',
                editingPosition: undefined,
                valuesToSet: dataToSet,
            };
        }
        case 'copy-to-clipboard': {
            assert(stringifyForCopy);
            const toCopy = valueToUse;
            assert(toCopy.length);
            // Having side effects inside reducer - very ugly, but it works if we
            // dont care about the result or errors outside of the reducer
            const stringified = stringifyForCopy(toCopy.map(it => it.value));
            if (stringified === '') {
                // When there are no values in select, copy button should not be available.
                // This should be only possible if there is static hlcfg reference that resolves to nothing.
                createNotification({ title: 'widgets:global.copyEmpty', type: 'danger' });
                return state;
            }
            void navigator.clipboard.writeText(stringified).then(() => {
                createNotification({ title: 'widgets:global.copied', type: 'info' });
            }).catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
                createNotification({ title: 'widgets:global.copyFailed', type: 'danger' });
            });
            return state;
        }
        default:
            throw new Error('Invalid action');
        }

    }, [ clipboardParse, parse, prepareOption, value, stringify, stringifyForCopy ]);

    const [ state, dispatch ] = useReducer(reduce, initialState);

    // I hate this block of code calling onChange and everything that is needed for it.
    // But see note about valuesToSet state property.
    // I wish I knew how to do this better without the error, or why the error actually happens.
    const { valuesToSet } = state;
    const newValuesOnChange = newValues => {
        if (newValues === props.value) {
            return;
        }
        onChange(newValues.map(it => it.value));
    };
    const change = useRef(newValuesOnChange);
    change.current = newValuesOnChange;
    useEffect(() => {
        change.current(valuesToSet);
    }, [ valuesToSet ]);

    const createDispatcher = <Type extends ActionType>(type: Type) =>
        (value: ActionValue<T, Type>) => dispatch({ type, value });
    const dispatchers = useConstant({
        paste: createDispatcher('paste'),
        setEditingPosition: createDispatcher('set-editing-item'),
        setInputValue: createDispatcher('set-input-value'),
        onChange: createDispatcher('react-select-on-change'),
        setFocused: createDispatcher('set-focused'),
        clearInput: createDispatcher('clear-input'),
        createOption: createDispatcher('create-option'),
        copyToClipboard: createDispatcher('copy-to-clipboard'),
    });

    return [ state, dispatchers ] as const;
};

type ReactSelectOnChange<T> = (data: SelectOption<T>[], opts: ActionMeta<SelectOption<T>>) => void

type Action<Name extends string, Value> = {type: Name, value: Value}

type SelectReducerAction<T> =
    Action<'paste', string> |
    Action<'react-select-on-change', Parameters<ReactSelectOnChange<T>>> |
    Action<'set-input-value', string> |
    Action<'create-option', string> |
    Action<'set-focused', boolean> |
    Action<'clear-input', void> |
    Action<'copy-to-clipboard', void> |
    Action<'set-editing-item', SelectOption<T>|undefined>;

type ActionValue<T, Type extends ActionType> = Extract<SelectReducerAction<T>, {type: Type, value: any}>['value'];
type ActionType = SelectReducerAction<any>['type'];

type SelectReducerState<T> = {
    /**
     * valuesToSet state property exists only to defer calling onChange callback to prevent this error:
     * Cannot update a component (`XXX`) while rendering a different component (`SelectV2`).
     *
     * This state property should not be used for any other purposes as it may get stale.
     */
    valuesToSet: SelectOption<T>[],
    editingPosition?: number|undefined,
    inputValue: string,
    focused: boolean,
}
