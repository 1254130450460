/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Cards, Icon, Overlay } from '~frontendComponents/Generic/index.js';
import {
    getWafProfilesIds, getWafProfileProps,
    duplicateFromNormalize,
    addToNormalize,
    setNormalize,
    setNormalizeOrder,
    getHlcfgRowUuid,
    deleteNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import { getActiveCardHook } from '~frontendDucks/activeCards/index.js';
import { DELETE_CONFIRM, MEDIUM_SIZE, WAF_PROFILES } from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import TurnedOff from '~frontendRoot/components/TurnedOff/index.js';
import { DEFAULT_RULE_NAME } from '~sharedConstants/index.ts';
import { areArraysEqual } from '~frontendRoot/lib/arrayUtils.js';

import ProfileRules from './components/ProfileRules/index.ts';
import ProfileSettings from './components/WafProfileSettings/index.ts';
import ProfileAddresses from './components/ProfileAddresses.tsx';


const Profiles = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const active = useSelector(getActiveCardHook(WAF_PROFILES));
    const ids = useSelector(getWafProfilesIds, areArraysEqual);

    const wafProfileProps = useSelector(getWafProfileProps);
    const setProfileParameters = useCallback(({ value, key }) => {
        dispatch(setNormalize({ uuid: active, value, key }));
    }, [ active, dispatch ]);


    const turnOffProfile = useCallback(({ value, uuid }) => {
        dispatch(setNormalize({ uuid: uuid || active, value, key: '__off' }));
    }, [ active, dispatch ]);


    const addProfile = useCallback(() => {
        const newUuid = getHlcfgRowUuid(WAF_PROFILES);
        dispatch(addToNormalize({
            type: WAF_PROFILES,
            typeId: WAF_PROFILES,
            newUuid,
        }));
        dispatch(addToNormalize({
            type: 'wafProfileRule',
            typeId: WAF_PROFILES,
            key: 'rules',
            uuid: newUuid,
            addingAfter: true,
            extraValues: {
                fake: true,
                action: 'block',
                name: DEFAULT_RULE_NAME,
            }
        }));
    }, [ dispatch ]);

    const deleteFunc = useCallback(({ uuid }) => {
        dispatch(deleteNormalize({ type: WAF_PROFILES, value: uuid }));
    }, [ dispatch ]);


    const copyService = useCallback(({ uuid }) => {
        dispatch(duplicateFromNormalize({
            uuid,
            parent: WAF_PROFILES
        }));
    }, [ dispatch ]);

    const deleteProfile = useCallback(({ uuid }) => {
        dispatch(setModalState({ value: true, modal: DELETE_CONFIRM, specialValues: {
            uuid, name: wafProfileProps[uuid]?.name,
            action: deleteFunc, service: WAF_PROFILES
        } }));
    }, [ dispatch, deleteFunc, wafProfileProps ]);


    const setOrder = useCallback(({ order }) => {
        dispatch(setNormalizeOrder({ type: WAF_PROFILES, value: order }));
    }, [ dispatch ]);

    return (
        <>
            <div className="userNavigation">
                <MDBBtn
                    className="navigation__button"
                    color="secondary"
                    id="addProfile"
                    onClick={addProfile}
                    size="sm"
                    type="button"
                >
                    <Icon name="plus" />
                    {t(`widgets:${WAF_PROFILES}.title`)}
                </MDBBtn>
            </div>
            <div>
                <Cards
                    copyService={copyService}
                    deleteService={deleteProfile}
                    ids={ids}
                    menuItemProps={wafProfileProps}
                    setOrder={setOrder}
                    setValue={setProfileParameters}
                    type={WAF_PROFILES}
                    wrapClassName="vpn__scene"
                />

                <div className="overlay--div">
                    <MDBRow>
                        <MDBCol
                            className={classNames('profile__profile')}
                        >
                            <MDBRow>
                                <MDBCol size="12">
                                    <ProfileSettings profile={active} />
                                </MDBCol>
                                <MDBCol size="12">
                                    {active && (
                                        <ProfileAddresses
                                            active={active}
                                            spacing={MEDIUM_SIZE}
                                        />
                                    )}
                                </MDBCol>
                                <MDBCol size="12">
                                    <ProfileRules />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <TurnedOff
                        disabled={wafProfileProps[active]?.__off}
                        onChange={turnOffProfile}
                    />
                </div>
                {!ids.length &&
                            <Overlay
                                active={!ids.length}
                            >
                                <div className="dhcpd__overlay">
                                    <h2>{t(`widgets:${WAF_PROFILES}.title`)}</h2>
                                    <p className="mb-2">
                                        {t(`widgets:${WAF_PROFILES}.desc`)}
                                    </p>
                                    <p className="dataTableWidget__Rowtext">
                                        {t(`widgets:${WAF_PROFILES}.desc1`)}
                                        <MDBBtn
                                            className="navigation__button ml-1"
                                            color="secondary"
                                            onClick={addProfile}
                                            size="sm"
                                            type="button"
                                        >
                                            <Icon name="plus" />
                                            {t(`widgets:${WAF_PROFILES}.title`)}
                                        </MDBBtn>

                                    </p>
                                    <MDBBtn
                                        className="navigation__button ml-1"
                                        color="secondary"
                                        onClick={addProfile}
                                        size="sm"
                                        type="button"
                                    >
                                        <Icon name="plus" />
                                        {t(`widgets:${WAF_PROFILES}.title`)}
                                    </MDBBtn>
                                </div>
                            </Overlay>}

            </div>
        </>
    );
};

export default Profiles;
