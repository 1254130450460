/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import {  MDBBtn } from 'mdbreact';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Draggable } from '@hello-pangea/dnd';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {  Icon, Select } from '~frontendComponents/Generic/index.js';
import {
    setNormalize,
    getTableSchemaGetter,
    getProfileAddressTable
} from '~frontendDucks/hlcfgEditor/index.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import {
    PROFILE_RULE_COLUMNS_DRAG,
    PROFILE_RULE_COLUMNS_PORT,
    PROFILE_RULE_COLUMNS_ADDRESSES,
    SELECTABLE_TABLE_WAF_ADDRESSES_POLICY,
    userSetting,
    TableSizeType } from '~frontendConstants/index.js';
import { HlcfgTableItemSchema } from '~frontendTypes/externalTypes.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import { useUserSetting } from '~frontendRoot/lib/hooks/userSettings.ts';
import RowDivider from '~frontendRoot/components/RowDivider.tsx';
import type { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { makeSelectSearchedTableItem } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';

import { SocketAddressesFuncContext } from '../SocketAddressesFuncContext.ts';


type RowProps = {
    uuid: HlcfgRowId,
    dataIndex: number,
    spacing: TableSizeType,
    search?: string,
};


const Row = ({ uuid, dataIndex, spacing, search }: RowProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const schemas: HlcfgTableItemSchema<'addressesTable'> = useSelector(getTableSchemaGetter(uuid));
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const data = useSelector(state => getProfileAddressTable(state, uuid), shallowEqual);

    const { add, copy, del, addressesType } = useContext(SocketAddressesFuncContext);
    const [ allColumns ] = useUserSetting(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] =
        getColumnsUtils(allColumns, SELECTABLE_TABLE_WAF_ADDRESSES_POLICY);


    const addRow = useCallback((addingAfter: boolean) => {
        add(uuid, addingAfter);
    }, [ uuid, add ]);

    const addFirstRow = useCallback(() => {
        addRow(false);
    }, [ addRow ]);

    const copyRow = useCallback(() => {
        copy(uuid);
    }, [ uuid, copy ]);

    const delRow = useCallback(() => {
        del(uuid);
    }, [ uuid, del ]);

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addRow, translation: 'profile:profiles.addressTable.add' },
    ]);

    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ value, key: name, uuid }));
    }, [ uuid, dispatch ]);

    if (!data?.id) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRule"
            >
                <td></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={5}
                >
                    <div className="pl-2">
                        <h2>{t(`profile:${addressesType}.addressTable.title`)}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t(`profile:${addressesType}.addressTable.desc1`)}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                onClick={addFirstRow}
                            >
                                <Icon name="plus" /> {t('profile:profiles.addressTable.add')}
                            </MDBBtn>
                            {t(`profile:${addressesType}.addressTable.desc2`)}
                        </p>
                        <p>
                            {t(`profile:${addressesType}.addressTable.desc3`)}
                        (
                            <Icon
                                name="menu"
                                size="sm"
                            />)
                            {t(`profile:${addressesType}.addressTable.desc4`)}

                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            data-cy="addAddressTable"
                            onClick={addFirstRow}
                        >
                            <Icon name="plus" /> {t('profile:profiles.addressTable.add')}
                        </MDBBtn>
                    </div>
                </td>
            </tr>
        );
    }
    const { addresses, ports, __off, id } = data;
    return (
        <Draggable
        //it needs string
            draggableId={String(uuid)}
            index={dataIndex}
            isDragDisabled={closed}
            key={uuid}
        >
            {(provided) => (
                <>
                    <RowDivider
                        id={id}
                        length={getColumnsSelectedLength()}
                        types={types}
                    />
                    <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames(
                            'dataTableWidget__Row',
                            'profiles__row',
                            { 'dataTableWidget__Row--disable': __off },
                            { 'dataTableWidget__Row--match': searchMatches },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },

                        )}
                        key={dataIndex}
                    >
                        {getColumnsShow(PROFILE_RULE_COLUMNS_DRAG) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--match': searchMatches },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                                {...provided.dragHandleProps}
                            >
                                <Icon
                                    name="drag"
                                    size="sm"
                                />
                            </td>
                        }
                        {getColumnsShow(PROFILE_RULE_COLUMNS_ADDRESSES) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <NetworkInterfaceDevice
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off}
                                    id="addresses"
                                    iface={addresses}
                                    isCreatable
                                    isMulti
                                    isRow
                                    name="addresses"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    schema={schemas.addresses}
                                    withAllValues
                                    withAllValuesNetwork
                                />
                            </td>
                        }
                        {getColumnsShow(PROFILE_RULE_COLUMNS_PORT) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { [`dataTableWidget__RowInput--${spacing}`]: spacing },
                                    )}
                                    disabled={__off}
                                    id={'portAddress' +  uuid}
                                    isCreatable
                                    isMulti
                                    isRow
                                    name="ports"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={setValue}
                                    schema={schemas.ports}
                                    value={ports}
                                />
                            </td>
                        }
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={copyRow}
                            deleteFunc={delRow}
                            id={'profileAddress' + uuid}
                            onChange={setValue}
                            spacing={spacing}
                        />
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={getColumnsSelectedLength()}
                        types={types}
                    />

                </>
            )}
        </Draggable>
    );
};


export default Row;
