/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {  MDBCardTitle, MDBCardBody, MDBRow, MDBCol, MDBBtn, MDBCard } from 'mdbreact';
import classNames from 'classnames';

import {
    getQoS,
    getQoSService,
    setNormalize,
    addToNormalize,
    duplicateFromNormalize,
    getLowestPossibleClassId,
    getQoSNode,
    getTableSchema
} from '~frontendDucks/hlcfgEditor/index.js';
import { DELETE_CONFIRM, MEGABIT_S, QOS_NODE_TABLE_TYPE, QOS
} from '~frontendConstants/index.js';
import { setModalState } from '~frontendDucks/modals/index.js';
import { Input, Icon } from '~frontendComponents/Generic/index.js';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import { setTreeChartHidden, getTreeChartHiddenNode } from '~frontendDucks/treeChartHidden/index.js';
import { hlcfgTableNameByRowId } from '~sharedLib/hlcfgTableUtils.ts';


const makeMapStateToProps = () => {
    const getNode = getQoSNode();
    const mapStateToProps = (state, { uuid, secondTreeParent }) => {
        return {
            data: getNode(state, uuid),
            activeQoS: getQoSService(state),
            schema: getTableSchema(state, uuid),
            parent: getQoS(state, secondTreeParent),
            hidden: getTreeChartHiddenNode(state, QOS, uuid),
            lowestPossibleClassId: getLowestPossibleClassId(state)
        };
    };
    return mapStateToProps;
};

@withTranslation()
@connect(
    makeMapStateToProps,
    {
        add: addToNormalize,
        setValue: setNormalize,
        copyService: duplicateFromNormalize,
        doSetModal: setModalState,
        hide: setTreeChartHidden,
    }
)
class QoSLeaf extends Component {
    static get propTypes() {
        return {
            data: PropTypes.object,
            uuid: PropTypes.string,
            setValue: PropTypes.func,
            copyService: PropTypes.func,
            doSetModal: PropTypes.func,
            add: PropTypes.func,
            t: PropTypes.func,
            activeQoS: PropTypes.object,
            secondTreeParent: PropTypes.string,
            parent: PropTypes.object,
            hide: PropTypes.func,
            hidden: PropTypes.bool,
            parentUuid: PropTypes.string,
            lowestPossibleClassId: PropTypes.number,
            schema: PropTypes.object,
        };
    }

    addNode = () => {
        const { uuid, data,  hide, add, lowestPossibleClassId } = this.props;
        hide({ uuid, type: QOS, value: undefined });
        add({
            key: 'childrenIds',
            type: hlcfgTableNameByRowId(uuid),
            uuid: data.id,
            uuidToAddBefore: data.childrenIds?.[data.childrenIds?.length - 1],
            addingAfter: true,
            successText: 'widgets:QoS.added',
            extraValues: {
                ceilBandwidthMbit: data.ceilBandwidthMbit,
                classId: lowestPossibleClassId
            }
        });
    };

    deleteService = () => {
        const { doSetModal, uuid, data } = this.props;
        doSetModal({ modal: DELETE_CONFIRM, value: true, specialValues: { uuid, name: data.name,
            action: this.delete, service: data.childrenIds?.length ? 'QoSNode.withChildrens' :
                'QoSNode.withoutChildrens' } });
    };

    delete = () => {
        const { uuid, setValue, parentUuid } = this.props;
        if (parentUuid.startsWith(QOS_NODE_TABLE_TYPE)) {
            setValue({ uuid: parentUuid, value: uuid, key: 'childrenIds' });
        } else {
            setValue({ uuid: parentUuid, value: undefined, key: 'rootNodeId' });
        }
    };

    copyService = () => {
        const { copyService, uuid } = this.props;
        copyService({ uuid });
    };


    changeValue = ({ value, name,  }) => {
        const { setValue, uuid } = this.props;
        setValue({ value, uuid, key: name, });
    };

    hideOrShow = () => {
        const { hide, hidden, uuid } = this.props;
        hide({ uuid, type: QOS, value: !hidden });
    };

    render() {
        const { t, data, activeQoS, hidden, secondTreeParent, parent, schema
        } = this.props;
        if (!data) {
            return null;
        }
        const {
            name,
            guaranteedBandwidthMbit,
            ceilBandwidthMbit, childrenIds, id, canBeUsed } = data;
        return (
            <MDBCard
                className="treeChart__card"
                style={
                    parent.color || activeQoS.color ? {
                        boxShadow: `0 3px 6px ${canBeUsed < 0 ? 'red' : parent.color || activeQoS.color}`
                    } : {}
                }
            >

                <MDBCardTitle className="cardHide__title">
                    <div className="flex-center">
                        <IconWithTooltip
                            className="m-1"
                            iconSize="sm"
                            link
                            name={childrenIds?.length ? 'source-branch' : 'leaf'}
                            tooltipText={t(`widgets:QoS.${childrenIds?.length ? 'sourceBranch' : 'leaf'}.title`)}
                            withoutTranslation
                        />
                        <Input
                            className="treeChart__card__title"
                            disabled={secondTreeParent}
                            id={'name' + id}
                            name="name"
                            onChange={this.changeValue}
                            schema={schema?.name}
                            value={name}
                            withoutBorder
                            withoutPaddingLeft
                        />
                    </div>
                    <div className="display--flex">
                        {Boolean(childrenIds?.length) && !isNaN(canBeUsed) &&
                        <MDBCol className="pt-2 pr-0 pl-0">
                            <TextWithTooltip
                                className={classNames('treeChart__ActionText', { 'color--red': canBeUsed < 0 })}
                                defaultClass={false}
                                text={`${canBeUsed}${MEGABIT_S}`}
                                tooltipText={t('widgets:QoS.unused')}
                                withoutTranslation
                            />
                        </MDBCol>
                        }
                        {!secondTreeParent &&
                        <MDBCol className="profile__icons pt-2">
                            <IconWithTooltip
                                className="treeChart__ActionIcons icon--red"
                                iconSize="sm"
                                link
                                name="trash-can-outline"
                                onClick={this.deleteService}
                                tooltipText={t('widgets:global.delete')}
                                withoutTranslation
                            />
                        </MDBCol>
                        }
                    </div>
                </MDBCardTitle>
                <MDBCardBody className={childrenIds?.length ? 'pb-0' : ''}>
                    <MDBRow>
                        <MDBCol>
                            <Input
                                className="treeChart__input"
                                disabled={secondTreeParent}
                                endText={MEGABIT_S}
                                id={'guaranteedBandwidthMbit' + id}
                                label={t('widgets:QoS.guaranteedBandwidthMbit.title')}
                                name="guaranteedBandwidthMbit"
                                number
                                onChange={this.changeValue}
                                value={guaranteedBandwidthMbit}
                                withoutBorder
                            />

                        </MDBCol>
                        <MDBCol>
                            <Input
                                className="treeChart__input"
                                disabled={secondTreeParent}
                                endText={MEGABIT_S}
                                id={'ceilBandwidthMbit' + id}
                                label={t('widgets:QoS.ceilBandwidthMbit.title')}
                                name="ceilBandwidthMbit"
                                number
                                onChange={this.changeValue}
                                value={ceilBandwidthMbit}
                                withoutBorder
                            />
                        </MDBCol>
                    </MDBRow>
                    {Boolean(childrenIds?.length) &&
                    <MDBRow>
                        <MDBCol>

                            <IconWithTooltip
                                link
                                name={hidden ? 'chevron-down' : 'chevron-up'}
                                onClick={this.hideOrShow}
                                tooltipText={t(`widgets:QoS.${hidden ? 'show' : 'hide'}`)}
                            />
                            {hidden &&
                            <div className="treeChart__number">
                                {childrenIds?.length}
                            </div>}
                        </MDBCol>
                    </MDBRow>
                    }
                    {!secondTreeParent &&
                    <MDBRow className="treeChart__Add">
                        <div className="treeChart__AddButtons">
                            <MDBBtn
                                className="treeChart__AddButtons--button"
                                onClick={this.addNode}

                            >
                                <Icon name="plus" /> {t('widgets:QoS.node')}
                            </MDBBtn>
                        </div>
                    </MDBRow>
                    }
                </MDBCardBody>
            </MDBCard>

        );
    }
}

export default QoSLeaf;
