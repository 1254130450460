/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCol, MDBBtn } from 'mdbreact';
import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import assert from 'assert';

import { Icon, InputFile } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import { VPN_SETTING_MENU_ADVANCED, VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE } from '~frontendRoot/constants/index.js';
import { hlcfgRowIdIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { MDBColSizeProp, MDBColSizeType } from '~frontendConstants/types.ts';
import { HlcfgSelect, HlcfgSwitch, HlcfgSwitchProps } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { InterfaceTypeSelect } from '~frontendComponents/Generic/HlcfgInput/InterfaceTypeSelect.tsx';
import { HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { poDef, testProps, testPropsStatus } from '~commonLib/PageObjectMap.ts';


export const VpnSwitch = (
    { size, ...switchProps }: {size?: MDBColSizeType} & HlcfgSwitchProps
) => {
    return (
        <MDBCol lg={size}>
            <HlcfgSwitch
                align="spaceBetween"
                {...switchProps}
            />
        </MDBCol>
    );
};


const VpnInputPropTypes = {
    id: PropTypes.string,
    uuid: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.shape({
        name: PropTypes.string,
    }),
    func: PropTypes.func,
    __off: PropTypes.bool,
    hlcfgPath: PropTypes.string
};

export const VpnFileInput = (
    { id, name, func, value, __off, uuid, hlcfgPath }: InferProps<typeof VpnInputPropTypes>
) => {
    const translationKey = uuid && hlcfgRowIdIsFromTable(uuid, hlcfgTableName.openvpnClient) ? 'client' + id : id;
    return (
        <InputFile
            className="license__upload"
            disabled={Boolean(__off)}
            fakeFile={value?.name}
            hlcfgPath={`${hlcfgPath}.${name}.${id}`}
            id={id}
            label={<Message message={`widgets:Vpn.files.${translationKey}.title`} />}
            name={name}
            onChange={func}
            renderId={uuid}
        />
    );
};
VpnFileInput.propTypes = VpnInputPropTypes;


export type VpnPathGetter = HlcfgRowPathGetter<'openvpnRas'|'openvpnClient'>
export type VpnRasPathGetter = HlcfgRowPathGetter<'openvpnRas'>
export type VpnClientPathGetter = HlcfgRowPathGetter<'openvpnClient'>
export const VpnTlsAuthOptionButton = ({ pathGetter, option }: {
    pathGetter: VpnPathGetter['tlsAuth']['option']
    option: '1'|'0'|''
}) => {
    const { valueOrDefault, setValue } = useHlcfgValue(pathGetter);
    return (
        <MDBBtn
            color={valueOrDefault === option ? 'primary' : undefined}
            onClick={() => {
                setValue(option);
            }}
            outline={valueOrDefault !== option}
            {...testPropsStatus(
                poDef.pathId(pathGetter.getPath()) + `-${option}`,
                valueOrDefault === option ? 'active' : 'inactive'
            )}
        >
            {option !== '' ? option : <Message message="widgets:Vpn.tlsAuth.option.nullValue" />}
        </MDBBtn>
    );
};


const getMenuIcon = (item) => {
    switch (item) {
    case VPN_SETTING_MENU_FILES:
        return 'file-upload-outline';
    case VPN_SETTING_MENU_ROUTE:
        return 'table-icon';
    case VPN_SETTING_MENU_ADVANCED:
        return 'settings-outline';
    default:
        throw new Error('Unknown item type');
    }
};

const MenuItemPropTypes = {
    item: PropTypes.string.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
};

const MenuItem = ({ item, selected }: InferProps<typeof MenuItemPropTypes>) => {
    return (
        <div
            className={classNames('scrollMenu__item',
                'card-title',
                'scrollMenu__item--primary',
                { 'scrollMenu__item--selected': selected })}
            {...testPropsStatus(item, selected ? 'active' : 'inactive')}
        >
            <Icon
                className="icon--textColor mr-1"
                name={getMenuIcon(item)}
                size="sm"
            />
            <Message message={`widgets:Vpn.${item}.title`} />
        </div>
    );
};
MenuItem.propTypes = MenuItemPropTypes;

const CreateMenuPropTypes = {
    selected: PropTypes.string,
};

export const CreateMenu = ({ selected }: InferProps<typeof CreateMenuPropTypes>) => {
    return (
        [ VPN_SETTING_MENU_FILES, VPN_SETTING_MENU_ROUTE, VPN_SETTING_MENU_ADVANCED ].map(
            item => {
                return (
                    <MenuItem
                        item={item}
                        key={item}
                        selected={selected}
                        {...testProps(item)}
                    />
                );
            }
        )
    );
};
CreateMenu.propTypes = CreateMenuPropTypes;


export const GetIcon = () => {
    return (
        <Icon
            className="vpn__chevron"
            name="chevron-right"
        />
    );
};


const GetSelectWithOptionsPropTypes = {
    pathGetterVpn: PropTypes.any,
    size: MDBColSizeProp
};

export const GetSelectWithOptions = ({ pathGetterVpn, size }: InferProps<typeof GetSelectWithOptionsPropTypes>) => {
    const lg = size ?? '2';
    assert(lg !== 'auto', 'auto not supported for lg');
    return (
        <>
            <MDBCol lg={lg}>
                <HlcfgSelect
                    label={<Message message="widgets:Vpn.interfaceTopology.title" />}
                    message="dev topology"
                    pathGetter={pathGetterVpn.interfaceTopology}
                />
            </MDBCol>
            <MDBCol lg={lg}>
                <InterfaceTypeSelect
                    label={<Message message="widgets:Vpn.wanLan.title" />}
                    pathGetter={pathGetterVpn}
                />
            </MDBCol>
        </>
    );
};

GetSelectWithOptions.propTypes = GetSelectWithOptionsPropTypes;
