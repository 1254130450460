/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    setGlcfgProxyValue,
    getProxyWpadExceptions, getProxyWpadExceptionsSchema, getProxyWpadAddresses, getProxyWpadAddressesSchema,
    getProxyWpadOff
} from '~frontendDucks/hlcfgEditor/index.js';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import { stringifyAddress } from '~frontendLib/addressUtils.ts';
import { STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN } from '~sharedLib/staticHlcfgReferenceUtils.ts';
import { Switch } from '~frontendComponents/Generic/index.js';


const Wpad = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const wpadExceptions = useSelector(getProxyWpadExceptions);
    const wpadAddresses = useSelector(getProxyWpadAddresses);
    const wpadAddressesSchema = useSelector(getProxyWpadAddressesSchema);
    const wpadExceptionsServerSchema = useSelector(state => getProxyWpadExceptionsSchema(state, 'server'));
    const wpadExceptionsClientSchema = useSelector(state => getProxyWpadExceptionsSchema(state, 'client'));
    const __off = useSelector(getProxyWpadOff);


    const setWpadExceptions = useCallback(({ value, name }) => {
        dispatch(setGlcfgProxyValue(value, name, null, 'setWpadExceptions'));
    }, [ dispatch ]);

    const setWpadAddresses = useCallback(({ value, name }) => {
        dispatch(setGlcfgProxyValue(value, name, null, 'setWpadAddress'));
    }, [ dispatch ]);

    const setWpadOff = useCallback(({ value, name }) => {
        dispatch(setGlcfgProxyValue(value, 'wpad', name, 'objectChange'));
    }, [ dispatch ]);


    return (
        <MDBCard>
            <MDBCardTitle className={classNames(
                'profiles__title',
                'wpad__title',
                { 'wpad__title--disabled': __off },
            )}
            >
                <div>
                    {t('proxy:proxy.wpad.title')}
                </div>
                <div className="profiles__titleActions">
                    <Switch
                        align="left"
                        checked={__off}
                        className={classNames(
                            'mb-0',
                            'minHeight-unset'
                        )}
                        datacy="toggleWpad"
                        id={'__offWpad'}
                        mini
                        name={'__off'}
                        onChange={setWpadOff}
                        withoutBorder
                        withoutLabelMargins
                        withoutMinWidhtHeight
                    />
                </div>
            </MDBCardTitle>
            <MDBCardBody>
                <div
                    className={classNames('overlay', 'overlay--secondary', 'overlay--card', 'overlay--absolute',
                        { 'overlay--active': __off })}
                >
                </div>
                <MDBRow>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <NetworkInterfaceDevice
                            disabled={__off}
                            id="addresses"
                            iface={stringifyAddress(wpadAddresses)}
                            isCreatable
                            isMulti
                            label={t(`packetFilter:staticRef.${STATIC_HLCFG_REFERENCE_NAME_WPAD_LISTEN}.title`)}
                            name="addresses"
                            onChange={setWpadAddresses}
                            schema={wpadAddressesSchema}
                            withAllValues
                            withAllValuesNetwork
                        />
                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <NetworkInterfaceDevice
                            disabled={__off}
                            id="proxyWpadServer"
                            iface={stringifyAddress(wpadExceptions.server)}
                            isCreatable
                            isMulti
                            label={t('proxy:proxy.wpad.server.title')}
                            name="server"
                            noDropdownIndicator
                            noOptionsMessage
                            onChange={setWpadExceptions}
                            schema={wpadExceptionsServerSchema}
                        />
                    </MDBCol>
                    <MDBCol
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <NetworkInterfaceDevice
                            disabled={__off}
                            id="proxyWpadClient"
                            iface={stringifyAddress(wpadExceptions.client)}
                            isCreatable
                            isMulti
                            label={t('proxy:proxy.wpad.client.title')}
                            name="client"
                            noDropdownIndicator
                            onChange={setWpadExceptions}
                            schema={wpadExceptionsClientSchema}

                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );

};

export default Wpad;
