/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, MDBRow, MDBCol, } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import IconWithPopover from '~frontendComponents/IconWithPopover/index.js';
import { PathGetter } from '~commonLib/objectUtils.ts';
import { HlcfgTextInput, HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';


type List = 'alertList'|'suricataBlacklist'|'suricataWhitelist';
const BlackList = ({ ipsList, idsList, commentPath, valuePath }: {
    ipsList: List, idsList: List, commentPath: PathGetter, valuePath: PathGetter
}) => {
    const { t } = useTranslation();
    const isIps = useHlcfgValue(hlcfgPathGetter.protection.suricata.service.ipsMode);
    const list = isIps ? ipsList : idsList;
    return (
        <>
            <MDBCard>
                <MDBCardTitle className="ips__cardTitle">
                    {t(`ips:${list}.title`)}
                    <IconWithPopover
                        body={
                            t(`ips:${list}.desc`)
                        }
                        divClassName="ml-1"
                        header={
                            t(`ips:${list}.title`)
                        }
                        iconSize="sx"
                        name="information-outline"
                    />
                </MDBCardTitle>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <HlcfgSelect
                                label={
                                    t(`ips:${list}.title`)
                                }
                                pathGetter={valuePath}
                            />
                        </MDBCol>
                        <MDBCol
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <HlcfgTextInput
                                label={t('ips:comment')}
                                pathGetter={commentPath}
                                rows={3}
                                type="textarea"
                            />
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </>
    );
};

export default BlackList;
