/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardBody } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { ValuesType } from 'utility-types';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { makeSelectGetCategoriesTranslation, getSuperCategoriesObjectSelector } from '~frontendRoot/ducks/policy/index.ts';
import { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';
import { InputSearch, Switch } from '~frontendComponents/Generic/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { getStringMatch } from '~frontendLib/stringUtils.js';
import { setNormalize } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { PROFILE_CATEGORIES, DEFAULT_PROFILE_CATEGORY_ENABLE } from '~sharedConstants/constants.ts';
import { objectKeys } from '~commonLib/objectUtils.ts';
import { SMALL_SIZE, TableSizeType } from '~frontendConstants/index.js';


const areAllValuesTrue = (data: HlcfgTableItem<'profileRule'>['categories'], categories) => {
    return categories.every((key) => data?.[key] ?? DEFAULT_PROFILE_CATEGORY_ENABLE);
};

interface CategoriesProps {
    uuid: string,
    data: HlcfgTableItem<'profileRule'>['categories'],
    spacing: TableSizeType
}

const Categories = ({ uuid, data, spacing }: CategoriesProps) => {
    const [ search, setSearch ] = useString('');
    const categories = useSelector(getSuperCategoriesObjectSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ type: 'profileRules',
            value: value === DEFAULT_PROFILE_CATEGORY_ENABLE ? undefined : value, key: 'categories', subkey: name,
            uuid, }));
    }, [ uuid, dispatch ]);
    const changeAllInSuperCategory = useCallback(({ value, name }) => {
        categories[name].forEach((key) => {
            setValue({ value, name: key });
        });
    }, [ categories, setValue ]);
    return (
        <MDBRow className="p-2 vertical">
            {objectKeys(categories).map((category) => {
                const allValues = areAllValuesTrue(data, categories[category]);
                return (
                    <MDBCol
                        key={category}
                        size="12"
                    >
                        <MDBCard>
                            <MDBCardTitle className={classNames('profiles__title',
                                `profiles__title--${spacing}`)}
                            >
                                <Switch
                                    align="left"
                                    checked={allValues}
                                    id={category + uuid}
                                    label={t(`policy:superCategories.${category}`)}
                                    name={category}
                                    onChange={changeAllInSuperCategory}
                                    withoutBorder
                                    withoutFlexWrap
                                    wrap={false}
                                />
                                {category === 'security-risk' && (

                                    <InputSearch
                                        className="mb-0 mt-0"
                                        id="searchValueIDVlans"
                                        search={search}
                                        setter={setSearch}
                                    />
                                )
                                }
                            </MDBCardTitle>
                            <MDBCardBody  className={classNames('profiles__body',
                                `profiles__body--${spacing}`)}
                            >
                                <MDBRow>

                                    {categories[category].map((subcategory: ValuesType<typeof PROFILE_CATEGORIES>) => {
                                        return (
                                            <TranslateCategory
                                                category={subcategory}
                                                change={setValue}
                                                //if category is not in data, it is default value
                                                checked={data?.[subcategory] === undefined ?
                                                    DEFAULT_PROFILE_CATEGORY_ENABLE :
                                                    data?.[subcategory]}
                                                key={subcategory}
                                                search={search}
                                                spacing={spacing}
                                                uuid={uuid}
                                            />
                                        );
                                    })
                                    }
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                );
            })
            }
        </MDBRow>
    );
};

export default Categories;


interface TranslateCategoryType  {
    category: ValuesType<typeof PROFILE_CATEGORIES>,
    checked: boolean,
    change: (value: { name: string, value: boolean }) => void,
    uuid: string,
    search: string,
    spacing: TableSizeType
}

const TranslateCategory = ({ category, checked, change, uuid, search, spacing }: TranslateCategoryType) => {
    const getter = useMemo(makeSelectGetCategoriesTranslation, []);
    const data = useSelector(state => getter(state, category));
    if (search && !getStringMatch({ toMatch: data.title, searchValue: search })) {
        return null;
    }
    return (
        <MDBCol
            className="p-1"
            key={category}
            size={spacing === SMALL_SIZE ? '2' : '3'}
        >
            <Switch
                align="spaceBetween"
                checked={checked}
                id={category + uuid}
                label={data.title}
                mini
                name={category}
                onChange={change}
                tooltipText={data.doc}
                withoutFlexWrap
                withoutTranslation
                wrap={false}
            />
        </MDBCol>
    );
};
