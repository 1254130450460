/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createSelector } from 'reselect';
import getValue from 'get-value';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { Offable } from '~frontendConstants/types.ts';
import { stringifyAddress } from '~frontendRoot/lib/addressUtils.ts';
import { EMPTY_IMMUTABLE_ARR, EMPTY_IMMUTABLE_OBJ, OPENVPN_USER_TYPE_S2S } from '~sharedConstants/constants.ts';
import { netport } from '~sharedLib/Netport/Netport.ts';
import { hlcfgRowIdIsFromTable, hlcfgRowObjectIsFromTable, hlcfgTableName } from '~sharedLib/hlcfgTableUtils.ts';
import { getObjectCrawler } from '~commonLib/objectUtils.ts';
import { addSuffixToUuid, getStringMatch, getSuffixFromUuid, getUuidFromSuffixedUuid } from '~frontendRoot/lib/stringUtils.js';
import { BRIDGE_TYPE, DNS_PROXY_PROFILES, IPV6_ENABLED, NODE_A_ID, NODE_B_ID, OPENVPN_USER, PROFILES, PROXY, SSHD_SOCKETS, WAF_PROFILES } from '~frontendRoot/constants/index.js';
import { ClusterOwnSelector, NODE_SHARED } from '~commonLib/constants.ts';
import { stringifyAsNetaddr, netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { areArraysEqual } from '~frontendRoot/lib/arrayUtils.js';
import { splitDhcpPoolForCluster } from '~sharedLib/dhcp/splitDhcpPoolForCluster.ts';
import { isNamedObject, namedObjectToString } from '~sharedLib/namedObjectUtils.ts';
import { generateRouteForOpenVpnUser } from '~sharedLib/openvpnUtils.ts';
import { useNetworkInfoQuery, useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { HlcfgSchemaJSON, HlcfgTableItem, HlcfgTypeDhcpPool, HlcfgTableDirtyItem  } from '~frontendRoot/types/externalTypes.ts';

import { getTableItem, doesntExists, getInterfaceItems,
    isFromConfiguration, getIsCluster, getTableItemInit, getTableById } from './glcfgGettersAndSetters.js';
import { getMyNode } from '../clusterSetup/index.js';
import { getGlcfgValue } from './glcfgGettersAndSettersUtils.ts';
import { getNamedObjectNetaddrAllValues, getNamedObjectNetaddrScalarById, getNamedObjectNetaddrVectorById } from './namedObjectsGettersAndSetters.ts';
import { getActiveCard } from '../activeCards/index.js';
import { getCwdbSchema } from './hlcfgEditor.ts';


export const makeSelectGetWafProfileRule = () =>
    createSelector(
        (state, uuid: string) => getTableItem(state, uuid),
        (item: Offable<'wafProfileRule'> | Offable<'profileHeader'>) => {
            if (doesntExists(item)) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            if (hlcfgRowObjectIsFromTable(item, 'profileHeader')) {
                return item;
            }
            if (hlcfgRowObjectIsFromTable(item, 'wafProfileRule')) {
                return {
                    ...item,
                    server: stringifyAddress(item.server),
                    client: stringifyAddress(item.client),
                    sendToServerAddr: stringifyAddress(item.sendToServerAddr),
                    sendToServerPort: item.sendToServerPort ? [ netport(item.sendToServerPort).toString() ] : [],
                };
            }
        }
    );

export const makeSelectGetDnsProxyProfileRule = (uuid: string) =>
    createSelector(
        [ (state) => getTableItem(state, uuid) ],
        (item: Offable<'dnsProxyRule'>) => {
            if (doesntExists(item)) {
                return undefined;
            }
            if (hlcfgRowObjectIsFromTable(item, 'dnsProxyRule')) {
                return {
                    ...item,
                    matchQname: stringifyAddress(item.matchQname),
                    matchSrc: stringifyAddress(item.matchSrc),
                    actionFakeAddr: stringifyAddress(item.actionFakeAddr),
                    actionNsList: stringifyAddress(item.actionNsList),
                };
            }
        }
    );
export const makeSelectGetDnsProxyProfileHeader = (uuid: string) =>
    createSelector(
        [ (state) => getTableItem(state, uuid) ],
        (item: Offable<'dnsProxyHeader'>) => {
            if (doesntExists(item)) {
                return undefined;
            }
            if (hlcfgRowObjectIsFromTable(item, 'dnsProxyHeader')) {
                return item;
            }
        }
    );

export const makeSelectGetProfileRule = () =>
    createSelector(
        (state, uuid: string) => getTableItem(state, uuid),
        (item: Offable<'profileRule'> | Offable<'profileHeader'> | Offable<'profileSpecialItem'>) => {
            if (doesntExists(item)) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            if (hlcfgRowObjectIsFromTable(item, 'profileHeader') ||
            hlcfgRowObjectIsFromTable(item, 'profileSpecialItem')) {
                return item;
            }
            if (hlcfgRowObjectIsFromTable(item, 'profileRule')) {
                return {
                    ...item,
                    server: stringifyAddress(item.server),
                    client: stringifyAddress(item.client),
                };
            }
        }
    );

export const getDnsProxyActiveProfile = (state) => {
    const item: HlcfgTableItem<'dnsProxyProfile'> = getTableItem(state, getActiveCard(state, DNS_PROXY_PROFILES));
    if (!item) {
        return undefined;
    }
    return item;
};

export const getCwVersion = (rootState) => {
    return getGlcfgValue(rootState, PROXY)?.cwdb?.version;
};

export const getCwdbV2Enabled = createSelector([
    (state) => getCwVersion(state),
    getCwdbSchema,
], (version, defaultSchemaValue: HlcfgSchemaJSON) => {
    return (version ? version : defaultSchemaValue?.properties?.version?.[DEFAULT_SCHEMA_VALUE]) === 'v2';
});

export const useDhcpOnExternal = () => {
    const { data: networkInfo } = useNetworkInfoQuery();
    const interfaceItems = useSelector(getInterfaceItems) as any[];
    const externalHasDhcp = interfaceItems.some(item => item.isExternal && item.dhcp);
    return externalHasDhcp ? networkInfo : undefined;
};

/**
 * Global exception for search in table items.
 */
const exceptionsToMatch = [ 'stateA', 'stateB', 'physicalLayerUpA', 'physicalLayerUpB',
    'type', 'color', 'address6', 'mac', 'id' ];


const twoArrayContainsSameItem = (arr1: string[], arr2: string[]) => {
    for (const item of arr1) {
        if (arr2.includes(item)) {
            return true;
        }
    }
    return false;
};

// It would be much better to put "search" into redux.
// That would make it not nenessary to create new selector on every search change.
// While all the search functions would still run, only the rows that actually changed search-match result
// would actually re-render.
// Maybe some day...
export const makeSelectSearchedTableItem = (uuid: string, search = '', exceptions = exceptionsToMatch) =>
    createSelector(
        [
            (state) => getNamedObjectNetaddrVectorById(state),
            (state) => getNamedObjectNetaddrScalarById(state),
            (state) => getNamedObjectNetaddrAllValues(state),
            (state) => getTableItem(state, uuid),
        ],
        (vectorTable, scalarTable, allValues, item): boolean => {
            const netAddresObjectTable = { ...vectorTable, ...scalarTable };
            if (!search) {
                return false;
            }
            let found = false;
            const crawler = getObjectCrawler({
                matchValue: (val, path) => {
                    if (typeof val === 'number') {
                        return val.toString().includes(search);
                    }
                    if (typeof val !== 'string' || twoArrayContainsSameItem(exceptions, path)) {
                        return;
                    }
                    if (!val.includes('netaddrScalar') && !val.includes('netaddrVector')) {
                        return getStringMatch({ toMatch: val, searchValue: search });
                    }
                    if (!isNamedObject(val)) {
                        return;
                    }
                    const objectString = namedObjectToString(val);
                    const netAddresObject = netAddresObjectTable[objectString];
                    const normalizedArray = allValues[objectString].flat();
                    return (
                        getStringMatch({ toMatch: netAddresObject.name, searchValue: search }) ||
                        normalizedArray.find(item => {
                            return getStringMatch({ toMatch: item, searchValue: search });
                        })
                    );
                },
                onValueMatched: (_val, opts) => {
                    found = true;
                    opts.abort();
                }
            });
            crawler(item);
            return found;
        }
    );


export const useInterface = (uuid: string) => {
    const networkInterfacesInfo = useNetworkInterfacesQuery().data ?? EMPTY_IMMUTABLE_ARR;
    const selector = useMemo(() => createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
        getIsCluster,
    ], (data, isCluster,) => {
        // TODO: AK-3661: One of these is using wrong networkInterfacesInfo
        const { state: stateA, physicalLayerUp: physicalLayerUpA,
            addresses4: addresses4A } = networkInterfacesInfo.find(
            item => isFromConfiguration({ item: item, value: data, clusterNodeSelector: NODE_A_ID })
        ) || {} as any;
        const { state: stateB, physicalLayerUp: physicalLayerUpB, address4: addresses4B } = isCluster ?
            networkInterfacesInfo.find(
                item => isFromConfiguration({ item: item, value: data, clusterNodeSelector: NODE_B_ID })
            ) || EMPTY_IMMUTABLE_OBJ as any : EMPTY_IMMUTABLE_OBJ;
        return {
            ...data,
            stateA,
            physicalLayerUpA,
            stateB,
            physicalLayerUpB,
            address: data?.type !== BRIDGE_TYPE ? {
                [NODE_A_ID]: data?.dhcp ? (addresses4A || []).map(item =>
                    `${item?.address}/${item?.mask}`) :
                    stringifyAddress(data?.address?.[NODE_A_ID]) || EMPTY_IMMUTABLE_ARR,
                [NODE_B_ID]: data?.dhcp ? (addresses4B || []).map(item =>
                    `${item?.address}/${item?.mask}`) :
                    stringifyAddress(data?.address?.[NODE_B_ID]) || EMPTY_IMMUTABLE_ARR,
                shared: data?.dhcp ? ((addresses4A || []).concat(addresses4B || []) || []).map(item =>
                    `${item?.address}/${item?.mask}`) :
                    stringifyAddress(data?.address?.[NODE_SHARED]) || EMPTY_IMMUTABLE_ARR
            } : {
                [NODE_SHARED]: stringifyAddress(data?.address?.[NODE_SHARED])
            },
            address6: data?.type !== BRIDGE_TYPE ? {
                [NODE_A_ID]: stringifyAddress(data?.address6?.[NODE_A_ID]) || EMPTY_IMMUTABLE_ARR,
                [NODE_B_ID]: stringifyAddress(data?.address6?.[NODE_B_ID]) || EMPTY_IMMUTABLE_ARR,
                shared: stringifyAddress(data?.address6?.[NODE_SHARED]) || EMPTY_IMMUTABLE_ARR
            } : {
                [NODE_SHARED]: stringifyAddress(data?.address6?.[NODE_SHARED])
            },
            vlanIface: data?.vlanIface ? [ data?.vlanIface ] : undefined,

        };
    }), [ networkInterfacesInfo ]);

    return  useSelector(state => selector(state, uuid));
};

const checkParamDiffArray = (key, firstTree, secondTree, diff, init) => {
    const array = (secondTree || []).map(stringifyAsNetaddr);
    const arrayInit = (init || []).map(stringifyAsNetaddr);

    for (const address in firstTree) {
        const value = `${firstTree[address]?.address}/${firstTree[address]?.mask || firstTree[address]?.prefix}`;
        if (!array.includes(value) && areArraysEqual(array, arrayInit)) {
            diff.push({
                oldItem: firstTree[address],
                key: key,
            });
        }
    }
};


export const useInterfaceNeedsUpdate = (uuid: string) => {
    const networkInterfacesInfo = useNetworkInterfacesQuery().data ?? EMPTY_IMMUTABLE_ARR;
    const selector = useMemo(() => createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
        getTableItemInit,
        getIsCluster,
        getMyNode,
        state => getGlcfgValue(state, IPV6_ENABLED)
    ], (
        fromConfiguration: Offable<'hwIface'>,
        fromConfigurationInit: Offable<'hwIface'>,
        isCluster: boolean,
        myNode: ClusterOwnSelector,
        ipv6: boolean
    ) => {
        // TODO: AK-3661: This feature only works for current cluster node. Could be made to work for both at once.
        const fromNetwork = networkInterfacesInfo.find(
            item => isFromConfiguration({ item: item, value: fromConfiguration, clusterNodeSelector: myNode })
        );
        const diff: {oldItem: any, key: string}[] = [];
        if (!fromConfiguration?.id) {
            return EMPTY_IMMUTABLE_ARR;
        }
        if (hlcfgRowIdIsFromTable(fromConfiguration?.id, 'hwIface') && fromNetwork) {
            if (!fromConfiguration.dhcp) {
                checkParamDiffArray(
                    'address',
                    fromNetwork.addresses4,
                    isCluster ? fromConfiguration?.address?.[NODE_SHARED].concat(fromConfiguration?.address?.[myNode])
                        .filter(Boolean) :
                        fromConfiguration?.address?.[NODE_SHARED],
                    diff,
                    isCluster ?
                        fromConfigurationInit?.address?.[NODE_SHARED].concat(fromConfigurationInit?.address?.[myNode])
                            .filter(Boolean) :
                        fromConfigurationInit?.address?.[NODE_SHARED],
                );
            }
            if (ipv6) {
                const getAddrs = from => isCluster ?
                    from?.address6?.[NODE_SHARED].concat(from?.address6?.[myNode]).filter(Boolean) :
                    from?.address6?.[NODE_SHARED];
                checkParamDiffArray(
                    'address6',
                    fromNetwork.address6,
                    getAddrs(fromConfiguration),
                    diff,
                    getAddrs(fromConfigurationInit),
                );
            }
            /* if (fromConfiguration.type === HW_TYPE) {
                if (fromNetwork.addressMac !== fromConfiguration.mac?.nodeA) {
                    diff.push({
                        oldItem: fromConfiguration.mac?.nodeA,
                        newItem: fromNetwork.addressMac,
                        key: 'mac',
                    });
                }
            }*/

        }
        if (!diff.length) {
            return EMPTY_IMMUTABLE_ARR;
        }
        return diff;
    }), [ networkInterfacesInfo ]);

    return useSelector(state => selector(state, uuid));
};
export const makeSelectorGetFakeClusterPool = () =>
    createSelector(
        [   (state, uuid: string) => {
            const pool = getTableItem(state, uuid);
            const allValues = getNamedObjectNetaddrAllValues(state);
            const newPool = structuredClone(pool);
            if (isNamedObject(pool.rangeFrom)) {
                const netAddrString = namedObjectToString(pool.rangeFrom);
                newPool.rangeFrom = netaddr(allValues[netAddrString].flat()[0]);
            }
            if (isNamedObject(pool.rangeTo)) {
                const netAddrString = namedObjectToString(pool.rangeTo);
                newPool.rangeTo = netaddr(allValues[netAddrString].flat()[0]);
            }
            return newPool;
        },
        (state) => getIsCluster(state),
        (state, uuid: string, parentUid: string) => {
            const dhcpServer: Offable<'dhcpServer'> = getTableItem(state, parentUid);
            return dhcpServer.leases?.map(item => {
                const lease = getTableItem(state, item);
                if (isNamedObject(lease.ip)) {
                    const netAddrString = namedObjectToString(lease.ip);
                    const newLease = structuredClone(lease);
                    const allValues = getNamedObjectNetaddrAllValues(state);
                    newLease.ip = netaddr(allValues[netAddrString].flat()[0]);
                    return newLease;
                }
                if (lease.ip) {
                    return lease;
                }
                return undefined;
            }).filter(it => it !== undefined).filter(lease => lease.ip && !lease.__off) ?? [];
        } ],
        (pool, isCluster: boolean, leases) => {
            if (isCluster && pool.rangeFrom && pool.rangeTo) {
                return splitDhcpPoolForCluster(pool as HlcfgTypeDhcpPool, leases.filter(it => it.ip));
            }
            return EMPTY_IMMUTABLE_OBJ;
        }
    );


export const getOpenVpnRoutesUuid = () => {
    return createSelector([
        (state, uuidWithSuffix) => getTableItem(state, getUuidFromSuffixedUuid(uuidWithSuffix)),
        (state, uuidWithSuffix) => uuidWithSuffix
    ], (openVpnUserToCreateRouteFrom: Offable<'openvpnUser'>, uuidWithSuffix) => {
        if (!openVpnUserToCreateRouteFrom) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        const userRoute = generateRouteForOpenVpnUser(openVpnUserToCreateRouteFrom as any)?.[
            Number(getSuffixFromUuid(uuidWithSuffix))
        ];
        if (!userRoute) {
            return { dontRender: true };
        }
        return  {
            ...userRoute,
            id: uuidWithSuffix,
            __off: openVpnUserToCreateRouteFrom.__off,
            fake: true,
            gateway: stringifyAddress(userRoute.gateway),
            destination: stringifyAddress(userRoute.destination),
        };


    });
};

export const getVpnUser = () => {
    return createSelector(
        [ (state, uuid: string) => getTableItem(state, uuid) ],
        (user: Offable<'openvpnUser'>) => {
            if (!user || user === EMPTY_IMMUTABLE_OBJ) {
                return EMPTY_IMMUTABLE_OBJ;
            }
            return {
                ...user,
                nameservers: stringifyAddress(user.nameservers.addresses),
                ntp: stringifyAddress(user.ntp),
                force: user.nameservers.force,
                defaultGateway: user.defaultGateway,
                routes: user.routes,
                domain: stringifyAddress(user.domain),
                addresses: stringifyAddress(user.addresses),
                siteToSiteNetworks: stringifyAddress(user.siteToSiteNetworks)
            };
        }
    );
};

export const getDisplayedVpnService = createSelector([
    (state) => getTableItem(state, getActiveCard(state, 'vpn')),
    (state) => getTableById(state, OPENVPN_USER),
], (vpnService: HlcfgTableDirtyItem<'openvpnClient'|'openvpnRas'>, users) =>  {
    if (!vpnService || vpnService === EMPTY_IMMUTABLE_OBJ) {
        return EMPTY_IMMUTABLE_OBJ;
    }
    if (hlcfgRowObjectIsFromTable(vpnService, hlcfgTableName.openvpnClient)) {
        return {
            ...vpnService,
            serverAddress: {
                addr: stringifyAddress(vpnService.serverAddress?.addr),
                port: vpnService.serverAddress?.port ?  [ netport(vpnService.serverAddress?.port).toString() ] : []
            },
        };
    }
    const userRoutes = vpnService.pushToUser?.filter(item => users[item]?.type === OPENVPN_USER_TYPE_S2S)
        .flatMap(item => {
            return users[item].siteToSiteNetworks?.flatMap((unused, index) => {
                return addSuffixToUuid(item, index);
            });
        }).filter(Boolean);
    return {
        ...vpnService,
        serverAddress: {
            addr: stringifyAddress(vpnService.serverAddress?.addr),
            port: vpnService.serverAddress?.port ?
                [ netport(vpnService.serverAddress?.port).toString() ] : []
        },
        routes: (userRoutes || []).concat(vpnService.routes),
        vpnAddress: stringifyAddress(vpnService.vpnAddress),
        pushToClient: {
            nameservers: stringifyAddress(vpnService.pushToClient?.nameservers?.addresses),
            force: vpnService.pushToClient?.nameservers?.force,
            defaultGateway: vpnService.pushToClient?.defaultGateway,
            routes: vpnService.pushToClient?.routes,
            ntp: stringifyAddress(vpnService.pushToClient?.ntp),
            domain: stringifyAddress(vpnService.pushToClient?.domain)
        }
    };
});

export const getDisplayedVpnServiceRoutes = createSelector([
    (state) => getTableItem(state, getActiveCard(state, 'vpn')).routes,
    (state) => getTableItem(state, getActiveCard(state, 'vpn')).pushToUser,
    (state) => getTableById(state, OPENVPN_USER),
], (
    routes: HlcfgTableDirtyItem<'openvpnClient'|'openvpnRas'>['routes'],
    pushToUser: HlcfgTableDirtyItem<'openvpnRas'>['pushToUser']|undefined,
    users: Record<string, HlcfgTableDirtyItem<'openvpnUser'>>,
) =>  {
    if (!pushToUser) {
        return routes;
    }

    const userRoutes = pushToUser.filter(item => users[item]?.type === OPENVPN_USER_TYPE_S2S) ?? [];
    return [ ...userRoutes, ...routes ];
});

// *********************** ROUTES *************************

export const getRoutesUuid = () => {
    return createSelector([
        (state, uuid: string) => getTableItem(state, uuid),
    ], (route) => {
        if (!route) {
            return EMPTY_IMMUTABLE_OBJ;
        }
        return {
            ...route,
            gateway: stringifyAddress(route.gateway),
            destination: stringifyAddress(route.destination),
        };
    });
};

// *********************** ADDRESS TABLE *************************

const getTableIdsFromCards = (state, table, pathToIds: string[]) => {
    const item = getTableItem(state, getActiveCard(state, table));
    if (!item) {
        return EMPTY_IMMUTABLE_ARR;
    }

    return getValue(item, pathToIds);
};

export const getAddressTableWafSelector = createSelector(
    (state) => getTableIdsFromCards(state, WAF_PROFILES, [ 'addressesTable' ]),
    (ids) =>  ids
);

export const getAddressTableProfileSelector = createSelector(
    (state) => getTableIdsFromCards(state, PROFILES, [ 'parameters', 'addressesTable' ]),
    (ids) =>  ids
);

export const getAddressTableSshSelector = createSelector(
    (state) => getGlcfgValue(state, SSHD_SOCKETS),
    (ids) =>  ids
);
