/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getShowTodos, setShowTodos } from '~frontendDucks/dynamicSettings/index.ts';

import ButtonWithTooltip from '../../../../../components/ButtonWithTooltip/index.js';


const ShowTodosButtonBase = ({ setShowTodos, currentShowTodos }) =>  (
    <ButtonWithTooltip
        desc="flip the value of showTodos"
        onClick={() => setShowTodos(!currentShowTodos)}
        title="kouzelne zmizet vsechny nehotove veci"
    />
);

ShowTodosButtonBase.propTypes = {
    setShowTodos: PropTypes.func,
    currentShowTodos: PropTypes.bool,
};

const mapStateToProps = state => ({
    currentShowTodos: getShowTodos(state),
});

const mapDispatchToProps = {
    setShowTodos: setShowTodos
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowTodosButtonBase);
