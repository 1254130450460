/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import { getValue, setValue } from '~commonLib/objectUtils.ts';
import { PathStrict, SchemaPathStrict } from '~commonLib/schema/types.ts';


const ANY_PROP = Symbol('*');
const schemaPathToIntermediatePath = (schemaPath: SchemaPathStrict) => {
    const remaining = [ ...schemaPath ];
    const intermediate: (string | typeof ANY_PROP)[] = [];
    while (remaining.length) {
        const firstSeg = remaining.shift();
        if (firstSeg === 'properties') {
            const prop = remaining.shift();
            assert(prop !== undefined, `Path has undefined property: ${schemaPath.join('.')}`);
            intermediate.push(prop);
        } else if (firstSeg === 'additionalProperties') {
            intermediate.push(ANY_PROP);
        } else if (firstSeg !== 'items') {
            throw new Error(`Unallowed jsonschema structure found ${schemaPath}, ${remaining}, ${firstSeg}`);
        }
    }
    return intermediate;
};

export const findPathsBySchemaPath = <T>(
    hlcfgTree: T, schemaPath: SchemaPathStrict
): PathStrict[] => {
    const intermediatePath = schemaPathToIntermediatePath(schemaPath);

    let currentPartialPath: PathStrict = [];
    let currentPaths: PathStrict[] = [ [] ];

    while (intermediatePath.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const currentSegment = intermediatePath.shift()!;
        if (currentSegment === ANY_PROP) {
            currentPaths = currentPaths.map(path => {
                const newPath = [ ...path, ...currentPartialPath ];
                const props = Object.keys(getValue(hlcfgTree, newPath) ?? {});
                return props.map(prop => [ ...newPath, prop ]);
            }).flat();
            currentPartialPath = [];
        } else {
            currentPartialPath.push(currentSegment);
        }
    }
    if (currentPartialPath.length) {
        currentPaths = currentPaths.map(path => {
            return [ ...path, ...currentPartialPath ];
        });
    }

    return currentPaths;
};

/**
 * Finds all paths in object that match the schemaPath. Adds the value provided.
 * Also adds parent objects if neccessary to add the value.
 */
export const setValueIfUndefinedBySchemaPath = <T extends Record<string, string>>(
    object: T, schemaPath: SchemaPathStrict, value
): void => {
    const intermediatePath = schemaPathToIntermediatePath(schemaPath);

    const segments: SchemaPathStrict[] = [];
    while (intermediatePath.length) {
        const anyPropAt = intermediatePath.indexOf(ANY_PROP);
        const lenToSplice = anyPropAt === -1 ?
            intermediatePath.length : anyPropAt;
        segments.push(intermediatePath.splice(0, lenToSplice) as SchemaPathStrict);
        intermediatePath.shift();
    }

    let objectsToCheck: any[] = [ object ];
    while (segments.length) {
        const segment = segments.shift();
        assert(segment);
        const isLastSegment = segments.length === 0;

        if (isLastSegment) {
            objectsToCheck.forEach(object => {
                const valueIsUndef = getValue(object, segment) === undefined;
                if (valueIsUndef) {
                    setValue(object, segment, value);
                }
            });
            return;
        }
        objectsToCheck = objectsToCheck.map(object => {
            const record = getValue(object, segment);
            const newObj = record ? record : {};
            setValue(object, segment, newObj);
            return Object.values(newObj);
        }).flat();
    }

};
