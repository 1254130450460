/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InputSearch } from '~frontendComponents/Generic/index.js';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_BASIC_AUTH, SMALL_SIZE, TableSizeType } from '~frontendConstants/index.js';
import { useString } from '~frontendLib/hooks/defaultHooks.ts';
import { DatatableWidget } from '~frontendRoot/widgets/index.js';
import { HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import { getBasicAuthTable } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { testProps } from '~commonLib/PageObjectMap.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';

import Row, { NoRowsRow, BasicAuthentication } from './Row/index.ts';
import { authenticationSceneMap } from '../../pageObjectMap.ts';


type RowPropsType = {
    dataIndex: number,
    key: string,
    search: string,
    spacing: TableSizeType,
    uuid: HlcfgTableRowId<'basicTextUser'> | 'newRow',
}

const CreateRow = ({ dataIndex, spacing, search, uuid }: RowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <NoRowsRow key={uuid} />;
    }
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};
const AuthenticationUsersTable = () => {

    const [ search, setSearch ] = useString('');
    const { t } = useTranslation();
    const users: string[] = useSelector(getBasicAuthTable);
    const data = useMemo(() => users?.length ? users : [ NEW_ROW_CONSTANT ], [ users ]);
    const reorder = useTableReorder({ tablePathGetter: hlcfgPathGetter.protection.proxy.authentication.basicTextUser });

    return (
        <MDBCard
            {...testProps(authenticationSceneMap.child.rules.id)}
        >
            <MDBCardTitle className="profiles__title">
                {t('widgets:Authentication.basicUserTable.title')}
                <InputSearch
                    className="mb-3 mt-0"
                    id="searchValueIDRoute"
                    search={search}
                    setter={setSearch}
                />
            </MDBCardTitle>
            <BasicAuthentication.Provider value={{ search: search }}>
                <DatatableWidget
                    columnsId={SELECTABLE_TABLE_BASIC_AUTH}
                    createRow={CreateRow}
                    data={data}
                    passReorderData={reorder}
                    search={search}
                    spacing={SMALL_SIZE}
                />
            </BasicAuthentication.Provider>
        </MDBCard>
    );
};

export default AuthenticationUsersTable;
