/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { poDef } from '~commonLib/PageObjectMap.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';


const suriSvc = hlcfgPathGetter.protection.suricata.service;
const ports = suriSvc.variables.portGroups;
const addrs = suriSvc.variables.addressGroups;
export const suricataVarsSceneMap = poDef.scene('suricataVarsScene', '/protection/ips/variables', {
    status: poDef.onOffButtons(poDef.pathId(suriSvc.__off)),

    addrs: poDef.group({
        homeNet: poDef.select(poDef.pathId(addrs.homeNet)),
        externalNet: poDef.select(poDef.pathId(addrs.externalNet)),
        httpServers: poDef.select(poDef.pathId(addrs.httpServers)),
        smtpServers: poDef.select(poDef.pathId(addrs.smtpServers)),
        sqlServers: poDef.select(poDef.pathId(addrs.sqlServers)),
        dnsServers: poDef.select(poDef.pathId(addrs.dnsServers)),
    }),
    advancedAddrs: poDef.collapsible('advAddrGroups', {
        telnetServers: poDef.select(poDef.pathId(addrs.telnetServers)),
        aimServers: poDef.select(poDef.pathId(addrs.aimServers)),
        dnp3Server: poDef.select(poDef.pathId(addrs.dnp3Server)),
        dnp3Client: poDef.select(poDef.pathId(addrs.dnp3Client)),
        modbusClient: poDef.select(poDef.pathId(addrs.modbusClient)),
        modbusServer: poDef.select(poDef.pathId(addrs.modbusServer)),
        enipClient: poDef.select(poDef.pathId(addrs.enipClient)),
        enipServer: poDef.select(poDef.pathId(addrs.enipServer)),
    }),

    ports: poDef.group({
        http: poDef.select(poDef.pathId(ports.httpPorts)),
        shellcode: poDef.select(poDef.pathId(ports.shellcodePorts)),
        oracle: poDef.select(poDef.pathId(ports.oraclePorts)),
        ssh: poDef.select(poDef.pathId(ports.sshPorts)),
    }),
    advancedPorts: poDef.collapsible('advPortGroups', {
        dnp3: poDef.select(poDef.pathId(ports.dnp3Ports)),
        modbus: poDef.select(poDef.pathId(ports.modbusPorts)),
        fileData: poDef.select(poDef.pathId(ports.fileDataPorts)),
        ftp: poDef.select(poDef.pathId(ports.ftpPorts)),
    })
});
