/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { Suspense, lazy } from 'react';

import { memoizeByFirstStringifiedArg, retryFnNTimesWithInterval } from '~commonLib/functionUtils.ts';
import { SECOND } from '~commonLib/constants.ts';
import { AnyFunc, JSXElement } from '~commonLib/types.ts';


let preloading = false;
const imports: any[] = [];
const preloadWorker = {
    startPreloading: () => {
        preloading = true;
        imports.forEach(importStatement => importStatement());
    },
    preload: importStatement => {
        if (preloading) {
            importStatement();
        } else {
            imports.push(importStatement);
        }
    }
};

if (window) {
    window.addEventListener('load', () => {
        // add arbitrary low timeout, to let frontend finish the loading
        // which is done during this event, so we want not to slow it down
        setTimeout(() => {
            preloadWorker.startPreloading();
        }, 1000);
    });
}

type Import<T extends AnyFunc> = () => Promise<{default: T}>
type LazyOpts = {
    // Skip preload when component not being loaded when first displayed does not hurt UX
    // Commonly development scenes or non-scene components. This allows skipping some work and bandwidth when loading FE
    skipPreload?: boolean
};
export const createLazy = <T extends AnyFunc, >(importStatement: Import<T>, opts?: LazyOpts): T => {
    const doImport = memoizeByFirstStringifiedArg(async () => {
        return await retryFnNTimesWithInterval({
            fn: async () => importStatement().catch(err => {
                // Log the import error, otherwise it gets swallowed by somebody and it is extremely hard to debug
                // eslint-disable-next-line no-console
                console.error(err);
                throw err;
            }),
            times: 3,
            interval: 3 * SECOND,
        });
    });
    const LazyComponent = lazy(doImport);
    if (!opts?.skipPreload) {
        preloadWorker.preload(doImport);
    }
    return LazyComponent as any as T;
};
interface LazyWithSuspenseOpts extends LazyOpts {
    suspenseFallback?: JSXElement,
}
export const createLazyWithSuspense = <T extends AnyFunc, >(
    importStatement: Import<T>, opts?: LazyWithSuspenseOpts
) => {
    const { suspenseFallback = '', ...lazyOpts } = opts ?? {};
    const LazyComponent = createLazy(importStatement, lazyOpts);
    const LazyComponentWithSuspense = (props: Parameters<T>[0]) => (
        <Suspense fallback={suspenseFallback}>
            <LazyComponent {...props} />
        </Suspense>
    );
    return LazyComponentWithSuspense;
};
