/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { InputSearch, SelectColumns, Switch } from '~frontendComponents/Generic/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { setGlcfgValueScalar, setNormalizeOrder, getInterfaceIds, getGlcfgValue, getInterfaceItems
} from '~frontendDucks/hlcfgEditor/index.js';
import { isFromConfiguration } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSetters.js';
import { INTERFACES, MEDIUM_SIZE, IPV6_ENABLED, SELECTABLE_TABLE_INTERFACES, ENABLE_IP6_MODAL } from '~frontendConstants/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { INTERFACE_TABLES } from '~sharedLib/schemaTypes.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import { getShowHidden } from '~frontendDucks/interfaces/interfaces.js';
import { LOOPBACK_NAME } from '~frontendDucks/systemComponents/systemComponents.ts';
import { useNetworkInfoQuery, useNetworkInterfacesQuery } from '~frontendQueries/system/hooks.ts';
import { getMyNode } from '~frontendDucks/clusterSetup/clusterSetup.js';

import { Row, Footer, RowUnknown } from './components/index.js';


const CreateRow = ({ dataIndex,
    spacing,
    search, uuid, }: CreateRowPropsType) => {
    if (INTERFACE_TABLES.some((table) => hlcfgRowIdIsFromTable(uuid, table))) {
        return (
            <Row
                dataIndex={dataIndex}
                key={uuid}
                search={search}
                spacing={spacing}
                uuid={uuid}
            />
        );
    }
    return (
        <RowUnknown
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid}
        />
    );
};


//Special class for translation
const CreateFooter = () =>  (
    <Footer />
);


const ReloadIcon = () => {
    const networkInfo = useNetworkInfoQuery();
    return (
        <IconWithTooltip
            className="ml-2"
            iconSize="sm"
            link
            loading={networkInfo.isFetching}
            name="reload"
            onClick={event => {
                event.preventDefault();
                if (networkInfo.isFetching) {
                    return;
                }
                void networkInfo.refetch();
            }}
            tooltipText="widgets:Interfaces.update"
        />
    );
};


const useInterfaces = () => {
    const items: any = useSelector(getInterfaceItems);
    const showHidden = useSelector(getShowHidden);
    const clusterNodeSelector = useSelector(getMyNode);
    const ifaces = items.map(item => ({
        uuid: item.id,
        device: item.device,
        vlanTag: item.vlanTag,
        ifaceTag: item.ifaceTag,
        __off: showHidden ? false : item.__off,
    }));
    const networkInterfacesInfo = useNetworkInterfacesQuery().data ?? [];
    const interfaces: string[] = ifaces.filter(iface => !iface.__off).map(item => item.uuid);
    networkInterfacesInfo?.forEach(item => {
        if (item.name === LOOPBACK_NAME || !item.name) {
            return;
        }
        if (ifaces.find(value => isFromConfiguration({ item: item, value: value, clusterNodeSelector }))) {
            return;
        }
        if (item.name.startsWith('tun') || item.name.startsWith('tap')) {
            interfaces.push(item.name);
        } else {
            interfaces.unshift(item.name);
        }
    });
    return interfaces;
};
const NetworkDatatable = () => {
    const [ search, setSearch ] = useString('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const ipv6Enabled = useSelector(state => getGlcfgValue(state, IPV6_ENABLED));
    const allIds: string[] = useSelector(getInterfaceIds);

    const data = useInterfaces();

    const changeValue = useCallback(({ value, id }) => {
        dispatch(setGlcfgValueScalar(id, value));
        if (value) {
            dispatch(setModalState({ modal: ENABLE_IP6_MODAL, value: true }));
        }
    }, [ dispatch ]);


    const createNewOrder = useCallback((value: string[]) => {
        const newOrder: string[] = value.filter(item => hlcfgRowIdIsFromTable(item, 'hwIface') ||
        hlcfgRowIdIsFromTable(item, 'vlanIface') ||  hlcfgRowIdIsFromTable(item, 'bridgeIface'));
        const offed = allIds.filter(item => !newOrder.includes(item));
        dispatch(setNormalizeOrder({ type: INTERFACES, value: [ ...newOrder, ...offed ] }));
    }, [ allIds, dispatch ]);

    return (
        <div className="p-1">
            <MDBRow>
                <MDBCol
                    className={classNames(
                        'mb-2 mb-lg-4',
                    )}
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <MDBCard>
                        <MDBCardTitle className="profiles__title networkDatatable">
                            <div>
                                {t('widgets:Interfaces.foundInterfaces')}
                                <ReloadIcon />
                            </div>
                            <div className="profiles__titleActions">
                                <Switch
                                    align="spaceBetween"
                                    checked={ipv6Enabled}
                                    className="mb-3 mt-0 mr-2"

                                    id={'ipv6Enabled'}
                                    label={t('widgets:ipv6Enabled.title')}
                                    name={'ccdExclusive'}
                                    onChange={changeValue}
                                />
                                <SelectColumns id="interfaces" />
                                <InputSearch
                                    className="mb-3 mt-0"
                                    id="searchValueIDHosts"
                                    search={search}
                                    setter={setSearch}
                                />
                            </div>
                        </MDBCardTitle>

                        <DatatableWidget
                            columnsId={SELECTABLE_TABLE_INTERFACES}
                            createFooter={CreateFooter}
                            createRow={CreateRow}
                            data={data}
                            passReorderData={createNewOrder}
                            search={search}
                            spacing={MEDIUM_SIZE}
                        />
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </div>
    );
};


export default NetworkDatatable;
