/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LOOPBACK_NETWORK } from '~frontendConstants/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { netaddr } from '~sharedLib/Netaddr/Netaddr.ts';
import { isNetaddr4IpData } from '~commonLib/Netaddr/NetaddrIp4.ts';


/**
 * Component to return icon based on input , helps with packetFilter object data.
 *
 */
const GetIcon = ({ icon, spacing, destinationTranslationAddress, t }) => {
    switch (icon) {
    case 'IN': return (
        <IconWithTooltip
            className={classNames(
                'icon--black',
            )}
            iconSize={spacing}
            link
            name="arrow-down"
            tooltipText={t('packetFilter:in')}
            withoutTranslation
        />
    );
    case 'OUT': return (
        <IconWithTooltip
            className={classNames(
                'icon--black',
            )}
            iconSize={spacing}
            link
            name="arrow-up"
            tooltipText={t('packetFilter:out')}
            withoutTranslation
        />
    );
    case 'drop': return (
        <IconWithTooltip
            className={classNames(
                'icon--red',
            )}
            iconSize="sm"
            link
            name="drop"
            tooltipText={t('packetFilter:drop')}
            withoutTranslation
        />

    );
    case 'accept':
    case 'force-accept':
        return (
            <IconWithTooltip
                className={classNames(
                    'icon--primary',
                )}
                iconSize="sm"
                link
                name="shield-check-outline"
                tooltipText={t('packetFilter:accept')}
                withoutTranslation
            />
        );
    case 'web':  return (
        <IconWithTooltip
            className="icon--yellow"
            iconSize="sm"
            link
            name="web"
            tooltipText={t('packetFilter:web')}
            withoutTranslation
        />
    );
    case 'SNAT':  return (
        <IconWithTooltip
            className={classNames(
                'packetFilter__icon',
            )}
            iconSize="sm"
            link
            name="drama-masks"
            tooltipText={t('packetFilter:snat')}
            withoutTranslation
        />
    );
    case 'REDIRECT': {
        const loopbackNetwork = netaddr(LOOPBACK_NETWORK);
        if ((destinationTranslationAddress || []).some(
            item => isNetaddr4IpData(item) && loopbackNetwork.networkContainsAddress(item)
        )) {
            return (
                <IconWithTooltip
                    iconSize={spacing}
                    link
                    name="loopback"
                    tooltipText={t('packetFilter:loopback')}
                    withoutTranslation
                />
            );
        }
        return (
            <IconWithTooltip
                iconSize={spacing}
                link
                name="call-missed"
                tooltipText={t('packetFilter:redirect')}
                withoutTranslation
            />
        );
    }
    case 'SNAT REDIRECT':  return (
        <IconWithTooltip
            iconSize={spacing}
            link
            name="masksWithRedirect"
            tooltipText={t('packetFilter:snatRedirect')}
            withoutTranslation
        />
    );
    default:
        return null;
    }
};

GetIcon.propTypes = {
    icon: PropTypes.string,
    spacing: PropTypes.string,
    destinationTranslationAddress: PropTypes.array,
    t: PropTypes.func,
};

export default GetIcon;
