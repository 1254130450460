/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { Draggable } from '@hello-pangea/dnd';
import assert from 'assert';
import { createContext, useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { MDBBtn } from 'mdbreact';
import { t } from 'i18next';

import { ConfirmPopover, Icon } from '~frontendComponents/Generic/index.js';
import RowDivider, { RowDividerTypes } from '~frontendComponents/RowDivider.tsx';
import {
    TableSizeType } from '~frontendConstants/index.js';
import { hlcfgRowIdIsFromTable, HlcfgTableRowId } from '~sharedLib/hlcfgTableUtils.ts';
import RowMenuAndSwitch from '~frontendComponents/RowMenuAndSwitch.tsx';
import { getRowPathGetter, hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { useBoolean, useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';
import { makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { useHlcfgValue, useTableManipulator, useTableRowManipulator } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { HlcfgTextInput } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { testProps } from '~commonLib/PageObjectMap.ts';

import { getBasicAuthenticationUserMap } from '../../../pageObjectMap.ts';


export const BasicAuthentication = createContext({ search: '' } as { search: string });

export const NoRowsRow = () => {

    const { t } = useTranslation();

    const { addRow } = useTableManipulator({
        tablePathGetter: hlcfgPathGetter.protection.proxy.authentication.basicTextUser,
        addRowType: 'basicTextUser',
        addRowSuccessText: 'placeholder',
    });

    const handleAddRow = useCallback((event: React.SyntheticEvent<HTMLButtonElement, Event>) => {
        event.preventDefault();
        addRow();
    }, [ addRow ]);

    return (
        <tr
            className="dataTableWidget__RowFirstRule"
            key="newRule"
        >
            <td></td>
            <td
                className="dataTableWidget__RowFirstRuleRow"
                colSpan={5}
            >
                <div className="pl-2">
                    <h2>{t('widgets:Authentication.basicUserTable.title')}</h2>
                    <p className="dataTableWidget__Rowtext">
                        {t('widgets:Authentication.basicUserTable.desc')}
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={handleAddRow}
                            {... testProps('AddRow', { typeId: 'basicTextUser' })}
                        >
                            <Icon name="plus" /> {t('widgets:Authentication.basicUserTable.addUser')}
                        </MDBBtn>
                    </p>
                </div>
            </td>
        </tr>
    );

};


type RowProps = {
    uuid: HlcfgTableRowId<'basicTextUser'>,
    dataIndex: number,
    spacing: TableSizeType,
    search?: string,
};

const Row = ({ dataIndex, spacing, uuid }: RowProps) => {

    const [ confirtmReset, setConfirmReset ] = useBoolean();
    assert(hlcfgRowIdIsFromTable(uuid, 'basicTextUser'));
    const userPath = getRowPathGetter(uuid);
    const map = getBasicAuthenticationUserMap(uuid);
    const { search } = useContext(BasicAuthentication);

    const { value: id } = useHlcfgValue(userPath.id);
    const { value: pwd, setValue: setPassword } = useHlcfgValue(userPath.password);
    const { value: __off, setValue: setOff } = useHlcfgValue(userPath.__off);

    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const { addRow, deleteRow, duplicateRow } = useTableRowManipulator({
        tablePathGetter: hlcfgPathGetter.protection.proxy.authentication.basicTextUser,
        rowPathGetter: userPath,
        addRowType: 'basicTextUser',
        addRowSuccessText: 'widgets:Authentication.basicUserTable.added',
    });

    const [ types ] = useState<RowDividerTypes>([
        { addFunc: addRow, translation: 'widgets:Authentication.basicUserTable.addUser' },
    ]);

    const handelPasswordReset = useCallback(() => {
        if (confirtmReset) {
            setPassword({ raw: undefined, hashed: undefined });
            setConfirmReset.off();
        } else {
            setConfirmReset.on();
        }
    }, [ setPassword, setConfirmReset, confirtmReset ]);

    return (
        <Draggable
        //it needs string
            draggableId={String(uuid)}
            index={dataIndex}
            isDragDisabled={closed}
            key={uuid}
        >
            {(provided) => (
                <>
                    <RowDivider
                        id={id}
                        length={4}
                        types={types}
                    />
                    <tr
                        className={classNames(
                            'dataTableWidget__Row',
                            'profiles__row',
                            'dataTableWidget__Row--important',
                            'packetFilter__edit',
                            { 'dataTableWidget__Row--disable': __off },
                            { 'dataTableWidget__Row--match': searchMatches },
                        )}
                        key={dataIndex}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...testProps(uuid, { typeId: 'TableRow' })}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': searchMatches },
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                            {...provided.dragHandleProps}
                        >
                            <Icon
                                name="drag"
                                size="sm"
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            <HlcfgTextInput
                                className="dataTableWidget__RowInput"
                                disabled={__off}
                                pathGetter={userPath.name}
                                withoutBorder
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing },
                            )}
                        >
                            {pwd.hashed === undefined ?
                                <HlcfgTextInput
                                    className="dataTableWidget__RowInput"
                                    disabled={__off}
                                    generate
                                    generateLength={20}
                                    pathGetter={userPath.password.raw}
                                    withoutBorder
                                /> :
                                <ConfirmPopover
                                    isOpen={confirtmReset}
                                    onCancel={setConfirmReset.off}
                                    onSuccess={handelPasswordReset}
                                    text={'widgets:Authentication.basicUserTable.confirmReset'}
                                    title={'widgets:Authentication.basicUserTable.confirmResetTitle'}
                                >

                                    <MDBBtn
                                        className={classNames(
                                            'dataTableWidget__RowAddButtons--button',
                                            { 'packetFilter__delete--open': confirtmReset }
                                        )}
                                        disabled={__off}
                                        onClick={setConfirmReset.on}
                                        {...testProps(map.child.resetPassword.id)}
                                    >
                                        <Icon name="reload" />
                                        {t('widgets:Authentication.basicUserTable.resetPassword')}
                                    </MDBBtn>
                                </ConfirmPopover>
                            }
                        </td>
                        <RowMenuAndSwitch
                            __off={__off}
                            copyFunc={duplicateRow}
                            deleteFunc={deleteRow}
                            id={'basicAuthUser' + uuid}
                            onChange={({ value }) => setOff(value)}
                            spacing={spacing}
                        />
                    </tr>
                    <RowDivider
                        after
                        id={id}
                        length={4}
                        types={types}
                    />

                </>
            )}
        </Draggable>
    );
};

export default Row;
