/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import assert from 'assert';

import type { HlcfgDiff } from '~sharedLib/hlcfg/diffHlcfg/diffHlcfg.ts';
import type { HlcfgPath } from '~sharedLib/types.ts';
import { ShallowArrMap } from '~commonLib/ShallowArrMap.ts';
import {
    arrayIsReordered,
    diffIsDisplayable,
    isOffPath,
    replaceValuesWithUndefinedIfBothDefault
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/helpers.ts';
import { DiffForDisplay, FromToHlcfg, LeafDiffInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/types.ts';
import {
    liftedLeafDiffInfoListToRegularDiff
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/liftedLeafDiffInfoListToRegularDiff.ts';
import {
    drilledLeafDiffInfoToRegularDiffs
} from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/drilledLeafDiffInfoToRegularDiffs.ts';
import { hlcfgDiffToDiffLeafInfo } from '~sharedLib/hlcfg/prepareHlcfgDiffsForDisplay/hlcfgDiffToDiffLeafInfo.ts';
import { isRowAddDiff, isRowDeleteDiff, isRowReorderDiff } from '~sharedLib/hlcfg/diffUtils.ts';
import { getValue } from '~commonLib/objectUtils.ts';
import { orderOfScene } from '~sharedConstants/index.ts';
import { descriptiveHlcfgPathToRealPath } from '~sharedLib/hlcfg/resolvedPathToRealPath.ts';
import { findSchemaByObjectPathAndSchema } from '~commonLib/schemaUtils.ts';


export const prepareHlcfgDiffsForDisplay = (
    diffList: HlcfgDiff[], hlcfgTree: FromToHlcfg, hlcfgSchema
): DiffForDisplay[] => {
    const { rowDiffs, nonRowDiffs } = sortDiffListByRowAssociation(diffList);
    const diffInfos = nonRowDiffs.map(diff => hlcfgDiffToDiffLeafInfo(diff, hlcfgSchema)).flat();
    const diffsMap = makeDiffListToLeafMap(diffInfos);

    const nonRowDiffsProcessed = [ ...diffsMap.values() ].map(processLeafDiffs(hlcfgTree, hlcfgSchema)).flat();
    //TODO AK-2390: remove removeClosed filter.
    const diffs = [ ...rowDiffs, ...nonRowDiffsProcessed ].filter(diffIsDisplayable)
        .filter(removeClosed).sort(isTypeLower);

    return diffs;
};

//TODO AK-2390: remove.
const removeClosed = (diff: HlcfgDiff) =>  {
    if (diff.hlcfgRealPath[diff.hlcfgRealPath.length - 1] === 'closed' && !diff.hlcfgRealPath.find(item =>
        item.includes('nftDivider') || item.includes('proxyHeader')))  {
        return false;
    }
    if (diff.hlcfgRealPath.at(-1) === 'clusterStepA' ||
            diff.hlcfgRealPath.at(-1) === 'clusterStepB') {
        return false;
    }
    return true;
};

const sortDiffListByRowAssociation = (diffList: HlcfgDiff[]) => {
    const rowDiffs: HlcfgDiff[] = [];
    const nonRowDiffs: HlcfgDiff[] = [];
    diffList.forEach(diff => {
        switch (true) {
        case isRowReorderDiff(diff): {
            if (arrayIsReordered(diff.fromValue, diff.toValue)) {
                // only add it if the rows have actually been reordered
                rowDiffs.push(diff);
            }
            break;
        }
        case isRowAddDiff(diff):
        case isRowDeleteDiff(diff):
            rowDiffs.push(diff);
            break;
        default:
            nonRowDiffs.push(diff);
            break;
        }
    });
    return { rowDiffs, nonRowDiffs };
};

const processLeafDiffs = (hlcfgTree: FromToHlcfg, hlcfgSchema) => (diffs: LeafDiffInfo[]) => {
    const type = diffs[0].type;
    if (type === 'lifted') {
        return [ liftedLeafDiffInfoListToRegularDiff(diffs, hlcfgTree) ];
    }
    if (type === 'drilled') {
        return drilledLeafDiffInfoToRegularDiffs(diffs, hlcfgTree);
    }
    if (type === 'regular') {
        return regularDiffInfoToDiffs(diffs, hlcfgTree, hlcfgSchema);
    }
    throw new Error('TS, pls, you should know that this cant happen.');
};


const regularDiffInfoToDiffs = (
    diffs: LeafDiffInfo[], hlcfgTree: FromToHlcfg, hlcfgSchema
): HlcfgDiff[] => {
    assert(diffs.length === 1);

    if (isOffPath(diffs[0].leafPath)) {
        return makeOffDiff(diffs[0], hlcfgTree);
    }

    const theDiff = diffs[0].diff;


    if (theDiff.fromValue === undefined || theDiff.toValue === undefined) {
        const schema = findSchemaByObjectPathAndSchema(theDiff.hlcfgRealPath, hlcfgSchema);


        const { fromValue, toValue } = schema ?
            replaceValuesWithUndefinedIfBothDefault(theDiff.fromValue, theDiff.toValue, schema) :
            theDiff;
        return [ {
            ...theDiff,
            fromValue, toValue,
        } ];
    }
    return [ theDiff ];
};

const makeOffDiff = (diffInfo: LeafDiffInfo, hlcfgTree: FromToHlcfg): HlcfgDiff[] => {
    const treeToCheck = diffInfo.diff.fromValue === undefined ? hlcfgTree.from : hlcfgTree.to;
    const pathToCheck = diffInfo.leafPath.slice(0, diffInfo.leafPath.length - 1);

    const objectIsPresentInTreeToCheck = getValue(treeToCheck, descriptiveHlcfgPathToRealPath(pathToCheck));
    return objectIsPresentInTreeToCheck ? [ diffInfo.diff ] : [];
};

const makeDiffListToLeafMap = (diffList: LeafDiffInfo[]) => {
    const map: ShallowArrMap<HlcfgPath, LeafDiffInfo[]> = new ShallowArrMap();

    diffList.forEach(diff => {
        const key = descriptiveHlcfgPathToRealPath(diff.leafPath);
        const diffs = map.get(key) || [];
        map.set(key, [ ...diffs, diff ]);
    });
    return map;
};

const isTypeLower = (first: DiffForDisplay, second: DiffForDisplay) => {
    return orderOfScene[first.hlcfgDescriptivePath[0]] - orderOfScene[second.hlcfgDescriptivePath[0]];
};
