/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback, useMemo } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBCardTitle, MDBCardBody, MDBBtnGroup } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ValuesType } from 'utility-types';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { makeSelectGetCategoriesTranslation, getSuperCategoriesObjectSelector } from '~frontendRoot/ducks/policy/index.ts';
import { HlcfgTableItem } from '~frontendRoot/types/externalTypes.ts';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/IconWithTooltip.js';
import { setNormalize } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { PROFILE_CATEGORIES, PROFILE_RULE_ACTION_ALLOW, PROFILE_RULE_ACTION_BLOCK,
    PROFILE_RULE_ACTION_BYPASS, DEFAULT_PROFILE_CATEGORY_ACTION,
    PROFILE_CATEGORIES_BY_SUPERCATEGORY, PROFILE_RULE_ACTIONS,
    ProfileRuleActionType,
    EMPTY_IMMUTABLE_OBJ } from '~sharedConstants/constants.ts';
import { InputSearch } from '~frontendRoot/components/Generic/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { getStringMatch } from '~frontendRoot/lib/stringUtils.js';
import { objectKeys } from '~commonLib/objectUtils.ts';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/TextWithTooltip.js';
import { SMALL_SIZE, TableSizeType } from '~frontendConstants/index.js';


/**
 * Default parameter of action is allow so if its undefined it will be allow
 */
const isAllAction = (
    data: HlcfgTableItem<'profileRule'>['action']['accordingToCategory'],
    categories: ValuesType<typeof PROFILE_CATEGORIES_BY_SUPERCATEGORY>,
    action: ProfileRuleActionType
) => {
    // object is not required by default, so it can be undefined or empty object,
    // there is also some weird situation that data contains items that are undefined.
    // All the above mentioned situations are equal to default action.
    if (data === undefined || Object.values(data || EMPTY_IMMUTABLE_OBJ).filter(Boolean)?.length === 0) {
        return action === DEFAULT_PROFILE_CATEGORY_ACTION;
    }
    //https://stackoverflow.com/questions/70262217/this-expression-is-not-callable-each-member-of-the-union-type-has-signature
    return [ ...categories ].every(key => {
        return data[key] === action;
    });
};

interface ActionCategoriesType {
    data: HlcfgTableItem<'profileRule'>['action']['accordingToCategory'],
    uuid: string,
    spacing: TableSizeType
}

const ActionCategories = ({ uuid, data, spacing }: ActionCategoriesType) => {
    const dispatch = useDispatch();
    const categories = useSelector(getSuperCategoriesObjectSelector);
    const { t } = useTranslation();
    const [ search, setSearch ] = useString('');


    const setValue = useCallback(({ name, value }) => {
        dispatch(setNormalize({ type: 'profileRules',
            value: value === DEFAULT_PROFILE_CATEGORY_ACTION ? undefined : value,
            key: 'action', subkey: 'accordingToCategory',
            subsubkey: name, uuid, }));
    }, [ uuid, dispatch ]);

    const changeAllInSuperCategory = useCallback(({ value, name }) => {
        categories[name].forEach((key) => {
            setValue({ value, name: key });
        });
    }, [ setValue, categories ]);

    return (
        <MDBRow className="p-2 vertical">
            {objectKeys(categories).map((category) => {
                const value = categories[category];
                const active = PROFILE_RULE_ACTIONS.find(action => isAllAction(data, value, action));
                return (
                    <MDBCol
                        key={category}
                        size="12"
                    >
                        <MDBCard>
                            <MDBCardTitle className={classNames('profiles__title',
                                `profiles__title--${spacing}`)}
                            >
                                <div className="profiles__accordingToCategory">
                                    <h5 className="mb-0 pl-2">{t(`policy:superCategories.${category}`)}</h5>
                                    <ActionButtons
                                        active={active}
                                        category={category}
                                        onClick={changeAllInSuperCategory}
                                    />
                                </div>
                                {category === 'security-risk' && (

                                    <InputSearch
                                        className="mb-0 mt-0"
                                        id="searchValueIDVlans"
                                        search={search}
                                        setter={setSearch}
                                    />
                                )
                                }
                            </MDBCardTitle>
                            <MDBCardBody className={classNames('profiles__body',
                                `profiles__body--${spacing}`)}
                            >
                                <MDBRow>
                                    {[ ...value ].map((subcategory) => {
                                        return (
                                            <TranslateCategory
                                                active={data?.[subcategory] || DEFAULT_PROFILE_CATEGORY_ACTION}
                                                category={subcategory}
                                                change={setValue}
                                                key={subcategory}
                                                search={search}
                                                spacing={spacing}
                                                uuid={uuid}
                                            />
                                        );
                                    })
                                    }
                                </MDBRow>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                );
            })
            }
        </MDBRow>
    );
};

export default ActionCategories;

interface TranslateCategoryType {
    category: typeof PROFILE_CATEGORIES[number],
    active: ProfileRuleActionType,
    change: (event: { value: string; name: string }) => void,
    uuid: string,
    search: string,
    spacing: TableSizeType
}

const TranslateCategory = ({ category, active, change, search, spacing }: TranslateCategoryType) => {
    const getter = useMemo(makeSelectGetCategoriesTranslation, []);
    const data = useSelector(state => getter(state, category));
    if (search && !getStringMatch({ toMatch: data.title, searchValue: search })) {
        return null;
    }
    return (
        <MDBCol
            className={spacing === SMALL_SIZE ? '' : 'p-1'}
            key={category}
            size={'3'}
        >
            <div className="policy__categoryDiv">
                <TextWithTooltip
                    tooltipText={data.doc}
                    withoutTranslation
                >
                    <h5 className="mb-0">{data.title}</h5>
                </TextWithTooltip>
                <ActionButtons
                    active={active}
                    category={category}
                    onClick={change}
                    spacing={spacing}
                />
            </div>
        </MDBCol>
    );
};

interface ActionButtonsProps {
    active: ProfileRuleActionType | undefined,
    onClick: (event: { value: string; name: string }) => void;
    category: string;
    spacing?: TableSizeType
}

const ActionButtons = ({ active, onClick, category, spacing }: ActionButtonsProps) => {

    const onChangeAllow = useCallback(() => {
        onClick({ value: PROFILE_RULE_ACTION_ALLOW, name: category });
    }, [ onClick, category ]);

    const onChangeBypass = useCallback(() => {
        onClick({ value: PROFILE_RULE_ACTION_BYPASS, name: category });
    }, [ onClick, category ]);

    const onChangeBlock = useCallback(() => {
        onClick({ value: PROFILE_RULE_ACTION_BLOCK, name: category });
    }, [ onClick, category ]);

    return (
        <MDBBtnGroup className="mb-0 policy__category">
            <IconWithTooltip
                btnClassName={classNames('p-1 policy__categoryButton',
                    { 'policy__categoryButton--active': active === PROFILE_RULE_ACTION_ALLOW },
                    { 'w-1p5': spacing === SMALL_SIZE },)}
                btnSize={spacing === SMALL_SIZE ? 'xsm' : 'sm'}
                className={classNames('policy__icon',
                    'm-0',
                    { 'icon--primary': active === PROFILE_RULE_ACTION_ALLOW })}
                iconSize="sx"
                name="checkbox-marked-circle"
                onClick={onChangeAllow}
                outline
                tooltipText="policy:allow.title"
            />
            <IconWithTooltip
                btnClassName={classNames('p-1 policy__categoryButton',
                    { 'policy__categoryButton--active': active === PROFILE_RULE_ACTION_BYPASS },
                    { 'w-1p5': spacing === SMALL_SIZE },)}
                btnSize={spacing === SMALL_SIZE ? 'xsm' : 'sm'}
                className={classNames('policy__icon',
                    'm-0',
                    { 'icon--yellow': active === PROFILE_RULE_ACTION_BYPASS })}
                iconSize="sx"
                name="alert-circle"
                onClick={onChangeBypass}
                outline
                tooltipText="policy:bypass.title"
            />
            <IconWithTooltip
                btnClassName={classNames('p-1 policy__categoryButton',
                    { 'policy__categoryButton--active': active === PROFILE_RULE_ACTION_BLOCK },
                    { 'w-1p5': spacing === SMALL_SIZE },)}
                btnSize={spacing === SMALL_SIZE ? 'xsm' : 'sm'}
                className={classNames('policy__icon',
                    'm-0',
                    { 'icon--red': active === PROFILE_RULE_ACTION_BLOCK })}
                iconSize="sx"
                name="close-circle"
                onClick={onChangeBlock}
                outline
                tooltipText="policy:block.title"
            />
        </MDBBtnGroup>
    );
};
