/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';

import Message from '~frontendComponents/Message/Message.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';


const SshKernunTech = () => {
    return (
        <HlcfgSwitch
            align="spaceBetween"
            label={<Message message="widgets:Sshd.techAccess.title" />}
            pathGetter={hlcfgPathGetter.services.sshd.kernunTechnicians}
        />
    );
};


export default SshKernunTech;
