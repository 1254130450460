/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import { clusterInitDbReplicationRequest } from '~frontendRoot/ducks/clusterDbReplication/index.ts';
import { DB_REPLICATION_CONFIRM } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';


const DbReplicationConfirmModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleReinitialize = useCallback(() => {
        dispatch(clusterInitDbReplicationRequest());
        dispatch(setModalState({ modal: DB_REPLICATION_CONFIRM, value: false }));
    }, [ dispatch ]);

    const closeModal = () => {
        dispatch(setModalState({ modal: DB_REPLICATION_CONFIRM, value: false }));
    };

    return (
        <Modal
            body={t('widgets:dbReplication.warning.desc')}
            dangerous
            exitHandle={closeModal}
            headerClose
            headerText={'widgets:dbReplication.warning.title'}
            modalOpen
            negativeResponse={closeModal}
            negativeText={'widgets:global.close'}
            position="top-right"
            positiveResponse={handleReinitialize}
            positiveText={'widgets:dbReplication.reinitialize'}
        />
    );
};

export default DbReplicationConfirmModal;
