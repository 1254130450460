/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { createContext, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { Draggable } from '@hello-pangea/dnd';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { makeSelectSearchedTableItem } from '~frontendRoot/ducks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import {
    getTableSchema, deleteNormalize, setNormalize, addToNormalize, duplicateFromNormalize,
} from '~frontendDucks/hlcfgEditor/index.js';
import { getNamedObjectNetaddrScalarUuid, getNamedObjectNetaddrVectorUuid } from '~frontendDucks/hlcfgEditor/namedObjectsGettersAndSettersTS.ts';
import { NAMED_OBJECTS_NETADDR_SCALAR, NAMED_OBJECTS_NETADDR_VECTOR } from '~frontendRoot/constants/index.js';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { useMakeSelector } from '~frontendLib/hooks/defaultHooks.ts';


export const RowContext = createContext({ isScalar: false, search: '' } as { isScalar?: boolean, search: string });


const NamedObjectRow = ({ dataIndex, spacing, uuid }: CreateRowPropsType) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isScalar, search } = useContext(RowContext);
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);
    const scalarGetter = useMemo(getNamedObjectNetaddrScalarUuid, []);
    const vectorGetter = useMemo(getNamedObjectNetaddrVectorUuid, []);

    const schema = useSelector(state => getTableSchema(state, uuid));
    const data = useSelector(isScalar ? state => scalarGetter(state, uuid) : state => vectorGetter(state, uuid));

    const addRow = useCallback((addingAfter: boolean) => {
        dispatch(addToNormalize({
            type: isScalar ? 'netaddrScalar' : 'netaddrVector',
            typeId: isScalar ? NAMED_OBJECTS_NETADDR_SCALAR : NAMED_OBJECTS_NETADDR_VECTOR,
            addingAfter,
            uuidToAddBefore: data.id,
            successsText: `widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}`
        }));
    }, [ dispatch, data.id, isScalar ]);

    const addRowAfter = useCallback(() => addRow(true), [ addRow ]);

    const changeValue = useCallback(({ value, name, }) => {
        dispatch(setNormalize({ value, uuid, key: name }));
    }, [ dispatch, uuid ]);

    const duplicate = useCallback(() => {
        dispatch(duplicateFromNormalize({
            parent: isScalar ? NAMED_OBJECTS_NETADDR_SCALAR : NAMED_OBJECTS_NETADDR_VECTOR,
            uuid,
        }));
    }, [ dispatch, isScalar, uuid ]);

    const deleteRow = useCallback(() => {
        dispatch(deleteNormalize({ type: isScalar ? NAMED_OBJECTS_NETADDR_SCALAR :
            NAMED_OBJECTS_NETADDR_VECTOR, value: data.id }));
    }, [ dispatch, isScalar, data ]);

    if (!data.id) {
        return (
            <tr
                className="dataTableWidget__RowFirstRule"
                key="newRule"
            >
                <td colSpan={1}></td>
                <td
                    className="dataTableWidget__RowFirstRuleRow"
                    colSpan={2}
                >
                    <div className="pl-2">
                        <h2>{t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.add`)}</h2>
                        <p className="dataTableWidget__Rowtext">
                            {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.desc1`)}
                            <MDBBtn
                                className="dataTableWidget__RowAddButtons--button"
                                onClick={event => {
                                    event.preventDefault();
                                    addRow(false);
                                }}
                            >
                                <Icon name="plus" />
                                {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.add`)}
                            </MDBBtn>
                            {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.desc2`)}
                        </p>
                        <p>
                            {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.desc3`)}
                        (
                            <Icon
                                name="menu"
                                size="sm"
                            />)
                            {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.desc4`)}
                        </p>
                        <MDBBtn
                            className="dataTableWidget__RowAddButtons--button"
                            onClick={event => {
                                event.preventDefault();
                                addRow(false);
                            }}
                        >
                            <Icon name="plus" />
                            {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.add`)}
                        </MDBBtn>
                    </div>
                </td>
                <td colSpan={1}></td>
            </tr>
        );
    }
    const { name, value } = data;
    return (
        <Draggable
            //it needs string
            draggableId={String(uuid)}
            index={dataIndex}
            key={uuid}
        >
            {(provided) => (
                <>

                    <tr
                        className="dataTableWidget__RowAdd"
                        key={uuid + 'add'}
                    >
                        <td
                            className="dataTableWidget__RowAddRule"
                            colSpan={5}
                        >
                            <div className="dataTableWidget__RowAddButtons">
                                <MDBBtn
                                    className="dataTableWidget__RowAddButtons--button"
                                    onClick={event => {
                                        event.preventDefault();
                                        addRow(false);}
                                    }
                                >
                                    <Icon name="plus" />
                                    {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.add`)}
                                </MDBBtn>
                            </div>
                        </td>
                    </tr>
                    <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames(
                            'dataTableWidget__Row',
                            'dataTableWidget__Row--important',
                            { 'dataTableWidget__Row--match': searchMatches },

                        )}
                        key={uuid}
                    >
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                'dataTableWidget__cell--icon',
                                { 'dataTableWidget__cell--match': searchMatches },
                                { [`dataTableWidget__cell--${spacing}`]: spacing }
                            )}
                            {...provided.dragHandleProps}
                        >
                            <Icon
                                className="packetFilter__icon"
                                name="drag"
                                size="sm"
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing }
                            )}
                        >
                            <Input
                                className="dataTableWidget__RowInput packetFilter__nameInput"
                                id={'name' + uuid}
                                isName
                                name="name"
                                onChange={changeValue}
                                schema={schema}
                                value={name}
                                withoutBorder
                            />
                        </td>
                        <td
                            className={classNames(
                                'dataTableWidget__cell',
                                { [`dataTableWidget__cell--${spacing}`]: spacing }
                            )}
                        >
                            <Select
                                className="dataTableWidget__RowInput"
                                id={'value' + uuid}
                                isCreatable
                                isMulti

                                name="value"
                                noDropdownIndicator
                                noOptionsMessage
                                onChange={changeValue}
                                paste
                                schema={schema.value}
                                value={value}
                            />
                        </td>
                        <RowMenuAndSwitch
                            copyFunc={duplicate}
                            deleteFunc={deleteRow}
                            id={uuid}
                        />
                    </tr>
                    <tr
                        className="dataTableWidget__RowAddEnd"
                        key={uuid + 'addEnd'}
                    >
                        <td
                            className="dataTableWidget__RowAddRule"
                            colSpan={5}
                        >
                            <div className="dataTableWidget__RowAddButtons">
                                <MDBBtn
                                    className="dataTableWidget__RowAddButtons--button"
                                    onClick={addRowAfter}
                                >
                                    <Icon name="plus" />
                                    {t(`widgets:NamedObjects.${isScalar ? 'netaddrScalar' : 'netaddrVector'}.add`)}
                                </MDBBtn>
                            </div>
                        </td>
                    </tr>
                </>
            )}
        </Draggable>
    );
};

export default NamedObjectRow;
