/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ValuesType } from 'utility-types';

import { NetserviceData, NetserviceString
} from '~sharedLib/types.ts';
import { isNetportData, isNetportString, netport, Netport } from '~sharedLib/Netport/Netport.ts';
import { NetserviceBase } from '~sharedLib/Netservice/NetserviceBase.ts';
import { isStaticReference } from '~sharedLib/staticHlcfgReferenceUtils.ts';


const SUPPORTED_PORT_PROTOCOLS = <const>[ 'tcp', 'udp' ];

const SUPPORTED_PROTOCOLS = <const>[
    ...SUPPORTED_PORT_PROTOCOLS,
    'icmp',
    'icmpv6',
    'esp',
    'ah',
    'comp',
    'udplite',
    'dccp',
    'sctp',
    'vrrp',
    'gre',
];

export type SupportedPortProtocol = ValuesType<typeof SUPPORTED_PORT_PROTOCOLS>
export type SupportedProtocol = ValuesType<typeof SUPPORTED_PROTOCOLS>


export class Netservice extends NetserviceBase {
    ports: Netport[];
    protocol: string;

    constructor(service: NetserviceString | NetserviceData) {
        super();

        if (isNetserviceString(service)) {
            const { protocol, ports } = parseNetserviceStr(service);
            this.protocol = protocol;
            this.ports = ports.map(netport);
            return;
        }

        if (isNetserviceData(service)) {
            this.protocol = service.protocol;
            this.ports = service.ports.map(netport);
            return;
        }
        throw new Error(`Invalid service passed to Netservice: ${JSON.stringify(service)}`);
    }

    getProtocol() {
        return this.protocol;
    }

    isService(): this is Netservice {
        return true;
    }

    hasPorts() {
        return this.ports.length;
    }

    isSinglePort() {
        return this.ports.length === 1;
    }
    isMultiPort() {
        return this.ports.length > 1;
    }

    isProtocolOnly() {
        return this.ports.length === 0;
    }

    protocolSupportsPorts() {
        return isSupportedPortProtocol(this.protocol);
    }

    isEmpty() {
        return false;
    }

    toString() {
        if (!this.ports.length) {
            return this.protocol;
        }
        return `${this.protocol}:${this.ports.join(',')}`;
    }

    protocolIsValid() {
        return isSupportedProtocol(this.protocol);
    }

    portsAreValid() {
        return this.ports.every(port => port.isValid());
    }

    isValid() {
        return this.protocolIsValid() &&
            this.portsAreValid() &&
            (this.hasPorts() ? this.protocolSupportsPorts() : true);
    }
}

export const isSupportedPortProtocol = (proto: any): proto is SupportedPortProtocol =>
    SUPPORTED_PORT_PROTOCOLS.includes(proto);
export const isSupportedProtocol = (proto: any): proto is SupportedProtocol =>
    SUPPORTED_PROTOCOLS.includes(proto);

const parseNetserviceStr = (str: string) => {
    const [ protocol, portsStr ] = str.split(':');

    if (`${parseInt(protocol)}` === protocol) {
        return {
            protocol: 'tcp',
            ports: protocol.split(','),
        };
    }

    return {
        protocol: protocol,
        ports: portsStr ? portsStr.split(',') : []
    };
};

export const isNetserviceData = (data): data is NetserviceData => {
    return data &&
        ('protocol' in data) &&
        typeof data.protocol === 'string' && (isStaticReference(data.ports) ||
        (Array.isArray(data.ports) &&
        (data.ports.every(item => isNetportData(item) || isStaticReference(item.port)) ||
        isStaticReference(data.ports))));
};

export const isNetserviceDataArray = (data): data is NetserviceData[] => {
    return Array.isArray(data) ? data.every(isNetserviceData) : false;
};

const NETSERVICE_REGEX = /^([a-zA-Z]+(:([0-9-,]+))?)?([0-9-,]+)?$/;
export const isNetserviceString = (strService): strService is NetserviceString => {
    if (!strService || typeof strService !== 'string') {
        return false;
    }

    if (!NETSERVICE_REGEX.test(strService)) {
        return false;
    }

    const { protocol, ports } = parseNetserviceStr(strService);


    const isValidProtocol = isSupportedProtocol(protocol);
    const everyPortIsNetportStr = ports.every(isNetportString);

    return isValidProtocol && everyPortIsNetportStr;
};

export class InvalidServiceError extends Error {
    constructor(svc) {
        super(`Invalid service: "${svc}"`);
        this.name = 'InvalidServiceError';
    }
}

export type NetserviceReturn<T> =
    T extends NetserviceData | NetserviceString | string ? Netservice : never;

export type NetserviceParams = NetserviceData | string;

/**
 * Returns an object representing one of:
 * - a single network service
 * - the fact that no network service is specified
 *
 * A network service is a network protocol with an optional network port or
 * network port range.
 * Note that only some network protocols support having a network port.
 */
export const netservice = <T extends NetserviceParams>(svc: T): NetserviceReturn<T> => {
    type ItIsOkType = NetserviceReturn<T>;
    if (typeof svc === 'object' && isNetserviceData(svc)) {
        return new Netservice(svc) as ItIsOkType;
    }
    if (typeof svc === 'string' && isNetserviceString(svc)) {
        return new Netservice(svc) as ItIsOkType;
    }
    throw new InvalidServiceError(svc);
};

export const stringifyNetservice = (service: NetserviceParams) => {
    return netservice(service).toString();
};
