/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { isProductionMode } from './constants.ts';
import { objectPick, PathGetter } from './objectUtils.ts';


export type POChildren = Record<string, PODefinition>
type IdGetterWrapped<T> = T|((id: string) => T);
export interface PODefinitionSelect {
    id: string,
    type: 'select',
}
export interface PODefinitionStatusBox {
    id: string,
    type: 'statusBox',
}
export interface PODefinitionClickableStatusBox {
    id: string,
    type: 'clickableStatusBox',
}
export interface PODefinitionClickable {
    id: string,
    type: 'clickable',
}
export interface PODefinitionCardHandles {
    id: string,
    type: 'cardHandles',
    typeId?: undefined,
}
export interface PODefinitionContainer<T extends POChildren> {
    id: string,
    typeId?: string,
    type: 'container',
    child: T
}
export interface PODefinitionGroup<T extends POChildren> {
    id: undefined,
    type: 'group',
    child: T,
    typeId?: undefined,
}
export interface PODefinitionScene<T extends POChildren> {
    url: string,
    id: string,
    type: 'scene',
    child: T,
    typeId?: undefined,
}
export interface PODefinitionOnOffButtons {
    id: string,
    type: 'onOffButtons',
}
export interface PODefinitionSwitch {
    id: string,
    type: 'switch',
}
export interface PODefinitionTable<T extends PODefinition, T2 extends PODefinition = never> {
    id: string,
    type: 'table',
    getRow: (id: string) => T
    getDetail?: (id: string) => T2
}
export interface PODefinitionTextInput {
    id: string,
    type: 'textInput',
}
export interface PODefinitionCollapsible<T extends POChildren> {
    id: string,
    type: 'collapsible',
    child: T,
    typeId?: undefined,
}
export type PODefinition = PODefinitionCardHandles|PODefinitionTable<any, any>|
    PODefinitionClickable|IdGetterWrapped<PODefinitionContainer<any>>|PODefinitionSelect|
    PODefinitionStatusBox|PODefinitionClickableStatusBox|PODefinitionScene<any>|
    PODefinitionOnOffButtons | PODefinitionSwitch | PODefinitionTextInput|
    PODefinitionCollapsible<any>|PODefinitionGroup<any>;

export const TEST_ID_PROP = 'test-id';
export const TEST_TYPE_ID_PROP = 'test-type-id';
export const TEST_STATUS_PROP = 'test-status';
export interface TestProps {
    [TEST_ID_PROP]?: string,
    [TEST_STATUS_PROP]?: string,
}

export const poDef = {
    /**
     * Creates ID from path getter or path
     */
    pathId: (getHlcfgPath: PathGetter|string[]|readonly string[]): string => {
        if ('join' in getHlcfgPath) {
            // I hate TS sometimes. Why does it not allow me to just check Array.isArray() ...?
            // Why is readonly string[] not an array? Wut?
            return getHlcfgPath.join('.');
        }
        return getHlcfgPath.getPath().join('.');
    },
    typeIds: {
        tableRow: 'TableRow',
        tableRowDetail: 'Detail',
    },
    statusBox: (id: string): PODefinitionStatusBox => ({ id, type: 'statusBox' }),
    onOffButtons: (id: string): PODefinitionOnOffButtons => ({ id, type: 'onOffButtons' }),
    switch: (id: string): PODefinitionSwitch => ({ id, type: 'switch' }),
    /**
     * Containing element that can be collapsed to show only the handle
     */
    collapsible: <T extends POChildren>(id: string, child: T): PODefinitionCollapsible<T> =>
        ({ id, type: 'collapsible', child }),
    textInput: (id: string): PODefinitionTextInput => ({ id, type: 'textInput' }),
    /**
     * Logical group without containing element (or with containing element where providing ID would be bothersome)
     */
    group: <T extends POChildren>(child: T): PODefinitionGroup<T> =>
        ({ type: 'group', child, id: undefined }),
    /**
     * Containing element
     */
    container: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> =>
        ({ id, type: 'container', child }),
    tableRow: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> =>
        ({ id, type: 'container', child, typeId: poDef.typeIds.tableRow }),
    tableRowDetail: <T extends POChildren>(id: string, child: T): PODefinitionContainer<T> =>
        ({ id, type: 'container', child, typeId: poDef.typeIds.tableRowDetail }),
    clickableStatusBox: (id: string): PODefinitionClickableStatusBox => ({ id, type: 'clickableStatusBox' }),
    clickable: (id: string): PODefinitionClickable => ({ id, type: 'clickable' }),
    cardHandles: (id: string): PODefinitionCardHandles => ({ id, type: 'cardHandles' }),
    table: <T extends PODefinition, T2 extends PODefinition = never>(
        id: string, getRow: (id: string) => T, getDetail?: (id: string) => T2
    ): PODefinitionTable<T, T2> =>
        ({ id, type: 'table', getRow, getDetail }),
    select: (id: string): PODefinitionSelect => ({ id, type: 'select' }),
    /**
     * Containing element that represents URL of the application
     */
    scene: <T extends POChildren>(id: string, url: string, child: T): PODefinitionScene<T> =>
        ({ id, url, type: 'scene', child }),
} as const;


type TestPropsOpts = {status?: string, typeId?: string}
export const testPropsPassthrough = (props: Record<string, any>) =>
    objectPick(props, [ TEST_ID_PROP, TEST_STATUS_PROP, TEST_TYPE_ID_PROP ]);
export const testProps = (id: string, opts: TestPropsOpts = {}) => {
    if (isProductionMode) {
        return {};
    }
    const props = {
        [TEST_ID_PROP]: id,
    };
    if (opts.status) {
        props[TEST_STATUS_PROP] = opts.status;
    }
    if (opts.typeId) {
        props[TEST_TYPE_ID_PROP] = opts.typeId;
    }
    return props;
};
export const testPropsPoDef = (
    poDef: Extract<PODefinition, {id: string}>, opts: Omit<TestPropsOpts, 'typeId'> = {}
) => {
    if ('typeId' in poDef) {
        return testProps(poDef.id, { ...opts, typeId: poDef.typeId });
    }
    return testProps(poDef.id, opts);
};
export const testPropsStatus = (id: string, status: string) => {
    if (isProductionMode) {
        return {};
    }
    return {
        [TEST_ID_PROP]: id,
        [TEST_STATUS_PROP]: status,
    };

};

export const testPropsCollapsible = (
    id: string, isOpen: boolean,
    containerOpts: Omit<TestPropsOpts, 'status'> = {}
) => {
    return {
        containerProps: testProps(id, { ...containerOpts, status: isOpen ? 'open' : 'closed' }),
        buttonProps: testProps(id + '__collapse-btn'),
    };

};
