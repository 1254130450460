/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle, } from 'mdbreact';

import { SuricataPortGroupsSelect, SuricataAddressGroupsSelect } from '~frontendComponents/SuricataVariablesInput.tsx';
import Message from '~frontendComponents/Message/index.js';
import { DEFAULT_SURICATA_VARIABLES, ADVANCED_SURICATA_VARIABLES, DEFAULT_SURICATA_PORTS,
    ADVANCED_SURICATA_PORTS } from '~frontendRoot/constants/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import { JSXElement } from '~commonLib/types.ts';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { testPropsCollapsible } from '~commonLib/PageObjectMap.ts';
import { suricataVarsSceneMap } from '~frontendRoot/scenes/Protection/scenes/Ips/components/SuricataVariables/pageObjectMap.ts';


export const SuricataPortGroupsVars = () => {
    const portGroupsPath = hlcfgPathGetter.protection.suricata.service.variables.portGroups;
    return (
        <SuricataVarsLayout title={'widgets:Suricata.variables.portGroups.title'}>
            {DEFAULT_SURICATA_PORTS.map(item => (
                <SuricataPortGroupsSelect
                    key={item}
                    pathGetter={portGroupsPath[item]}
                />
            ))}

            <SuricataVarsAdvanced id={suricataVarsSceneMap.child.advancedPorts.id}>
                {ADVANCED_SURICATA_PORTS.map(item => (
                    <SuricataPortGroupsSelect
                        key={item}
                        pathGetter={portGroupsPath[item]}
                    />
                ))}
            </SuricataVarsAdvanced>
        </SuricataVarsLayout>
    );
};
export const SuricataAddressGroupsVars = () => {
    const addrGroupsPath = hlcfgPathGetter.protection.suricata.service.variables.addressGroups;
    return (
        <SuricataVarsLayout title={'widgets:Suricata.variables.addressGroups.title'}>
            {DEFAULT_SURICATA_VARIABLES.map(item => (
                <SuricataAddressGroupsSelect
                    key={item}
                    pathGetter={addrGroupsPath[item]}
                />
            ))}

            <SuricataVarsAdvanced id={suricataVarsSceneMap.child.advancedAddrs.id}>
                {ADVANCED_SURICATA_VARIABLES.map(item => (
                    <SuricataAddressGroupsSelect
                        key={item}
                        pathGetter={addrGroupsPath[item]}
                    />
                ))}
            </SuricataVarsAdvanced>
        </SuricataVarsLayout>
    );
};

const SuricataVarsAdvanced = ({ children, id }: {children: JSXElement, id:string}) => {
    const [ advanced, setAdvanced ] = useBoolean();
    const testProps = testPropsCollapsible(id, advanced);
    return (
        <div {...testProps.containerProps}>
            <IconWithTooltip
                className="icon--secondary"
                name={advanced ? 'chevron-up' : 'chevron-down'}
                onClick={setAdvanced.swap}
                tooltipText={`widgets:global.${advanced ? 'showLess' : 'showMore'}`}
                {...testProps.buttonProps}
            />
            {advanced ? children : null}
        </div>
    );
};

const SuricataVarsLayout = ({ children, title }: {children: JSXElement, title: string}) => {
    return (
        <MDBCard className="card--withoutMH">
            <MDBCardTitle>
                <Message message={title} />
            </MDBCardTitle>
            <MDBCardBody>

                {children}
            </MDBCardBody>
        </MDBCard>
    );
};
