/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Modal } from '~frontendComponents/Generic/index.js';
import { CONFIRM_LOGOUT_MODAL } from '~frontendRoot/constants/index.js';
import { setModalState } from '~frontendRoot/ducks/modals/index.js';
import { logoutRequest } from '~frontendRoot/ducks/userAuthentication/ducks/login.js';


const ConfirmLogoutModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const closeModal = () => {
        dispatch(setModalState({ modal: CONFIRM_LOGOUT_MODAL, value: false }));
    };

    const doLogout = () => {
        dispatch(setModalState({ modal: CONFIRM_LOGOUT_MODAL, value: false }));
        dispatch(logoutRequest());
    };

    return (
        <Modal
            body={t('accounting:actions.logout.confirmModal.body')}
            dangerous
            exitHandle={closeModal}
            headerClose
            headerText={t('accounting:actions.logout.confirmModal.header')}
            modalOpen
            negativeResponse={closeModal}
            negativeText={t('accounting:actions.logout.confirmModal.negative')}
            positiveResponse={doLogout}
            positiveText={t('accounting:actions.logout.confirmModal.positive')}
        />
    );
};

export default ConfirmLogoutModal;
