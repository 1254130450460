/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { ValuesType } from 'utility-types';

import { SimpleFromSchema } from '~commonLib/simpleFromSchema.ts';
import {
    DEFAULT_SCHEMA_VALUE,
    DURATION_SCHEMA_VALUE,
    PASSWORD_SCHEMA_VALUE,
    PLACEHOLDER_SCHEMA_VALUE,
    TRANSLATION_SCHEMA_VALUE,
    SYSTEM_ONLY_SETTABLE_ENUMS, CLI_OPT_SHORTHAND, CLI_OPT_AUTOCOMPLETE
} from '~commonLib/schemaFlags.ts';

import { okResponseSchema } from '../schemas/okResponseSchema.ts';
import { genericInternalServerErrorSchema } from '../schemas/genericInternalServerErrorSchema.ts';


export const apiCallDef = ({
    method = 'post',
    controllerName,
    controllerFn,
    description = '',
    tags = [],
    requestBodySchema = emptyObjectSchema,
    security = [],
    okResponseDataSchema,
}) => {
    const apiObj = {
        [method]: {
            'x-exegesis-controller': controllerName,
            operationId: controllerFn,
            responses: {
                '200': okResponseSchema(okResponseDataSchema),
                '500': genericInternalServerErrorSchema
            }
        }
    };

    const body = apiObj[method] as Record<string, any>;

    if (method === 'post') {
        body.requestBody = {
            content: {
                'application/json': {
                    schema: requestBodySchema
                }
            }
        };
    }
    if (description) {
        body.summary = description;
        body.description = description;
    }
    if (tags.length) {
        body.tags = tags;
    }
    if (security.length) {
        body.security = security;
    }
    return apiObj;
};


export type SchemaTestHelperInner<T> = SimpleFromSchema<T>;
export type SchemaTestHelper<T> = SchemaTestHelperInner<T> extends never ?
    'fromSchNever' : SchemaTestHelperInner<T>;


interface ObjSch<T, T2 extends readonly string[]> {
    readonly type: 'object',
    readonly required: T2,
    readonly additionalProperties: false,
    readonly properties: T,
}
export const object = <
    const T,
    const T2 extends readonly (keyof T&string)[]
>(properties: T, required: T2): ObjSch<T, T2> => {
    return <const>{
        type: 'object',
        required: required,
        additionalProperties: false,
        properties,
    };
};

interface CommonAdditional {

    readonly description?: string,
    readonly [CLI_OPT_SHORTHAND]?: string
    readonly [CLI_OPT_AUTOCOMPLETE]?: 'file'|'directory'|{shell: string},
}

type RecordRet<T> = {
    readonly type: 'object',
    readonly additionalProperties: T
}
export const record = <const T>(itemSchema: T, additional = <const>{}): RecordRet<T> => <RecordRet<T>>{
    type: 'object',
    // maybe this should be anyOf: [undefined, itemSchema],
    // because like this we are not forced to check for property existence
    // and thus easily get property access on undefined
    additionalProperties: itemSchema,
    ...additional
};


interface IntAdditional<T> extends CommonAdditional {
    readonly minimum?: number,
    readonly maximum?: number,
    readonly [DEFAULT_SCHEMA_VALUE]?: T,
    readonly [DURATION_SCHEMA_VALUE]?: boolean,
    readonly [PLACEHOLDER_SCHEMA_VALUE]?: string,
}
interface IntSch<T> extends IntAdditional<T> {
    readonly type: 'integer',
}
export const integer = <const T = undefined>(additional: IntAdditional<T> = {}): IntSch<T> => <const>{
    type: 'integer',
    ...additional,
};

interface StrAdditional <T> extends CommonAdditional {
    readonly format?: string,
    readonly pattern?: string,
    readonly [DEFAULT_SCHEMA_VALUE]?: T,
    readonly minLength?: number,
    readonly maxLength?: number,
    readonly [PASSWORD_SCHEMA_VALUE]?: boolean
}
export interface StrSch<T> extends StrAdditional<T> {
    readonly type: 'string',
}

export const string = <const T = undefined>(additional: StrAdditional<T> = {}): StrSch<T> => <const>{
    type: 'string',
    ...additional,
};

interface BoolAdditional<T> extends CommonAdditional {
    readonly [DEFAULT_SCHEMA_VALUE]?: T,
}
interface BoolSch<T> extends BoolAdditional<T> {
    readonly type: 'boolean',
}
export const boolean = <const T = undefined>(additional: BoolAdditional<T> = {}): BoolSch<T> => <const>{
    type: 'boolean',
    ...additional
};

interface EnumAdditional<T extends readonly PropertyKey[]> extends CommonAdditional {
    readonly [DEFAULT_SCHEMA_VALUE]?: ValuesType<T>,
    readonly [SYSTEM_ONLY_SETTABLE_ENUMS]?: T
}
export interface EnumSch<T extends readonly PropertyKey[]> extends EnumAdditional<T> {
    readonly enum: T,
}
export const enumeration = <const T extends readonly PropertyKey[]>(
    items: T, additional: EnumAdditional<T> = {}
): EnumSch<T> => <const>{
    // Turns out also specifying type breaks exegesis.
    // type: 'enum',
    enum: items,
    ...additional
};

export interface ArrayAdditional<Def = undefined> extends CommonAdditional {
    readonly minItems?: number,
    readonly maxItems?: number,
    readonly additionalItems?: any,
    readonly [TRANSLATION_SCHEMA_VALUE]?: string,
    readonly [PLACEHOLDER_SCHEMA_VALUE]?: string,
    readonly [DEFAULT_SCHEMA_VALUE]?: Def
}
export interface ArrSch<T, Def> extends ArrayAdditional<Def> {
    readonly type: 'array',
    readonly items: T,
}

export const array = <
    const T, const Def = undefined
>(itemSchema: T, additional: ArrayAdditional<Def> = {}): ArrSch<T, Def> => <const>{
    type: 'array',
    items: itemSchema,
    ...additional,
};


export const undefinedSchema = { enum: [ undefined ] };

export const emptyObjectSchema = <const>{
    type: 'object',
    additionalProperties: false,
    description: 'Has optional prop because otherwise undesired type {} is generated, ' +
        'which means any non-nullish value',
    properties: {
        __thisObjectIsEmpty: boolean()
    }
};
export type EmptyObjectT = {
    __thisObjectIsEmpty?: boolean,
}


export const findSchemaByObjectPathAndSchema = (objectPath: PropertyKey[], schema) => {
    if (!schema) {
        return;
    }

    let traversingSchema = schema;

    for (const pathSegment of objectPath) {
        traversingSchema =
            traversingSchema.properties ? traversingSchema.properties[pathSegment] :
                traversingSchema.additionalProperties || traversingSchema.items;
        if (!traversingSchema) {
            return;
        }
    }

    return traversingSchema;
};
export const findSchemaPathByObjectPathAndSchema = (objectPath: string[], schema): string[] => {
    if (!schema) {
        return [];
    }

    const path: string[] = [];
    let traversingSchema = schema;

    for (const pathSegment of objectPath) {
        if (traversingSchema.properties) {
            traversingSchema = traversingSchema.properties[pathSegment];
            path.push('properties');
            path.push(pathSegment);
        } else if (traversingSchema.additionalProperties) {
            traversingSchema = traversingSchema.additionalProperties;
            path.push('additionalProperties');
        } else {
            traversingSchema = traversingSchema.items;
            path.push('items');
        }
    }

    return path;
};
