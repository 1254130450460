/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback, useMemo } from 'react';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon } from '~frontendComponents/Generic/index.js';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SelectOption, SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { ENUM_COLOR, ENUM_COLOR_TRANSLUCENT, FULLY_TRANSPARENT_COLOR } from '~frontendConstants/constants.ts';
import { IconNameType } from '~frontendComponents/Generic/Icon/Icon.tsx';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';


export type EnumSelectValue = string|number;
export interface EnumSelectProps extends SelectV2BaseProps {
    isRequired: boolean,
    enumValueTranslationPathPrefix: string,
    enumeration: EnumSelectValue[],
    hideValuesFromMenu?: EnumSelectValue[],
    value: EnumSelectValue|undefined,
    valueIcons?: Record<EnumSelectValue, {name: IconNameType, className?: string}>,
    onChange: (newValue: EnumSelectValue|undefined) => void,
}
export const EnumSelect = (props: EnumSelectProps) => {
    const {
        enumeration, hideValuesFromMenu, isRequired, enumValueTranslationPathPrefix, valueIcons, ...selectProps
    } = props;

    const { t } = useTranslation();

    const prepareOption = useCallback((value: EnumSelectValue): SelectOption<EnumSelectValue> => {
        const asText = t(`${enumValueTranslationPathPrefix}.${value}`);
        const icon = valueIcons?.[value];
        if (icon) {
            return {
                shortLabel: (
                    <IconWithTooltip
                        className="icon--secondary"
                        dontBlur
                        iconSize="sm"
                        tooltipText={asText}
                        {...icon}
                    />
                ),
                label: (
                    <div className="profile__select">
                        <div>
                            <Icon
                                className="icon--secondary"
                                dontBlur
                                size="sm"
                                {...icon}
                            />
                        </div>
                        <span className="profile__select--text">
                            {asText}
                        </span>
                    </div>
                ),
                searchStrings: [ asText ],
                value, notRemovable: isRequired,
                backgroundColor: FULLY_TRANSPARENT_COLOR,
                disabledBackgroundColor: FULLY_TRANSPARENT_COLOR,
            };
        }
        return {
            label: asText, value, notRemovable: isRequired,
            backgroundColor: FULLY_TRANSPARENT_COLOR,
            disabledBackgroundColor: FULLY_TRANSPARENT_COLOR,
        };
    }, [ isRequired, enumValueTranslationPathPrefix, t, valueIcons ]);

    const options = useMemo(
        () => hideValuesFromMenu ? enumeration.filter(it => !hideValuesFromMenu.includes(it)) : enumeration,
        [ hideValuesFromMenu, enumeration ]
    );
    return (
        <SelectV2
            options={options}
            prepareOption={prepareOption}
            {...selectProps}
            {...useSingleValueSelectWrapper(props)}
        />
    );
};

interface EnumArraySelectProps extends SelectV2BaseProps {
    enumValueTranslationPathPrefix: string,
    enumeration: EnumSelectValue[],
    value: EnumSelectValue[],
    staticValues?: EnumSelectValue[],
    onChange: (newValue: EnumSelectValue[]) => void,
}
export const EnumArraySelect = (props: EnumArraySelectProps) => {
    const { enumeration, enumValueTranslationPathPrefix, staticValues, ...selectProps } = props;

    const { t } = useTranslation();

    const prepareOption = useCallback((value: EnumSelectValue): SelectOption<EnumSelectValue> => {
        const isStatic = staticValues?.includes(value);
        return {
            label: t(`${enumValueTranslationPathPrefix}.${value}`), value,
            backgroundColor: isStatic ? ENUM_COLOR_TRANSLUCENT : ENUM_COLOR,
            notRemovable: isStatic,
        };
    }, [ enumValueTranslationPathPrefix, t, staticValues ]);

    return (
        <SelectV2
            options={enumeration}
            prepareOption={prepareOption}
            {...selectProps}
        />
    );
};
