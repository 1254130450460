/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCardTitle, MDBCol } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import valueFormatter from '~sharedLib/reporterLibrary/valueFormatter.js';
import { HlcfgTypeIpsec, HlcfgTypeIpsecSchema } from '~frontendTypes/externalTypes.ts';
import { InputCallbackFunction } from '~frontendConstants/types.ts';
import { DEFAULT_SCHEMA_VALUE } from '~commonLib/schemaFlags.ts';
import { UNSAFE_DH_GROUPS } from '~frontendConstants/constants.ts';


interface Phase1Props {
    schemas: HlcfgTypeIpsecSchema,
    data: HlcfgTypeIpsec,
    changeValue: InputCallbackFunction,
    timeoutValidator: InputCallbackFunction
    setTimeout: InputCallbackFunction

}

const Phase2 = ({ schemas, data, changeValue, timeoutValidator, setTimeout }: Phase1Props) => {
    const [ show, setShow ] = useState(false);
    const { t } = useTranslation();
    const {
        espEncryption, espDhGroup, espHashing, lifetimeSeconds } = data;
    return (
        <MDBCard className="card--withoutMH pb-0">
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': show },
            )}
            >
                <div
                    className="clicable"
                    onClick={() => setShow(!show)}
                >
                    <>
                        {t('widgets:Ipsec.faze.2')}
                        <Icon
                            name={show ? 'chevron-up' : 'chevron-down'}
                        />
                    </>
                </div>
            </MDBCardTitle>

            <MDBCardBody className={classNames('cardHide__body', 'pb-0',
                { 'cardHide__body--hide': !show },
                { 'cardHide__title--borderBottom': show })}
            >
                <MDBRow>
                    <MDBCol>
                        <Input
                            id={'lifetimeSeconds'}
                            label={t('differs:tables.ipsec.lifetimeSeconds')}
                            name="lifetimeSeconds"
                            onChange={setTimeout}
                            placeholder={
                                valueFormatter.formatSeconds(schemas.lifetimeSeconds[DEFAULT_SCHEMA_VALUE])}
                            schema={schemas.lifetimeSeconds}
                            validator={timeoutValidator}
                            value={lifetimeSeconds}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="espEncryption"
                            label={t('differs:tables.ipsec.espEncryption.title')}
                            name="espEncryption"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.espEncryption}
                            value={espEncryption}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="espHashing"
                            label={t('differs:tables.ipsec.espHashing.title')}
                            name="espHashing"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.espHashing}
                            value={espHashing}
                        />
                    </MDBCol>
                    <MDBCol>
                        <Select
                            id="espDhGroup"
                            label={t('differs:tables.ipsec.espDhGroup.title')}
                            name="espDhGroup"
                            onChange={changeValue}
                            paste={false}
                            schema={schemas.espDhGroup}
                            value={espDhGroup}
                            warning={espDhGroup === UNSAFE_DH_GROUPS ? t('global:DhGroupConnectionWarning') : false}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};

export default Phase2;
