/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { useCallback } from 'react';

import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { useStaticHlcfgReferenceModelWrap } from '~frontendComponents/Generic/SelectV2/staticHlcfgReferenceModelWrapper.tsx';
import { SelectModelWrap, SelectOption, SelectV2BaseProps, SelectV2Props } from '~frontendComponents/Generic/SelectV2/types.ts';
import { useSingleValueSelectWrapper } from '~frontendComponents/Generic/SelectV2/utils.ts';
import { SERVICE_COLOR, SERVICE_OPTIONS } from '~frontendConstants/constants.ts';
import { netserviceValidate } from '~sharedLib/Netservice/lib/netserviceValidate.ts';
import { netservice, stringifyNetservice } from '~sharedLib/Netservice/Netservice.ts';
import { NetserviceData, TypeNetservice } from '~sharedLib/types.ts';


export type NetserviceSelectValue = NetserviceData;
interface NetserviceModelProps {
    netserviceType: TypeNetservice,
    modelWrap?: SelectModelWrap<NetserviceSelectValue, any>,
    onChange: (newValue: unknown) => void,
}
export interface NetserviceSelectProps extends SelectV2BaseProps, NetserviceModelProps {
    value: NetserviceSelectValue|undefined,
    onChange: (newValue: NetserviceSelectValue|undefined) => void,
}

export const NetserviceSelectBase = (props: NetserviceSelectProps) => {
    return (
        <SelectV2
            {...props}
            {...useNetserviceSelectModel(props)}
            {...useSingleValueSelectWrapper(props)}
        />
    );
};
export const NetserviceSelect = (props: Omit<NetserviceSelectProps, 'modelWrap'>) => {
    return (
        <NetserviceSelectBase
            {...props}
            modelWrap={useStaticHlcfgReferenceModelWrap(val => val.ports, { noStringifyForCopyOverride: true })}
        />
    );
};

export interface NetserviceArraySelectProps extends SelectV2BaseProps, NetserviceModelProps {
    value: NetserviceSelectValue[],
    onChange: (newValue: NetserviceSelectValue[]) => void,
}
export const NetserviceArraySelectBase = (props: NetserviceArraySelectProps) => {
    return (
        <SelectV2
            {...props}
            {...useNetserviceSelectModel(props)}
        />
    );
};
export const NetserviceArraySelect = (props: Omit<NetserviceArraySelectProps, 'modelWrap'>) => {
    return (
        <NetserviceArraySelectBase
            {...props}
            modelWrap={useStaticHlcfgReferenceModelWrap(val => val.ports, { noStringifyForCopyOverride: true })}
        />
    );
};

const PREDEFINED_SERVICES = Object.values(SERVICE_OPTIONS);
const NETSERVICE_OPTIONS = PREDEFINED_SERVICES.map(it => netservice(it.value).toObject());
const NETSERVICE_LABEL_BY_STRINGIFIED = Object.fromEntries(PREDEFINED_SERVICES.map(it => [ it.value, it.label ]));

export const useNetserviceSelectModel = (
    {
        netserviceType,
        modelWrap = model => model,
        onChange,
    }: NetserviceModelProps
) => {
    const prepareOption = useCallback((value: NetserviceSelectValue): SelectOption<NetserviceSelectValue> => {
        const netserviceStringified = netservice(value).toString();
        const label = NETSERVICE_LABEL_BY_STRINGIFIED[netserviceStringified] ?? netserviceStringified;

        return {
            label, value,
            searchStrings: [ netserviceStringified ],
            backgroundColor: SERVICE_COLOR,
            tooltip: label === netserviceStringified ? undefined : netserviceStringified,
        };
    }, []);

    const parse = useCallback((strVal: string) => {
        try {
            const nets = netservice(strVal);
            const errors = netserviceValidate(it => it, nets, netserviceType);
            if (errors.length) {
                return undefined;
            }
            return { parsed: nets.toObject() };
        } catch (err) {
            return undefined;
        }
    }, [ netserviceType ]);

    const clipboardParse = useCallback((str: string) => {
        try {
            const parsed = JSON.parse(str);
            if (!Array.isArray) {
                return [];
            }
            return parsed;
        } catch (_err) {
            return [];
        }

    }, []);

    return modelWrap({
        prepareOption,
        onChange,
        parse,
        stringifyForCopy: JSON.stringify,
        clipboardParse,
        options: NETSERVICE_OPTIONS,
        stringify: stringifyNetservice,
    }) satisfies Partial<SelectV2Props<unknown>>;
};
