/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import PropTypes from 'prop-types';
import { PayloadAction } from '@reduxjs/toolkit';

import { AnyFuncMaybeAsync } from '~commonLib/types.ts';
import { HlcfgTableName } from '~frontendRoot/types/externalTypes.ts';
import { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.ts';

import createBetterChainableTypeChecker, { Isable } from './betterPropTypes.ts';


export const OptionalBool: Isable<boolean> = createBetterChainableTypeChecker(PropTypes.bool);
export const OptionalString: Isable<string> = createBetterChainableTypeChecker(PropTypes.string);
export const OptionalNumber: Isable<number> = createBetterChainableTypeChecker(PropTypes.number);

const Mdbsize = <const>[ '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', 'auto', undefined ];
export const MDBColSizeProp = PropTypes.oneOf(
    Mdbsize
);

export type MDBColSizeType = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | undefined;

const SwitchAlign = <const>[ 'left', 'center', 'right', 'spaceBetween', 'start' ];

export const SwitchAlignProp = PropTypes.oneOf(
    SwitchAlign
);
export type SwitchAlignType = PropTypes.InferProps<typeof MDBColSizeProp>

type InputCallbackFucntionsParams = {
    id: string,
    name: string,
    value: string | string[] | boolean
}

export type InputCallbackFunction = (params:InputCallbackFucntionsParams) => void

export type DefaultInputType = {
    onChange: InputCallbackFunction,
    name: string,
    id: string,
}

export type SelectOptions = {
    value: any,
    label: JSX.Element,
    tooltipMessage?: JSX.Element,
}[]

export type SelectOptionsHeader = {
    label: string | JSX.Element;
    options: SelectOptions;
    color: string;
}[]

declare global {
    interface Window {
      Cypress: any;
    }
}

export type ApiCallerPayload<T extends AnyFuncMaybeAsync>= PayloadAction<Awaited<ReturnType<T>>['data']>

export type DashboardDefinitionType = {
    [k: string]: {
        dashboardIds: {
            id: string,
            title?: string,
            columnName?: string,
        }[],
        title: string,
    }
}

export type DashboardDefinitionFilterValuesType = {
    name?: string,
    filter: string,
    color?: string,
}[]

export type HlcfgDirtyTableItem<T extends HlcfgTableName> = HlcfgDirtyTree['tables'][T]['k']
export type Offable <T extends HlcfgTableName> = HlcfgDirtyTableItem<T>

export type OffableNftItem = Offable<'nftDivider' | 'nftRule'>;

export type CreateSelectorWithUuid = <T>(state: any, uuid: string) => T

export const dangerouslyCast = <T>(val: any) => val as T;
