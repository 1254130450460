/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext, useMemo, useCallback } from 'react';
import { MDBCard, MDBCardTitle } from 'mdbreact';
import { useDispatch } from 'react-redux';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { setNormalize } from '~frontendDucks/hlcfgEditor/index.js';
import { ACTUAL_LEASES_TYPE, NEW_ROW_CONSTANT } from '~frontendRoot/constants/index.js';
import { InputSearch } from '~frontendComponents/Generic/index.js';
import { useString } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { HlcfgRowId } from '~sharedLib/hlcfgTableUtils.ts';

import { DhcpdDatatableContext } from '../DhcpdDatatableContext/index.ts';
import Row from './components/PoolOrStaticRow/index.ts';


const CreateRow = ({ dataIndex,
    spacing, uuid, search }: CreateRowPropsType) => {
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            search={search}
            spacing={spacing}
            uuid={uuid || NEW_ROW_CONSTANT}
        />
    );
};

const DhcpdDatatable = ({ ids }: { ids: HlcfgRowId[]}) => {
    const [ search, setSearch ] = useString('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { pools, parentUuid, type } = useContext(DhcpdDatatableContext);

    const createNewOrder = useCallback((order) => {
        dispatch(setNormalize({ value: order, pools, uuid: parentUuid, key: pools ? 'pools' : 'leases' }));
    }, [ dispatch, pools, parentUuid ]);

    const data = useMemo(() => ids || [ NEW_ROW_CONSTANT ], [ ids ]);

    return (
        <MDBCard>
            <MDBCardTitle className="dhcpd__cardTitle profiles__title">
                <div>
                    {type !== ACTUAL_LEASES_TYPE && t(`widgets:Dhcpd.type.${type}`)}
                </div>
                <div className="profiles__titleActions">
                    <InputSearch
                        className="mb-3 mt-0"
                        id={`searchValueID${type}`}
                        search={search}
                        setter={setSearch}
                    />
                </div>
            </MDBCardTitle>
            <DatatableWidget
                className={'pb-0'}
                columnsId={type}
                compact
                createRow={CreateRow}
                data={data}
                passReorderData={createNewOrder}
                search={search}
            />
        </MDBCard>

    );
};

export default DhcpdDatatable;
