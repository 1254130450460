/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBModalFooter, MDBRow } from 'mdbreact';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Checkbox, Tooltip } from '~frontendComponents/Generic/index.js';
import Message from '~frontendRoot/components/Message/index.js';
import { UPGRADE_CONFIRM_MODAL } from '~frontendRoot/constants/index.js';
import { getGlcfgValue, getUpgradeNotice } from '~frontendRoot/ducks/hlcfgEditor/index.js';
import { getSelectedLanguage } from '~frontendRoot/ducks/language/index.js';
import { getModalState, setModalState } from '~frontendRoot/ducks/modals/index.js';
import { useBoolean } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { buildnumParse } from '~commonLib/buildnumParse.ts';
import Loader from '~frontendComponents/Loader/Loader.tsx';
import { useTryHlcfgUpgrade, useUpgradeCommitMutation, useWarningsToNextVersionQuery } from '~frontendQueries/upgrade/hooks.ts';
import Icon from '~frontendComponents/Generic/Icon/Icon.tsx';

import DeploymentModal from './DeploymentModal.tsx';


type VersionWarnings = {
    version: string
    warnings: Languages[]
}

type Languages = {
    cs: string
    en: string
}


export const UpgradeModal = () => {

    const dispatch = useDispatch();
    const [ accept, setAccept ] = useBoolean(false);


    const withConfirming = useSelector(state => getGlcfgValue(state, 'upgrade').withConfirming);
    const userNotes = useSelector(getUpgradeNotice);
    const upgradeConfirmModal = useSelector(state => getModalState(state, UPGRADE_CONFIRM_MODAL));
    const isFiveMinuteTimer = useSelector(state => getGlcfgValue(state, 'upgrade').isFiveMinuteTimer);

    const { mutate: commitUpgrade } = useUpgradeCommitMutation();

    const onUpgrade = () => {
        commitUpgrade({ timeout: isFiveMinuteTimer ? 5 : 20 });
        closeModal();
    };

    const closeModal = () => {
        dispatch(setModalState({ modal: UPGRADE_CONFIRM_MODAL, value: false }));
    };

    const { data, isFetching } = useTryHlcfgUpgrade();
    const hlcfgUpgradeError = data !== undefined && data.errors.length !== 0;
    const canProceed = accept && !isFetching && !hlcfgUpgradeError;

    return (
        <DeploymentModal
            className="cluster__modal"
            headerText="upgrade:upgrade.header"
            modalOpen={upgradeConfirmModal}
            type={UPGRADE_CONFIRM_MODAL}
        >
            <MDBRow
                className="m-3"
            >
                <MDBCol>
                    {!withConfirming && upgradeConfirmModal &&
                        <p className="infobox infobox--primary mb-0">
                            <Message message="upgrade:userConfirmNotActive" />
                        </p>
                    }
                    <p className="infobox infobox--info mt-1">
                        <Message message="upgrade:upgrade.desc" />
                    </p>
                    <MDBCard className="card--forUpgrade">
                        <MDBCardHeader>
                            <Message message="upgrade:upgradeNotice.title" />
                        </MDBCardHeader>
                        <MDBCardBody className="upgrade__cardBody upgrade__cardBody--small">
                            <div>
                                <p
                                    className="mb-0 infobox--keepSpace"
                                    data-cy="userNoticeWarning"
                                >
                                    <Message message={userNotes ? userNotes : 'upgrade:upgradeNotice.noUpgradeNotice'} />
                                </p>
                            </div>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                <MDBCol>
                    <MDBCard className="card">
                        <MDBCardHeader>
                            <Message message="upgrade:upgrade.allWarnings" />
                        </MDBCardHeader>
                        <MDBCardBody className="upgrade__cardBody">
                            <WarningsWithHeaders />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <MDBModalFooter className="authentication__footer">
                <MDBBtn
                    color="secondary"
                    onClick={closeModal}
                >
                    <Message message="widgets:global.back" />
                </MDBBtn>
                <div className="spaceBetween h-2h">
                    <div className="spaceBetween mt-2">
                        <Message
                            message="upgrade:upgrade.acceptWarnings"
                        />
                        <Checkbox
                            checked={accept}
                            className="mt-0 mb-0 ml-1"
                            id="acceptWarnings"
                            name="showHidden"
                            onChange={setAccept.swap}
                        />
                    </div>
                    <Tooltip
                        content={
                            hlcfgUpgradeError ?
                                <Message message="upgrade:upgrade.button.tryHlcfgUpgradeFailed" /> :
                                null
                        }
                    >
                        <MDBBtn
                            color="danger"
                            disabled={!canProceed}
                            onClick={onUpgrade}
                        >
                            {isFetching ? (
                                <Icon
                                    className="upgrade__loaderIcon"
                                    name="loading"
                                />
                            ) : null}
                            <Message message="upgrade:upgrade.button.prepared" />
                        </MDBBtn>
                    </Tooltip>
                </div>
            </MDBModalFooter>
        </DeploymentModal>
    );
};

const WarningsWithHeaders = () => {

    const { t } = useTranslation();

    const { isLoading, data: warnings } = useWarningsToNextVersionQuery();
    const language =  useSelector(getSelectedLanguage);

    if (isLoading) {
        return <Loader />;
    }
    assert(warnings);
    return (
        <>
            {warnings.map((version: VersionWarnings) => {
                return (
                    <>
                        <div
                            className="changes__headline"
                            key={version.version}
                        >
                            {t('changes:header.warning')}
                            {buildnumParse(version.version).productVersion}
                        </div>
                        <ul className="pl-3">
                            {version.warnings.map(warning => {
                                return (
                                    <li key={warning[language]}>
                                        {warning[language]}
                                    </li>
                                );
                            })}
                        </ul>
                    </>
                );
            })}
        </>
    );
};
