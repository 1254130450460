/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { netport } from '~sharedLib/Netport/Netport.ts';
import { NetportStr } from '~sharedLib/types.ts';
import { notEmpty } from '~commonLib/arrayUtils.ts';
import { NotUndefined } from '~commonLib/types.ts';
import { HlcfgDirtyTree } from '~sharedLib/HlcfgDirtyTree.ts';


type HoneypotPorts = NotUndefined<HlcfgDirtyTree['protection']['honeypot']>['ports'];
export const portsForHoneypot = (ports: HoneypotPorts): HoneypotPorts => {
    return ports.map(netport).map(port => {
        return [ 80, 443 ].reduce((resultPorts, portNum) => {
            return resultPorts.map(resultPort => {
                if (resultPort.containsPortNum(portNum)) {
                    if (!resultPort.isRange()) {
                        return undefined;
                    }
                    const lowPort = resultPort.getFromPortNum();
                    const highPort = resultPort.getToPortNum();

                    const port1Str: NetportStr = lowPort === portNum - 1 ?
                        `${lowPort}` :
                        `${lowPort}-${portNum - 1}`;
                    const port2Str: NetportStr = highPort === portNum + 1 ?
                        `${highPort}` :
                        `${portNum + 1}-${highPort}`;

                    return [ netport(port1Str), netport(port2Str) ].filter(port => port.isValid());
                }
                return resultPort;
            }).filter(notEmpty).flat();
        }, [ port ]).flat();
    }).flat();
};
