/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useCallback } from 'react';
import classNames from 'classnames';
import { MDBBtn } from 'mdbreact';
import { useSelector, useDispatch } from 'react-redux';
import { Draggable } from '@hello-pangea/dnd';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { NODE_SHARED } from '~commonLib/constants.ts';
import { Icon, Input, Select } from '~frontendComponents/Generic/index.js';
import Message from '~frontendComponents/Message/index.js';
import {
    addToNormalize,
    deleteNormalize,
    duplicateFromNormalize,
    getInterfacesTypesCreatable,
    getIsCluster,
    getTableLowestPossibleIndex,
    getTableSchema,
    setNormalize,
    updateInterface,
} from '~frontendDucks/hlcfgEditor/index.js';
import { useInterface, useInterfaceNeedsUpdate, makeSelectSearchedTableItem } from '~frontendDucks/hlcfgEditor/glcfgGettersAndSettersTS.ts';
import { BRIDGE_TYPE, DHCP_VALUE, HW_TYPE, INTERFACES, VLAN_TYPE } from '~frontendConstants/index.js';
import IconWithTooltip from '~frontendRoot/components/IconWithTooltip/index.js';
import TextWithTooltip from '~frontendRoot/components/TextWithTooltip/index.js';
import {
    BOND_IFACE_TYPE,
    BOND_TYPE,
    BRIDGE_IFACE_TYPE,
    INTERFACES_COLUMNS_ANTISPOOFING,
    INTERFACES_COLUMNS_BOND_MODE,
    INTERFACES_COLUMNS_IP4,
    INTERFACES_COLUMNS_IP6,
    INTERFACES_COLUMNS_MAC,
    INTERFACES_COLUMNS_MENU,
    INTERFACES_COLUMNS_NAME,
    INTERFACES_COLUMNS_PHYSICALIFACE,
    INTERFACES_COLUMNS_STATE,
    INTERFACES_COLUMNS_TAG,
    INTERFACES_COLUMNS_TYPE,
    INTERFACES_COLUMNS_VLANIFACE,
    INTERFACES_COLUMNS_WANLAN,
    SELECTABLE_TABLE_INTERFACES,
    userSetting,
    VLAN_IFACE_TYPE,
} from '~frontendConstants/constants.ts';
import { NODE_A_ID, NODE_B_ID } from '~sharedConstants/index.ts';
import NetworkInterfaceDevice from '~frontendComponents/NetworkInterfaceDevice/index.js';
import { useNetworkInterfacesFromNode } from '~frontendDucks/networkInterfaces.ts';
import RowMenuAndSwitch from '~frontendRoot/components/RowMenuAndSwitch.tsx';
import { CreateRowPropsType } from '~frontendRoot/widgets/DatatableWidget/index.ts';
import { useBoolean, useMakeSelector } from '~frontendRoot/lib/hooks/defaultHooks.ts';
import { useUserSettingPathSetter } from '~frontendRoot/lib/hooks/userSettings.ts';
import { getColumnsUtils } from '~frontendRoot/lib/columnUtils.ts';
import ColorInput from '~frontendRoot/components/ColorInput.tsx';

import { getIcon } from '../rowsUtils.js';
import ClusterRow, { optionsFromHwDevices } from '../ClusterRows/ClusterRow.tsx';


const selectOptionsList = [
    {
        id: '1',
        label: (
            <Message message="widgets:pathFiltering.strict" />
        ),
        value: 'strict',
    },
    {
        id: '2',
        label: (
            <Message message="widgets:pathFiltering.moderate" />
        ),
        value: 'moderate',
    },
    {
        id: '3',
        label: (
            <Message message="widgets:pathFiltering.off" />
        ),
        value: 'off',
    },

];

export const selectOptionsListWanLan = [
    {
        id: '1',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"

                text="widgets:network.wanLan.wanLan.title"
                tooltipText="widgets:network.wanLan.wanLan.desc"
            />
        ),
        value: 'wanLan',
    },
    {
        id: '2',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.lan.title"
                tooltipText="widgets:network.wanLan.lan.desc"
            />
        ),
        value: 'lan',
    },
    {
        id: '3',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.wan.title"
                tooltipText="widgets:network.wanLan.wan.desc"
            />
        ),
        value: 'wan',
    },
    {
        id: '3',
        label: (
            <IconWithTooltip
                className="icon--secondary"
                iconSize="sx"
                link
                name="circle-outline-off"
                tooltipText="widgets:network.wanLan.off"
            />
        ),
        value: 'unspecific',
    },
];


export const getInternalExternal = (isInternal, isExternal) => {
    if (isInternal || isExternal) {
        if (isInternal && isExternal) {
            return 'wanLan';
        }
        if (isInternal) {
            return 'lan';
        }
        return 'wan';
    }
    return 'unspecific';
};

const RowNoMemo = ({ uuid, spacing, dataIndex, search }: CreateRowPropsType) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const searchMatches = useMakeSelector(makeSelectSearchedTableItem, uuid, search);

    const [ allColumns, setColumn ] = useUserSettingPathSetter(userSetting.columnsByTable);
    const [ getColumnsShow, getColumnsSelectedLength ] = getColumnsUtils(allColumns, SELECTABLE_TABLE_INTERFACES);
    const data = useInterface(uuid);
    const needsUpdate = useInterfaceNeedsUpdate(uuid);
    const isCluster = useSelector(getIsCluster);
    const schema = useSelector(state => getTableSchema(state, uuid));
    const types = useSelector(getInterfacesTypesCreatable);
    const numberOfVlans = useSelector(state => getTableLowestPossibleIndex(state, VLAN_IFACE_TYPE, 'vlanTag'));
    const numberOfBridges = useSelector(state => getTableLowestPossibleIndex(state, BRIDGE_IFACE_TYPE, 'ifaceTag'));
    const numberOfBonds = useSelector(state => getTableLowestPossibleIndex(state, BOND_IFACE_TYPE, 'ifaceTag'));

    const hwDevices = useNetworkInterfacesFromNode(NODE_A_ID);

    const [ openDevice, setOpenDevice ] = useBoolean(false);

    const { name, address, address6, device, isInternal, isExternal, type,
        vlanTag, __off, error, pathFiltering, stateA, physicalLayerUpA, stateB, physicalLayerUpB, mac, dhcp,
        vlanIface, color, isOpen, slaveIfaces, ifaceTag, bondMode } = data;

    const changeValue = useCallback(({ value, name, }) => {
        dispatch(setNormalize({ value, uuid, key: name }));
    }, [ dispatch, uuid ]);

    const changeValueInterface = useCallback(({ value, name, id }) => {
        dispatch(setNormalize({ value, uuid, key: name, subkey: id }));
        setOpenDevice.off();
    }, [ dispatch, uuid, setOpenDevice ]);

    const changeValueMac = useCallback(({ value, name, id }) => {
        dispatch(setNormalize({ value: value[0]?.toLowerCase(), uuid, key: name, subkey: id }));
    }, [ dispatch, uuid ]);

    const changeValueNetAddr = useCallback(({ value, name, id }) => {
        dispatch(setNormalize({ value: value, uuid, key: name, subkey: id }));
    }, [ dispatch, uuid ]);

    const changeValueNetAddrWithDhcp = useCallback(({ value, name, id }) => {
        if (dhcp || value.includes(DHCP_VALUE)) {
            dispatch(setNormalize({ value: !dhcp, uuid, key: 'dhcp' }));
            return;
        }
        dispatch(setNormalize({ value, uuid, key: name, subkey: id }));
    }, [ dhcp, dispatch, uuid ]);

    const addRow = (value, addingAfter?: boolean) => {
        dispatch(addToNormalize({
            type: value,
            typeId: INTERFACES,
            addingAfter,
            uuidToAddBefore: data.id,
            successText: `widgets:${value}.added`,
            extraValues: value === VLAN_IFACE_TYPE ? { vlanTag: numberOfVlans } : value === BRIDGE_IFACE_TYPE ?
                { ifaceTag: numberOfBridges } : value === BOND_IFACE_TYPE ? { ifaceTag: numberOfBonds } : undefined

        }));
        if (!getColumnsShow(INTERFACES_COLUMNS_TAG)) {
            setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_TAG ], value: true });
        }
        if (value === VLAN_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_VLANIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_VLANIFACE ], value: true });
            }
        }
        if (value === BRIDGE_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE ], value: true });
            }
        }
        if (value === BOND_IFACE_TYPE) {
            if (!getColumnsShow(INTERFACES_COLUMNS_BOND_MODE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_BOND_MODE ], value: true });
            }
            if (!getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE)) {
                setColumn({ path: [ SELECTABLE_TABLE_INTERFACES, INTERFACES_COLUMNS_PHYSICALIFACE ], value: true });
            }
        }
    };

    const updateRow = useCallback(() => {
        dispatch(updateInterface({ uuid }));

    }, [ dispatch, uuid ]);

    const delRow = useCallback(() => {
        dispatch(deleteNormalize({ type: INTERFACES, value: uuid }));
    }, [ dispatch, uuid ]);

    const copyRow = useCallback(() => {
        dispatch(duplicateFromNormalize({ uuid, parent: INTERFACES }));
    }, [ dispatch, uuid ]);

    const changeValueWanLan = useCallback(({ value }) => {
        switch (value) {
        case 'wan': {
            dispatch(setNormalize({ value: true, uuid, key: 'isExternal' }));
            dispatch(setNormalize({ value: false, uuid, key: 'isInternal' }));
            break;
        }
        case 'lan': {
            dispatch(setNormalize({ value: false, uuid, key: 'isExternal' }));
            dispatch(setNormalize({ value: true, uuid, key: 'isInternal' }));
            break;
        }
        case 'wanLan': {
            dispatch(setNormalize({ value: true, uuid, key: 'isExternal' }));
            dispatch(setNormalize({ value: true, uuid, key: 'isInternal' }));
            break;
        }
        case 'unspecific': {
            dispatch(setNormalize({ value: false, uuid, key: 'isExternal' }));
            dispatch(setNormalize({ value: false, uuid, key: 'isInternal' }));
            break;
        }
        default: throw new Error('Unknown state');
        }
    }, [ dispatch, uuid ]);


    if (!data?.id) {
        return null;
    }

    return (
        <Draggable
            draggableId={uuid}
            index={dataIndex}
            isDragDisabled={isOpen && isCluster}
            key={uuid}
        >
            {(provided) => (
                <>
                    <tr
                        className="dataTableWidget__RowAdd"
                        data-cy={'RowAdd'}
                        key={uuid + 'add'}
                    >
                        <td
                            className="dataTableWidget__RowAddRule"
                            colSpan={getColumnsSelectedLength()}
                        >
                            <div
                                className="dataTableWidget__RowAddButtons"
                                data-cy={'RowAdd' + 'Buttons'}
                            >
                                {
                                    (types || []).map(item => {
                                        if (isCluster && item === BRIDGE_IFACE_TYPE) {
                                            return;
                                        }
                                        return (
                                            <MDBBtn
                                                className="dataTableWidget__RowAddButtons--button"
                                                data-cy={item + 'Add'}
                                                key={uuid + item + ' above'}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    addRow(item);
                                                }}
                                            >
                                                <Icon name="plus" /> {t(`widgets:${item}.${item}`)}
                                            </MDBBtn>
                                        );
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                    <tr
                        data-cy={uuid}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classNames(
                            'dataTableWidget__Row',
                            { 'dataTableWidget__Row--match': searchMatches },
                            { 'dataTableWidget__Row--warning': needsUpdate.length },
                            { 'dataTableWidget__Row--error': error },
                            { [`dataTableWidget__cell--${spacing}`]: spacing },
                            { 'dataTableWidget__Row--disable': __off },

                            'network__row',
                        )}
                        key={uuid}
                        style={
                            {
                                ...provided.draggableProps.style
                            }
                        }
                    >
                        {getColumnsShow(INTERFACES_COLUMNS_STATE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--error': error },
                                    { 'dataTableWidget__cell--match': searchMatches },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                                {...provided.dragHandleProps}
                            >
                                {
                                    getIcon(`${stateA}${stateB || ''}`, __off)
                                }
                                {
                                    getIcon(`${physicalLayerUpA === undefined ?
                                        '' :
                                        physicalLayerUpA ? 'connected' : 'disconnected'}${
                                        physicalLayerUpB === undefined ?
                                            '' :
                                            physicalLayerUpB ?
                                                'connected' : 'disconnected'}`, __off)
                                }
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_TYPE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    'dataTableWidget__cell--icon',
                                    { 'dataTableWidget__cell--error': error },
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {
                                    getIcon(type, __off)
                                }
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_NAME) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}

                            >
                                <Input
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        { 'h-2': name },
                                    )}
                                    id={'interfaceName' + uuid}
                                    isName
                                    name="name"
                                    onChange={changeValue}
                                    placeholder={device?.[NODE_A_ID] ||
                                         `${type}${type === VLAN_TYPE ? vlanTag : ifaceTag}`}
                                    schema={schema?.name}
                                    value={name}
                                    withoutBorder
                                />
                                {Boolean(name) && type === HW_TYPE && !openDevice &&
                                    <small
                                        className="network__rowCell--deviceName"
                                        onClick={event => {
                                            event.preventDefault();
                                            if (isCluster) {
                                                changeValue({ value: !isOpen, name: 'isOpen' });
                                            } else {
                                                setOpenDevice.swap();
                                            }
                                        }}
                                    >
                                        {device?.[NODE_A_ID]}{isCluster && device?.[NODE_B_ID] ?
                                            `, ${device?.[NODE_B_ID]}` : null}
                                    </small>
                                }
                                {openDevice &&
                                <Select
                                    className={classNames(
                                        'dataTableWidget__RowInput',
                                        'network__rowCell--clusterHw',
                                        { 'h-2': name },
                                    )}
                                    id={NODE_A_ID}
                                    isRow
                                    name="device"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={changeValueInterface}
                                    options={optionsFromHwDevices(hwDevices)}
                                    paste={false}
                                    value={device?.[NODE_A_ID]}
                                />}
                                <div className={'network__rowCell--deviceColor'}>
                                    <ColorInput
                                        __off={__off}
                                        className={'colorInput'}
                                        onChange={changeValueInterface}
                                        value={color}
                                    />
                                </div>
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_IP4) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    dhcp={dhcp}
                                    disabled={__off}
                                    id="shared"
                                    isCreatable={!dhcp}
                                    isMulti
                                    isRow
                                    maxValueShown={3}
                                    name="address"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={type === BRIDGE_TYPE ? changeValueNetAddr : changeValueNetAddrWithDhcp}
                                    options={type === HW_TYPE ?
                                        [ {
                                            label: 'DHCP', options: [
                                                { value: DHCP_VALUE, label: 'DHCP',
                                                    objectValue: DHCP_VALUE }
                                            ]
                                        } ] :
                                        undefined}
                                    schema={schema?.address?.additionalProperties}
                                    value={dhcp ?
                                        [ DHCP_VALUE ].concat(address?.[NODE_SHARED]) :
                                        address?.[NODE_SHARED]}
                                />
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_WANLAN) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                    'network__rowCell--rightBorder',
                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    disabled={__off}
                                    id={'wanLan' + uuid}

                                    name="wanLanName"
                                    noDropdownIndicator
                                    noWrap
                                    onChange={changeValueWanLan}
                                    options={selectOptionsListWanLan}
                                    paste={false}
                                    value={getInternalExternal(isInternal, isExternal)}
                                    withoutBorder
                                />
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_TAG) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                    'network__rowCell--leftPadding',

                                )}
                            >{type === VLAN_TYPE || type === BRIDGE_TYPE || type === BOND_TYPE ?
                                    <Input
                                        className="dataTableWidget__RowInput network__rowCell--withoutMin"
                                        id={type === VLAN_TYPE ? 'vlanTag' + uuid : 'ifaceTag' + uuid}
                                        name={type === VLAN_TYPE ? 'vlanTag' : 'ifaceTag'}
                                        number
                                        onChange={changeValue}
                                        value={type === VLAN_TYPE ? vlanTag : ifaceTag}
                                        withoutBorder
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}

                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_BOND_MODE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {type === BOND_TYPE ?
                                    <Select
                                        className="dataTableWidget__RowInput"
                                        enumTranslationDifferPath={'differs:tables.bondIface.bondMode'}
                                        id={'type' + uuid}

                                        name="bondMode"
                                        noDropdownIndicator
                                        noWrap
                                        onChange={changeValue}
                                        paste={false}
                                        schema={schema.bondMode}
                                        value={bondMode}
                                        withoutBorder
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary ml-2"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_VLANIFACE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {type === VLAN_TYPE ?
                                    <NetworkInterfaceDevice
                                        className="dataTableWidget__RowInput"
                                        disabled={__off}
                                        id={'type' + uuid}
                                        iface={vlanIface}
                                        isMulti
                                        name="vlanIface"
                                        noDropdownIndicator
                                        noWrap
                                        onChange={changeValue}
                                        paste={false}
                                        schema={schema.vlanIface}
                                        withoutBorder
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary ml-2"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_PHYSICALIFACE) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {type === BRIDGE_TYPE || type === BOND_TYPE ?
                                    <NetworkInterfaceDevice
                                        className="dataTableWidget__RowInput"
                                        disabled={__off}
                                        id={'type' + uuid}
                                        iface={slaveIfaces}
                                        isMulti

                                        name="slaveIfaces"
                                        noDropdownIndicator
                                        noWrap
                                        onChange={changeValue}
                                        paste={false}
                                        schema={schema.slaveIfaces}
                                        withoutBorder
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary ml-2"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_ANTISPOOFING) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },
                                )}
                            >
                                {type !== BRIDGE_TYPE && type !== BOND_TYPE ?
                                    <Select
                                        className="dataTableWidget__RowInput"
                                        id={'type' + uuid}

                                        name="pathFiltering"
                                        noDropdownIndicator
                                        noWrap
                                        onChange={changeValue}
                                        options={selectOptionsList}
                                        paste={false}
                                        value={pathFiltering}
                                        withoutBorder
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary ml-2"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_IP6) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >
                                <Select
                                    className="dataTableWidget__RowInput"
                                    disabled={__off}
                                    id="shared"
                                    isCreatable
                                    isMulti
                                    isRow
                                    name="address6"
                                    noDropdownIndicator
                                    noOptionsMessage
                                    onChange={changeValueNetAddr}
                                    schema={schema?.address6?.additionalProperties}
                                    value={address6?.[NODE_SHARED] || []}
                                />
                            </td>
                        }
                        {getColumnsShow(INTERFACES_COLUMNS_MAC) &&
                            <td
                                className={classNames(
                                    'dataTableWidget__cell',
                                    { [`dataTableWidget__cell--${spacing}`]: spacing },

                                )}
                            >

                                {type === HW_TYPE ?
                                    isCluster ||
                                    <Select
                                        className="dataTableWidget__RowInput"
                                        disabled={__off}
                                        id="nodeA"
                                        isCreatable
                                        isMulti
                                        isRow
                                        name="mac"
                                        noDropdownIndicator
                                        noOptionsMessage
                                        onChange={changeValueMac}
                                        schema={schema?.mac?.additionalProperties}
                                        value={mac?.[NODE_A_ID] ? [ mac?.[NODE_A_ID] ] : []}
                                    /> :
                                    <IconWithTooltip
                                        className="icon--secondary ml-2"
                                        iconSize="sx"
                                        link
                                        name="minus"
                                        tooltipText="widgets:network.wrongType"
                                    />}

                            </td>
                        }

                        <td>
                            {Boolean(needsUpdate.length) &&
                                <div className="network__rowUpdateButton">
                                    <IconWithTooltip
                                        btnClassName="network__rowUpdateButton--button"
                                        btnText="widgets:network.update.title"
                                        name="plus"
                                        onClick={event => {
                                            event.preventDefault();
                                            updateRow();
                                        }}
                                        tooltipText="widgets:network.update.desc"
                                    />
                                </div>
                            }
                            {Boolean(__off) &&
                                <div className="network__rowUpdateButton">
                                    <IconWithTooltip
                                        btnClassName="network__rowUpdateButton--button"
                                        btnText="widgets:network.show.title"
                                        name="plus"
                                        onClick={event => {
                                            event.preventDefault();
                                            changeValue({ value: undefined, name: '__off' });
                                        }}
                                        tooltipText="widgets:network.show.desc"
                                    />
                                </div>
                            }
                            {isCluster  &&
                                <IconWithTooltip
                                    className="icon--secondary"
                                    name={isOpen ? 'chevron-right' : 'chevron-down'}
                                    onClick={event => {
                                        event.preventDefault();
                                        changeValue({ value: !isOpen, name: 'isOpen' });
                                    }}
                                    tooltipText="widgets:network.open.desc"
                                />
                            }
                        </td>
                        {getColumnsShow(INTERFACES_COLUMNS_MENU) &&
                            <RowMenuAndSwitch
                                __off={__off}
                                copyFunc={type === VLAN_TYPE ? copyRow : undefined}
                                deleteFunc={delRow}
                                id={'off' + uuid}
                                onChange={changeValue}
                            />
                        }
                    </tr>
                    {isCluster && isOpen &&
                        <>
                            <ClusterRow
                                data={data}
                                matches={searchMatches}
                                needsUpdate={Boolean(needsUpdate.length)}
                                node={NODE_A_ID}
                                schema={schema}
                                spacing={spacing}
                                uuid={uuid}
                            />
                            <ClusterRow
                                data={data}
                                matches={searchMatches}
                                needsUpdate={Boolean(needsUpdate.length)}
                                node={NODE_B_ID}
                                schema={schema}
                                spacing={spacing}
                                uuid={uuid}
                            />
                        </>}
                    <tr
                        className="dataTableWidget__RowAddEnd"
                        key={uuid + 'addEnd'}
                    >
                        <td
                            className="dataTableWidget__RowAddRule"
                            colSpan={getColumnsSelectedLength()}
                        >
                            <div className="dataTableWidget__RowAddButtons">
                                {
                                    (types || []).map(item => {
                                        if (isCluster && item === BRIDGE_IFACE_TYPE) {
                                            return;
                                        }
                                        return (
                                            <MDBBtn
                                                className="dataTableWidget__RowAddButtons--button"
                                                data-cy={item + 'Add'}
                                                key={uuid + item + ' above'}
                                                onClick={event => {
                                                    event.preventDefault();
                                                    addRow(item);
                                                }}
                                            >
                                                <Icon name="plus" /> {t(`widgets:${item}.${item}`)}
                                            </MDBBtn>
                                        );
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                </>
            )}
        </Draggable>
    );

};


const Row = React.memo(RowNoMemo);
export default Row;
