/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { createContext, useContext } from 'react';

import { DEFAULT_LANGUAGE } from '~commonLib/constants.ts';


export type TFunction = (trMsg: string, params?: Record<string, any>) => string;
const caches: Record<string, Map<string, string>> = {};
export const SimpleTranslationCtx = createContext({ t: (str => str) as TFunction, lang: DEFAULT_LANGUAGE });

/**
 * This wrapper aims to solve expesiveness of react-i18next translation function and of the hook.
 * Translation function expensiveness is solved by caching the results.
 * The hook expensiveness is solved by passing the t function from the hook through context from application root.
 */
export const useTranslation = () => {
    const { t: origT, lang } = useContext(SimpleTranslationCtx);
    const cache = caches[lang] ??= new Map();
    return {
        t: (trMsg: string, params?: Record<string, any>) => {
            if (params) {
                // original t function can be extremely slow. Seen 1.5ms!!! per single translation with parameters.
                // It is most likely worth caching even translations with parameters.
                const key = trMsg + JSON.stringify(params);
                const cached = cache.get(key);
                if (!cached) {
                    const translated = origT(trMsg, params);
                    cache.set(key, translated);
                    return translated;
                }
                return cached;
            }

            const cached = cache.get(trMsg);
            if (!cached) {
                const translated = origT(trMsg);
                cache.set(trMsg, translated);
                return translated;
            }
            return cached;
        },
    };
};
