/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import assert from 'assert';

import {
    getVpnUsersIdsFromService
} from '~frontendDucks/hlcfgEditor/index.js';
import DatatableWidget, { CreateRowPropsType } from '~frontendWidgets/DatatableWidget/index.ts';
import { NEW_ROW_CONSTANT, SELECTABLE_TABLE_VPN_USERS, SMALL_SIZE } from '~frontendConstants/index.js';
import { useTableReorder } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';

import Row, { NoRowsRow, VpnPushToUserContext } from './Row/index.ts';


const CreateRow = ({ dataIndex, spacing, uuid }: CreateRowPropsType) => {
    if (uuid === NEW_ROW_CONSTANT) {
        return <NoRowsRow key={uuid} />;
    }
    return (
        <Row
            dataIndex={dataIndex}
            key={uuid}
            spacing={spacing}
            uuid={uuid}
        />
    );
};

const VpnUserTable = () => {
    const { uuid } = useContext(VpnPushToUserContext);

    const users = useSelector(state => getVpnUsersIdsFromService(state, uuid));
    const data = useMemo(() => users?.length ? users : [ NEW_ROW_CONSTANT ], [ users ]);

    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas'));
    const reorder = useTableReorder({ tablePathGetter: getRowPathGetter(uuid).pushToUser });


    return (
        <DatatableWidget
            columnsId={SELECTABLE_TABLE_VPN_USERS}
            createRow={CreateRow}
            data={data}
            passReorderData={reorder}
            spacing={SMALL_SIZE}
        />
    );
};


export default VpnUserTable;
