/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

// Little helper file to deal with ESM vs CJS compatibility issues.
export type { Result, ResultPromise, ReturnedResult, AsyncResultPromise, ReturnedResultPromise } from 'base-ts-result';

export {
    Err, Ok, createAsyncResult, AsyncResult, toResult,
    resultify, toAsyncResult, asyncResultify
} from 'base-ts-result';
