/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import { makeStringConstsObj } from '~commonLib/objectUtils.ts';
import { HlcfgInputsCommonProps } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SelectV2 } from '~frontendComponents/Generic/SelectV2/SelectV2.tsx';
import { SelectV2BaseProps } from '~frontendComponents/Generic/SelectV2/types.ts';
import { selectV1OptionsToV2Options } from '~frontendComponents/Generic/SelectV2/utils.ts';
import IconWithTooltip from '~frontendComponents/IconWithTooltip/IconWithTooltip.js';
import TextWithTooltip from '~frontendComponents/TextWithTooltip/TextWithTooltip.js';
import { HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { setHlcfgValues } from '~frontendDucks/hlcfgEditor/hlcfgEditor.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { useDispatchCallback } from '~frontendLib/hooks/defaultHooks.ts';


type InterfaceTypeSelectProps = Omit<HlcfgInputsCommonProps, 'pathGetter'> &
    Omit<SelectV2BaseProps, 'id'> &
    {pathGetter: HlcfgRowPathGetter<'hwIface'|'bridgeIface'|'openvpnRas'|'vlanIface'|'bondIface'>}

export const InterfaceTypeSelect = ({
    pathGetter, ...passThroughProps
}: InterfaceTypeSelectProps) => {

    const {
        value: isExternal, path: isExternalPath
    } = useHlcfgValue(pathGetter.isExternal);
    const {
        value: isInternal, path: isInternalPath
    } = useHlcfgValue(pathGetter.isInternal);

    const optionVal = getOptionValue({ isExternal, isInternal });

    const onChange = useDispatchCallback(values => {
        const value = values.at(-1);
        return setHlcfgValues([
            { hlcfgPath: isExternalPath, value: [ VALS.wanLan, VALS.wan ].includes(value) ? true : undefined },
            { hlcfgPath: isInternalPath, value: [ VALS.wanLan, VALS.lan ].includes(value) ? true : undefined },
        ]);
    }, [ isExternalPath, isInternalPath ]);
    return (
        <SelectV2
            id="wanLan"
            {...passThroughProps}
            {...selectV1OptionsToV2Options(selectOptionsListWanLan, { isRequired: true, singleValue: true })}
            onChange={onChange}
            singleValueMode={true}
            value={[ optionVal ]}
        />
    );

};
const VALS = makeStringConstsObj([ 'wanLan', 'wan', 'lan', 'unspecific' ]);

const getOptionValue = ({ isExternal, isInternal }: {isExternal?: boolean, isInternal?: boolean}) => {

    if (isExternal && isInternal) {
        return VALS.wanLan;
    }
    if (isExternal) {
        return VALS.wan;
    }
    if (isInternal) {
        return VALS.lan;
    }
    return VALS.unspecific;
};

const selectOptionsListWanLan = [
    {
        id: '1',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.wanLan.title"
                tooltipText="widgets:network.wanLan.wanLan.desc"
            />
        ),
        value: VALS.wanLan,
    },
    {
        id: '2',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.lan.title"
                tooltipText="widgets:network.wanLan.lan.desc"
            />
        ),
        value: VALS.lan,
    },
    {
        id: '3',
        label: (
            <TextWithTooltip
                className="textAsIcon textAsIcon--secondary"
                text="widgets:network.wanLan.wan.title"
                tooltipText="widgets:network.wanLan.wan.desc"
            />
        ),
        value: VALS.wan,
    },
    {
        id: '3',
        label: (
            <IconWithTooltip
                className="icon--secondary"
                dontBlur
                iconSize="sx"
                name="circle-outline-off"
                tooltipText="widgets:network.wanLan.off"
            />
        ),
        value: VALS.unspecific,
    },
];
