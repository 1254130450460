/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCol, MDBRow,  MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import {
    ACCEPT_ACTION,
    WEB_ACTION,
    DROP_ACTION
} from '~sharedConstants/index.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { HlcfgRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { SourceTranslationSelect } from '~frontendRoot/scenes/Protection/scenes/PacketFilter/components/Row/renderers.tsx';


interface ActionProps {
    pathGetter: HlcfgRowPathGetter<'nftRule'>,
    disabled: boolean,
    smallSpacing: boolean,
}

export const Action = ({ disabled, smallSpacing, pathGetter }: ActionProps) => {
    const { t } = useTranslation();
    const { value: fake } = useHlcfgValue(pathGetter.fake);
    const { value: action } = useHlcfgValue(pathGetter.action);
    const { value: service } = useHlcfgValue(pathGetter.service);
    return (
        <MDBCard>
            {action !== DROP_ACTION && (
                <MDBCardTitle className={classNames({ 'py-0': smallSpacing })}>
                    <MDBRow>
                        <MDBCol>
                            {t('packetFilter:actions.title')}
                        </MDBCol>
                    </MDBRow>
                </MDBCardTitle>
            )}
            <MDBCardBody className={smallSpacing ? 'px-2 py-0' : 'pt-2 pb-0'}>
                <MDBRow>
                    {action === DROP_ACTION ?
                        null :
                        action === WEB_ACTION ? (
                            <MDBCol size={'12'}>
                                <HlcfgSelect
                                    className={smallSpacing ? 'mb-1' : 'mb-3'}
                                    disabled={disabled}
                                    label={t('packetFilter:webProfile')}
                                    notEditable={fake || disabled}
                                    pathGetter={pathGetter.webProfile}
                                />

                            </MDBCol>
                        ) : (
                            <>
                                <MDBCol
                                    className={classNames({ 'pr-1': smallSpacing })}
                                    size={'6'}
                                >
                                    <SourceTranslationSelect
                                        className={smallSpacing ? 'mb-1' : 'mb-3'}
                                        disabled={fake || disabled}
                                        label={t('packetFilter:source.translation')}
                                        notEditable={fake || disabled}
                                        pathGetter={pathGetter.sourceTranslation}
                                    />
                                </MDBCol>
                                <MDBCol className={classNames({ 'pl-1': smallSpacing })}>
                                    <HlcfgSelect
                                        className={smallSpacing ? 'mb-1' : 'mb-3'}
                                        disabled={fake || disabled || action !== ACCEPT_ACTION}
                                        label={t('packetFilter:destination.translation')}
                                        notEditable={fake || disabled}
                                        pathGetter={pathGetter.destinationTranslation.address}
                                    />

                                </MDBCol>
                                <MDBCol
                                    className={classNames({ 'pr-1': smallSpacing })}
                                    size={'6'}
                                >
                                    <HlcfgSelect
                                        className={smallSpacing ? 'mb-1' : 'mb-3'}
                                        disabled={fake || disabled}
                                        label={t('packetFilter:qosNodes')}
                                        notEditable={fake || disabled}
                                        pathGetter={pathGetter.qosNodes}
                                    />
                                </MDBCol>
                                <MDBCol className={classNames({ 'pl-1': smallSpacing })}>
                                    <HlcfgSelect
                                        className={smallSpacing ? 'mb-1' : 'mb-3'}
                                        disabled={fake || !service?.length || action !== ACCEPT_ACTION || disabled}
                                        label={t('packetFilter:destination.portTranslation')}
                                        notEditable={fake || disabled}
                                        pathGetter={pathGetter.destinationTranslation.port}
                                    />
                                </MDBCol>
                            </>
                        )
                    }
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
};
