/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBCardTitle } from 'mdbreact';
import classNames from 'classnames';
import assert from 'assert';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { Icon, Switch } from '~frontendComponents/Generic/index.js';
import { VPN_ROUTES_TYPE_PUSH_TO_CLIENT } from '~frontendRoot/constants/index.js';
import { getRowPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';
import { hlcfgRowIdIsFromTable } from '~sharedLib/hlcfgTableUtils.ts';
import { HlcfgSelect, HlcfgSwitch } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { useBoolean } from '~frontendLib/hooks/defaultHooks.ts';
import { useHlcfgValue } from '~frontendDucks/hlcfgEditor/hlcfgEditorV2.ts';
import { poDef, testProps, testPropsCollapsible } from '~commonLib/PageObjectMap.ts';
import { getVpnCardMap } from '~frontendRoot/scenes/Configuration/scenes/NetworkServices/scenes/VpnServer/components/VpnScene/pageObjectMap.ts';

import { VpnRoutingTableContext } from '../VpnRouteTable/Row/index.ts';
import VpnRouteTable from '../VpnRouteTable/index.js';


const VpnPushToClient = ({ uuid }: {uuid: string}) => {
    const { t } = useTranslation();
    const [ shownSettings, setShownSettings ] = useBoolean(false);
    assert(hlcfgRowIdIsFromTable(uuid, 'openvpnRas'));
    const vpnPath = getRowPathGetter(uuid);
    const pushToClient = vpnPath.pushToClient;
    const routeIds = useHlcfgValue(pushToClient.routes).value;

    const force = useHlcfgValue(pushToClient.nameservers.force);
    const nameserversAddrs = useHlcfgValue(pushToClient.nameservers.addresses);
    const pushToClientPoDef = getVpnCardMap(uuid).child.pushToClient;
    const collapsibleTestProps = testPropsCollapsible(pushToClientPoDef.id, shownSettings);
    return (
        <MDBCard {...collapsibleTestProps.containerProps}>
            <MDBCardTitle className={classNames(
                'cardHide__title',
                { 'cardHide__title--noBorderBottom': shownSettings },
            )}
            >
                <div
                    className="clicable"
                    onClick={setShownSettings.swap}
                    {...collapsibleTestProps.buttonProps}
                >
                    {t('widgets:Vpn.header.pushToClient')}
                    <Icon
                        name={shownSettings ? 'chevron-up' : 'chevron-down'}
                    />
                </div>
            </MDBCardTitle>

            <MDBCardBody  className={classNames('cardHide__body',
                { 'cardHide__body--hide': !shownSettings },
                { 'cardHide__title--borderBottom': shownSettings },)}
            >
                {shownSettings &&
                     <MDBRow>
                         <MDBCol
                             lg="8"
                             md="8"
                             sm="12"
                             {...testProps(pushToClientPoDef.child.routes.id)}
                         >
                             <VpnRoutingTableContext.Provider
                                 value={
                                     {
                                         routeTablePathGetter: pushToClient.routes,
                                         type: VPN_ROUTES_TYPE_PUSH_TO_CLIENT,
                                     }
                                 }
                             >
                                 <VpnRouteTable
                                     data={routeIds ?? []}
                                 />
                             </VpnRoutingTableContext.Provider>
                         </MDBCol>
                         <MDBCol
                             lg="4"
                             md="4"
                             sm="12"
                         >

                             <HlcfgSelect
                                 label={t('widgets:Vpn.pushToClient.nameservers.addresses.title')}
                                 message="dhcp-option DNS (windows only option)"
                                 pathGetter={pushToClient.nameservers.addresses}
                             />
                             <Switch
                                 align="spaceBetween"
                                 checked={!!(force.value && nameserversAddrs.value?.length)}
                                 disabled={!nameserversAddrs.value?.length}
                                 id={poDef.pathId(force.path)}
                                 label={t('widgets:Vpn.pushToClient.nameservers.force.title')}
                                 message={'push "block-outside-dns"'}
                                 onChange={({ value }) => {
                                     force.setValue(value);
                                 }}
                             />
                             <HlcfgSwitch
                                 label={t('widgets:Vpn.pushToClient.defaultGateway.title')}
                                 message={'push "redirect-gateway def1"'}
                                 pathGetter={pushToClient.defaultGateway}
                             />
                             <HlcfgSelect
                                 label={t('widgets:Vpn.pushToClient.ntp.title')}
                                 message="dhcp-option NTP (windows only option)"
                                 pathGetter={pushToClient.ntp}
                             />
                             <HlcfgSelect
                                 label={t('widgets:Vpn.pushToClient.domain.title')}
                                 message="dhcp-option DOMAIN (windows only option)"
                                 pathGetter={pushToClient.domain}
                             />

                         </MDBCol>
                     </MDBRow>}
            </MDBCardBody>
        </MDBCard>
    );
};

export default VpnPushToClient;
