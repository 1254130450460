/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React from 'react';
import { MDBCard, MDBCardBody, MDBCardTitle } from 'mdbreact';

import { useTranslation } from '~frontendLib/useTranslation.ts';
import { HlcfgSelect } from '~frontendComponents/Generic/HlcfgInput/HlcfgInputs.tsx';
import { hlcfgPathGetter } from '~frontendDucks/hlcfgEditor/constants.ts';


const SuricataSettings = () => {
    const { t } = useTranslation();
    return (
        <MDBCard>
            <MDBCardTitle>
                {t('ips:suricata.title')}
            </MDBCardTitle>
            <MDBCardBody>
                <HlcfgSelect
                    pathGetter={hlcfgPathGetter.protection.suricata.service.interfaces}
                />
            </MDBCardBody>
        </MDBCard>
    );
};

export default SuricataSettings;
