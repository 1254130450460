/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *\
* POZOR: Tento soubor obsahuje CITLIVE INFORMACE              *
* CAUTION: This file contains SENSITIVE INFORMATION           *
* Kernun                                                      *
* Copyright (C) 2000-2024 by Trusted Network Solutions, a.s.  *
* All rights reserved.                                        *
\* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

import React, { MouseEventHandler, useCallback } from 'react';
import classnames from 'classnames';
import {
    mdiAccount,
    mdiLoading,
    mdiAccountAlertOutline,
    mdiAccountArrowLeftOutline,
    mdiAccountArrowRightOutline,
    mdiAccountEditOutline,
    mdiAccountKeyOutline,
    mdiAccountMultipleOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiArrowCollapseDown,
    mdiArrowCollapseUp,
    mdiArrowCollapseLeft,
    mdiArrowRight,
    mdiAutoFix,
    mdiAlertOutline,
    mdiAlertRhombusOutline,
    mdiBookSearchOutline,
    mdiChartAreaspline,
    mdiCheck,
    mdiChevronDown,
    mdiChevronUp,
    mdiClockOutline,
    mdiClose,
    mdiCogSyncOutline,
    mdiEyeOutline,
    mdiEyeOffOutline,
    mdiHelp,
    mdiInformation,
    mdiKeyRemove,
    mdiLifebuoy,
    mdiLockReset,
    mdiMagnify,
    mdiMagnifyMinusOutline,
    mdiMagnifyPlusOutline,
    mdiPencilOutline,
    mdiPlaylistCheck,
    mdiPower,
    mdiReload,
    mdiCogOutline,
    mdiShieldCheckOutline,
    mdiTestTube,
    mdiWrenchOutline,
    mdiDebugStepInto,
    mdiDebugStepOut,
    mdiTrashCanOutline,
    mdiCircle,
    mdiCircleOutline,
    mdiCancel,
    mdiEthernetCableOff,
    mdiEthernet,
    mdiMenu,
    mdiCloseOutline,
    mdiBankTransferIn,
    mdiBankTransferOut,
    mdiMathLog,
    mdiAlphaNBoxOutline,
    mdiAlphaRBoxOutline,
    mdiLockOutline,
    mdiLockOpenOutline,
    mdiUndo,
    mdiArrowUpBoldOutline,
    mdiArrowDownBoldOutline,
    mdiFormatLineSpacing,
    mdiArrowExpandVertical,
    mdiArrowCollapseVertical,
    mdiArrowDownBoldCircleOutline,
    mdiDrag,
    mdiPlus,
    mdiArrowUp,
    mdiArrowLeft,
    mdiArrowDown,
    mdiAutorenew,
    mdiTabPlus,
    mdiShieldPlusOutline,
    mdiContentCopy,
    mdiContentDuplicate,
    mdiImport,
    mdiDeleteRestore,
    mdiCheckCircleOutline,
    mdiCloseCircleOutline,
    mdiShieldPlus,
    mdiChevronRight,
    mdiArrowCollapse,
    mdiArrowExpand,
    mdiRecycle,
    mdiDotsHorizontal,
    mdiFormatColorFill,
    mdiChartLineVariant,
    mdiChartBar,
    mdiCheckboxMarkedCircle,
    mdiAlertCircle,
    mdiCloseCircle,
    mdiInformationVariant,
    mdiInformationOutline,
    mdiThumbUpOutline,
    mdiThumbDownOutline,
    mdiPlay,
    mdiPause,
    mdiRepeat,
    mdiRestore,
    mdiCheckboxBlankCircle,
    mdiPlusCircleOutline,
    mdiServer,
    mdiMapMarkerUp,
    mdiSwapVertical,
    mdiCalendarToday,
    mdiShapeSquarePlus,
    mdiCallMissed,
    mdiDramaMasks,
    mdiFile,
    mdiLanDisconnect,
    mdiSyncAlert,
    mdiDatabaseEdit,
    mdiCrown,
    mdiAlert,
    mdiProgressAlert,
    mdiAlertCircleOutline,
    mdiServerRemove,
    mdiChevronRightBoxOutline,
    mdiChevronLeftBoxOutline,
    mdiDownload,
    mdiServerNetwork,
    mdiWeb,
    mdiLogout,
    mdiExpansionCard,
    mdiExpansionCardVariant,
    mdiLan,
    mdiCircleOffOutline,
    mdiMinus,
    mdiCarBrakeAlert,
    mdiSendCircleOutline,
    mdiDnsOutline,
    mdiAccountNetworkOutline,
    mdiVpn,
    mdiTable,
    mdiFileUploadOutline,
    mdiFolderPlusOutline,
    mdiFileCog,
    mdiSquareEditOutline,
    mdiCropSquare,
    mdiText,
    mdiTextShort,
    mdiSleep,
    mdiContentSaveCogOutline,
    mdiLanConnect,
    mdiLeaf,
    mdiSourceBranch,
    mdiConnection,
    mdiBridge,
    mdiMerge,
    mdiChevronLeft,
    mdiHome,
    mdiUpload,
    mdiExclamation,
    mdiPlaylistPlus,
    mdiDesktopClassic,
    mdiHumanGreetingProximity,
    mdiFileAlertOutline,
    mdiQrcode,
    mdiPackageDown,
    mdiPackageUp,
    mdiCloseOctagonOutline,
    mdiPauseOctagonOutline,
    mdiFilterPlus,
    mdiFormatLetterCase,
    mdiNotEqual,
    mdiFormatLetterMatches,
    mdiTimerOffOutline,
    mdiMessageProcessingOutline,
    mdiShieldLock,
    mdiSpeedometerSlow,
    mdiSpeedometerMedium,
    mdiSpeedometer,
    mdiShieldAccount,
    mdiShieldOffOutline,
    mdiAccountMultiple,
    mdiPin,

} from '@mdi/js';
import assert from 'assert';

import { LOG } from '~frontendRoot/constants/index.js';
import { testPropsPassthrough } from '~commonLib/PageObjectMap.ts';


/*eslint-disable */

const myIcons = {
    drop: <path id="Union_3" data-name="Union 3" d="M0,10.909V4.365L12,0,24,4.365v6.544C24,16.964,18.878,22.625,12,24,5.118,22.625,0,16.964,0,10.909ZM2.666,5.782V11.15C2.666,15.862,7,20.73,12,21.821c5-1.091,9.334-5.959,9.334-10.671V5.782L12,2.377ZM9.327,16.138a1.049,1.049,0,0,1-1.075-1.024V8.464H7.713V7.44H10.4V6.926H13.63V7.44h2.689V8.464H15.78v6.65a1.049,1.049,0,0,1-1.075,1.024Zm0-1.024h5.378V8.464H9.327Zm3.228-1.024v-4.6H13.63v4.6Zm-2.153,0v-4.6H11.48v4.6Z" transform="translate(0 0)" fill="#8a8177"/>,
    block:  <path id="Union_4" data-name="Union 4" d="M0,10.909V4.365L12,0,24,4.365v6.544C24,16.964,18.878,22.625,12,24,5.118,22.625,0,16.964,0,10.909ZM2.668,5.782V11.15C2.668,15.862,7,20.73,12,21.821c5-1.091,9.334-5.959,9.334-10.671V5.782L12,2.377ZM11.776,12.6l-4.354,3.15-1.1-.794,4.357-3.15L6.322,8.655l1.1-.8,4.354,3.15,4.357-3.15,1.1.8L12.877,11.8l4.354,3.15-1.1.794Z" transform="translate(0 0)" fill="#8a8177"/>,
    masksWithRedirect:  <>
     <path d="M 20.056 20.381 L 12.466 12.791 L 6.876 18.381 L 11.466 18.381 L 11.466 20.381 L 3.466 20.381 L 3.466 12.381 L 5.466 12.381 L 5.466 16.971 L 12.466 9.971 L 21.466 18.971" transform="matrix(-1, 0, 0, -1, 24.931999, 30.351999)"/>
    <path d="M 3.52 8.864 C 2.554 8.496 1.786 7.635 1.56 6.517 L 0.82 2.923 C 0.713 2.425 1.02 1.938 1.502 1.832 L 5.856 0.879 L 5.869 0.875 C 6.347 0.778 6.814 1.095 6.913 1.584 L 7.069 2.352 L 9.008 2.78 L 9.021 2.78 C 9.489 2.891 9.793 3.378 9.694 3.867 L 8.955 7.465 C 8.597 9.2 6.94 10.314 5.254 9.941 C 4.55 9.789 3.953 9.398 3.52 8.864 L 3.52 8.864 M 8.82 3.678 L 4.465 2.72 L 3.725 6.319 L 3.725 6.333 C 3.472 7.567 4.243 8.782 5.442 9.044 C 6.64 9.306 7.826 8.514 8.081 7.276 L 8.82 3.678 M 7.037 7.507 C 6.757 7.999 6.195 8.27 5.625 8.146 C 5.059 8.022 4.653 7.538 4.586 6.972 L 7.037 7.507 M 3.681 2.292 L 1.689 2.734 L 2.429 6.329 L 2.433 6.342 C 2.5 6.669 2.634 6.963 2.816 7.217 C 2.772 6.862 2.78 6.494 2.857 6.126 L 3.048 5.205 C 2.848 5.168 2.674 5.053 2.58 4.888 C 2.607 4.607 2.83 4.358 3.138 4.285 C 3.174 4.285 3.205 4.285 3.249 4.285 L 3.597 2.531 C 3.615 2.444 3.641 2.366 3.681 2.292 M 6.606 5.542 C 6.748 5.297 7.051 5.168 7.359 5.238 C 7.666 5.302 7.889 5.546 7.929 5.831 C 7.782 6.071 7.483 6.2 7.172 6.126 C 6.863 6.066 6.64 5.823 6.606 5.542 M 4.43 5.063 C 4.573 4.818 4.872 4.69 5.179 4.759 C 5.477 4.824 5.714 5.072 5.749 5.353 C 5.602 5.592 5.303 5.725 4.996 5.665 C 4.688 5.588 4.465 5.344 4.43 5.063 M 5.241 1.952 L 6.115 2.145 L 6.043 1.776 L 5.241 1.952 Z"/>
    </>,
    loopback:  <g id="Group_825" data-name="Group 825" transform="translate(-663 -600)">
    <path id="Path_617" data-name="Path 617" d="M3,16l9-9,7,7V9.41h2v8H13v-2h4.59L12,9.82,4.41,17.41Z" transform="translate(660 600)" fill="#8a8177"/>
    <g id="Ellipse_25" data-name="Ellipse 25" transform="translate(681 618)" strokeWidth="1">
      <circle cx="1" cy="1" r="1" stroke="none"/>
      <circle cx="1" cy="1" r="0.5" fill="none"/>
    </g>
  </g>

};



/*eslint-enable */

const importedIcons = {
    'add': mdiPlus,
    'account': mdiAccount,
    'account-alert-outline': mdiAccountAlertOutline,
    'account-arrow-left-outline': mdiAccountArrowLeftOutline,
    'account-arrow-right-outline': mdiAccountArrowRightOutline,
    'account-edit-outline': mdiAccountEditOutline,
    'account-key-outline': mdiAccountKeyOutline,
    'account-multiple': mdiAccountMultiple,
    'account-multiple-outline': mdiAccountMultipleOutline,
    'account-network-outline': mdiAccountNetworkOutline,
    'account-outline': mdiAccountOutline,
    'account-plus-outline': mdiAccountPlusOutline,
    'account-remove-outline': mdiAccountRemoveOutline,
    'active': mdiCircle,
    'alpha-n-box-outline': mdiAlphaNBoxOutline,
    'alpha-r-box-outline': mdiAlphaRBoxOutline,
    'alert': mdiAlert,
    'alert-circle': mdiAlertCircle,
    'alert-circle-outline': mdiAlertCircleOutline,
    'arrow-collapse': mdiArrowCollapse,
    'arrow-collapse-up': mdiArrowCollapseUp,
    'arrow-collapse-down': mdiArrowCollapseDown,
    'arrow-collapse-left': mdiArrowCollapseLeft,
    'arrow-up-bold-outline': mdiArrowUpBoldOutline,
    'arrow-up': mdiArrowUp,
    'arrow-down': mdiArrowDown,
    'arrow-down-bold-outline': mdiArrowDownBoldOutline,
    'arrow-down-bold-circle-outline': mdiArrowDownBoldCircleOutline,
    'arrow-expand': mdiArrowExpand,
    'arrow-expand-vertical': mdiArrowExpandVertical,
    'arrow-collapse-vertical': mdiArrowCollapseVertical,
    'arrow-right': mdiArrowRight,
    'arrow-left': mdiArrowLeft,
    'autorenew': mdiAutorenew,
    'auto-fix': mdiAutoFix,
    'book-search-outline': mdiBookSearchOutline,
    'bank-transfer-in': mdiBankTransferIn,
    'bank-transfer-out': mdiBankTransferOut,
    'bridge': mdiBridge,
    'call-missed': mdiCallMissed,
    'calendar-today': mdiCalendarToday,
    'car-brake-alert': mdiCarBrakeAlert,
    'chart-areaspline': mdiChartAreaspline,
    'chart-bar': mdiChartBar,
    'chart-line-variant': mdiChartLineVariant,
    'check': mdiCheck,
    'checkbox-blank-circle': mdiCheckboxBlankCircle,
    'check-circle-outline': mdiCheckCircleOutline,
    'checkbox-marked-circle': mdiCheckboxMarkedCircle,
    'chevron-right-box-outline': mdiChevronRightBoxOutline,
    'chevron-left-box-outline': mdiChevronLeftBoxOutline,
    'chevron-down': mdiChevronDown,
    'chevron-up': mdiChevronUp,
    'chevron-right': mdiChevronRight,
    'chevron-left': mdiChevronLeft,
    'circle': mdiCircle,
    'circle-outline': mdiCircleOutline,
    'circle-outline-off': mdiCircleOffOutline,
    'clock-outline': mdiClockOutline,
    'close': mdiClose,
    'close-circle': mdiCloseCircle,
    'close-circle-outline': mdiCloseCircleOutline,
    'close-octagon-outline': mdiCloseOctagonOutline,
    'close-outline': mdiCloseOutline,
    'cog-sync-outline': mdiCogSyncOutline,
    'connection': mdiConnection,
    'content-copy': mdiContentCopy,
    'content-duplicate': mdiContentDuplicate,
    'crop-squere': mdiCropSquare,
    'content-save-cog-outline': mdiContentSaveCogOutline,
    'crown': mdiCrown,
    'change': mdiPencilOutline,
    'delete': mdiMinus,
    'database-edit': mdiDatabaseEdit,
    'download': mdiDownload,
    'dots-horizontal': mdiDotsHorizontal,
    'drag': mdiDrag,
    'drama-masks': mdiDramaMasks,
    'delete-restore': mdiDeleteRestore,
    'dns': mdiDnsOutline,
    'desktop-classic': mdiDesktopClassic,
    'edit': mdiSquareEditOutline,
    'expansion-card': mdiExpansionCard,
    'expansion-card-variant': mdiExpansionCardVariant,
    'ethernet': mdiEthernet,
    'ethernet-cable-off': mdiEthernetCableOff,
    'alert-outline': mdiAlertOutline,
    'alert-rhombus-outline': mdiAlertRhombusOutline,
    'eye-off-outline': mdiEyeOffOutline,
    'eye-outline': mdiEyeOutline,
    'exclamation': mdiExclamation,
    'debug-step-into': mdiDebugStepInto,
    'debug-step-out': mdiDebugStepOut,
    'file': mdiFile,
    'file-upload-outline': mdiFileUploadOutline,
    'file-cog': mdiFileCog,
    'file-alert-outline': mdiFileAlertOutline,
    'filterPlus': mdiFilterPlus,
    'folder-plus-outline': mdiFolderPlusOutline,
    'format-line-spacing': mdiFormatLineSpacing,
    'format-color-fill': mdiFormatColorFill,
    'format-letter-case': mdiFormatLetterCase,
    'format-letter-matches': mdiFormatLetterMatches,
    'help': mdiHelp,
    'home': mdiHome,
    'human-greeting-proximity': mdiHumanGreetingProximity,
    'information': mdiInformation,
    'information-outline': mdiInformationOutline,
    'information-variant': mdiInformationVariant,
    'import': mdiImport,
    'state': mdiSleep,
    'inactive': mdiSleep,
    'key-remove': mdiKeyRemove,
    'lan': mdiLan,
    'lan-connect': mdiLanConnect,
    'lan-disconnect': mdiLanDisconnect,
    'leaf': mdiLeaf,
    'lifebuoy': mdiLifebuoy,
    'loading': mdiLoading,
    'logout': mdiLogout,
    'lock-reset': mdiLockReset,
    'lock-outline': mdiLockOutline,
    'lock-open-outline': mdiLockOpenOutline,
    'math-log': mdiMathLog,
    'magnify': mdiMagnify,
    'magnify-minus-outline': mdiMagnifyMinusOutline,
    'magnify-plus-outline': mdiMagnifyPlusOutline,
    'menu': mdiMenu,
    'merge': mdiMerge,
    'message-processing-outline': mdiMessageProcessingOutline,
    'minus': mdiMinus,
    'map-marker-up': mdiMapMarkerUp,
    'not-equal': mdiNotEqual,
    'package-down': mdiPackageDown,
    'package-up': mdiPackageUp,
    'pause': mdiPause,
    'pause-octagon-outline': mdiPauseOctagonOutline,
    'pencil-outline': mdiPencilOutline,
    'pin': mdiPin,
    'play': mdiPlay,
    'playlist-check': mdiPlaylistCheck,
    'playlist-plus': mdiPlaylistPlus,
    'plus': mdiPlus,
    'plus-circle-outline': mdiPlusCircleOutline,
    'power': mdiPower,
    'progress-alert': mdiProgressAlert,
    'recycle': mdiRecycle,
    'reorder': mdiSwapVertical,
    'reload': mdiReload,
    'repeat': mdiRepeat,
    'restore': mdiRestore,
    'table-icon': mdiTable,
    'tab-plus': mdiTabPlus,
    'test-tube': mdiTestTube,
    'text': mdiText,
    'text-short': mdiTextShort,
    'thumb-up-outline': mdiThumbUpOutline,
    'thumb-down-outline': mdiThumbDownOutline,
    'timer-off-outline': mdiTimerOffOutline,
    'trash-can-outline': mdiTrashCanOutline,
    'undo': mdiUndo,
    'upload': mdiUpload,
    'send-circle-outline': mdiSendCircleOutline,
    'server-network': mdiServerNetwork,
    'server-remove': mdiServerRemove,
    'settings-outline': mdiCogOutline,
    'server': mdiServer,
    'sleep': mdiSleep,
    'source-branch': mdiSourceBranch,
    'shape-square-plus': mdiShapeSquarePlus,
    'shield-account': mdiShieldAccount,
    'shield-check-outline': mdiShieldCheckOutline,
    'shield-lock': mdiShieldLock,
    'shield-off-outline': mdiShieldOffOutline,
    'shield-plus': mdiShieldPlus,
    'shield-plus-outline': mdiShieldPlusOutline,
    'speedometer': mdiSpeedometer,
    'speedometerMedium': mdiSpeedometerMedium,
    'speedometerSlow': mdiSpeedometerSlow,
    'swap-vertical': mdiSwapVertical,
    'sync-alert': mdiSyncAlert,
    'qrcode': mdiQrcode,
    'vpn': mdiVpn,
    'web': mdiWeb,
    'wrench-outline': mdiWrenchOutline,
    'cancel': mdiCancel,
};


export type IconMdbType = typeof importedIcons;
export type CustomThingsType = typeof customThings;


export type IconMyIconType = typeof myIcons;

export type IconNameType = keyof IconMdbType | keyof IconMyIconType | keyof CustomThingsType;


export type IconSize = 'sxx' | 'sx' | 'nav' | 'sm' | 'md' | 'lg' | 'xl';
export type IconType = {
    className?: string,
    size?: IconSize,
    name: IconNameType,
    color?: string,
    width?: number,
    height?: number,
    onClick?: (event: React.MouseEvent<SVGSVGElement>) => void,
    loading?: boolean,
    datacy?: string,
    dontBlur?: boolean,
}


const Icon = ({ className, size = 'md', name, color, width, height, onClick, loading, datacy,
    dontBlur, ...rest }: IconType) => {
    assert(name);
    const onClickIcon: MouseEventHandler<SVGSVGElement> = useCallback((event)  => {
        if (document.activeElement instanceof HTMLElement && !dontBlur) {
            document.activeElement.blur();
        }
        if (onClick) {
            onClick(event);
        }
    }, [ onClick, dontBlur ]);
    switch (size) {
    case 'sxx':
        height = 12;
        width = 12;
        break;
    case 'sx':
        height = 16;
        width = 16;
        break;
    case 'nav':
        height = 20;
        width = 20;
        break;
    case 'sm':
        height = 24;
        width = 24;
        break;
    case 'md':
        height = 32;
        width = 32;
        break;
    case 'lg':
        height = 40;
        width = 40;
        break;
    case 'xl':
        height = 64;
        width = 128;
        break;
    default:
    }
    if (myIcons[name]) {
        return (
            <svg
                className={classnames('icon', className, name)}
                height={height}
                onClick={onClickIcon}
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
                width={width}
                {...testPropsPassthrough(rest)}
            >
                {myIcons[name]}
            </svg>
        );
    }
    if (customThings[name]) {
        return customThings[name] as React.JSX.Element;
    }
    const data = name && importedIcons[loading ? 'loading' : name];
    return (
        <svg
            className={classnames('icon', className, name,
                { 'icon--clicable': onClick },
                { 'loading': loading })}
            data-cy={datacy || name}
            height={height}
            onClick={onClickIcon}
            preserveAspectRatio="xMidYMid meet"
            type="icon"
            viewBox="0 0 24 24"
            width={width}
            {...testPropsPassthrough(rest)}
        >

            <path
                d={data}
                fill={color}
                style={{ color: color }}
            />

        </svg>
    );
};


const customThings = {
    'log': (
        <div className={classnames(
        )}
        >
            {LOG}
        </div>),
    'log-lined-red': (
        <div className={classnames(
            'text--line',
            'text--red',
        )}
        >
            {LOG}
        </div>),
    'accordingToCategory': (
        <div className="d-flex">
            <Icon
                className="icon--primary"
                dontBlur
                name="checkbox-marked-circle"
                size="sx"
            />
            <Icon
                className="icon--yellow"
                dontBlur
                name="alert-circle"
                size="sx"
            />
            <Icon
                className="icon--red"
                dontBlur
                name="close-circle"
                size="sx"
            />
        </div>
    ),
    'accordingToNewCategory': (
        <div className="d-flex">
            <Icon
                className="icon--primary"
                dontBlur
                name="checkbox-marked-circle"
                size="sx"
            />
            <Icon
                className="icon--red"
                dontBlur
                name="close-circle"
                size="sx"
            />
        </div>
    )
};

export default Icon;
